import React, { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { styleButtonSearch } from '../../../css/globlalStyle'
const Deadline = () => {
	const { state, onChange,
		onBlurValidMinMax,
	} = useContext(formContext)
	const { contract_Attachment } = useContext(requestContext)
	const [edit, setEdit] = useState(false)
	const {
		praz_dias,
		praz_ane_inf,
		serv_sub_cat_desc,
		ane_desc_infla,
		praz_cod,
	} = state
	useEffect(() => {
		setEdit(praz_cod)
	}, [praz_cod])
	return (
		<div className='row'>
			<div className="form-group col-12">
				<label>Serviço selecionado:</label>
				<div className="input-group">
					<input type="text"
						disabled
						className="form-control form-control-border"
						name='serv_sub_cat_desc' value={serv_sub_cat_desc}
						placeholder='Selecione um serviço' />
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-12">
				<label>Prazo em dias:</label>
				<div className="input-group">
					<input type="number" onChange={onChange}
						className={`form-control form-control-border ${!praz_dias && 'is-invalid'}`}
						name='praz_dias' value={praz_dias} onBlur={onBlurValidMinMax}
						min={0} max={31}
						placeholder='Prazo em dias inteiros' />
				</div>
			</div>
			{!edit ?
				<div className="form-group col-md-6 col-sm-12">
					<label>Prazo em qual anexo:</label>
					<div className="input-group">
						<select className={`form-control form-control-border ${!praz_ane_inf && 'is-invalid'}`}
							name='praz_ane_inf' value={praz_ane_inf} onChange={onChange}
						>
							<option>Selecionar</option>
							{contract_Attachment?.total > 0 && (
								contract_Attachment.data.map(item => (
									<option key={item.ane_cod} value={item.ane_cod}>{item.ane_desc_infla} - {item.ane_clausula_infla}</option>
								)))}
						</select>
						{praz_cod && (<div className="input-group-prepend" onClick={() => setEdit(!edit)}>
							<span className="input-group-text bg-danger"
								style={styleButtonSearch}
							>
								<i className="fa fa-edit"></i>
							</span>
						</div>)}
					</div>
				</div>
				:
				<div className="form-group col-md-6 col-sm-12">
					<label>Prazo em qual anexo:</label>
					<div className="input-group">
						<div className='form-control form-control-border'>
							{ane_desc_infla}
						</div>
						<div className="input-group-prepend" onClick={() => setEdit(!edit)}>
							<span className="input-group-text bg-danger"
								style={styleButtonSearch}
							>
								<i className="fa fa-edit"></i>
							</span>
						</div>
					</div>
				</div>
			}
		</div>
	)
}
export default Deadline