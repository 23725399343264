import React, { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { styleButtonSearch } from '../../../css/globlalStyle'
const Selectservice = ({ label, constructionPlan }) => {
	const { onChangeSelectInput, state, handleForm } = useContext(formContext)
	const { service, setServicecat, setServiceSubcat } = useContext(requestContext)
	const { serv_cod, serv_desc } = state
	const [items, setItems] = useState(null)
	useEffect(() => {
		const loadItems = () => {
			if (!Array.isArray(service?.data)) {
				return
			}
			const { data: data_ } = service
			if (constructionPlan) {
				return setItems(data_.filter(item => item.serv_valor))
			}
			return setItems(data_.filter(item => !item.serv_valor))
		}
		loadItems()
	}, [service, constructionPlan])
	useEffect(() => {
		const setValueServiceCatDesc = () => {
			if (serv_desc) {
				handleForm({
					serv_cat_desc: serv_desc,
				})
			}
		}
		setValueServiceCatDesc()
	}, [serv_desc])
	const handleCancel = () => {
		setServicecat(null)
		setServiceSubcat(null)
		handleForm({
			praz_dias: '',
			serv_cat_prazo: '',
			serv_desc: '',
			serv_cod: '',
			serv_cat_cod: '',
			serv_sub_cat_cod: '',
			serv_cat_desc: '',
			serv_sub_cat_desc: '',
			pv_servico: '',
		})
	}
	return (
		<div className="form-group">
			<label>{label || 'Serviço:'}</label>
			<div className="input-group">
				<select
					onChange={onChangeSelectInput}
					className={`form-control form-control-border ${!serv_cod && 'is-invalid'
					}`}
				>
					<option value={JSON.stringify({ 'serv_cod': 0 })}>Selecionar Serviço</option>
					{Array.isArray(items) &&
						items.map((item) => (
							<option key={item.serv_cod} value={JSON.stringify(item)}>
								{item.serv_desc}
							</option>
						))}
				</select>
				{serv_desc && (
					<div className="input-group-prepend" onClick={handleCancel}>
						<span
							className="input-group-text bg-danger"
							style={styleButtonSearch}
						>
							<i className="fa fa-times-circle"></i>
						</span>
					</div>
				)}
			</div>
		</div>
	)
}
export default Selectservice
