import React, { useContext, useState } from 'react'
import { requestContext } from '../../../context/requestContext'
import { handleWaring, isValid } from '../../../services/isValid'
import { filterAndRemove } from '../../../services/arrayHandless'
import { formContext } from '../../../context/formContext'
const ListHighwayDirections = ({ item, edit_highway }) => {
	const { cardinal_points, postItemCardinalPoints, setInfoRequest, deleteItemCardinalPoints } = useContext(requestContext)
	const { state, handleForm } = useContext(formContext)
	const { rod_sent_rodovia } = state
	const [edit, setEdit] = useState(false)
	const [values, setState] = useState({
		rod_sent_cod: '',
		rod_sent_desc: '',
		rod_sent_rodovia: ''
	})
	const handleEdit = (item) => {
		setEdit(true)
		setState(item)
	}
	if (!cardinal_points?.total > 0) {
		return <div></div>
	}
	const handleSubmit = () => {
		const valid = isValid(values)
		if (!valid) {
			return setInfoRequest(handleWaring())
		}
		postItemCardinalPoints({
			id: values.rod_sent_cod,
			...values,
		})
	}
	const handleCancel = () => {
		setState({
			rod_sent_cod: '',
			rod_sent_desc: '',
			rod_sent_rodovia: ''
		})
		setEdit(false)
	}
	const handleDelete = (item) => {
		if (edit_highway) {
			return handleRemove(item)
		}
		const confirm = window.confirm(`Você tem certeza que deseja remover o sentido ${item.rod_sent_desc} dessa rodovia?`)
		if (confirm) {
			deleteItemCardinalPoints(item)
		}
	}
	const handleRemove = (item) => {
		if (!rod_sent_rodovia?.lenght) {
			return handleForm({
				rod_sent_rodovia: null,
			})
		}
		const { data } = filterAndRemove(rod_sent_rodovia, 'id', item.id)
		handleForm({
			rod_sent_rodovia: data
		})
	}
	return (
		<div className="row p-3 w-100">
			<div className="col-md-8">
				{item.rod_sent_desc}
			</div>
			{edit ? (
				<div className="col-md-4">
					<div className="btn btn-danger btn-sm mr-3" onClick={handleSubmit}>
						<i className="fa fa-save"></i>
					</div>
					<div className="btn btn-secondary btn-sm" onClick={handleCancel}>
						<i className="fa fa-times-circle"></i>
					</div>
				</div>
			) : (
				<div className="col-md-4">
					{!edit_highway && (
						<div className="btn btn-danger btn-sm mr-3" onClick={() => handleEdit(item)}>
							<i className="fa fa-edit"></i>
						</div>
					)}
					<div className="btn btn-secondary btn-sm" onClick={() => handleDelete(item)}>
						<i className="fa fa-trash"></i>
					</div>
				</div>
			)}
		</div>
	)
}
export default ListHighwayDirections