import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ContentHeader from '../../../components/contentHeader'
import Dealership from '../../../components/form/dealership'
import Highway from '../../../components/form/highway'
import Selectservice from '../../../components/form/selectServices'
import ImageItem from '../../../components/list/img'
import Spinner from '../../../components/spinner'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { handleWaring, isValid } from '../../../services/isValid'
import { find } from '../../../services/arrayHandless'
const OverSightNew = () => {
	const {
		bases64,
		setShow,
		state,
		setShowCamera,
		setBases64,
		handleForm } = useContext(formContext)
	const {
		setHighway,
		setInfoRequest,
		postItemOversight,
		getAllItemDeadline,
		deadline,
		setDeadline,
		getAllItemDealershipLot,
		getAllItemHighway,
	} = useContext(requestContext)
	const [load, setLoad] = useState(false)
	const navigate = useNavigate()
	const {
		conc_cod,
		rod_cod,
		pv_servico,
		lot_conc_cod,
		serv_sub_cat_cod } = state
	useEffect(() => {
		resetValues()
	}, [])
	useEffect(() => {
		if (conc_cod) {
			getAllItemHighway(conc_cod)
		} else {
			getAllItemHighway()
		}
	}, [conc_cod])
	useEffect(() => {
		setShow(true)
		getAllItemDealershipLot()
	}, [])
	useEffect(() => {
		getAllItemDeadline(lot_conc_cod, serv_sub_cat_cod)
	}, [serv_sub_cat_cod, lot_conc_cod])
	useEffect(() => {
		if (deadline?.total > 0 && serv_sub_cat_cod && lot_conc_cod) {
			try {
				const { data: deadline_data } = deadline
				const { data: findDeadline } = find(deadline_data, 'serv_sub_cat_cod', serv_sub_cat_cod)
				if (findDeadline) {
					handleForm({
						...findDeadline,
					})
				}
			} catch (error) {
				setInfoRequest(handleWaring(String(error?.error)))
			}
		}
	}, [serv_sub_cat_cod, lot_conc_cod, deadline])
	const handleCancel = () => {
		resetValues()
		navigate('/oversight')
	}
	const resetValues = () => {
		handleForm({
			conc_nome: '',
			conc_cod: '',
			rod_cod: '',
			rod_sent_cod: '',
			rod_km: '',
			rod_km_ini: '',
			rod_km_fim: '',
			rod_km_ini_mt: '',
			rod_km_fim_mt: '',
			os_obs: '',
			pv_servico: '',
			praz_dias: '',
			serv_cod: '',
			serv_cat_cod: '',
			serv_sub_cat_cod: '',
			lot_conc_cod: '',
			serv_desc: '',
			serv_cat_desc: '',
			serv_sub_cat_desc: '',
			serv_cat_prazo: '',
			os_obs_prazo: '',
		})
		setHighway(null)
		setBases64(null)
		setDeadline(null)
	}
	const onSubmit = async (e) => {
		window.scrollTo(0, 0)
		e.preventDefault()
		e.stopPropagation()
		setShowCamera(false)
		if (!bases64) {
			return setInfoRequest(
				handleWaring('Você precisa tirar pelo menos uma foto do local')
			)
		}
		if (!Array.isArray(pv_servico)) {
			return setInfoRequest(
				handleWaring(
					'Você precisa selecionar pelo menos um serviço, categoria e Atividade'
				)
			)
		}

		const items = {
			rod_cod,
			conc_cod,
			pv_servico,
		}
		if (!isValid(items)) {
			return setInfoRequest(handleWaring())
		}
		setLoad(true)
		postItemOversight({
			...items,
		})
	}
	return (
		<>
			<ContentHeader title={'Abertura de ocorrência'} />
			<div className="content">
				<Spinner show={load} />
				<form onSubmit={onSubmit}>
					<div className="card card-danger">
						<div className="card-header">
							<h3 className="card-title">Concessionária</h3>
							<div className="card-tools">
								<button
									type="button"
									className="btn btn-tool"
									data-card-widget="collapse"
								>
									<i className="fas fa-minus"></i>
								</button>
								<button
									type="button"
									className="btn btn-tool"
									data-toggle="modal"
									data-target="#concessionaria"
								>
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
						<div className="card-body">
							<Dealership />
						</div>
					</div>
					<div className="card card-danger">
						<div className="card-header">
							<h3 className="card-title">Rodovia</h3>
							<div className="card-tools">
								<button
									type="button"
									className="btn btn-tool"
									data-card-widget="collapse"
								>
									<i className="fas fa-minus"></i>
								</button>
								<button type="button" className="btn btn-tool">
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
						<div className="card-body">
							<Highway />
						</div>
					</div>
					<div className="card card-danger">
						<div className="card-header">
							<h3 className="card-title">Serviço</h3>
							<div className="card-tools">
								<button
									type="button"
									className="btn btn-tool"
									data-card-widget="collapse"
								>
									<i className="fas fa-minus"></i>
								</button>
								<button type="button" className="btn btn-tool">
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
						<div className="card-body">
							<Selectservice />
						</div>
					</div>
					<div className="card card-danger">
						<div className="card-header">
							<h3 className="card-title">Fotos das atividades</h3>
							<div className="card-tools">
								<button
									type="button"
									className="btn btn-tool"
									data-card-widget="collapse"
								>
									<i className="fas fa-minus"></i>
								</button>
								<button type="button" className="btn btn-tool">
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
						<div className="card-body">
							{Array.isArray(bases64) && (
								<div
									className="row"
									id="imagems"
								>
									{bases64.map((item) => (
										<div key={item} className="col-md-4 col-sm-12">
											<ImageItem
												legend={item.pvs_loc_foto_legenda}
												src={item.base64}
												data={bases64} />
										</div>
									))}
								</div>
							)}
						</div>
					</div>
					<div className="card">
						<div className="card-body">
							<button className="btn btn-danger mr-3">
								Salvar <i className="fa fa-save"></i>
							</button>
							<div className="btn btn-secondary" onClick={handleCancel}>
								Cancelar <i className="fa fa-times-circle"></i>
							</div>
						</div>
					</div>
				</form >
			</div >
		</>
	)
}
export default OverSightNew
