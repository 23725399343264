import React from 'react'
import AuthContext from './context/authContext'
import RequestContext from './context/requestContext'
import Router from './router'
import './css/fontawesome-free/css/all.min.css'
import 'admin-lte/dist/css/adminlte.css'
import 'admin-lte/dist/js/adminlte'
import './css/globlalStyle/index.css'
import FormContext from './context/formContext'

import { BrowserRouter } from 'react-router-dom'
const App = () => (
	<BrowserRouter>
		<RequestContext>
			<AuthContext>
				<FormContext>
					<Router />
				</FormContext>
			</AuthContext>
		</RequestContext>
	</BrowserRouter>
)
export default App