/* eslint-disable eqeqeq */
var a = ['', 'um', 'duas', 'três', 'quatro', 'cinco', 'seis', 'sete', 'oito', 'nove', 'dez', 'onze', 'doze', 'treze', 'quatorze', 'quinze', 'dezesseis', 'dezessete', 'dezoito', 'dezenove']
var b = ['', '', 'vinte', 'trinta', 'quarenta', 'cinquenta', 'sessenta', 'setenta', 'oitenta', 'noventa']

function numberInWords(num) {
	if ((num = num.toString()).length > 9) return 'transbordar'
	var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/)
	if (!n) return; var str = ''
	str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : ''
	str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : ''
	str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'mil ' : ''
	str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'centenas' : ''
	str += (n[5] != 0) ? ((str != '') ? 'e ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + '' : ''
	return str
}
/**
 * Adds a dot every three digits to a number.
 *
 * @param {number} number - The number to be formatted.
 * @returns {string} A string containing the number formatted with dots every three digits.
 * @throws {TypeError} If the argument is not a number.
 * @example
 * const number = 1234567890;
 * const formattedNumber = addDotToEveryThreeDigits(number);
 * console.log(formattedNumber); // Output: "1,234,567,890"
 */
const addPoints = (number) => {
	// Converte o número para uma string
	const numeroString = String(number)
	// Divides the string into groups of three digits, starting from the end
	const partes = []
	let temp = ''
	for (let i = numeroString.length - 1; i >= 0; i--) {
		temp = numeroString[i] + temp
		if (temp.length === 3 || i === 0) {
			partes.unshift(temp)
			temp = ''
		}
	}

	// Junta os grupos com pontos
	const resultado = partes.join('.')

	return resultado
}

export { numberInWords, addPoints }