import React, { useContext } from 'react'
import { formContext } from '../../../context/formContext'
import { filterAndRemove } from '../../../services/arrayHandless'
const ListNewServiceOversight = () => {
	const { state: { pv_servico }, handleForm } = useContext(formContext)
	const handleDelete = (item) => {
		const { serv_sub_cat_desc, pvs_servico_sub_cat } = item
		const confirm = window.confirm(`Você deseja mesmo remover ${serv_sub_cat_desc} da lista das atividades ?`)
		if (!confirm) {
			return
		}
		const { data: filterAndRemoveItem } = filterAndRemove(pv_servico, 'pvs_servico_sub_cat', pvs_servico_sub_cat)
		if (filterAndRemoveItem?.total === 0) {
			return handleForm({
				pv_servico: null,
			})
		}
		handleForm({
			pv_servico: filterAndRemoveItem
		})
	}
	if (!Array.isArray(pv_servico)) {
		return (
			<div className="row">
				<p className="text-secondary">As atividades adicionadas apareceram aqui</p>
			</div>
		)
	}
	return (
		pv_servico.map(item => (
			<div className="row" key={item.pvs_servico_sub_cat}>
				<div className="col-md-10 col-sm-12">
					<div className="row">
						<div className="col-md-4 col-sm-12">
							<span className="text-secondary">Tipo atividade: </span>
							{item.serv_desc}
						</div>
						<div className="col-md-4 col-sm-12">
							<span className="text-secondary">Grupo atividade: </span>
							{item.serv_cat_desc}
						</div>
						<div className="col-md-4 col-sm-12">
							<span className="text-secondary">Grupo atividade: </span>
							{item.serv_sub_cat_desc}
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<p className="text-secondary">Localização:</p>
						</div>
						<div className="col-md-3 col-sm-6">
                            Km Inicial: {item.pedido_venda_local.pv_loc_km_ini}, {item.pedido_venda_local.pv_loc_km_init_mts} mts.
						</div>
						<div className="col-md-3 col-sm-6">
                            Km Final: {item.pedido_venda_local.pv_loc_km_fim}, {item.pedido_venda_local.pv_loc_km_fim_mts} mts.
						</div>
						<div className="col-md-3 col-sm-6">
							<span className="text-secondary">Sentido: </span>
							{item.pedido_venda_local.rod_sent_desc}
						</div>
						<div className="col-md-3 col-sm-12">
							<span className="text-secondary">Observações: </span>
							{item.pedido_venda_local.pv_loc_obs}
						</div>
					</div>
				</div>
				<div className="col-md-1 col-sm-12">
					<div className="btn btn-secondary" onClick={() => handleDelete(item)}>
						<i className="fa fa-trash"></i>
					</div>
				</div>
				<hr className="col-12 w-100"></hr>
			</div>
		))
	)
}
export default ListNewServiceOversight