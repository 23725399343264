import React, { useContext } from 'react'
import ContentHeader from '../../../components/contentHeader'
import Spinner from '../../../components/spinner'
import { handleWaring, isValid } from '../../../services/isValid'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { useNavigate } from 'react-router-dom'
import FormComplements from '../../../components/form/complements'
const NewComplements = () => {
	const { state, handleForm } = useContext(formContext)
	const { setInfoRequest, postItemComplements } = useContext(requestContext)
	const { comp_descricao, comp_ativo, comp_cod } = state
	const navigate = useNavigate()
	const onSubmit = (e) => {
		e.preventDefault()
		e.stopPropagation()
		const item = {
			comp_descricao,
			comp_ativo
		}
		const validate = isValid(item)
		if (!validate) {
			return setInfoRequest(handleWaring())
		}
		postItemComplements({
			id: comp_cod,
			comp_ativo,
			comp_descricao
		})
	}
	const handleCancel = () => {
		handleForm({
			comp_descricao: ''
		})
		navigate('/complements')
	}
	return (
		<>
			<ContentHeader title={'Cadastro de Complemento'} />
			<div className='content'>
				<Spinner />
				<div className="card card-danger">
					<div className="card-header">
						<h3 className="card-title">Informações sobre o complemento</h3>
						<div className="card-tools">
							<button
								type="button"
								className="btn btn-tool"
								data-card-widget="collapse"
							>
								<i className="fas fa-minus"></i>
							</button>
							<button
								type="button"
								className="btn btn-tool"
								data-toggle="modal"
								data-target="#concessionaria"
							>
								<i className="fa fa-question-circle"></i>
							</button>
						</div>
					</div>
					<form onSubmit={onSubmit}>
						<div className="card-body">
							<FormComplements />
						</div>
						<div className="card-body">
							<button type="submit" className="btn btn-danger mr-3">
                                Salvar <i className="fa fa-save"></i>
							</button>
							<div className="btn btn-secondary" onClick={handleCancel}>
                                Cancelar <i className="fa fa-times-circle"></i>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}
export default NewComplements