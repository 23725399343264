import React, { useRef } from 'react'
import { modalShade, modalBody, modalHeaderTitle, modalHeader, modalClose } from './style'
const SimpleModal = ({visible, setVisible, properties }) => {
	const modalRef = useRef(null)
	const {modalWidth, modalHeight, modalTitle, modalChild} = properties
	return (
		<>
			{visible ? (
				<div
					style={modalShade}
					onClick={(e) => {
						if (modalRef.current.contains(e.target)) {
							return
						}
						setVisible(false)
					}}
				>
					<div style={modalBody(modalWidth?modalWidth:50, modalHeight?modalHeight:50)} ref={modalRef}>
						<div style={modalHeader}>
							<p style={modalHeaderTitle}>{modalTitle?modalTitle:'Modal'}</p>
							<button style={modalClose} onClick={() => setVisible(false)}>x</button>
						</div>

						{modalChild}
            
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	)
}
export default SimpleModal