import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { requestContext } from '../../../context/requestContext'
import { formContext } from '../../../context/formContext'

const ListHighwayLimitPagination = ({ data }) => {
	const { getWhereItemHighwayLimit, getItemHighwayLimit } = useContext(requestContext)
	const { state: { gp_desc, gp_cod, conc_cod, rod_cod } } = useContext(formContext)
	const handlePage = (item) => {
		if (!item) {
			return
		}
		const { label } = item
		const { current_page, last_page } = data
		window.scrollTo(0, 0)
		if (label === 'pagination.previous') {
			return gp_desc ? getWhereItemHighwayLimit(current_page > 1 && `${gp_desc}?page=${current_page - 1}`) : getItemHighwayLimit({
				id: gp_cod,
				conc_cod,
				rod_cod: current_page > 1 && `${rod_cod}?page=${current_page - 1}`
			})
		}
		if (label === 'pagination.next') {
			return gp_desc ? getWhereItemHighwayLimit(current_page < last_page && `${gp_desc}?page=${current_page + 1}`) : getItemHighwayLimit({
				id: gp_cod,
				conc_cod,
				rod_cod: current_page < last_page && `${rod_cod}?page=${current_page + 1}`})
		}
		if (!label) {
			return gp_desc ? getWhereItemHighwayLimit(gp_desc) : getItemHighwayLimit({
				id: gp_cod,
				conc_cod,
				rod_cod
			})
		}
		if (gp_desc) {
			return getWhereItemHighwayLimit(`${gp_desc}?page=${label}`)
		}
		getItemHighwayLimit({
			id: gp_cod,
			conc_cod,
			rod_cod: `${rod_cod}?page=${label}`
		})
	}
	return (
		<ul className="pagination pagination-sm m-0 float-right">
			{Array.isArray(data?.links) &&
                data.links.map((item, i) => (
                	<li className="page-item" key={item.label}>
                		<Link
                			className={`page-link ${item.active && 'text-light bg-danger'
                			}`}
                			to="#"
                			onClick={() => handlePage(item)}
                		>
                			{item.label === 'pagination.previous' ? (
                				<i className="fa fa-angle-left"></i>
                			) : item.label === 'pagination.next' ? (
                				<i className="fa fa-angle-right"></i>
                			) : (
                				item.label
                			)}
                		</Link>
                	</li>
                ))}
		</ul>
	)
}
export default ListHighwayLimitPagination