import html2canvas from 'html2canvas'
import * as XLSX from 'xlsx'
import { generateDateTimeStampJS } from '../dateHandless'

export const generateImage = async (element) => {
	try {
		const canvas = await html2canvas(element)
		if (!canvas) {
			return null
		}
		return canvas.toDataURL('image/jpeg', 0.9)
	} catch (error) {
		return null
	}
}
export const downLoadFiles = async (url) => {
	try {
		window.open(url)
		return true
	} catch (error) {
		console.log(error)
		return false
	}
}
/**
 * Generates a base64 representation of the given file.
 * @param {File} file - The file to generate base64 representation for.
 * @returns {Promise<string>} - A promise that resolves with the base64 representation of the file.
 *                             If an error occurs during the process, the promise is rejected with the error.
 */
export const generateBse64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = (error) => reject(error)
	})
/**
 * Returns the file extension from a given filename.
 * @param {string} filename - The filename from which to extract the extension.
 * @returns {string} The file extension or an empty string if the filename is falsy.
 */
export const getExt = (filename) => {
	if (!filename) {
		return ''
	}
	if (isBase64(filename)) {
		const ext = filename.match(/data:(.*?);base64,/)[1]
		return String(ext).replace('image/', '')
	}
	return filename.split('.').pop()
}

/**
 * Exports an array to an Excel file.
 * @param {Array<Object>} dataArray - Array of objects to be exported to Excel.
 * @param {string} filename - Name of the Excel file to be generated (without the .xlsx extension).
 * @param {Array<string>} [header] - Optional. Array of strings representing the custom header for the Excel file.
 * @returns {void}
 */
export const exportToExcel = (dataArray, filename, header) => {
	if (!dataArray || !filename || !header) {
		return false
	}
	const temp_header = header.map(item => item.key)
	const ws = XLSX.utils.json_to_sheet(dataArray, { header: [...temp_header] })
	const wb = XLSX.utils.book_new()
	XLSX.utils.book_append_sheet(wb, ws, String(filename).slice(0, 5))
	XLSX.writeFile(wb, `${String(filename).slice(0, 5)}-${generateDateTimeStampJS()}.xlsx`)
	return true
}
/**
 * Reads an Excel file and converts it into an array of data.
 * @param {File} file - The Excel file to import.
 * @returns {Promise<Array<Array<any>>>} A promise that resolves to an array containing the data from the Excel file.
 */
export const importExcelToArray = (file) => {
	return new Promise((resolve, reject) => {
		const fileReader = new FileReader()

		fileReader.onload = (event) => {
			const data = event.target.result
			const workbook = XLSX.read(data, { type: 'binary', cellDates: true, dateNF: 'dd/mm/yyy' })
			const sheetName = workbook.SheetNames[0]
			const sheet = workbook.Sheets[sheetName]
			const arrayData = XLSX.utils.sheet_to_json(sheet, { header: 1 })

			resolve(arrayData)
		}

		fileReader.onerror = (error) => {
			reject(error)
		}

		fileReader.readAsBinaryString(file)
	})
}
/**
 * Converts a base64 string back to a file and initiates the download.
 * 
 * @param {string} base64String - The base64 encoded string.
 * @param {string} filename - The filename of the downloaded file.
 */
export function base64toFile(base64String, filename) {
	try {
		// Convert the base64 string to an array of bytes
		const byteCharacters = atob(base64String)
		const byteNumbers = new Array(byteCharacters.length)
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i)
		}
		const byteArray = new Uint8Array(byteNumbers)

		// Create a Blob object from the byte array
		const fileBlob = new Blob([byteArray], { type: 'application/octet-stream' })

		// Create a temporary URL for the Blob object
		const fileUrl = URL.createObjectURL(fileBlob)

		// Create a download link for the file
		const downloadLink = document.createElement('a')
		downloadLink.href = fileUrl
		downloadLink.download = filename

		// Add the link to the document and simulate click to initiate download
		document.body.appendChild(downloadLink)
		downloadLink.click()

		// Remove the link from the document
		document.body.removeChild(downloadLink)

	} catch (error) {
		console.log(error)
	}
}
/**
 * Formats the size in bytes into a human-readable string with appropriate units (bytes, KB, MB, GB).
 * 
 * @param {number} bytes - The size in bytes to be formatted.
 * @returns {string} The formatted size string with appropriate units.
 */
export function formatBytes(bytes) {
	if (bytes < 1024) {
		return bytes + ' bytes'
	} else if (bytes < 1024 * 1024) {
		return (bytes / 1024).toFixed(2) + ' KB'
	} else if (bytes < 1024 * 1024 * 1024) {
		return (bytes / (1024 * 1024)).toFixed(2) + ' MB'
	} else {
		return (bytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB'
	}
}
/**
* Check if a string is a valid base64-encoded string.
*
* This function checks whether the provided string is a valid base64-encoded string.
*
* @param {string} string The string to be checked.
* @return {boolean} True if the string is a valid base64-encoded string, false otherwise.
*/
export function isBase64(string) {
	try {
		if (!string) {
			return false
		}
		const file64 = string // your base64 encoded data
		const replace = file64.substring(0, file64.indexOf(',') + 1)
		let file = file64.replace(replace, '')
		file = file.replace(/ /g, '+')
		const result = btoa(atob(file)) === file
		return result
	} catch (error) {
		console.error(error)
		return false
	}
}
