import React, { useContext } from 'react'
import { formContext } from '../../../context/formContext'
const FormGroupsIndex = () => {
	const { state, onChange } = useContext(formContext)
	const { gp_ind_pav_desc } = state
	return (
		<div className="row">
			<div className="form-group col-md-12 col-sm-12">
				<label>Descrição do grupo de Indices:</label>
				<div className="input-group">
					<input type="text" onChange={onChange}
						className={`form-control form-control-border ${!gp_ind_pav_desc && 'is-invalid'}`}
						name='gp_ind_pav_desc' value={gp_ind_pav_desc}
						placeholder='Descrição do grupo de indices' />
				</div>
			</div>
		</div>
	)
}
export default FormGroupsIndex