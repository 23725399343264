import React, { useContext, useEffect, useState } from 'react'
import { requestContext } from '../../../../context/requestContext'
import { formContext } from '../../../../context/formContext'
import { useNavigate } from 'react-router-dom'
const ListWorkOfArtClass = ({ data }) => {
	const [items, setItems] = useState(null)
	const { deleteItemObArtClass } = useContext(requestContext)
	const { handleForm } = useContext(formContext)
	const navigate = useNavigate()
	useEffect(() => {
		if (data?.total > 0) {
			setItems(data.data)
		}
	}, [data])
	if (!Array.isArray(items)) {
		return <></>
	}
	const handleEdit = (item) => {
		handleForm(item)
		navigate('/work_of_art_type/new')
	}
	const handleDelete = (item) => {
		const { ob_art_class_desc, ob_art_class_cod } = item
		const confirm = window.confirm(`Você deseja mesmo excluir o classificado de obra de arte ${ob_art_class_desc} ?`)
		if (confirm) {
			deleteItemObArtClass(ob_art_class_cod)
		}
	}
	return (
		items.map(item => (
			<div className="row p-3" key={item.ob_art_class_cod}>
				<div className="col-md-6 col-sm-6">
					<span className="text-secondary mr-2">Descrição:</span>
					{item.ob_art_class_desc}
				</div>
				<div className="col-md-4 col-sm-6">
					<span className="text-secondary mr-2">Situação:</span>
					{item.ob_art_class_ativo === 1 ? 'Ativo' : 'Inativo'}

				</div>
				<div className="col-md-2 col-sm-6">
					<button
						className="btn btn-danger btn-sm mr-2"
						onClick={() => handleEdit(item)}
					>
						<i className="ml-1 fa fa-edit"></i>
					</button>
					<button
						className="btn btn-secondary btn-sm"
						onClick={() => handleDelete(item)}
					>
						<i className="fa fa-trash"></i>
					</button>
				</div>
			</div>
		))
	)
}
export default ListWorkOfArtClass