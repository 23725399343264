import React, { useContext, useEffect } from "react";
import SelectTypeContruction from "../../selectTypeContruction";
import SelectServiceConstructionPlan from "../../selectServiceConstructionPlan";
import { requestContext } from "../../../../context/requestContext";
const FormFilterServiceConstruction = ({ handleClick, handleClearFilters }) => {
    const { getAllItemTypeConstructions } = useContext(requestContext)
    useEffect(() => {
        getAllItemTypeConstructions()
    }, [])
    return (
        <div className="row">
            <div className="col-md-4 col-sm-6">
                <SelectTypeContruction />
            </div>
            <div className="col-md-8 col-sm-12">
                <SelectServiceConstructionPlan justFilter />
            </div>
            <div className='col-12'>
                <button className='btn btn-danger mr-2' onClick={handleClick}>
                    Aplicar filtro <i className="fa fa-filter"></i>
                </button>
                <button className='btn btn-dark' onClick={handleClearFilters}>
                    Limpar filtros <i className="fa fa-eraser"></i>
                </button>
            </div>
        </div>
    )
}
export default FormFilterServiceConstruction