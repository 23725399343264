import React, { useContext } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { authContext } from '../../../context/authContext'
const SearchDealership = ({ handleNew }) => {
	const { state, onChange } = useContext(formContext)
	const { getWhereItemDealership, getItemDealership } = useContext(requestContext)
	const { user } = useContext(authContext)
	const {
		conc_nome
	} = state
	const handleSearch = () => {
		if (!conc_nome) {
			return getItemDealership()
		}
		getWhereItemDealership(conc_nome)
	}
	const handleRefresh = () => {
		getItemDealership()
	}
	return (
		<div className="input-group input-group-sm">
			{user?.set_nivel <= 1 && (
				<div className="input-group-append">
					<button className="btn btn-tool btn-sm" onClick={handleNew}>
						Novo <i className="fa fa-plus"></i>
					</button>
				</div>
			)}
			<div className="input-group-append">
				<button className="btn btn-tool btn-sm" onClick={handleRefresh}>
					Atualizar lista
				</button>
			</div>
			<input type="text" className="form-control float-right"
				name="conc_nome" value={conc_nome} onChange={onChange}
				placeholder="Procurar por concessionaria" />
			<div className="input-group-append">
				<button className="btn btn-default" onClick={handleSearch}>
					<i className="fas fa-search"></i>
				</button>
			</div>
		</div >
	)
}
export default SearchDealership