import React, { useContext } from 'react'
import { formContext } from '../../../context/formContext'
import { filterAndRemove } from '../../../services/arrayHandless'
import { requestContext } from '../../../context/requestContext'

const ListTypeAttachments = ({ loadedItems, addedItems }) => {
	const { setBases64, bases64 } = useContext(formContext)
	const { deleteItemAttachmentsConstructionPlan } = useContext(requestContext)
	const handleRemoveAttath = (item) => {
		const { fileName, ane_pl_ob_cod, pl_ob_cod, ane_pl_ob_obs } = item
		if (fileName) {
			const confirm = window.confirm(`Você deseja mesmo remover o anexo ${fileName} da lista de anexos?`)
			if (!confirm) {
				return
			}
			const { data: filterAtthments } = filterAndRemove(bases64, 'fileName', fileName)
			if (!filterAtthments?.length) {
				return setBases64(null)
			}
			return setBases64(filterAtthments)
		}
		if (ane_pl_ob_cod && pl_ob_cod && ane_pl_ob_obs) {
			const confirm = window.confirm(`Você deseja mesmo remover o anexo ${ane_pl_ob_obs} da lista de anexos?`)
			if (!confirm) {
				return
			}
			return deleteItemAttachmentsConstructionPlan(pl_ob_cod)
		}
	}
	return (
		<>
			<div className="row">
				{Array.isArray(addedItems) &&
					addedItems.filter(
						(item) =>
							item.ext === 'jpeg' || item.ext === 'jpg' || item.ext === 'png'
					).length > 1 && (
					<div className="col-12 text-secondary mb-3">Imagens anexadas</div>
				)}
				{Array.isArray(addedItems) &&
					addedItems
						.filter(
							(item) =>
								item.ext === 'jpeg' || item.ext === 'jpg' || item.ext === 'png'
						)
						.map((item, i) => (
							<div className="col-12 mb-3" key={i}>
								<div className="row">
									<div className="col-md-3 col-sm-12">
										<img
											src={item.ane_pl_ob_path}
											style={{ maxWidth: 150, maxHeight: 150 }}
											alt={`attach_${i}`}
										/>
									</div>
									<div className="col-md-3 col-sm-6">
										<p className="text-secondary mr-3">Observações sobre o anexo:</p>
										{item.ane_pl_ob_obs}
									</div>
									<div className="col-md-3 col-sm-6">
										<p className="text-secondary mr-3">Nome do arquivo:</p>
										{item.fileName}
									</div>
									<div className="col-md-3 col-sm-6">
										<div className="btn btn-secondary" onClick={() => handleRemoveAttath(item)}>
											<i className="fa fa-trash"></i>
										</div>
									</div>
								</div>
							</div>
						))}
			</div>
			{Array.isArray(addedItems) &&
				addedItems.filter(
					(item) =>
						item.ext !== 'jpeg' && item.ext !== 'jpg' && item.ext !== 'png'
				).length > 0 && (
				<div className="text-secondary col-12">Outros anexos</div>
			)}
			{Array.isArray(addedItems) &&
				addedItems
					.filter(
						(item) =>
							item.ext !== 'jpeg' && item.ext !== 'jpg' && item.ext !== 'png'
					)
					.map((item, i) => (
						<div className="col-12 mb-3" key={i}>
							<div className="row">
								<div className="col-6">{item.fileName}</div>
								<div className="col-5">
									{
										loadedItems?.data?.find((el) => el.tip_ane_cod)
											.tip_ane_desc
									}
								</div>
								<div className="col-1">
									<div className="btn btn-secondary" onClick={() => handleRemoveAttath(item)}>
										<i className="fa fa-trash"></i>
									</div>
								</div>
								<div className="col-12">
									<span className="text-secondary">Obs:</span>{' '}
									{item.ane_pl_ob_obs || item.ane_pl_ob_obs}
								</div>
							</div>
							<hr />
						</div>
					))}
		</>
	)
}
export default ListTypeAttachments
