import React, { useContext } from "react";
import { requestContext } from "../../../context/requestContext";
import { formContext } from "../../../context/formContext";
import { useNavigate } from "react-router-dom";
const ListComplements = ({ data }) => {
  const { deleteItemComplements } = useContext(requestContext)
  const { handleForm } = useContext(formContext)
  const navigate = useNavigate()
  const handleDelete = (item) => {
    const { comp_cod, comp_descricao } = item
    const confirm = window.confirm(`Você deseja mesmo remover o complemento ${comp_descricao}`)
    if (!confirm) {
      return
    }
    deleteItemComplements({
      id: comp_cod
    })
  };
  const handleEdit = (item) => {
    handleForm({
      ...item,
    })
    navigate('/complements/new')
  }
  if (!Array.isArray(data)) {
    return <div></div>;
  }
  return data.map((item) => (
    <div className="row p-3" key={item.comp_cod}>
      <div className="col-md-11 col-sm-10">{item.comp_descricao}</div>
      <div className="col-md-1 col-sm-2">
        <div className="row">
          <button
            className="btn btn-danger btn-sm mr-2"
            onClick={() => handleEdit(item)}
          >
            <i className="ml-1 fa fa-edit"></i>
          </button>
          <button
            className="btn btn-secondary btn-sm"
            onClick={() => handleDelete(item)}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
  ));
};
export default ListComplements