import { storageCache } from '../cacheManager'
import { randomNumber } from '../randomId'
import { filterAndRemoveRepeated } from '../arrayHandless'
import { downloadReport } from '../debugger'
import { ENV } from '../../config/'
export const insert = (data, table, column) => {
	try {
		if (!data || !table) {
			return
		}	
		let id = randomNumber()
		if (data.id) {
			id = data[column || 'id']
		} else {
			data[column || 'id'] = id
		}
		const getItem = localStorage.getItem(table)
		if (!getItem) {
			const remove = filterAndRemoveRepeated([{
				...data
			}])
			localStorage.setItem(table, JSON.stringify([
				...remove
			]))
			return data
		}
		const oldData = JSON.parse(getItem)
		if (Array.isArray(oldData)) {
			const removerepeated = filterAndRemoveRepeated(oldData)
			localStorage.setItem(table, JSON.stringify([
				...removerepeated,
				{ ...data }
			]))
		} else {
			localStorage.setItem(table, JSON.stringify([{
				...data
			}]))
		}
		return data
	} catch (error) {
		if (ENV === 'develop' || ENV === 'teste') {
			downloadReport([
				{ ...data },
				{ error },
				{ table }
			], table)
		}
		return null
	}
}
export const update = (id, data, table, column) => {
	try {
		if (!id || !data || !table) {
			return
		}
		const parseData = localStorage.getItem(table)
		if (!parseData) {
			return
		}
		const oldData = JSON.parse(parseData)
		if (!Array.isArray(oldData)) {
			return insert(data, table)
		}
		const filter = oldData.filter(el => el[column || id] !== id)
		if (filter.lenght === 0) {
			return
		}
		const removerepeated = filterAndRemoveRepeated(filter)
		localStorage.setItem(table, JSON.stringify([
			...removerepeated,
			{
				id,
				...data
			}
		]))
		return data
	} catch (error) {
		downloadReport([
			{ ...data },
			{ error },
			{ table }
		], table)
		return null
	}
}
export const delete_ = (id, table) => {
	try {
		const oldData = JSON.parse(localStorage.getItem(table))
		if (!Array.isArray(oldData)) {
			return
		}
		const filter = oldData.filter(el => el.id !== id)
		if (filter.length === 0) {
			return storageCache(table)
		}
		const removerepeated = filterAndRemoveRepeated(filter)
		localStorage.setItem(table, [
			...removerepeated
		])
		return removerepeated
	} catch (error) {
		downloadReport([
			{ id },
			{ error },
			{ table }
		], table)
		return null
	}
}
export const select = (id, table, column) => {
	try {
		if (!table) {
			return
		}
		const data = localStorage.getItem(table)
		if (!data) {
			return
		}
		const oldData = JSON.parse(data)
		if (!oldData) {
			return null
		}
		if (!id) {
			return oldData
		}
		const removerepeated = filterAndRemoveRepeated(oldData)
		const filter = removerepeated.filter(el => parseInt(el[column || 'id']) === parseInt(id))
		return filter
	} catch (error) {
		downloadReport([
			{ id },
			{ error },
			table
		], table)
		return null
	}
}
export const where = (word, column, table) => {
	try {
		const oldData = JSON.parse(localStorage.getItem(table))
		if (!oldData) {
			return
		}
		const word_one = word.split(' ')[0]// separar str por espaços
		const filter = oldData.filter(el => String(el[column]).toLowerCase() === String(word_one).toLowerCase())
		return filter
	} catch (error) {
		downloadReport([
			{ word, column },
			{ error },
			{ table }
		], table)
		return null
	}
}