import React, { useContext, useEffect } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import ListHighwayDirections from '../../list/highwayDirections'
import { styleButtonPlus } from '../../../css/globlalStyle'
import { randomNumber } from '../../../services/randomId'
import { itemExistsByProperty } from '../../../services/arrayHandless'
import { handleWaring } from '../../../services/isValid'
const FormNewHighway = ({ edit }) => {
	const {
		state, onChange, handleForm,
		onChangeNumber, } = useContext(formContext)
	const {
		cardinal_points, type_highway, setInfoRequest } = useContext(requestContext)
	const {
		rod_desc,
		rod_sent_desc,
		rod_km,
		rod_km_ini,
		rod_km_fim,
		rod_km_ini_mt,
		rod_km_fim_mt,
		rod_extencao,
		rod_tipo,
		conc_cod,
		rod_sent_rodovia,
		rod_cod
	} = state
	useEffect(() => {
		handleForm({
			rod_sent_rodovia: '',
			rod_sent_desc: '',
		})
	}, [])
	useEffect(() => {
		if (conc_cod) {
			handleForm({
				rod_concessionaria: conc_cod
			})
		}
	}, [conc_cod])
	useEffect(() => {
		const calc = ((parseInt(rod_km_fim) * 1000) + parseInt(rod_km_fim_mt)) - ((parseInt(rod_km_ini) * 1000) + parseInt(rod_km_ini_mt))
		handleForm({
			rod_extencao: calc || 0
		})
	}, [rod_km_ini, rod_km_ini_mt, rod_km_fim, rod_km_fim_mt])
	const handleAddHighwayDirect = () => {
		const id = randomNumber()
		const repeatedElement_ = itemExistsByProperty(rod_sent_rodovia, 'rod_sent_desc', rod_sent_desc)
		if (repeatedElement_) {
			return setInfoRequest(handleWaring(`O sentido ${rod_sent_desc} já foi adicionado`))
		}
		if (!Array.isArray(rod_sent_rodovia)) {
			return handleForm({
				rod_sent_rodovia: [{
					rod_sent_desc,
					id
				}]
			})
		}
		handleForm({
			rod_sent_rodovia: [...rod_sent_rodovia, {
				rod_sent_desc,
				id
			}]
		})
	}
	return (
		<div className='row'>
			<div className="form-group col-12">
				<label>Nome da Rodovia:</label>
				<div className="input-group">
					<input type="text"
						onChange={onChange}
						name='rod_desc' value={rod_desc}
						className={`form-control form-control-border ${!rod_desc && 'is-invalid'}`}
						placeholder='Nome da Rodovia' />
				</div>
			</div>
			<div className="form-group col-md-6 col-12">
				<label>Código Rodovia</label>
				<input type="text"
					onChange={onChange}
					name='rod_km' value={rod_km}
					className={`form-control form-control-border ${!rod_km && 'is-invalid'}`}
					placeholder='Código Rodovia' disabled={edit} />
			</div>
			<div className="form-group col-md-6 col-12">
				<label>Sentido da rodovia:</label>
				<div className="input-group">
					<input type="text"
						onChange={onChange}
						name='rod_sent_desc' value={rod_sent_desc}
						className={`form-control form-control-border ${!rod_sent_desc && 'is-invalid'}`}
						placeholder='Qual é o sentido dessa rodovia' />
					<div className="input-group-prepend" onClick={handleAddHighwayDirect}>
						<span className="input-group-text bg-danger" style={styleButtonPlus}>
							<i className="fa fa-plus" aria-hidden="true"></i>
						</span>
					</div>
				</div>
			</div>
			<div className="form-group col-md-3 col-6">
				<label>+ Rodovia KM inicial:</label>
				<input type="number"
					onChange={onChangeNumber}
					name='rod_km_ini' value={rod_km_ini}
					className={`form-control form-control-border ${rod_km_ini === '' && 'is-invalid'}`}
					placeholder='Km' />
			</div>
			<div className="form-group col-md-3 col-6">
				<label>+ Rodovia mts inicial:</label>
				<input type="number"
					onChange={onChangeNumber}
					name='rod_km_ini_mt' value={rod_km_ini_mt}
					className={`form-control form-control-border ${rod_km_ini_mt === '' && 'is-invalid'}`}
					placeholder='m' />
			</div>
			<div className="form-group col-md-3 col-6">
				<label>+ Rodovia KM final:</label>
				<input type="number"
					onChange={onChangeNumber}
					name='rod_km_fim' value={rod_km_fim}
					className={`form-control form-control-border ${rod_km_fim === '' && 'is-invalid'}`}
					placeholder='Km' />
			</div>
			<div className="form-group col-md-3 col-6">
				<label>+ Rodovia mts final:</label>
				<input type="number"
					onChange={onChangeNumber}
					name='rod_km_fim_mt' value={rod_km_fim_mt}
					className={`form-control form-control-border ${rod_km_fim_mt === '' && 'is-invalid'}`}
					placeholder='m' />
			</div>
			<div className="form-group col-md-6 col-sm-12">
				<label>Extensão da rodovia:</label>
				<input type="number"
					onChange={onChange}
					name='rod_extencao' value={rod_extencao}
					className={`form-control form-control-border ${!rod_extencao && 'is-invalid'}`}
					placeholder='Extensão da rodovia'
					disabled />
			</div>
			<div className="form-group col-md-6 col-sm-12">
				<label>Tipo de rodovia:</label>
				<select
					onChange={onChange}
					name='rod_tipo' value={rod_tipo}
					className={`form-control form-control-border ${!rod_tipo && 'is-invalid'}`}
				>
					<option>Selecionar</option>
					{type_highway?.total > 0 &&
						type_highway.data.map(item => (<option key={item.tip_rod_cod} value={item.tip_rod_cod}>{item.tip_rod_desc}
						</option>
						))
					}
				</select>
			</div>
			{Array.isArray(rod_sent_rodovia) && (
				<>
					<div className="form-group col-12">
						Sentidos dessa rodovia adicionados
					</div>
					{rod_sent_rodovia.map(item => (
						<ListHighwayDirections key={item.rod_sent_cod} item={item} edit_highway />
					))}
				</>
			)}
			{rod_cod && (
				<>
					<div className="form-group col-12">
						Sentidos dessa rodovia cadastrados
					</div>
					{Array.isArray(cardinal_points?.data) && cardinal_points?.data?.map(item => (
						<ListHighwayDirections key={item.rod_sent_cod} item={item} />
					))}
				</>
			)}
		</div>
	)
}
export default FormNewHighway