/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { requestContext } from '../../../context/requestContext'
import { formatDate } from '../../../services/dateHandless'
import { Link } from 'react-router-dom'
const ListManageEmails = ({ data, selectedItems, setSelectedItems }) => {
	const [items, setItems] = useState(null)
	const { deleteItemGerEm, reSendGerEm } = useContext(requestContext)
	useEffect(() => {
		if (data?.total > 0) {
			setItems(data.data)
		}
	}, [data])
	useEffect(() => {
		if (items?.length > 0) {
			let tempData = items.filter(item => item?.selected)
			setSelectedItems(tempData)
		}

	}, [items])
	if (!Array.isArray(items)) {
		return <></>
	}
	const handleDelete = (item) => {
		const { ger_em_cod } = item
		const confirm = window.confirm('Essa operação é ireeversível. Você deseja mesmo excluir o Registro de E-mail ?')
		if (confirm) {
			deleteItemGerEm(ger_em_cod)
		}
	}
	const selectItem = (prop) => {
		let tempData = items.map(item => {
			if (item.ger_em_cod === prop.ger_em_cod) {
				return { ...item, selected: !item.selected }
			} else {
				return { ...item }
			}
		})
		setItems(tempData)

	}
	return (
		items.map(item => (
			<>
				<div style={{ display: 'flex', flexDirection: 'row', marginLeft: '1rem' }}>
					<input
						type="checkbox"
						style={{ width: '20px', marginRight: '1rem' }}
						checked={item?.selected}
						onClick={() => selectItem(item)}
					/>
					<div className="row p-3">
						<div className="col-md-12">
							<span className="text-secondary mr-2">Remetente:</span>
							{item.ger_em_from_name}
						</div>
						<div className="col-md-12">
							<span className="text-secondary mr-2">Destinatário:</span>
							{`${item?.ger_em_to_name} (${item?.ger_em_to_email})`}
						</div>
						<div className="col-md-12">
							<span className="text-secondary mr-2">Assunto:</span>
							{item?.ger_em_subject}
						</div>
						<div className="col-md-6 col-sm-10">

							<span className="text-secondary mr-2">Situação:</span>
							{item?.ger_em_st_desc !== 'Enviado' ? <Link to='#' onClick={() => reSendGerEm(item.ger_em_cod)}>{item?.ger_em_st_desc} (Reenviar)</Link> : item?.ger_em_st_desc}
						</div>

						<div className="col-md-4 col-sm-12">
							<span className="text-secondary mr-2">Data de envio:</span>
							{formatDate(item?.ger_em_when)}

						</div>
						<div className="col-md-2 col-sm-2">
							<button
								className="btn btn-secondary btn-sm"
								onClick={() => handleDelete(item)}
							>
								<i className="fa fa-trash"></i>
							</button>
						</div>
					</div>
				</div>

				<hr />
			</>
		))
	)
}
export default ListManageEmails