import React, { useEffect } from 'react'
import Login from '../../../components/form/login'
import { LOGO, VERSION } from '../../../config/flavor'
const Authentication = () => {
	useEffect(() => {
		const body = document.getElementById('root')
		body.className = 'hold-transition login-page'
		body.style.minHeight = 466
	}, [])
	return (
		<div className="login-box">
			<div className="card card-outline card-danger">
				<div className="card-header text-center">
					<img src={LOGO} style={{ maxWidth: 250 }} alt="image_logo" />
				</div>
				<div className="card-body">
					<Login />
					<p className="login-box-msg">{VERSION}</p>
				</div>

			</div>

		</div>
	)
}
export default Authentication