import React, { useContext, useEffect, useState } from 'react'
import { requestContext } from '../../../context/requestContext'
import { formContext } from '../../../context/formContext'
import { useNavigate } from 'react-router-dom'
const ListSyncStatus = ({ data }) => {
	const [items, setItems] = useState(null)
	const { deleteItemSyncStatus } = useContext(requestContext)
	const { handleForm } = useContext(formContext)
	const navigate = useNavigate()
	useEffect(() => {
		if (data?.total > 0) {
			setItems(data.data)
		}
	}, [data])
	if (!Array.isArray(items)) {
		return <></>
	}
	const handleEdit = (item) => {
		handleForm(item)
		navigate('/sync_status/new')
	}
	const handleDelete = (item) => {
		const { sinc_st_desc, sinc_st_cod } = item
		const confirm = window.confirm(`Você deseja mesmo excluir o status ${sinc_st_desc} ?`)
		if (confirm) {
			deleteItemSyncStatus({ id: sinc_st_cod, ...item })
		}
	}
	return (
		items.map(item => (
			<div className="row p-3" key={item.sinc_st_cod}>
				<div className="col-md-10 col-sm-6">
					<span className="text-secondary mr-2">Descrição:</span>
					{item.sinc_st_desc}
				</div>
				<div className="col-md-2 col-sm-6">
					<button
						className="btn btn-danger btn-sm mr-2"
						onClick={() => handleEdit(item)}
					>
						<i className="ml-1 fa fa-edit"></i>
					</button>
					<button
						className="btn btn-secondary btn-sm"
						onClick={() => handleDelete(item)}
					>
						<i className="fa fa-trash"></i>
					</button>
				</div>
			</div>
		))
	)
}
export default ListSyncStatus