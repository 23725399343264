import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Spinner from '../../components/spinner'
import { requestContext } from '../../context/requestContext'
import { MODELS } from '../../model'
import { storageCache } from '../../services/cacheManager'
const Sync = () => {
	const {
		getAllItemDealership,
		getAllItemHighway,
		getAllItemService,
		getAllItemCardinalPoints,
		getAllItemServiceSubCat,
		getAllItemCategoryService,
		getAllItemDealershipLot,
		getItemTypeHigway,
		getItemApiKey,
		getAllItemDeadline,
		getAllItemComplements,
		getItemAllStatusOversight,
		getAllItemConfig,
	} = useContext(requestContext)
	const [load, setLoad] = useState(false)
	const [error, setError] = useState(false)
	const [success, setSuccess] = useState(false)
	const navigate = useNavigate()

	useEffect(() => {
		loadItems()
	}, [])
	const handlePage = () => {
		setTimeout(() => {
			navigate('/')
		}, 3000)
	}
	useEffect(() => {
		if (success) {
			handlePage()
		}
	}, [success])
	const loadItems = () => {

		new Promise((resolve) => {
			setLoad(true)
			resolve()
			Object.keys(MODELS).map((item) => storageCache(MODELS[item]))
		})
			.then(() => {
				getItemApiKey()
				getAllItemCardinalPoints()
				getItemAllStatusOversight()
				getAllItemDealership()
				getAllItemHighway()
				getAllItemService()
				getAllItemServiceSubCat()
				getAllItemDealership()
				getAllItemCategoryService()
				getItemTypeHigway()
				getAllItemDeadline()
				getAllItemComplements()
				getAllItemConfig()
			})
			.then(() => {
				storageCache('sync', {
					data: new Date(),
					sync: true,
				})
				return setSuccess(true)
			})
			.catch(() => {
				setError(true)
			})
			.finally(() => {
				setLoad(false)
			})

	}
	return (
		<div className="content" style={{ margin: '0 auto' }}>
			<div
				className="p-5"
				style={{ width: '80%', height: '100%', margin: '0 auto' }}
			>
				<Spinner show={load} />
				{error && (
					<h3 className="text-secondary text-center">
						Falha ao carregar os dados
					</h3>
				)}
				{error && (
					<button className="btn btn-danger">
						Tentar carregar novamente os dados
					</button>
				)}
				{success && (
					<h3 className="text-secondary text-center">
						Dados carregado com sucesso, <i className="fa fa-arrow-left"></i>{' '}
						Voltando para tela inicial, aguarde ser redirecionado para o inicio
					</h3>
				)}
			</div>
		</div>
	)
}
export default Sync
