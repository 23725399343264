import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ContentHeader from '../../../components/contentHeader'
import Spinner from '../../../components/spinner'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { isValid, handleWaring } from '../../../services/isValid'
import FormSpreedsheetConstructionPlan from '../../../components/form/spreedsheetConstructionPlan'
const NewSpreedsheetDefault = () => {
	const { state, handleForm, bases64, setBases64 } = useContext(formContext)
	const { setInfoRequest, postItemDefaultSpreedSheetConstructionPlan,
		getAllItemTypeConstructions, loading } = useContext(requestContext)
	const navigation = useNavigate()
	const {
		tip_ob_cod,
		pmo_cod,
		pmo_tip_pl_ob,
	} = state
	useEffect(() => {
		const laodItems = () => {
			setBases64(null)
			getAllItemTypeConstructions('0')
			if (tip_ob_cod) {
				return handleForm({
					pmo_tip_pl_ob: tip_ob_cod
				})
			}
		}
		laodItems()
	}, [tip_ob_cod])
	const onSubmit = (e) => {
		e.preventDefault()
		window.scrollTo(0, 0)
		const item = {
			pmo_tip_pl_ob,
		}
		const validate = isValid(item)
		if (!bases64 && !pmo_cod) {
			return setInfoRequest(handleWaring())
		}
		if (!validate) {
			return setInfoRequest(handleWaring())
		}
		postItemDefaultSpreedSheetConstructionPlan({
			...item,
			id: pmo_cod,
			pmo_base64: bases64?.bases64
		})

	}
	const handleCancel = () => {
		clearValues()
		navigation('/spreedsheet_default')
	}
	const clearValues = () => {
		handleForm({
			tip_ob_cod: '',
			tip_ob_desc: '',
			pmo_cod: '',
			pmo_path: '',
			pmo_tip_pl_ob: '',
			pmo_usu_cod: '',
		})
		setBases64(null)
	}
	return (
		<>
			<ContentHeader title={'Cadastro de Planilha Modelo para Plano de Obras'} />
			<div className='content'>
				<Spinner show={loading} />
				<form onSubmit={onSubmit}>
					<div className="card card-danger">
						<div className="card-header">
							<h3 className="card-title">Informações sobre a Planilha</h3>
							<div className="card-tools">
								<button type="button" className="btn btn-tool" data-card-widget="collapse">
									<i className="fas fa-minus"></i>
								</button>
								<button type="button" className="btn btn-tool" data-toggle="modal" data-target="#concessionaria">
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
						<div className="card-body">
							<FormSpreedsheetConstructionPlan />
						</div>
					</div>
					<div className="card">
						<div className='card-body'>
							<button className='btn btn-danger mr-3'>
								Salvar <i className="fa fa-save"></i>
							</button>
							<div className='btn btn-secondary' onClick={handleCancel}>
								Cancelar <i className="fa fa-times-circle"></i>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}
export default NewSpreedsheetDefault