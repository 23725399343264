import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { requestContext } from '../../context/requestContext'
import ContentHeader from '../../components/contentHeader'
import Spinner from '../../components/spinner'
import OverSightNotificationItem from '../../components/oversightNotification'
import SearchNotificationDealership from '../../components/form/searchNotificationDealership'
import groupBy from '../../services/groupBy'
const OverSightNotification = () => {
	const { getItemNotifyDealership, data, setData } = useContext(requestContext)
	const [items, setItems] = useState(null)
	const navigate = useNavigate()
	useEffect(() => {
		setData(null)
	}, [])
	useEffect(() => {
		resetValues()
		getItemNotifyDealership()
	}, [])
	useEffect(() => {
		if (data?.total >= 1) {
			const { data: data_ } = data
			const group = groupBy(data_, 'concessionaria')
			setItems(group)
		}
	}, [data])
	const handleCancel = () => {
		resetValues()
		navigate('/')
	}
	const resetValues = () => {
		setData(null)
	}
	return (
		<>
			<ContentHeader title={'Concessionárias para serem notificadas'} />
			<div className="content">
				<div className="card sticky-top">
					<div className="card-header">
						<h3 className="card-title d-md-block d-none">
              Ocorrências para notificar
						</h3>
						<div className="card-tools">
							<SearchNotificationDealership />
						</div>
					</div>
				</div>
				<div className="card">
					<Spinner direction={'center'} label="carregando" />
					{items && (Object.keys(items)?.map(
						(key, i) =>
							Array.isArray(items[key]) && (
								<OverSightNotificationItem
									group={key}
									key={i}
									index={i}
									items={items?.[key]}
								/>
							)
					))}
				</div>
				<div className="card">
					<div className="card-body">
						<button className="btn btn-secondary" onClick={handleCancel}>
							<i className="fa fa-arrow-left"></i> Voltar
						</button>
					</div>
				</div>
			</div>
		</>
	)
}
export default OverSightNotification
