import React from 'react'
export const colors = [
	'info',
	'success',
	'secondary',
]
export const ProgressBar = ({ value, label }) => {

	if (Array.isArray(value)) {
		return (
			<div className="progress">
				{value.map((item, i) => (
					<div
						key={i}
						className={`progress-bar progress-bar-striped progress-bar-animated bg-${colors[i]}`}
						role="progressbar"
						style={{ width: `${item.value}%` }}
						aria-valuenow={item.value}
						aria-valuemin="0"
						aria-valuemax="100"
					>
						{item?.label || ''}
					</div>))
				}
			</div>
		)
	}
	return (
		<div className="col-12 mt-3 mb-3">
			<div className="progress">
				<div
					className="progress-bar progress-bar-striped progress-bar-animated bg-success"
					role="progressbar"
					style={{ width: `${value}%` }}
					aria-valuenow={value}
					aria-valuemin="0"
					aria-valuemax="100"
				>
					{label}
				</div>
			</div>
		</div>
	)
}