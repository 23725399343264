/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { styleButtonPlus, styleButtonSearch } from '../../../css/globlalStyle'
const SelectServiceTimeLine = ({ handleAddItem, isQtd }) => {
	const { onChangeSelectInput, handleForm, state, onChange } = useContext(formContext)
	const { service, getAllItemServiceTimeLine } = useContext(requestContext)
	const {
		serv_sub_cat_desc, serv_sub_cat_cod, pvs_qtd
	} = state
	useEffect(() => {
		getAllItemServiceTimeLine()
	}, [])
	const handleCancel = () => {
		handleForm({
			serv_sub_cat_cod: '',
			serv_cat_cod: '',
			serv_cod: '',
			serv_sub_cat_desc: '',
		})
	}
	return (
		<>
			{isQtd && (
				<div className="form-group form-group col-md-3 col-sm-4">
					<label>Quantidade:</label>
					<div className="input-group">
						<input type="number" onChange={onChange}
							className={`form-control form-control-border ${!pvs_qtd && 'is-invalid'}`}
							name='pvs_qtd' value={pvs_qtd}
							min={1}
							placeholder='0' />
					</div>
				</div>

			)}
			<div className={isQtd ? 'form-group col-md-9 col-sm-12' : 'form-group col-md-12 col-sm-12'}>
				<label>Atividade programada:</label>
				<div className="input-group">
					{serv_sub_cat_desc ?
						<div className='form-control form-control-border'>
							{serv_sub_cat_desc}
						</div>
						:
						<select
							onChange={onChangeSelectInput}
							className={`form-control form-control-border ${!serv_sub_cat_cod && 'is-invalid'}`}>
							<option value={0}>Selecione uma atividade</option>
							{Array.isArray(service?.data) && service.data.map(item => (
								<option key={item.serv_sub_cat_cod} value={JSON.stringify(item)}>
									{item.serv_desc} - {item.serv_sub_cat_desc}
								</option>
							))}
						</select>
					}
					<div className="input-group-prepend">
						{serv_sub_cat_desc && (
							<span className="input-group-text bg-danger mr-2"
								style={styleButtonSearch}
								onClick={handleCancel}>
								<i className="fa fa-times-circle" aria-hidden="true"></i>
							</span>
						)}
						<span className="input-group-text bg-danger"
							style={styleButtonPlus}
							onClick={handleAddItem}>
							<i className="fa fa-plus" aria-hidden="true"></i>
						</span>
					</div>
				</div>
			</div >
		</>
	)
}
export default SelectServiceTimeLine