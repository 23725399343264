import React, { useContext, useEffect } from 'react'
import { requestContext } from '../../context/requestContext'
import ContentHeader from '../../components/contentHeader'
import Spinner from '../../components/spinner'
import { formContext } from '../../context/formContext'
import { Link, useNavigate } from 'react-router-dom'
import { authContext } from '../../context/authContext'
import ListSyncStatus from '../../components/list/listSyncStatus'
import SearchStatusOversight from '../../components/form/searchStatusOversight'
const ManagerSyncStatus = () => {
	const { getItemSyncStatus, status, setStatus } = useContext(requestContext)
	const { handleForm } = useContext(formContext)
	const { user } = useContext(authContext)
	const navigate = useNavigate()
	useEffect(() => {
		setStatus(null)
		getItemSyncStatus()
	}, [])
	const handlePage = (item) => {
		if (!item) {
			return
		}
		const { label } = item
		const { current_page, last_page } = status
		window.scrollTo(0, 0)
		if (label === 'pagination.previous') {
			return getItemSyncStatus(current_page > 1 && `0?page=${current_page - 1}`)
		}
		if (label === 'pagination.next') {
			return getItemSyncStatus(current_page < last_page && `0?page=${current_page + 1}`)
		}
		if (!label) {
			return getItemSyncStatus()
		}
		getItemSyncStatus(`0?page=${label}`)
	}
	const handleNew = () => {
		handleForm({
			sinc_st_cod: '',
			sinc_st_desc: '',
		})
		navigate('/sync_status/new')
	}
	return (
		<>
			<ContentHeader title={'Cadastro de Status para Sincronização'} />
			<div className="content">
				<div className="card sticky-top">
					<div className="card-header">
						<h3 className="card-title col-8 d-none d-md-block">
							Status cadastrados
						</h3>
						<div className="card-tools">
							<SearchStatusOversight handleNew={handleNew} />
						</div>
					</div>
				</div>
				<div className="card">
					<Spinner direction={'center'} label="carregando" />
					<ListSyncStatus data={status} />
					<div className="card-footer clearfix">
						<ul className="pagination pagination-sm m-0 float-right">
							{Array.isArray(status?.links) &&
								status.links.map((item) => (
									<li className="page-item" key={item.label}>
										<Link
											className={`page-link ${item.active && 'text-light bg-danger'
											}`}
											to="#"
											onClick={() => handlePage(item)}
										>
											{item.label === 'pagination.previous' ? (
												<i className="fa fa-angle-left"></i>
											) : item.label === 'pagination.next' ? (
												<i className="fa fa-angle-right"></i>
											) : (
												item.label
											)}
										</Link>
									</li>
								))}
						</ul>
						{user && user.set_nivel === 1 && (
							<ul className="pagination pagination-sm m-0 float-left">
								<li className="page-item">
									<div className="page-link" onClick={handleNew}>
										Novo <i className="fa fa-plus mr-1 ml-1"></i>
									</div>
								</li>
							</ul>
						)}
					</div>
				</div>
			</div>
		</>
	)
}
export default ManagerSyncStatus
