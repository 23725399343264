import React, { useContext } from 'react'
import { formContext } from '../../../context/formContext'
const ListEmail = ({ item, data }) => {
	const { handleForm } = useContext(formContext)
	const handleRemove = () => {
		const itemFilter = data.filter(value => value.conc_email !== item.conc_email)
		handleForm({
			conc_email: itemFilter
		})
	}
	return (
		item && (<div className='d-flex justify-content-between align-center' key={item.conc_email}>
			<div>{item.conc_email || item.em_email}</div>
			<div className='row'>
				<div className='btn btn-secondary btn-sm' onClick={handleRemove}>
					<i className='fa fa-trash'></i>
				</div>
			</div>
		</div>)
	)
}
export default ListEmail