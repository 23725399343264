import React, { useContext } from "react"
import { formContext } from "../../../context/formContext"
import { requestContext } from "../../../context/requestContext"
import { authContext } from "../../../context/authContext"
const SearchVersion = ({ handleNew }) => {
    const { state, onChange } = useContext(formContext)
    const { getItemWhereVersion, getItemVersion } = useContext(requestContext)
    const { user } = useContext(authContext)

    const {
        vers_number
    } = state
    const handleSearch = () => {
        if (!vers_number) {
            return getItemVersion()
        }
        getItemWhereVersion(vers_number)
    }
    return (
        <div className="input-group input-group-sm">
            <div className="input-group-append">
                <button className="btn btn-tool btn-sm" onClick={() => getItemVersion()}>
                    Atualizar lista
                </button>
            </div>
            {user?.set_nivel <= 1 && handleNew && (
                <div className="input-group-append">
                    <button className="btn btn-tool btn-sm" onClick={handleNew}>
                        <i className="fas fa-plus"></i> Novo
                    </button>
                </div>
            )}
            <input type="text" className="form-control float-right"
                name="vers_number" value={vers_number} onChange={onChange}
                placeholder="Digite aqui para pesquisar" />
            <div className="input-group-append">
                <button className="btn btn-default" onClick={handleSearch}>
                    <i className="fas fa-search"></i>
                </button>
            </div>
        </div>
    )
}
export default SearchVersion