import React from 'react'
const PanelOversight = () => {
	return (
		<div className="embed-responsive embed-responsive-16by9">
			<iframe className="embed-responsive-item"
				src="https://app.powerbi.com/view?r=eyJrIjoiNGUxZDVjZDEtZDBmNi00NzQwLTlkNzgtYjg0YzA3NzRlMTgxIiwidCI6IjNhNzhiMGNkLTdjOGUtNDkyOS04M2Q1LTE5MGE2Y2MwMTM2NSJ9" allowfullscreen
				title="Relatório de conservação"></iframe>
		</div>
	)
}
export default PanelOversight