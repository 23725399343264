import React, { useContext, useState } from 'react'
import ContentHeader from '../../../../components/contentHeader'
import { useNavigate } from 'react-router-dom'
import { requestContext } from '../../../../context/requestContext'
import { handleError } from '../../../../services/isValid'
const AnswerChecklist = () => {
	const { checklists, setInfoRequest, postChecklistArray } = useContext(requestContext)
	const navigate = useNavigate()
    const [answers, setAnswers] = useState(null)    

    const onBlur = ({ target }) => {
        let tempArr = []
        if(answers?.length>0){
            tempArr = [...answers]
        }
        const { name, value } = target
        const index = tempArr.findIndex(item => item.check_item_cod === name)
    
        if (index !== -1) {
            tempArr[index] = { check_item_cod: parseInt(name), value: value }
        } else {
            tempArr.push({ check_item_cod: parseInt(name), value: value })
        }

        setAnswers(tempArr)
    }

	const handleCancel = () => {
		
		navigate('/paviment/check_list')
	}
	const onSubmit = (e) => {
		e.preventDefault()
		e.stopPropagation()
		window.scrollTo(0, 0)
		if(checklists?.checklist_cat?.length>0){
            let checkItemCods = [];
    
            checklists.checklist_cat.forEach(category => {
                category.checklist_cat_item.forEach(item => {
                    checkItemCods.push({check_item_cod:item.check_item_cod, value:item.check_resp_value});
                });
            });
            const result = [];
            function existsInArray(array, checkItemCod) {
              return array.some(item => item.check_item_cod === checkItemCod);
            }
            answers.forEach(item => {
              result.push(item);
            });
            checkItemCods.forEach(item => {
              if (!existsInArray(answers, item.check_item_cod)) {
                result.push(item);
              }
            });

            for (let item of checkItemCods) {
                let correspondingAnswer = result?.find(answer => parseInt(answer.check_item_cod) === parseInt(item.check_item_cod));
                if (result?.length === 0 || !correspondingAnswer || !correspondingAnswer.value) {
                    return setInfoRequest(handleError('Responda todas as questões antes de salvar'))
                }
            }
            return postChecklistArray(answers)
        }else{
            return setInfoRequest(handleError('Responda todas as questões antes de salvar'))
        }
        

	}
	return (
		<>
			<ContentHeader title={`Checklist ${checklists.check_nome}`} />
			<div className='content'>
				<form onSubmit={onSubmit}>
                <div className="col-12">
                    {checklists?.checklist_cat?.length>0
                    &&checklists?.checklist_cat.map(item=>(<Form onBlur={onBlur} data={item} />)                    )}
                </div>
					
					<div className="card">
						<div className='card-body'>
							<button className='btn btn-danger mr-3'>
                                Salvar <i className="fa fa-save"></i>
							</button>
							<div className='btn btn-secondary' onClick={handleCancel}>
                                Cancelar <i className="fa fa-times-circle"></i>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}

const Form = ({onBlur, data}) =>{
    return(<div className="card card-danger">
        <div className="card-header">
            <h3 className="card-title">{data?.check_cat_desc}</h3>
            <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                    <i className="fas fa-minus"></i>
                </button>
            </div>
        </div>
        <div className="card-body">
            {data?.checklist_cat_item?.length>0&&data?.checklist_cat_item?.map(item=>(
                item?.check_item_type === 1?
                <div className="form-group col-12">
                    <label style={{display:'block'}}>{item?.check_item_desc}</label>
                    {item?.check_item_obs&&<p style={{fontSize:'0.7rem', color:'grey', margin:0, display:'inline'}}>{item?.check_item_obs}</p>}
                {item?.check_item_link&&<p style={{fontSize:'0.7rem', color:'grey', margin:0, display:'inline'}}> | Clique nesse <a href={item?.check_item_link} target='blank'>LINK</a> para mais informações</p>}
                    <div className="input-group">
                        <input type="number" onBlur={onBlur}
                            className={'form-control form-control-border'}
                            name={item?.check_item_cod}
                            defaultValue={item?.check_resp_value}
                            placeholder='0' />
                    </div>
                </div>
                :item?.check_item_type === 2?
                <div className="form-group col-12">
                    <label style={{display:'block'}}>{item?.check_item_desc}</label>
                    {item?.check_item_obs&&<p style={{fontSize:'0.7rem', color:'grey', margin:0, display:'inline'}}>{item?.check_item_obs}</p>}
                {item?.check_item_link&&<p style={{fontSize:'0.7rem', color:'grey', margin:0, display:'inline'}}> | Clique nesse <a href={item?.check_item_link} target='blank'>LINK</a> para mais informações</p>}
                    <div className="input-group">
                        <textarea
                            type="text"
                            className={'form-control form-control-border'}
                            onBlur={onBlur}
                            name={item?.check_item_cod}
                            maxLength={500}
                            placeholder="Escreva aqui sua resposta"
                            defaultValue={item?.check_resp_value}
                            rows={3}
                        />
                    </div>
                </div>
                :<div className="form-group col-12">
                <label style={{display:'block'}}>{item?.check_item_desc}</label>
                {item?.check_item_obs&&<p style={{fontSize:'0.7rem', color:'grey', margin:0, display:'inline'}}>{item?.check_item_obs}</p>}
                {item?.check_item_link&&<p style={{fontSize:'0.7rem', color:'grey', margin:0, display:'inline'}}> | Clique nesse <a href={item?.check_item_link} target='blank'>LINK</a> para mais informações</p>}
                <select
                    onBlur={onBlur}
                    name={item?.check_item_cod}
                    className={'form-control form-control-border'}
                >
                    <option>Selecionar</option>
                    <option selected={item?.check_resp_value==="Sim"?true:false}>Sim</option>
                    <option selected={item?.check_resp_value==="Não"?true:false}>Não</option>
                </select>
            </div>
            ))}            
        </div>
    </div>)
}
export default AnswerChecklist