import {
	URL_DEVELOP,
	URL_TESTE,
	URL_HOMOL,
	URL_PRODUCTION,
	URL_FILE,
	URL_FILE_DEVELOP,
	URL_FILE_TESTE,
} from '../config/flavor'
import { ENV } from '../config/'
import Package from '../../package.json'
import { GenerateDinicKey } from '../middleware'
import { recoverCache } from '../services/cacheManager'
export const getBaseUrl = () => {
	switch (ENV) {
	case 'develop': {
		return formatURL(URL_DEVELOP)
	}
	case 'teste': {
		return formatURL(URL_TESTE)
	}
	case 'homol': {
		return formatURL(URL_HOMOL)
	}
	case 'production': {
		return formatURL(URL_PRODUCTION)
	}
	default:
		return formatURL('')
	}
}
export const getBaseUrlFile = () => {
	if (ENV === 'develop') {
		return URL_FILE_DEVELOP
	}
	if (ENV === 'teste') {
		return URL_FILE_TESTE
	}
	return URL_FILE
}
const formatURL = (rawUrl) => {
	return `${rawUrl}/api_v${getVersion()}`
}
const getVersion = () => {
	const { version } = Package
	return String(version).slice(0, 3)
}
export const getEnvironment = () => {
	switch (ENV) {
	case 'develop': {
		return 'Desenvolvimento'
	}
	case 'teste': {
		return 'Teste'
	}
	case 'homol': {
		return 'Homologação'
	}
	case 'production': {
		return 'Produção'
	}
	default:
		return formatURL('')
	}
}
/**
 * Generates header options for making HTTP requests.
 * @param {string} uri - The URI endpoint for the request.
 * @param {string} method - The HTTP method (e.g., 'get', 'post', 'put', 'delete').
 * @param {Object} body - The request body (for methods like 'post' and 'put').
 * @returns {Object} An object containing URL and options for making the request.
 */
export const headerOptions = (uri, method, body) => {
	const token = recoverCache('authenticated')
	let headers = {
		'Content-Type': 'application/json',
		Authentication: GenerateDinicKey(),
	}
	if (token) {
		headers = {
			'Content-Type': 'application/json',
			Authentication: GenerateDinicKey(),
			Authorization: `Bearer ${token}`,
		}
	}
	if (method === 'get') {
		return {
			url: `${getBaseUrl()}${uri}`,
			options: {
				method: method,
				headers,
			},
		}
	}
	return {
		url: `${getBaseUrl()}${uri}`,
		options: {
			method: method,
			headers,
			body: JSON.stringify(body),
		},
	}
}
export const execptionWithIcons = (status) => {
	let icon = ''
	let color = ''
	if (!status) {
		return {
			icon: 'fa-solid fa-circle-info',
			color: 'info',
		}
	}
	if (status === 500) {
		icon = 'fa-solid fa-triangle-exclamation'
		color = 'danger'
	}
	if (status <= 499) {
		icon = 'fas fa-exclamation-triangle'
		color = 'warning'
	}
	if (status <= 399) {
		icon = 'fas fa-exclamation-triangle'
		color = 'secondary'
	}
	if (status <= 299) {
		icon = 'fa-solid fa-circle-check'
		color = 'success'
	}
	return {
		icon,
		color,
	}
}
export const getQueryParams = (obj) => {
	const keys = Object.keys(obj).map(item => `${obj[item]}`)
	return keys.join('/')
}