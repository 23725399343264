import React from 'react'
const ExportReport = ({ onSubmit, resetValues, handlePrinter }) => {
	return (
		<div className="card sticky-top">
			<div className="card-body">
				<div className="card-body">
					{onSubmit && (
						<button className="btn btn-success mr-3" onClick={onSubmit}>
                            Baixar Relatório <i className="fa fa-table"></i>
						</button>
					)}
					{resetValues && (
						<button className="btn btn-danger mr-3" onClick={resetValues}>
                            Limpar filtros <i className="fa fa-eraser"></i>
						</button>
					)}
					{handlePrinter && (
						<button className="btn btn-dark mr-3" onClick={handlePrinter}>
							<i className="fa fa-print"></i> Imprimir
						</button>
					)}
				</div>
			</div>
		</div>
	)
}
export default ExportReport