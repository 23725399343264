import React, { useContext } from "react"
import { formContext } from "../../../context/formContext"
import { requestContext } from "../../../context/requestContext"
const SearchHelp = ({ handleNew }) => {
    const { state, onChange } = useContext(formContext)
    const { getWhereItemHelp, getItemHelp } = useContext(requestContext)
    const {
        help_desc
    } = state
    const handleSearch = () => {
        if (!help_desc) {
            return getItemHelp()
        }
        getWhereItemHelp(help_desc)
    }
    return (
        <div className="input-group input-group-sm">
            <div className="input-group-append">
                <button className="btn btn-tool btn-sm" onClick={() => getItemHelp()}>
                    Atualizar lista
                </button>
            </div>
            <div className="input-group-append">
                <button className="btn btn-tool btn-sm" onClick={handleNew}>
                    <i className="fas fa-plus"></i> Novo
                </button>
            </div>
            <input type="text" className="form-control float-right"
                name="help_desc" value={help_desc} onChange={onChange}
                placeholder="Digite aqui para pesquisar" />
            <div className="input-group-append">
                <button className="btn btn-default" onClick={handleSearch}>
                    <i className="fas fa-search"></i>
                </button>
            </div>
        </div>
    )
}
export default SearchHelp