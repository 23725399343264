/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import SelectSector from '../../selectSector'
import { formContext } from '../../../../context/formContext'
import { requestContext } from '../../../../context/requestContext'
import Dealership from '../../dealership'
import { find } from '../../../../services/arrayHandless'
import SelectGroups from '../../selectGroups'
const FilterUser = () => {
	const { handleForm, state: { set_nivel, set_cod, conc_cod, gp_cod } } = useContext(formContext)
	const { getAllItemDealership, type_highway, getItemUsers } = useContext(requestContext)
	useEffect(() => {
		const setValuesFromCheck = () => {
			if (Array.isArray(type_highway?.data) && set_cod) {
				const { data: type_highway_data } = type_highway
				const { data: findSector } = find(type_highway_data, 'set_cod', parseInt(set_cod))
				if (findSector) {
					const { set_cod, set_nivel } = findSector
					return handleForm({
						set_cod, set_nivel
					})
				}
			}
		}
		setValuesFromCheck()
	}, [set_cod, type_highway])
	useEffect(() => {
		getAllItemDealership()
	}, [])
	const onSubmit = () => {
		getItemUsers({
			id: 0,
			set_nivel,
			set_cod,
			conc_cod,
			gp_cod,
		})

	}
	const resetValues = () => {
		handleForm({
			set_nivel: 0,
			set_cod: 0,
			conc_cod: 0,
			gp_cod: '',
			set_desc: '',
			conc_nome: '',
			gp_desc: '',
		})
		getItemUsers({
			id: 0,
			set_nivel: 0,
			set_cod: 0,
			conc_cod: 0,
			gp_cod: 0,
		})
	}
	return (
		<div className="row">
			<SelectSector col={'mb-3 col-md-4 col-sm-6'} />
			{set_nivel === 3 && (
				<div className="mb-3 col-md-4 col-sm-12">
					<Dealership selectToUser />
				</div>
			)}
			<SelectGroups col={'mb-3 col-md-4 col-sm-6'} />
			<div className="col-md-6 col-sm-6">
				<button className="btn btn-danger mr-3" onClick={onSubmit}>
                    Aplicar filtro <i className="fa fa-filter"></i>
				</button>
				<button className="btn btn-dark mr-3" onClick={resetValues}>
                    Limpar filtros <i className="fa fa-eraser"></i>
				</button>
			</div>
		</div>
	)
}
export default FilterUser