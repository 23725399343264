import React, { useContext } from 'react'
import { requestContext } from '../../context/requestContext'

const Spinner = ({ direction, label }) => {
	const { loading } = useContext(requestContext)
	return (
		loading ?
			<div className={`text-${direction || 'right'} m-3`}>
				<span className='mr-2'>{label || 'Salvando dados...'}</span>
				<div className="spinner-border spinner-border-sm" role="status" />
			</div>
			: ''
	)
}
export default Spinner