import React, { useContext } from 'react'
import ContentHeader from '../../../components/contentHeader'
import Spinner from '../../../components/spinner'
import { handleWaring, isValid } from '../../../services/isValid'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { useNavigate } from 'react-router-dom'
import FormConfigurationsParematers from '../../../components/form/configurationsParematers'
const NewConfigurationParameters = () => {
	const { state, handleForm } = useContext(formContext)
	const { setInfoRequest, postItemConfigurationParameters } = useContext(requestContext)
	const { config_para_cod,
		config_para_desc,
		config_para_value,
		config_para_settings,
		sett_cod,
		sett_desc, } = state
	const navigate = useNavigate()
	const onSubmit = (e) => {
		e.preventDefault()
		e.stopPropagation()
		const item = {
			config_para_desc,
			config_para_value,
			sett_desc,
		}
		const validate = isValid(item)
		if (!validate) {
			return setInfoRequest(handleWaring())
		}
		postItemConfigurationParameters({
			id: config_para_cod,
			config_para_cod,
			config_para_desc,
			config_para_value,
			config_para_settings,
			sett_cod,
			sett_desc,
		})
	}
	const handleCancel = () => {
		handleForm({
			config_para_cod: '',
			config_para_desc: '',
			config_para_value: '',
			config_para_settings: '',
			sett_cod: '',
			sett_desc: '',
		})
		navigate('/configurations_paramaters')
	}
	return (
		<>
			<ContentHeader title={'Cadastro de parametros de configuração'} />
			<div className='content'>
				<Spinner />
				<div className="card card-danger">
					<div className="card-header">
						<h3 className="card-title">Informações sobre o parametros de configuração</h3>
						<div className="card-tools">
							<button
								type="button"
								className="btn btn-tool"
								data-card-widget="collapse"
							>
								<i className="fas fa-minus"></i>
							</button>
							<button
								type="button"
								className="btn btn-tool"
								data-toggle="modal"
								data-target="#concessionaria"
							>
								<i className="fa fa-question-circle"></i>
							</button>
						</div>
					</div>
					<form onSubmit={onSubmit}>
						<div className="card-body">
							<FormConfigurationsParematers />
						</div>
						<div className="card-body">
							<button type="submit" className="btn btn-danger mr-3">
                                Salvar <i className="fa fa-save"></i>
							</button>
							<div className="btn btn-secondary" onClick={handleCancel}>
                                Cancelar <i className="fa fa-times-circle"></i>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}
export default NewConfigurationParameters