import React, { useContext } from "react";
import { formContext } from "../../../context/formContext";
import { requestContext } from "../../../context/requestContext";
import { authContext } from "../../../context/authContext";
const SearchTutorial = ({ handleNew }) => {
  const { state, onChange } = useContext(formContext);
  const { getItemWhereConstructionPlan, getItemConstructionPlan } =
    useContext(requestContext);
  const { user } = useContext(authContext)
  const { conc_nome } = state;
  const handleSearch = () => {
    if (!conc_nome) {
      return getItemConstructionPlan();
    }
    getItemWhereConstructionPlan(conc_nome);
  };
  return (
    <div className="input-group input-group-sm">
      {user?.set_nivel <= 3 && (
        <div className="input-group-append">
          <button className="btn btn-tool btn-sm" onClick={handleNew}>
            Novo <i className="fa fa-plus"></i>
          </button>
        </div>
      )}
      <div className="input-group-append">
        <button className="btn btn-tool btn-sm" onClick={() => getItemConstructionPlan()}>
          Atualizar lista
        </button>
      </div>
      <input
        type="text"
        className="form-control float-right"
        name="conc_nome"
        value={conc_nome}
        onChange={onChange}
        placeholder="Procurar tutorial"
      />
      <div className="input-group-append">
        <button className="btn btn-default" onClick={handleSearch}>
          <i className="fas fa-search"></i>
        </button>
      </div>
    </div>
  );
};
export default SearchTutorial;
