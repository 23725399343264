import React, { useContext } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { authContext } from '../../../context/authContext'
const SearchStatusOversight = ({ handleNew }) => {
	const { state, onChange } = useContext(formContext)
	const { getItemWhereStatusOversight, getItemStatusOversight } = useContext(requestContext)
	const { user } = useContext(authContext)

	const {
		st_desc
	} = state
	const handleSearch = () => {
		if (!st_desc) {
			return getItemStatusOversight()
		}
		getItemWhereStatusOversight(st_desc)
	}
	return (
		<div className="input-group input-group-sm">
			<div className="input-group-append">
				<button className="btn btn-tool btn-sm" onClick={() => getItemStatusOversight()}>
                    Atualizar lista
				</button>
			</div>
			{user?.set_nivel <= 1 && (
				<div className="input-group-append">
					<button className="btn btn-tool btn-sm" onClick={handleNew}>
						<i className="fas fa-plus"></i> Novo
					</button>
				</div>
			)}
			<input type="text" className="form-control float-right"
				name="st_desc" value={st_desc} onChange={onChange}
				placeholder="Digite aqui para pesquisar" />
			<div className="input-group-append">
				<button className="btn btn-default" onClick={handleSearch}>
					<i className="fas fa-search"></i>
				</button>
			</div>
		</div>
	)
}
export default SearchStatusOversight