import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ContentHeader from "../../components/contentHeader";
import { authContext } from "../../context/authContext";
const NoMatch = () => {
  const { isAuthenticated } = useContext(authContext);
  const navigate = useNavigate();

  useEffect(() => {
    const body = document.getElementById("root");
    body.className = "wrapper";
  }, []);
  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);
  return (
    <div className="content-wrapper">
      <ContentHeader title={"Pagina não encontrada"} />
      <section className="content">
        <div className="error-page">
          <h2 className="headline text-warning">404</h2>
          <div className="error-content">
            <h3>
              <i className="fas fa-exclamation-triangle text-warning"></i> Oops!
              Pagina não encontrada.
            </h3>
            <p>
              Nós não encontramos a página que está procurando Enquanto isso
              você pode{" "}
              <Link to="/">
                retornar para {!isAuthenticated ? "Login" : "Dashboard"}
              </Link>
              .
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
export default NoMatch;
