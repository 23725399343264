import React, { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { recoverCache, storageCache } from '../../services/cacheManager'
import { execptionWithIcons, headerOptions } from '../../webService'
import { requestContext } from '../requestContext'
import { statusCode } from '../../constants'
export const authContext = createContext()
const AuthContext = ({ children }) => {
	const [isAuthenticated, setIsAuthenticated] = useState(false)
	const [userToken, setUserToken] = useState(null)
	const [user, setUser] = useState(null)
	const { infoRequest, setInfoRequest, setEditingMenus, setUserMenus } = useContext(requestContext)
	const [showSpinner, setShowspinner] = useState(false)
	const navigation = useNavigate()
	useEffect(() => {
		const isauthenticated = recoverCache('authenticated')
		if (isauthenticated) {
			setIsAuthenticated(true)
			setUserToken(isauthenticated)
		} else {
			clearDataUser()
		}
	}, [])
	useEffect(() => {
		if (infoRequest.status === statusCode.forbidden) {
			clearDataUser()
		}
	}, [infoRequest.status])
	const login = async (state) => {
		const { url, options } = headerOptions('/login', 'post', state)
		setShowspinner(true)
		const response = await fetch(url, options)
		setShowspinner(false)
		const json = await response.json()
		if (!response.ok) {
			return isNotAuthenticated(response.status, json.message)
		}
		storageCache('sync')
		storageCache('authenticated', json.access_token)
		setUserToken(json.access_token)
		setIsAuthenticated(response.ok)
	}
	const forgotPassword = async (state) => {
		const { url, options } = headerOptions('/forgot_password', 'post', state)
		setShowspinner(true)
		const response = await fetch(url, options)
		setShowspinner(false)
		const json = await response.json()
		storageCache('sync')
		storageCache('authenticated')
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return navigation('/')
		}
	}
	const userProfile = async () => {
		const { url, options } = headerOptions('/users/profile', 'post')
		const response = await fetch(url, options)
		const json = await response.json()
		if (!response.ok) {
			return isNotAuthenticated(response.status, json.message)
		}
		setUser(json)
		storageCache('profile', json)
	}
	const userUpdate = async (state) => {
		const { url, options } = headerOptions('/users/update', 'put', state)
		setShowspinner(true)
		const response = await fetch(url, options)
		setShowspinner(false)
		const json = await response.json()
		const execptionWithicons = execptionWithIcons(response.status)
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithicons,
		})
		if (response.ok) {
			if (user.id === state?.id) {
				alert('Você deverá fazer o login novamente')
				return logout()
			} else {
				alert('Senha alterada com sucesso')
				return navigation('/users')
			}

		}
	}
	const logout = async () => {
		setUser(null)
		setEditingMenus(null)
		setUserMenus(null)
		setIsAuthenticated(false)
		const { url, options } = headerOptions('/logout', 'post')
		const response = await fetch(url, options)
		const json = await response?.json()
		const execptionWithicons = execptionWithIcons(response.status)
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithicons,
		})
		if (!response.ok) {
			return isNotAuthenticated(response.status, json.message)
		}
		storageCache('authenticated')
		navigation('/')
	}
	const isNotAuthenticated = (status, message) => {
		clearDataUser()
		const execptionWithicons = execptionWithIcons(status)
		setInfoRequest({
			status: status,
			message: message,
			...execptionWithicons,
		})
	}
	const clearDataUser = () => {
		setIsAuthenticated(false)
		storageCache('user')
		storageCache('authenticated')
		setUserToken(null)
		setUser(null)
		navigation('/')
	}
	return (
		<authContext.Provider
			value={{
				isAuthenticated,
				userToken,
				user,
				showSpinner,
				setInfoRequest,
				login,
				userProfile,
				logout,
				setUser,
				userUpdate,
				forgotPassword,
				clearDataUser,
			}}
		>
			{children}
		</authContext.Provider>
	)
}
export default AuthContext
