import React, { useContext, useEffect, useState } from 'react'
import { requestContext } from '../../../context/requestContext'
import { formContext } from '../../../context/formContext'
import { useNavigate } from 'react-router-dom'
const ListWorkOfArt = ({ data }) => {
	const [items, setItems] = useState(null)
	const { deleteItemObArt } = useContext(requestContext)
	const { handleForm } = useContext(formContext)
	const navigate = useNavigate()
	useEffect(() => {
		if (data?.total > 0) {
			setItems(data.data)
		}
	}, [data])
	if (!Array.isArray(items)) {
		return <></>
	}
	const handleEdit = (item) => {
		handleForm(item)
		navigate('/work_of_art/new')
	}
	const handleDelete = (item) => {
		const { ob_art_desc, ob_art_cod } = item
		const confirm = window.confirm(`Você deseja mesmo inativar a obra de arte ${ob_art_desc} ?`)
		if (confirm) {
			deleteItemObArt(ob_art_cod)
		}
	}
	return (
		items.map(item => (
			<div className="row p-3" key={item.ob_art_cod}>
				<div className='mb-3 col-md-10 col-sm-12'>
					<div className='row'>
						<div className="mb-3 col-md-6 col-sm-12">
							<span className="text-secondary mr-2">Obra de arte:</span>
							{item.ob_art_desc}
						</div>
						<div className="mb-3 col-md-3 col-sm-4">
							<span className="text-secondary mr-2">Código:</span>
							{item.ob_art_item_cod}
						</div>
						<div className='mb-3 col-md-3 col-sm-6'>
							<span className='text-secondary mr-2'>Elemento:</span>
							{item.ob_art_elem_desc}
						</div>
						<div className='mb-3 col-md-3 col-sm-6'>
							<span className='text-secondary mr-2'>Classificador:</span>
							{item.ob_art_class_desc}
						</div>
						<div className="mb-3 col-md-4 col-sm-10">
							<span className="text-secondary mr-2">Situação:</span>
							{item.ob_art_ativo === 1 ? 'Ativo' : 'Inativo'}
						</div>
						<div className="mb-3 col-md-4 col-sm-6">
							<span className="text-secondary mr-2">Rodovia:</span>
							{item.rod_desc}
						</div>
						<div className="mb-3 col-md-4 col-sm-6">
							<span className="text-secondary mr-2">Sentido:</span>
							{item.rod_sent_desc}
						</div>
						<div className='mb-3 col-md-4 col-sm-6'>
							<span className='text-secondary mr-2'>
								Concessionária:
							</span>
							{item.conc_nome}
						</div>
						<div className="mb-3 col-md-4 col-sm-6">
							<span className="text-secondary mr-2">Lote:</span>
							{item.ob_art_lote}
						</div>
					</div>
				</div>
				<div className="mb-3 col-md-2 col-sm-2">
					<button
						className="btn btn-danger btn-sm mr-2"
						onClick={() => handleEdit(item)}
					>
						<i className="ml-1 fa fa-edit"></i>
					</button>
					<button
						className="btn btn-secondary btn-sm"
						onClick={() => handleDelete(item)}
					>
						<i className="fa fa-trash"></i>
					</button>
				</div>
				<hr />
			</div>
		))
	)
}
export default ListWorkOfArt