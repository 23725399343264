import React, { useContext, useEffect, useRef, useState } from 'react'
import { formContext } from '../../../context/formContext'
import SelectTypeAttachmentConstructionPlan from '../selectTypeAttachmentConstructionPlan'
import { Link } from 'react-router-dom'
import { handleError, handleWaring, isValid } from '../../../services/isValid'
import { requestContext } from '../../../context/requestContext'
import returnPosition from '../../../services/geolocation'
import { generateBse64, getExt } from '../../../services/files'
import { allowedImageExtensions, allowedXlsExtensions } from '../../../constants'
import { randomNumber } from '../../../services/randomId'
import { find } from '../../../services/arrayHandless'
import ListAttachmentsContructionPlan from '../../list/attachmentsContructionPlan'
import { generateDateTimeStampJS } from '../../../services/dateHandless'
const FormaAttachmentsConstructionPlan = ({ closeModal, isPressed }) => {
	const input = useRef(null)
	const { state, onChange, handleForm } = useContext(formContext)
	const { setInfoRequest, postItemAttachmentsConstructionPlan, getItemTypeAttch, service, contract_Attachment } = useContext(requestContext)
	const [files, setFiles] = useState([])
	const [services, setServices] = useState([])
	const {
		ane_pl_ob_cod,
		ane_pl_ob_obra_cod,
		ane_pl_ob_path,
		ane_pl_ob_obs,
		pl_ob_cod,
		ane_pl_ob_tipo,
		ane_pl_ob_pvs_cod,
		tip_ane_desc,
	} = state
	useEffect(() => {
		if (!ane_pl_ob_obra_cod) {
			handleForm({
				ane_pl_ob_obra_cod: pl_ob_cod
			})
		}
	}, [ane_pl_ob_obra_cod])
	useEffect(() => {
		setFiles([])
	}, [isPressed])
	useEffect(() => {
		const controlItems = () => {
			if (Array.isArray(service?.data)) {
				const { data } = service
				setServices(data)
			}
			if (Array.isArray(contract_Attachment?.data) && ane_pl_ob_tipo) {
				const { data } = contract_Attachment
				const { data: findTipDesc } = find(data, 'tip_ane_cod', ane_pl_ob_tipo)
				handleForm({
					tip_ane_desc: findTipDesc?.tip_ane_desc
				})
			}
		}
		controlItems()
	}, [service, contract_Attachment, ane_pl_ob_tipo])
	useEffect(() => {
		getItemTypeAttch()
	}, [])
	useEffect(() => {
		const controllHandleForm = () => {
			if (pl_ob_cod) {
				handleForm({
					ane_pl_ob_obra_cod: pl_ob_cod
				})
			}
		}
		controllHandleForm()
	}, [pl_ob_cod])
	const handleAttach = () => {
		if (input?.current?.click) {
			return input?.current?.click()
		}
	}
	const onChangeFile = async ({ target }) => {
		try {
			const { files } = target
			if (files?.length > 1 && ane_pl_ob_cod) {
				return setInfoRequest(handleWaring('Você só pode enviar um arquivo por vez'))
			}
			if (!ane_pl_ob_obs) {
				return setInfoRequest(handleWaring('Você deve escrever alguma observação antes de enviar selecionar o arquivo'))
			}
			const newItems = []
			const types_valid = []
			allowedImageExtensions.forEach(item => (
				types_valid.push({
					type: item,
				})
			))
			allowedXlsExtensions.forEach(item => (
				types_valid.push({
					type: item,
				})
			))
			const position = await returnPosition()
			for (const itemFile of files) {
				const { name } = itemFile
				const type = getExt(name)
				const { data: find_type } = find(types_valid, 'type', type)
				if (find_type) {
					const base64 = await generateBse64(itemFile)
					newItems.push({
						file_name: name,
						ane_pl_ob_cod: ane_pl_ob_cod || randomNumber(),
						ane_pl_ob_obra_cod,
						ane_pl_ob_path: base64,
						tip_ane_desc,
						ane_pl_ob_obs,
						timestamp: generateDateTimeStampJS(),
						pl_ob_cod,
						ane_pl_ob_tipo,
						fisc_lat: position.lat,
						fisc_lng: position.lng,
						ane_pl_ob_pvs_cod
					})
				}
			}
			if (!newItems?.length) {
				return setInfoRequest(handleWaring('Você precisa adicionar pelo menos um arquivo'))
			}
			setFiles(newItems)
		} catch (error) {
			return setInfoRequest(handleError(String(error)))

		}
	}
	const handleSave = () => {
		const payload = {
			ane_pl_ob_obra_cod,
			ane_pl_ob_obs,
			ane_pl_ob_tipo,
		}
		const valid = isValid(payload)
		if (!valid) {
			return setInfoRequest(handleWaring('Você precisa preencher todos os campos'))
		}
		if (ane_pl_ob_cod) {
			payload.id = ane_pl_ob_cod
		}
		payload.pl_ob_cod = ane_pl_ob_obra_cod
		postItemAttachmentsConstructionPlan({
			...payload,
			ob_attachments: files
		})
		setFiles([])
		if (closeModal) {
			closeModal()
		}
	}

	return (
		<div className="row">
			<div className="form-group col-12">
				<label>Observação sobre o anexo:</label>
				<textarea type="text"
					className={`form-control form-control-border ${!ane_pl_ob_obs && 'is-invalid'}`}
					onChange={onChange}
					name='ane_pl_ob_obs' value={ane_pl_ob_obs}
					placeholder='Descreva brevemente sobre o anexo' />
			</div>
			<SelectTypeAttachmentConstructionPlan />
			{services.length ? (
				<div className="form-group col-md-6 col-sm-12">
					<label>Anexo relacionado a algum serviço:</label>
					<select
						onChange={onChange}
						name='ane_pl_ob_pvs_cod' value={ane_pl_ob_pvs_cod}
						className={`form-control form-control-border ${!ane_pl_ob_pvs_cod && 'is-invalid'}`}
					>
						<option>Selecionar</option>
						{services.map(item => (
							<option key={item.prog_serv_serv_cod} value={item.prog_serv_serv_cod}>
								{item.serv_sub_cat_desc}
							</option>
						))
						}
					</select>
				</div>
			) : ''}
			{ane_pl_ob_path ? (
				<div className="col-md-6 col-sm-12">
					<Link to="#" className="text-decoration-none text-dark" onClick={handleAttach}>Anexar novo arquivo</Link>
				</div>
			) : ''}
			{files?.length ? <ListAttachmentsContructionPlan items={files} justPreview={true} /> : ''}
			<input
				name="file_attachments"
				className="d-none"
				type="file"
				onChange={onChangeFile}
				ref={input}
				multiple
			/>
			<div className="col-12">
				<div className="row">
					<div className="col-6">
						<button className="btn btn-danger" onClick={handleSave}>
							<i className="fa fa-save"></i> Salvar
						</button>
					</div>
					{!files?.length ? (
						<div className="col-6">
							<button className="btn btn-secondary" disabled={!ane_pl_ob_obs} onClick={handleAttach}>
								<i className="fa fa-paperclip"></i> Anexar
							</button>
						</div>)
						: ''}
				</div>
			</div>
		</div>
	)
}
export default FormaAttachmentsConstructionPlan
