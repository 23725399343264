import React, { useContext, useEffect, useState } from 'react'
import groupBy from '../../services/groupBy'
import { randomNumber } from '../../services/randomId'
import { find } from '../../services/arrayHandless'
import { requestContext } from '../../context/requestContext'
import { handleWaring } from '../../services/isValid'
import { formContext } from '../../context/formContext'
import { formateValidatedDate, infoCalendar } from '../../services/dateHandless'
const Calender = ({ onRefresh, data, showCreateEvent, setShowCreateEvent, selectedDays, setSelectedDays, onClick, setCurrentMonth_, setItemsEvents }) => {
	const { setInfoRequest } = useContext(requestContext)
	const { handleForm, state: { serv_cat_prazo_from } } = useContext(formContext)
	const [currentMonthDisplay, setCurrentMonthDisplay] = useState(null)
	const [calendarDays, setCalendarDays] = useState(null)
	const [currentMonth, setCurrentMonth] = useState(null)
	const [disabledNextMonth, setDisableNexMonth] = useState(false)
	const [disabledPrevMonth, setDisablePrevMonth] = useState(false)
	useEffect(() => {
		if (currentMonth) {
			setCurrentMonth_(currentMonth)
		}
	}, [currentMonth, setCurrentMonth_])
	useEffect(() => {
		if (serv_cat_prazo_from) {
			const currentMonth_ = new Date(`${serv_cat_prazo_from} 11:59:00`).getMonth()
			setCurrentMonth(currentMonth_)
		} else {
			setCurrentMonth(new Date().getMonth())
			setDisableNexMonth(false)
			setDisablePrevMonth(false)
		}
	}, [serv_cat_prazo_from])
	useEffect(() => {
		const loadCurrentMonth = () => {
			setDisableNexMonth(false)
			setDisablePrevMonth(false)
			setCurrentMonth(new Date().getMonth())
		}
		loadCurrentMonth()
	}, [])

	const nextMonth = (month) => {
		if (Array.isArray(selectedDays)) {
			const confirm = window.confirm('Você seja mesmo ir para o próximo mês? Fazer isso fará com que você perca a seleção de dias atual.')
			if (!confirm) {
				return
			}
		}
		const valueMonth = month || 1
		setSelectedDays(null)
		setDisablePrevMonth(false)
		const newCurrentMonth = currentMonth + valueMonth
		if (newCurrentMonth === 11) {
			setDisableNexMonth(true)
			return setCurrentMonth(11)
		}
		setDisableNexMonth(false)
		setCurrentMonth(newCurrentMonth)
	}
	const previewMonth = (month) => {
		if (Array.isArray(selectedDays)) {
			const confirm = window.confirm('Você seja mesmo ir para o mês anterior? Fazer isso fará com que você perca a seleção de dias atual.')
			if (!confirm) {
				return
			}
		}
		const valueMonth = month || 1
		setSelectedDays(null)
		setDisableNexMonth(false)
		const newCurrentMonth = currentMonth - valueMonth
		if (newCurrentMonth === 0) {
			setDisablePrevMonth(true)
			return setCurrentMonth(newCurrentMonth)
		}
		setDisablePrevMonth(false)
		setCurrentMonth(newCurrentMonth)
	}
	useEffect(() => {
		const renderCalendar = () => {
			const itemcalendarDays = []
			const {
				currentDate,
				currentYear,
				currentHours,
				currenMinutes,
				currentSeconds,
				firstDayOfMonth,
				lastDayOfMonth,
				nameMonth,
			} = infoCalendar(currentMonth)
			setCurrentMonthDisplay(nameMonth)
			for (let i = 0; i < firstDayOfMonth.getDay(); i++) {
				itemcalendarDays.push({
					day: '',
					weekday: '',
					isToday: false,
					nameMonth,
					infoDay: '',
					key: `${randomNumber()}-${currentMonth}`,
					info: null
				})
			}
			for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
				let infoday = []
				if (data?.length >= 1) {
					const findInfoInit = data.filter(item => item.day_init === i && item.month === lastDayOfMonth.getMonth())
					if (findInfoInit?.length >= 1) {
						for (const item_day of findInfoInit) {
							infoday.push({
								...item_day,
							})
						}
					}
					const findInfoFinish = data.filter(item => item.day_fim === i && item.month === lastDayOfMonth.getMonth())
					if (findInfoFinish?.length >= 1) {
						for (const item_day_ of findInfoFinish) {
							infoday.push({
								...item_day_,
							})
						}
					}
				}
				const date = new Date(currentYear, currentMonth, i, currentHours, currenMinutes, currentSeconds)
				const weekday = date.toLocaleDateString('pt-BR', { weekday: 'long' })
				if (i === currentDate.getDate() && currentMonth === currentDate.getMonth() && currentYear === currentDate.getFullYear()) {
					itemcalendarDays.push({
						day: i,
						fullDate: date,
						weekday: String(weekday).replace('-feira', ''),
						isToday: true,
						key: `${i}-${currentMonth}`,
						infoday,
						nameMonth
					})
				} else {
					itemcalendarDays.push({
						day: i,
						fullDate: date,
						weekday: String(weekday).replace('-feira', ''),
						key: i,
						isToday: false,
						infoday,
						nameMonth
					})
				}
			}
			const groupCalendarDays = groupBy(itemcalendarDays, 'weekday')
			setCalendarDays(groupCalendarDays)
		}
		if (currentMonth !== null) {
			renderCalendar()
		}
	}, [currentMonth, data])
	const refresh = () => {
		setCurrentMonth(new Date().getMonth())
		setDisableNexMonth(false)
		setDisablePrevMonth(false)
		if (onRefresh) {
			onRefresh({
				id: 0,
				ped_venda_date_from: '',
				ped_venda_date_to: '',
				type_date: '',
			})
		}
	}
	const onClear = () => {
		setSelectedDays(null)
		handleForm({
			ped_venda_date_ini: '',
			ped_venda_date_fim: ''
		})
		setItemsEvents(null)
		setShowCreateEvent(false)
	}
	const createEvent = () => {
		if (!Array.isArray(selectedDays)) {
			return setInfoRequest(handleWaring('Você precisa pelo menos selecionar um dia'))
		}
		const length = selectedDays.length
		const intialDate = selectedDays[0].fullDate
		const finalDate = selectedDays[length - 1].fullDate
		handleForm({
			ped_venda_date_ini: formateValidatedDate(intialDate),
			ped_venda_date_fim: formateValidatedDate(finalDate)
		})
		setShowCreateEvent(true)
	}
	const RenderItem = ({ item }) => {
		if (Array.isArray(selectedDays)) {
			const findItem = find(selectedDays, 'key', item.key)
			if (findItem?.data) {
				return (
					<div className={'col p-3 border-bottom bg-danger'}
						style={{ cursor: 'pointer', maxWidth: 150, maxHeight: 230, overflow: 'hidden' }}
						onClick={() => onClick(item)}
					>
						<div className="col-12 text-right">
							{item.day}
						</div>
						{item?.infoday?.length >= 1 &&
							item.infoday.map((event, i) => (
								event?.color_event ? (
									<span key={i} style={{ backgroundColor: event.color_event }} className="col-12 badge badge-danger">
										{event.title_event}
									</span>
								) : (
									<span key={i} className="col-12 badge badge-danger">
										{event.title_event}
									</span>
								)
							)
							)}
					</div>
				)
			}
			return (
				<div className={item.isToday ? 'col p-3 border-bottom bg-dark' : 'col p-3 border-bottom'}
					style={{ cursor: 'pointer', maxWidth: 150, maxHeight: 230, overflow: 'hidden' }}
					onClick={() => onClick(item)}
				>
					<div className="col-12 text-right">
						{item.day}
					</div>
					{item?.infoday?.length >= 1 &&
						item.infoday.map((event, i) => (
							event?.color_event ? (
								<span key={i} style={{ backgroundColor: event.color_event }} className="col-12 badge badge-danger">
									{event.title_event}
								</span>
							) : (
								<span key={i} className="col-12 badge badge-danger">
									{event.title_event}
								</span>
							)
						)
						)}
				</div>
			)
		}
		return (
			<div className={item.isToday ? 'col p-3 border-bottom bg-dark' : 'col p-3 border-bottom'}
				style={{ cursor: 'pointer', maxWidth: 150, maxHeight: 230, overflow: 'hidden' }}
				onClick={() => onClick(item)}
			>
				<div className="col-12 text-right">
					{item.day}
				</div>
				{item?.infoday?.length >= 1 &&
					item.infoday.map((event, i) => (
						event?.color_event ? (
							<span key={i} style={{ backgroundColor: event.color_event }} className="col-12 badge badge-danger">
								{event.title_event}
							</span>
						) : (
							<span key={i} className="col-12 badge badge-danger">
								{event.title_event}
							</span>
						)
					)
					)}
			</div>
		)
	}
	return (
		<div className="card-danger">
			<div className="card-header">
				<div className="row">
					<div className="col-md-10 col-12">
						<div className="row">
							<button disabled={disabledPrevMonth} className="btn btn-dark mr-2" onClick={() => previewMonth()}>
								<i className="fa fa-arrow-left" aria-hidden="true"></i>
							</button>
							<button disabled={disabledNextMonth} className="btn btn-dark mr-2" onClick={() => nextMonth()}>
								<i className="fa fa-arrow-right" aria-hidden="true"></i>
							</button>
							<button disabled={showCreateEvent} className="btn btn-dark mr-2" onClick={refresh}>
								<i className="fa fa-retweet" aria-hidden="true"></i> Atualizar
							</button>
							{selectedDays?.length > 1 ? (
								<button disabled={showCreateEvent} className="btn btn-dark mr-2" onClick={createEvent}>
									<i className="fa fa-plus" aria-hidden="true"></i> Criar cronograma
								</button>
							) : (
								<button className="btn btn-tools">Selecione a data de inicio e a Até a data do cronograma</button>
							)}
							{Array.isArray(selectedDays) && (
								<button disabled={showCreateEvent} className="btn btn-secondary mr-2" onClick={onClear}>
									<i className="fa fa-trash" aria-hidden="true"></i> Limpar
								</button>
							)}
						</div>
					</div>
					<div className="col-md-2 col-sm-12">
						<div className="card-title">
							<h3>{currentMonthDisplay}</h3>
						</div>
					</div>
				</div>
			</div>
			<div className="card-body">
				<div className="row">
					{calendarDays && Object.keys(calendarDays)
						.filter(item => item !== '').map((item, i) => (
							<>
								<div className="col p-3 text-capitalize text-center"
									key={i}>
									<div className="border border-secondary">{item}</div>
									{calendarDays[item].map((element, i) => (
										<RenderItem item={element} key={i} />
									))}
								</div>
							</>
						))}
				</div>
			</div>
		</div>
	)
}
export default Calender