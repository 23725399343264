import React from "react";
import { convertToTitleCase } from "../../../../services/stringHandless";
const InfoWindowMonitoring = ({ state, ipcs }) => {
    if (!state) {
        return (
            <div className="col-12">Não há informações para serem exibidas</div>
        )
    }
    const {
        mon_pav_lat,
        mon_pav_lng,
        tip_reves_desc,
        sctrr_desc,
        loc_lipc_km_init,
        loc_lipc_km_init_mts,
        loc_lipc_km_fim,
        loc_lipc_km_fim_mts,
        loc_lipc_obs,
        rod_desc,
        rod_km,
        rod_sent_desc,
        loc_lipc_cod
    } = state
    const handleLink = () => {
        window
            .open(
                `https://www.google.com/maps/place/${mon_pav_lat},${mon_pav_lng}`,
                '_target'
            )
            .focus()
    }
    return (
        <div className="row">
            <div className="mb-3 col-12">
                <div className="row">
                    <div className="mb-3 col-md-6 col-sm-12">
                        <span className="text-secondary mr-2">
                            Rodovia:
                        </span>
                        {rod_km} - {rod_desc}
                    </div>
                    <div className="mb-3 col-md-6 col-sm-12">
                        <span className="text-secondary mr-2">
                            Sentido:
                        </span>
                        {rod_sent_desc}
                    </div>
                    <div className="mb-3 col-md-6 col-sm-12">
                        <span className="text-secondary mr-2">
                            KM inicial:
                        </span>
                        {loc_lipc_km_init}, {loc_lipc_km_init_mts} mts.
                    </div>
                    <div className="mb-3 col-md-6 col-sm-12">
                        <span className="text-secondary mr-2">
                            KM final:
                        </span>
                        {loc_lipc_km_fim}, {loc_lipc_km_fim_mts} mts.
                    </div>
                    <div className="mb-3 col-md-6 col-sm-12">
                        <span className="text-secondary mr-2">
                            Seção Terraplanagem:
                        </span>
                        {sctrr_desc}
                    </div>
                    <div className="mb-3 col-md-6 col-sm-12">
                        <span className="text-secondary mr-2">
                            Tipo revestimento:
                        </span>
                        {tip_reves_desc}
                    </div>
                    <div className="col-12 mb-3">
                        <span className="text-secondary mr-2">
                            Observações:
                        </span>
                        {loc_lipc_obs}
                    </div>
                    <div className="col-12 mb-3">
                        <a href={'#'} onClick={handleLink}>Ver no mapa</a>
                    </div>
                </div>
            </div>
            {loc_lipc_cod ? (
                <div className="col-12">
                    <div className="col-12 text-secondary mb-3">
                        {convertToTitleCase('Avaliação Objetiva Da Superfície')}
                    </div>
                    <div className="row">
                        {Array.isArray(ipcs) ? ipcs.map(item => (
                            <div className="mb-3 col-md-2 col-sm-6" key={item.ipc_cod}>
                                <span className="text-secondary mr-2">
                                    {item.tip_ind_pav_desc}:
                                </span>
                                {item.ipc_val}
                            </div>
                        )) : ''}
                    </div>
                </div>
            ) : ('')}
        </div>
    )
}
export default InfoWindowMonitoring