import md5 from 'blueimp-md5'
export const GenerateDinicKey = () => {

	const data = new Date()
	const day = data.getDate()
	const month = data.getMonth() + 1
	const year = data.getFullYear()
	const fullDate = `${month <= 9 ? `0${month}` : month}-${String(year).replace('20', '')}-${day <= 9 ? `0${day}` : day}`
	const value = `e1c5d0263d954d405cdbf512070${fullDate}`
	var hash = md5(value)
	return hash
}