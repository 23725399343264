import { colorsToChartPercentageExecution, config, labelsToChartPorcenExec } from '../../constants'
import { generateRandomColorPaletteWithBrightness, groupBy, sortBrazilianDates } from '../arrayHandless'
import { formatDate } from '../dateHandless'

const loadConfigOutros = async () => {
	const ane_pl_ob_tipo_outro = await config('ane_pl_ob_tipo_outro')
	if (!ane_pl_ob_tipo_outro) {
		return
	}
	const config_ane_pl_ob_tipo_outro = await ane_pl_ob_tipo_outro
	const { config_para_value } = config_ane_pl_ob_tipo_outro
	return Number(config_para_value)
}
const loadConfigGaleria = async () => {
	const ane_pl_ob_tipo_galeria = await config('ane_pl_ob_tipo_galeria')
	if (!ane_pl_ob_tipo_galeria) {
		return
	}
	const config_ane_pl_ob_tipo_galeria = await ane_pl_ob_tipo_galeria
	const { config_para_value } = config_ane_pl_ob_tipo_galeria

	return Number(config_para_value)
}
const loadConfigPvsStatusPedente = async () => {
	const ped_venda_status_pendente_aprovacao = await config('ped_venda_status_pendente_aprovacao')
	if (!ped_venda_status_pendente_aprovacao) {
		return
	}
	const config_ped_venda_status_pendente_aprovacao = await ped_venda_status_pendente_aprovacao
	const { config_para_value } = config_ped_venda_status_pendente_aprovacao
	return Number(config_para_value)
}
const loadConfigWhichPartToTake = async () => {
	const pl_ob_cod_which_part_to_take = await config('pl_ob_cod_which_part_to_take')
	if (!pl_ob_cod_which_part_to_take) {
		return
	}
	const config_pl_ob_cod_which_part_to_take = await pl_ob_cod_which_part_to_take
	const { config_para_value } = config_pl_ob_cod_which_part_to_take
	return Number(config_para_value)
}
/**
 * Creates a dataset for a chart, combining predicted and executed progress data by aligning their dates.
 * 
 * @param {Object} params - The parameters for the function.
 * @param {Array} params.executedProgress - Array of executed progress data.
 * @param {Array} params.predictedProgress - Array of predicted progress data.
 * @returns {Object|null} The combined dataset for the chart, or `null` if an error occurs.
 * 
 * @throws Will throw an error if the executed or predicted progress arrays are empty or not provided.
 * 
 * @example
 * const dataset = createCombinedDataset({
 *   executedProgress: [
 *     { apo_data: '2024-04-25', apo_prog_ob_por_cen_progress: 10 },
 *     { apo_data: '2024-05-10', apo_prog_ob_por_cen_progress: 20 }
 *   ],
 *   predictedProgress: [
 *     { acob_date_prev_fim: '2024-04-30', acob_perc_prev: 15 },
 *     { acob_date_prev_fim: '2024-05-15', acob_perc_prev: 30 }
 *   ]
 * });
 */
const createCombinedDataset = ({ executedProgress, predictedProgress }) => {
	try {
		if (!executedProgress?.length || !predictedProgress?.length) {
			throw new Error("Executed progress or predicted progress arrays are empty");
		}

		const groupByDate = groupBy(executedProgress, 'apo_data');
		const groupedExecutedProgress = [];

		Object.keys(groupByDate).forEach(date => {
			if (Array.isArray(groupByDate[date])) {
				const latestProgress = groupByDate[date]
					.sort((a, b) => new Date(a.apo_prog_ob_por_cen_progress) - new Date(b.apo_prog_ob_por_cen_progress))
					.slice(-1)[0];
				groupedExecutedProgress.push({ ...latestProgress });
			}
		});

		const formattedPredictedProgress = predictedProgress.map(item => ({
			date: item.acob_date_prev_fim,
			percentage: item.acob_perc_prev
		}));

		const formattedExecutedProgress = groupedExecutedProgress.map(item => ({
			date: item.apo_data,
			percentage: item.apo_prog_ob_por_cen_progress
		}));

		const combineDataByDate = (predicted, executed) => {
			const allDates = [
				...new Set([
					...predicted.map(item => formatDate(item.date)),
					...executed.map(item => formatDate(item.date))
				])
			].sort();

			const sortedDates = sortBrazilianDates(allDates);

			return sortedDates.map((date, index) => {
				if (index === 0) {
					return { date, predicted: 0, executed: 0 };
				}

				const predictedValue = predicted.find(item => formatDate(item.date) === date)?.percentage || null;
				const executedValue = executed.find(item => formatDate(item.date) === date)?.percentage || null;

				return { date, predicted: predictedValue, executed: executedValue };
			});
		};

		const combinedData = combineDataByDate(formattedPredictedProgress, formattedExecutedProgress);

		const chartData = {
			labels: combinedData.map(item => item.date),
			datasets: [
				{
					label: 'Predicted Progress',
					data: combinedData.map(item => item.predicted),
					borderColor: colorsToChartPercentageExecution[0],
					backgroundColor: colorsToChartPercentageExecution[0],
					fill: true,
					tension: 0.3
				},
				{
					label: 'Executed Progress',
					data: combinedData.map(item => item.executed),
					borderColor: colorsToChartPercentageExecution[1],
					backgroundColor: colorsToChartPercentageExecution[1],
					fill: true,
					tension: 0.3
				}
			]
		};
		return chartData;
	} catch (error) {
		console.error(error);
		return null;
	}
};

const geneateDataChartPizzaPorCenExec = ({
	data, state
}) => {
	const { pl_ob_cod, sisf_sinc_int_amp_id, conc_nome } = state
	try {
		const pl_ob_reais_prev = data.reduce((previuesValues, currentValues) => previuesValues + (currentValues.pvs_qtd * currentValues.pvs_valor), 0)
		const accumulated = data.reduce((previuesValues, currentValues) => {
			if (currentValues.prog_serv_integracao_sisf) {
				return previuesValues + currentValues.prog_serv_percen_acul_ant
			}
			if (currentValues?.prog_serv_qtd_acul_ant !== null) {
				return sumACCAntWhenExistQtdAnt({
					...currentValues,
					pl_ob_reais_prev,
					previuesValues,
				})
			}
			return sumAccAntWhenNotExistQtdAnt({
				...currentValues,
				pl_ob_reais_prev,
				previuesValues,
			})
		}, 0)
		const progress_period = data.reduce((previuesValues, currentValues) => {
			if (currentValues.prog_serv_integracao_sisf) {
				return previuesValues + ((currentValues.prog_serv_qtd_acul * currentValues.pvs_valor * 100 / pl_ob_reais_prev) - currentValues.prog_serv_percen_acul_ant)
			}
			if (currentValues?.prog_serv_qtd_acul_ant !== null) {
				const prog_serv_qtd_acul_ant = currentValues.prog_serv_qtd_acul_ant
				const prog_serv_percen_acul_ant = (prog_serv_qtd_acul_ant * currentValues.pvs_valor * 100 / pl_ob_reais_prev)
				return previuesValues + ((currentValues.prog_serv_qtd_acul * currentValues.pvs_valor * 100 / pl_ob_reais_prev) - prog_serv_percen_acul_ant)
			}
			const prog_serv_percen_acul_decimal = currentValues.prog_serv_percen_acul / 100
			const prog_serv_qtd_acul_ant = currentValues.prog_serv_qtd_acul - (currentValues.prog_serv_qtd_acul * prog_serv_percen_acul_decimal)
			const prog_serv_percen_acul_ant = (prog_serv_qtd_acul_ant * currentValues.pvs_valor * 100 / pl_ob_reais_prev)
			return previuesValues + ((currentValues.prog_serv_qtd_acul * currentValues.pvs_valor * 100 / pl_ob_reais_prev) - prog_serv_percen_acul_ant)
		}, 0)
		const will_be_executed = 100 - (accumulated + progress_period)
		const chartData = [accumulated, progress_period, will_be_executed]
		const backgroundColor = generateRandomColorPaletteWithBrightness(chartData.length)
		const labels = labelsToChartPorcenExec.map((item, i) => `${item} - ${parseFloat(chartData[i]).toFixed(2)}`)
		const option_legend = [{
			label: labels[0],
			value: chartData[0]
		},
		{
			label: labels[1],
			value: chartData[1]
		},
		{
			label: labels[2],
			value: chartData[2]
		}
		]
		return {
			labels,
			datasets: [
				{
					label: `Plano de obra n° ${sisf_sinc_int_amp_id || pl_ob_cod} | ${conc_nome}`,
					data: chartData.map(item => isNaN(item) ? 0 : Number(item)),
					backgroundColor: colorsToChartPercentageExecution,
					borderColor: backgroundColor[1].brightness,
					borderWidth: 1,
				},
			],
			option_legend
		}
	} catch (error) {
		console.log(error)
		return null
	}
}
const generateItemsCalculedAcPr = ({ dataWithCodigo, pl_ob_reais_prev }) => {
	try {
		const sumCalcAcProg = dataWithCodigo.map(item => {
			let prog_serv_percen_progress,
				prog_serv_percen_acul_ant,
				prog_serv_percen_acul
			if (item?.prog_serv_integracao_sisf) {
				if (item.prog_serv_percen_acul < item.prog_serv_percen_acul_ant) {
					prog_serv_percen_progress = item.prog_serv_percen_acul_ant - item.prog_serv_percen_acul
				} else {
					prog_serv_percen_progress = item.prog_serv_percen_acul - item.prog_serv_percen_acul_ant
				}
				prog_serv_percen_acul = item.prog_serv_percen_acul
				prog_serv_percen_acul_ant = item.prog_serv_percen_acul_ant
			} else {
				const prog_serv_percen_acul_temp = (item.prog_serv_qtd_acul * item.pvs_valor * 100 / pl_ob_reais_prev)
				const prog_serv_qtd_acul_ant = item.prog_serv_qtd_acul_ant
				prog_serv_percen_acul_ant = (prog_serv_qtd_acul_ant * item.pvs_valor * 100 / pl_ob_reais_prev)
				if (prog_serv_percen_acul_temp < prog_serv_percen_acul_ant) {
					prog_serv_percen_progress = prog_serv_percen_acul_ant - prog_serv_percen_acul_temp
				} else {
					prog_serv_percen_progress = prog_serv_percen_acul_temp - prog_serv_percen_acul_ant
				}
				prog_serv_percen_acul = prog_serv_percen_acul_temp
			}
			return {
				...item,
				prog_serv_percen_progress: isNaN(prog_serv_percen_progress) ? 0 : prog_serv_percen_progress,
				prog_serv_percen_acul: isNaN(prog_serv_percen_acul) ? 0 : prog_serv_percen_acul,
				prog_serv_percen_acul_ant: isNaN(prog_serv_percen_acul_ant) ? 0 : prog_serv_percen_acul_ant,
			}
		})
		return sumCalcAcProg
	} catch (error) {
		console.log(error)
		return []
	}
}
const sumProgWhenNotExistQtdAnt = ({
	prog_serv_percen_acul,
	pvs_valor,
	prog_serv_qtd_acul,
	pl_ob_reais_prev,
	previuesValues
}) => {
	const prog_serv_percen_acul_decimal = prog_serv_percen_acul / 100
	const prog_serv_qtd_acul_ant = prog_serv_qtd_acul - (prog_serv_qtd_acul * prog_serv_percen_acul_decimal)
	const prog_serv_percen_acul_ant = (prog_serv_qtd_acul_ant * pvs_valor * 100 / pl_ob_reais_prev)
	const prog_serv_percen_acul_temp = (prog_serv_qtd_acul * pvs_valor * 100 / pl_ob_reais_prev)
	if (prog_serv_percen_acul_temp < prog_serv_percen_acul_ant) {
		return previuesValues + (prog_serv_percen_acul_ant - prog_serv_percen_acul_ant)
	}
	return previuesValues + (prog_serv_percen_acul_temp - prog_serv_percen_acul_ant)
}
const sumProgWhenExistQtdAnt = ({
	prog_serv_qtd_acul_ant,
	pvs_valor,
	prog_serv_qtd_acul,
	pl_ob_reais_prev,
	previuesValues
}) => {
	const prog_serv_percen_acul_ant = (prog_serv_qtd_acul_ant * pvs_valor * 100 / pl_ob_reais_prev)
	const prog_serv_percen_acul = (prog_serv_qtd_acul * pvs_valor * 100 / pl_ob_reais_prev)
	if (prog_serv_percen_acul < prog_serv_percen_acul_ant) {
		return previuesValues + (prog_serv_percen_acul_ant - prog_serv_percen_acul_ant)
	}
	return previuesValues + (prog_serv_percen_acul - prog_serv_percen_acul_ant)
}
const sumAccAntWhenNotExistQtdAnt = ({
	prog_serv_percen_acul,
	prog_serv_qtd_acul,
	pvs_valor,
	pl_ob_reais_prev,
	previuesValues
}) => {
	const prog_serv_percen_acul_decimal = prog_serv_percen_acul / 100
	const prog_serv_qtd_acul_ant = prog_serv_qtd_acul - (prog_serv_qtd_acul * prog_serv_percen_acul_decimal)
	const prog_serv_percen_acul_ant = (prog_serv_qtd_acul_ant * pvs_valor * 100 / pl_ob_reais_prev)
	return previuesValues + prog_serv_percen_acul_ant
}
const sumACCAntWhenExistQtdAnt = ({
	prog_serv_qtd_acul_ant,
	pvs_valor,
	pl_ob_reais_prev,
	previuesValues
}) => {
	const prog_serv_percen_acul_ant = (prog_serv_qtd_acul_ant * pvs_valor * 100 / pl_ob_reais_prev)
	return previuesValues + prog_serv_percen_acul_ant
}
const generateSumItems = (items) => {
	if (!items?.length) {
		return {
			pl_ob_reais_prev: 0,
			pl_ob_reais_exec: 0,
			pl_ob_reais_miss: 0,
			prog_serv_percen_progress: 0,
			prog_serv_percen_acul: 0,
			prog_serv_percen_acul_ant: 0,
		}
	}
	const pl_ob_reais_prev = items.reduce((previuesValues, currentValues) => previuesValues + (currentValues.pvs_qtd * currentValues.pvs_valor), 0)
	const pl_ob_reais_exec = items.reduce((previuesValues, currentValues) => previuesValues + (currentValues.prog_serv_qtd_acul * currentValues.pvs_valor), 0)
	const pl_ob_reais_miss = pl_ob_reais_prev - pl_ob_reais_exec
	const prog_serv_percen_progress = items.reduce((previuesValues, currentValues) => {
		if (currentValues?.prog_serv_integracao_sisf) {
			if (currentValues.prog_serv_percen_acul < currentValues.prog_serv_percen_acul_ant) {
				return previuesValues + (currentValues.prog_serv_percen_acul_ant - currentValues.prog_serv_percen_acul)
			}
			return previuesValues + (currentValues.prog_serv_percen_acul - currentValues.prog_serv_percen_acul_ant)
		}
		if (currentValues?.prog_serv_qtd_acul_ant !== null) {
			return sumProgWhenExistQtdAnt({
				...currentValues,
				pl_ob_reais_prev,
				previuesValues
			})
		}
		return sumProgWhenNotExistQtdAnt({
			...currentValues,
			pl_ob_reais_prev,
			previuesValues
		})
	}, 0)
	const prog_serv_percen_acul = items.reduce((previuesValues, currentValues) => {
		if (currentValues?.prog_serv_integracao_sisf) {
			return previuesValues + currentValues?.prog_serv_integracao_sisf
		}
		return previuesValues + (currentValues.prog_serv_qtd_acul * currentValues.pvs_valor * 100 / pl_ob_reais_prev)
	}, 0)
	const prog_serv_percen_acul_ant = items.reduce((previuesValues, currentValues) => {
		if (currentValues.prog_serv_integracao_sisf) {
			return previuesValues + currentValues.prog_serv_percen_acul_ant
		}
		if (currentValues?.prog_serv_qtd_acul_ant !== null) {
			return sumACCAntWhenExistQtdAnt({
				...currentValues,
				pl_ob_reais_prev,
				previuesValues,
			})
		}
		return sumAccAntWhenNotExistQtdAnt({
			...currentValues,
			pl_ob_reais_prev,
			previuesValues,
		})
	}, 0)
	return {
		pl_ob_reais_prev,
		pl_ob_reais_exec,
		pl_ob_reais_miss,
		prog_serv_percen_progress,
		prog_serv_percen_acul,
		prog_serv_percen_acul_ant,
	}
}
export {
	loadConfigGaleria,
	loadConfigOutros,
	loadConfigPvsStatusPedente,
	createCombinedDataset,
	geneateDataChartPizzaPorCenExec,
	generateItemsCalculedAcPr,
	generateSumItems,
	loadConfigWhichPartToTake
}