import React, { useContext, useEffect } from 'react'
import { requestContext } from '../../../context/requestContext'
import ContentHeader from '../../../components/contentHeader'
import Spinner from '../../../components/spinner'
import { formContext } from '../../../context/formContext'
import { Link, useNavigate } from 'react-router-dom'
import { authContext } from '../../../context/authContext'
import ListWorkOfTipe from '../../../components/list/workOfArt/ListWorkOfTipe'
import SearchTypeWorkofArt from '../../../components/form/searchTypeWorkofArt'
const ManageWorkOfArtType = () => {
	const { getItemObArtType, getWhereItemObArtType, obArtType } = useContext(requestContext)
	const { handleForm, state: { ob_art_tipo_desc } } = useContext(formContext)
	const { user } = useContext(authContext)
	const navigate = useNavigate()
	useEffect(() => {
		getItemObArtType()
	}, [])
	const handlePage = (item) => {
		if (!item) {
			return
		}
		const { label } = item
		const { current_page, last_page } = obArtType
		window.scrollTo(0, 0)
		if (label === 'pagination.previous') {
			return ob_art_tipo_desc ? getWhereItemObArtType(current_page > 1 && `${ob_art_tipo_desc}?page=${current_page - 1}`) : getItemObArtType(current_page > 1 && `0?page=${current_page - 1}`)
		}
		if (label === 'pagination.next') {
			return ob_art_tipo_desc ? getWhereItemObArtType(current_page > 1 && `${ob_art_tipo_desc}?page=${current_page - 1}`) : getItemObArtType(current_page < last_page && `0?page=${current_page + 1}`)
		}
		if (!label) {
			return ob_art_tipo_desc ? getWhereItemObArtType(ob_art_tipo_desc) : getItemObArtType()
		}
		if (ob_art_tipo_desc) {
			return getWhereItemObArtType(`${ob_art_tipo_desc}?page=${label}`)
		}
		getItemObArtType(`0?page=${label}`)
	}
	const handleNew = () => {
		handleForm({
			ob_art_tipo_cod: '',
			ob_art_tipo_desc: '',
			ob_art_tipo_ativo: ''
		})
		navigate('/work_of_art_type/new')
	}
	const RenderPagination = ({ item }) => {
		return (
			<li className="page-item" key={item.label}>
				<Link
					className={`page-link ${item.active && 'text-light bg-danger'
					}`}
					to="#"
					onClick={() => handlePage(item)}
				>
					{item.label === 'pagination.previous' ? (
						<i className="fa fa-angle-left"></i>
					) : item.label === 'pagination.next' ? (
						<i className="fa fa-angle-right"></i>
					) : (
						item.label
					)}
				</Link>
			</li>
		)
	}
	return (
		<>
			<ContentHeader title={'Cadastro de Tipos de Obra de arte'} />
			<div className="content">
				<div className="card sticky-top">
					<div className="card-header">
						<h3 className="card-title col-8 d-none d-md-block">
                            Tipos de Obra de arte cadastrados
						</h3>
						<div className="card-tools">
							<SearchTypeWorkofArt handleNew={handleNew} />
						</div>
					</div>
				</div>
				<div className="card">
					<Spinner direction={'center'} label="carregando" />
					<ListWorkOfTipe data={obArtType} />
					<div className="card-footer clearfix">
						<ul className="pagination pagination-sm m-0 float-right">
							{Array.isArray(obArtType?.links) &&
                                obArtType.links.map(item => <RenderPagination item={item} key={item.label} />)}
						</ul>
						{user && user.set_nivel === 1 && (
							<ul className="pagination pagination-sm m-0 float-left">
								<li className="page-item">
									<div className="page-link" onClick={handleNew}>
                                        Novo <i className="fa fa-plus mr-1 ml-1"></i>
									</div>
								</li>
							</ul>
						)}
					</div>
				</div>
			</div>
		</>
	)
}
export default ManageWorkOfArtType