import { filterAndRemoveRepeated } from '../arrayHandless'

/* eslint-disable array-callback-return */
export const storageCache = (nameCache, value) => {
	if (!value) {
		return window.localStorage.removeItem(nameCache)
	}
	return window.localStorage.setItem(nameCache, JSON.stringify(value))
}
export const recoverCache = (nameCache) => {
	return JSON.parse(window.localStorage.getItem(nameCache))
}
export const postCache = (nameCache, value) => {
	if (!value) {
		return window.localStorage.removeItem(nameCache)
	}
	const oldeData = JSON.parse(window.localStorage.getItem(nameCache))
	if (!Array.isArray(oldeData)) {
		return window.localStorage.setItem(nameCache, JSON.stringify([value]))
	}
	const clearObject = filterAndRemoveRepeated([
		...oldeData,
		value
	])
	return window.localStorage.setItem(nameCache, JSON.stringify(clearObject))
}
export const getallStorage = () => {
	var values = [],
		keys = Object.keys(localStorage),
		i = keys.length

	while (i--) {
		values.push({
			[keys[i]]: recoverCache(keys[i])
		})
	}
	if (values.length === 0) {
		return
	}
	return values
}
