import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { requestContext } from '../../../context/requestContext'
const ListChecklists = ({ data }) => {
    const { setChecklists } = useContext(requestContext)
    const navigate = useNavigate()
    const handleAnswer = (item) => {
        setChecklists(item)
        navigate('/answer_checklist')
    }
    // const handleDelete = (item) => {
    //     const confirm = window.confirm(`Deseja mesmo excluir o serviço ${item.serv_desc}?`)
    //     if (confirm) {
    //     }
    // }
    return (
        data &&
            data.total > 0 &&
            data.data.map(item => (
                <div key={item.check_cod}>
                    <div className='d-flex justify-content-between align-center p-3'>
                        <div>{item.check_nome}</div>
                        <div className='row'>
                            <button className='btn btn-danger btn-sm mr-2' onClick={() => handleAnswer(item)}>
                                <i className='ml-1 fa fa-edit'></i> Responder
                            </button>
                            {/* <button className='btn btn-secondary btn-sm' onClick={() => handleDelete(item)}>
                                <i className='fa fa-trash'></i>
                            </button> */}
                        </div>
                    </div>
                    <div className='text-secondary ml-2 col-12'>
                        {item.check_desc}
                    </div>
                    <hr />
                </div>
            ))
    )
}
export default ListChecklists