import React, { useContext, useEffect, useRef, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { styleButtonPlus } from '../../../css/globlalStyle'
import returnPosition from '../../../services/geolocation'
import { handleWaring } from '../../../services/isValid'
import { filterAndRemoveRepeated, find } from '../../../services/arrayHandless'
import {
	isBeforeDate,
	formateValidatedDate,
	addDate,
	getToday,
} from '../../../services/dateHandless'
import Selectservice from '../selectservice'
import { isValid } from '../../../services/isValid'
import { cursorFocus } from '../../../services/scroll'
import Spinner from '../../spinner'
import { getExt, generateBse64 } from '../../../services/files'
import Camera from '../../camera'
import ListNewServiceOversight from '../../list/newServiceOversight'
import SelectServiceCat from '../selectServiceCat'
import SelectServiceSubCat from '../selectServiceSubCat'
const SelectServices = ({ deadline }) => {
	const { state, onChange, handleForm, showCamera, setShowCamera, bases64, setBases64 } =
		useContext(formContext)
	const {
		getAllItemService,
		getAllItemCategoryService,
		getAllItemServiceSubCat,
		setInfoRequest,
		setLoading,
	} = useContext(requestContext)
	const [minSelectedDate, setMinSelectedDate] = useState(null)
	const {
		serv_desc,
		serv_cod,
		serv_cat_cod,
		serv_sub_cat_cod,
		serv_sub_cat_desc,
		serv_cat_prazo,
		praz_dias,
		pv_servico,
		rod_sent_cod,
		rod_km_ini,
		rod_km_ini_mt,
		rod_km_fim,
		rod_km_fim_mt,
		pv_loc_obs,
		rod_sent_desc,
		ane_pl_ob_obs,
		pvs_loc_foto_legenda,
		serv_cat_desc,
	} = state
	useEffect(() => {
		getAllItemService()
	}, [])
	useEffect(() => {
		if (serv_sub_cat_desc) {
			handleForm({
				ane_pl_ob_obs: `Foto referente a atividade ${serv_sub_cat_desc}`,
				pvs_loc_foto_legenda: `Foto referente a atividade ${serv_sub_cat_desc}`
			})
		}
	}, [serv_sub_cat_desc])
	useEffect(() => setMinSelectedDate(formateValidatedDate()), [])
	useEffect(() => {
		const loadServiceCat = () => {
			if (serv_cod) {
				getAllItemCategoryService(serv_cod)
			}
		}
		loadServiceCat()
	}, [serv_cod])
	useEffect(() => {
		const loadServiceSubCat = () => {
			if (serv_cat_cod) {
				handleForm({
					serv_sub_cat_serv_cat: serv_cat_cod,
					serv_cat_prazo: '',
				})
				getAllItemServiceSubCat(serv_cat_cod)
			}
		}
		loadServiceSubCat()
	}, [serv_cat_cod])
	useEffect(() => {
		if (praz_dias) {
			handleForm({
				serv_cat_prazo: praz_dias > 0 ? addDate(praz_dias) : '',
			})
		}
	}, [praz_dias])
	const handleResetValues = () => {
		setLoading(false)
		handleForm({
			ane_pl_ob_obs: '',
			serv_cat_prazo: '',
			serv_cod: '',
			serv_sub_cat_cod: '',
			serv_cat_cod: '',
			praz_dias: '',
			rod_sent_cod: '',
			rod_km_ini: '',
			rod_km_fim: '',
			rod_km_ini_mt: '',
			rod_km_fim_mt: '',
		})
		try {
			const input_rod_km_ini = document.getElementById('rod_km_ini')
			cursorFocus(input_rod_km_ini)
		} catch (error) {
			setInfoRequest(handleWaring(String(error)))
		}
	}
	const handleService = async () => {
		if (!praz_dias && isBeforeDate(serv_cat_prazo)) {
			handleForm({
				serv_cat_prazo: '',
			})
			return setInfoRequest(
				handleWaring(
					'Você não definir o prazo do serviço menor ou igual ao dia de hoje'
				)
			)
		}
		if (!serv_cod || !serv_cat_cod || !serv_sub_cat_cod || !serv_cat_prazo) {
			return setInfoRequest(
				handleWaring(
					'Você precisa preencher todos os campos relacionados ao serviço'
				)
			)
		}
		if (filterAndRemoveRepeated(pv_servico, 'serv_sub_cat_cod', serv_sub_cat_cod)) {
			return setInfoRequest(
				handleWaring(
					`O serviço ${serv_desc} com a Atividade ${serv_sub_cat_desc} já foi adicionado`
				)
			)
		}
		const position = await returnPosition()
		const pedido_venda_local = {
			pv_loc_km_ini: rod_km_ini,
			pv_loc_km_init_mts: rod_km_ini_mt,
			pv_loc_km_fim: rod_km_fim,
			pv_loc_km_fim_mts: rod_km_fim_mt,
			pv_loc_lat: position.lat,
			pv_loc_lng: position.lng,
			pv_loc_obs: pv_loc_obs,
			rod_sent_desc,
			pv_loc_sentido: rod_sent_cod,
		}
		if (!isValid(pedido_venda_local)) {
			return setInfoRequest(
				handleWaring('Você precisa informar corretamente a sua localização')
			)
		}
		if (bases64?.length >= 1) {
			return pushServices({ position, pedido_venda_local, pv_servico_foto: bases64 })
		}
		return setInfoRequest(handleWaring('Você precisa pelo menos selecionar uma foto'))
	}
	const pushServices = ({ position, pedido_venda_local, pv_servico_foto }) => {
		if (!pv_servico_foto) {
			return setInfoRequest(handleWaring('Você precisa pelo menos selecionar uma foto'))
		}
		const newObjectItem = {
			pvs_servico: serv_cod,
			pvs_servico_cat: serv_cat_cod,
			pvs_servico_sub_cat: serv_sub_cat_cod,
			pvs_obs: serv_sub_cat_desc,
			serv_desc,
			serv_cat_desc,
			serv_sub_cat_desc,
			pvs_prazo_fim: `${serv_cat_prazo} 11:59:00`,
			pvs_lat: position.lat || 0,
			pvs_lng: position.lng || 0,
			pedido_venda_local: {
				...pedido_venda_local,
			},
			pv_servico_foto,
		}
		if (Array.isArray(pv_servico)) {
			handleForm({
				pv_servico: [
					...pv_servico, {
						...newObjectItem,
					}
				],
			})
		} else {
			handleForm({
				pv_servico: [{
					...newObjectItem
				}]
			})
		}
		handleResetValues()
	}
	const onvisibleCamera = () => {
		setShowCamera(!showCamera)
	}
	const input_file_ref = useRef()
	useEffect(() => {
		handleForm({
			ane_pl_ob_obs: '',
			help_desc: '',
		})
		setBases64(null)
	}, [])
	const onChangeFile = async ({ target }) => {
		if (!pvs_loc_foto_legenda) {
			return setInfoRequest(handleWaring('Você precisa selecionar uma atividade antes de adicionar uma foto'))
		}
		setLoading(true)
		const { files } = target
		if (files.length === 0) {
			return setLoading(false)
		}
		const newItems = []
		for (const itemFile of files) {
			const { name } = itemFile
			const type = getExt(name)
			const types_valid = [{ type: 'jpeg' }, { type: 'jpg' }, { type: 'png' }]
			const { data: find_type } = find(types_valid, 'type', type)
			if (find_type) {
				const position = await returnPosition()
				const base64 = await generateBse64(itemFile)
				newItems.push({
					base64: base64,
					name: name,
					pvs_loc_foto_lat: position.lat,
					pvs_loc_foto_lng: position.lng,
					pvs_loc_foto_legenda: `Foto tirada dia ${getToday()}. ${pvs_loc_foto_legenda}`,
				})
			}
		}
		if (!newItems.length) {
			return setLoading(false)
		}
		if (bases64?.length) {
			setLoading(false)
			return setBases64([...newItems, ...bases64])
		}
		setBases64([...newItems])
		setLoading(false)
	}
	const handleAttachments = () => {
		input_file_ref.current.click()
	}
	return (
		<div className="row">
			<div className="col-md-6 col-sm-12">
				<Selectservice label={'Tipo Atividade:'} />
			</div>
			<div className='col-md-6 col-sm-12'>
				<SelectServiceCat />
			</div>
			<div className='col-12'>
				<SelectServiceSubCat />
			</div>
			{serv_sub_cat_cod && !deadline && (
				<div className="form-group col-12">
					<label>Prazo para finalização do serviço:</label>
					<div className="input-group">
						<input
							type="date"
							onChange={onChange}
							min={minSelectedDate}
							name="serv_cat_prazo"
							value={serv_cat_prazo}
							className={`form-control form-control-border ${!serv_cat_prazo && 'is-invalid'}`}
							placeholder="Selecionar Serviço"
							disabled={praz_dias}
						/>
					</div>
				</div>
			)}
			{showCamera && <Camera pvs_loc_foto_legenda={pvs_loc_foto_legenda} />}
			{deadline ? '' : (
				<>
					<div className="form-group col-md-6 col-sm-12">
						<label>Anexar uma foto:</label>
						<div className="input-group">
							<div className="btn btn-dark btn-sm" onClick={handleAttachments}>
								Clique aqui para selecionar uma foto <i className="fa fa-paperclip"></i>
								{Array.isArray(bases64) && bases64.map(item => item?.name && item.name).join(',')}
							</div>
						</div>
					</div>
					<div className="form-group col-md-6 col-sm-12 mt-4">
						<div className="btn btn-danger" onClick={onvisibleCamera}>
							<i className="fa fa-camera"></i> Abrir camera
						</div>
					</div>
					<div className="form-group col-12">
						<label>Observações da foto:</label>
						<div className="input-group">
							<textarea
								type="text"
								onChange={onChange}
								rows={2}
								name="ane_pl_ob_obs"
								value={ane_pl_ob_obs}
								className={`form-control form-control-border ${!ane_pl_ob_obs && 'is-invalid'
								}`}
								placeholder="Coloque aqui informações sobre a foto"
							>
								{ane_pl_ob_obs}
							</textarea>
							<div className="input-group-prepend">
								<span
									className="input-group-text bg-danger"
									onClick={handleService}
									style={styleButtonPlus}
								>
									<Spinner label=" " />
									<i className="fa fa-plus" aria-hidden="true"></i>
								</span>
							</div>
						</div>
					</div>
					<input
						name="file_attachments"
						className="d-none"
						type="file"
						onChange={onChangeFile}
						ref={input_file_ref}
						multiple
					/>
				</>
			)}
			{Array.isArray(pv_servico) && (
				<>
					<div className="form-group col-12">
						<label>Serviços adicionados</label>
					</div>
					<div className="form-group col-12">
						<ListNewServiceOversight />
					</div>
				</>
			)}
		</div>
	)
}
export default SelectServices
