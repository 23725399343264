import React, { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../context/formContext'
import HighwayExtension from '../highwayExtension'
import SelectHighway from '../selectHighway'
import Dealership from '../dealership'
import SelectCompany from '../selectCompany'
import SelectGroups from '../selectGroups'

const FormHighwayLimit = () => {
	const { state, handleForm } = useContext(formContext)
	const [valuesFixed, setValuesFixed] = useState(null)
	const {
		conc_cod,
		rod_km_ini,
		rod_km_ini_mt,
		rod_km_fim,
		rod_km_fim_mt,
		rod_cod,
		emp_cod,
		gp_cod
	} = state
	useEffect(() => {
		const prepareValues = () => {
			handleForm({
				lim_km_ini: rod_km_ini,
				lim_km_ini_mts: rod_km_ini_mt,
				lim_km_fim: rod_km_fim,
				lim_km_fim_mts: rod_km_fim_mt,
				lim_conc: conc_cod,
				lim_rod: rod_cod,
				lim_emp: emp_cod,
				lim_gp: gp_cod,
			})
		}
		prepareValues()
	}, [
		conc_cod,
		rod_km_ini,
		rod_km_ini_mt,
		rod_km_fim,
		rod_km_fim_mt,
		rod_cod,
		emp_cod,
		gp_cod,
	])
	return (
		<div className="row">
			<SelectCompany col={'col-md-6 col-sm-12'} />
			<SelectGroups col={'col-md-6 col-sm-12'} />
			<Dealership col={'col-md-6 col-sm-12'} />
			{conc_cod ? (
				<SelectHighway setValuesFixed={setValuesFixed} col={'col-md-6 col-sm-6'} />
			) : (
				<div className="col-md-6 col-sm-6">
					<label>Rodovia:</label>
					<div className="input-group">
						<div className='form-control form-control-border'>
                            Selecione uma Concessionária
						</div>
					</div>
				</div >
			)}
			<HighwayExtension valuesFixed={valuesFixed} />
		</div>
	)
}
export default FormHighwayLimit