import React from 'react'

const PinContent = ({item}) =>{
	return(
		<div>
			
			{item?.fotos?.length>0&&<div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
				<div className="carousel-inner">
					{item?.fotos?.map(foto=>(
						<div key={foto.url} className="carousel-item active">
							<img style={{maxHeight:250}} className="d-block w-100" src={foto.url} alt="First slide" />
						</div>))}
				</div>
				<a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
					<span className="carousel-control-prev-icon" aria-hidden="true"></span>
					<span className="sr-only">Previous</span>
				</a>
				<a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
					<span className="carousel-control-next-icon" aria-hidden="true"></span>
					<span className="sr-only">Next</span>
				</a>
			</div>}

			<p><strong>Concessionária:</strong> {item.conc_nome}</p>
			<p><strong>Rodovia:</strong> {item.rod_km}</p>
			<p><strong>Grupo de atividade:</strong> {item.serv_cat_desc}</p>
			<p><strong>Atividade:</strong> {item.serv_sub_cat_desc}</p>
			<p><strong>Km Inicial:</strong> {item.km_ini},{item.mts_ini}  <strong>Km Final:</strong> {item.km_fim},{item.mts_fim}</p>
		</div>
	)
}

export default PinContent