/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ContentHeader from "../../../components/contentHeader";
import ListOversightReport from "../../../components/list/oversightReport";
import Spinner from "../../../components/spinner";
import { requestContext } from "../../../context/requestContext";
import FormReport from "../../../components/form/report";
import { formContext } from "../../../context/formContext";
import { getQueryParams } from "../../../webService";
import { handleWaring, handleSuccess } from "../../../services/isValid";
const ReportByOversight = () => {
  const navigate = useNavigate();
  const {
    getItemReportOversight_,
    report,
    setHighway,
    getAllItemHighway,
    getAllItemDealership,
    getAllItemCardinalPoints,
    getAllItemService,
    setReport,
    setInfoRequest,
    getItemReportOversight,
  } = useContext(requestContext);
  const { state, handleForm } = useContext(formContext);
  const [page, setPage] = useState("");
  const [itemsSelected, setItemsSelected] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [orderReport, setOrderReport] = useState(null)
  useEffect(() => {
    const controlToasSuccess = () => {
      if (selectAll && totalItems > 0) {
        setInfoRequest(handleSuccess(`Todos os ${totalItems} items foram selecionados`));
      }
    }
    controlToasSuccess()
  }, [selectAll, totalItems])
  const {
    conc_cod,
    rod_cod,
    rod_sent_cod,
    serv_cod,
    serv_cat_cod,
    serv_sub_cat_cod,
    serv_cat_prazo_from,
    serv_cat_prazo_to,
    rod_km_ini_from,
    rod_km_ini_to,
    ped_venda_status,
    ped_venda_responsavel,
    gp_cod,
  } = state;
  useEffect(() => {
    resetValues();
    setHighway();
    getAllItemHighway();
    getAllItemDealership();
    getAllItemCardinalPoints();
    getAllItemService(null,1);
  }, []);
  const handlePage = (item) => {
    if (!item) {
      return;
    }
    const { label } = item;
    const { current_page, last_page } = report;
    const whereFilters = {
      ped_venda_concessionaria: conc_cod || null,
      ped_venda_status: ped_venda_status || null,
      ped_venda_responsavel: ped_venda_responsavel || null,
      ped_venda_rodovia: rod_cod || null,
      ped_venda_date_from: serv_cat_prazo_from || null,
      ped_venda_date_to: serv_cat_prazo_to || null,
      pvs_servico: serv_cod || null,
      pvs_servico_cat: serv_cat_cod || null,
      pvs_servico_sub_cat: serv_sub_cat_cod || null,
      pv_loc_km_ini_from: rod_km_ini_from || null,
      pv_loc_km_ini_to: rod_km_ini_to || null,
      pv_loc_sentido: rod_sent_cod || null,
      gp_cod: gp_cod || null,
    };
    const queryFilter = getQueryParams(whereFilters);
    window.scrollTo(0, 0);
    if (label === "pagination.previous") {
      const page_ = current_page > 1 && `?page=${current_page - 1}`;
      setPage(page_);
      return getItemReportOversight_(`/${queryFilter}${page_}`);
    }
    if (label === "pagination.next") {
      const page_ = current_page < last_page && `?page=${current_page + 1}`;
      setPage(page_);
      return getItemReportOversight_(`/${queryFilter}${page_}`);
    }
    if (!label) {
      const page_ = "";
      setPage(page_);
      return getItemReportOversight_(`/${queryFilter}${page_}`);
    }
    const page_ = `?page=${label}`;
    getItemReportOversight_(`/${queryFilter}${page_}`);
  };
  const handleCancel = () => {
    navigate("/");
  };
  const onSubmit = () => {
    window.scrollTo(0, 0);
    const whereFilters = {
      ped_venda_concessionaria: conc_cod || null,
      ped_venda_status: ped_venda_status || null,
      ped_venda_responsavel: ped_venda_responsavel || null,
      ped_venda_rodovia: rod_cod || null,
      ped_venda_date_from: serv_cat_prazo_from || null,
      ped_venda_date_to: serv_cat_prazo_to || null,
      pvs_servico: serv_cod || null,
      pvs_servico_cat: serv_cat_cod || null,
      pvs_servico_sub_cat: serv_sub_cat_cod || null,
      pv_loc_km_ini_from: rod_km_ini_from || null,
      pv_loc_km_ini_to: rod_km_ini_to || null,
      pv_loc_sentido: rod_sent_cod || null,
      gp_cod: gp_cod || null,
    };
    const queryFilter = getQueryParams(whereFilters);
    getItemReportOversight_(`/${queryFilter}${page}`);
  };
  const resetValues = () => {
    window.scrollTo(0, 0);
    setReport(null);
    setItemsSelected(null);
    const whereFilters = {
      ped_venda_concessionaria: conc_cod || null,
      ped_venda_status: ped_venda_status || null,
      ped_venda_responsavel: ped_venda_responsavel || null,
      ped_venda_rodovia: rod_cod || null,
      ped_venda_date_from: serv_cat_prazo_from || null,
      ped_venda_date_to: serv_cat_prazo_to || null,
      pvs_servico: serv_cod || null,
      pvs_servico_cat: serv_cat_cod || null,
      pvs_servico_sub_cat: serv_sub_cat_cod || null,
      pv_loc_km_ini_from: rod_km_ini_from || null,
      pv_loc_km_ini_to: rod_km_ini_to || null,
      pv_loc_sentido: rod_sent_cod || null,
      gp_cod: gp_cod || null,
    };
    const queryFilter = getQueryParams(whereFilters);
    getItemReportOversight_(`/${queryFilter}`);
    handleForm({
      ped_venda_responsavel: "",
    });
  };

  useEffect(() => {
    if (report?.total > 0) {
      setTotalItems(report.total);
      const tempArr = report?.data?.sort((a, b) => b.ped_venda_cod - a.ped_venda_cod);
      setOrderReport({...report,data:tempArr})
    }
  }, [report]);

  const handleSeletcAllItems = () => {
    setSelectAll(!selectAll);
    setItemsSelected(null);
  };
  const handleMarkOnOff = () => {
    if (Array.isArray(itemsSelected)) {
      return setItemsSelected(null);
    }
  };
  const handleReport = () => {
    let ids = null;
    if(!itemsSelected?.length && !selectAll) {
      return setInfoRequest(handleWaring('Você precisa selecionar pelo menos 1 NC'))
    }
    if (Array.isArray(itemsSelected)) {
      let tempIds = "";
      itemsSelected.map(
        (item) =>
          (tempIds = `${tempIds}${tempIds?.length > 0 ? "," : ""}${
            item.ped_venda_cod
          }`)
      );
      ids = tempIds;
    }

    const whereFilters = {
      ped_venda_concessionaria: conc_cod || null,
      ped_venda_status: ped_venda_status || null,
      ped_venda_responsavel: ped_venda_responsavel || null,
      ped_venda_rodovia: rod_cod || null,
      ped_venda_date_from: serv_cat_prazo_from || null,
      ped_venda_date_to: serv_cat_prazo_to || null,
      pvs_servico: serv_cod || null,
      pvs_servico_cat: serv_cat_cod || null,
      pvs_servico_sub_cat: serv_sub_cat_cod || null,
      pv_loc_km_ini_from: rod_km_ini_from || null,
      pv_loc_km_ini_to: rod_km_ini_to || null,
      pv_loc_sentido: rod_sent_cod || null,
      gp_cod: gp_cod || null,
      ids_type: selectAll ? "notin" : "in",
      ids: ids,
    };
    const queryFilter = getQueryParams(whereFilters);
    getItemReportOversight(queryFilter);
  };
  const RenderPaginationPages = ({ item }) => {
    return (
      <li className="page-item" key={item.label}>
        <Link
          className={`page-link ${item.active && "text-light bg-danger"}`}
          to="#"
          onClick={() => handlePage(item)}
        >
          {item.label === "pagination.previous" ? (
            <i className="fa fa-angle-left"></i>
          ) : item.label === "pagination.next" ? (
            <i className="fa fa-angle-right"></i>
          ) : (
            item.label
          )}
        </Link>
      </li>
    );
  };
  return (
    <>
      <ContentHeader title={"Relatório Detalhado"} />
      <div className="content">
        <div className="card">
          <div className="card-body">
            <FormReport onSubmit={onSubmit} />
          </div>
        </div>
        <div className="card sticky-top">
          <div className="card-header">
            <h3 className="card-title d-md-block d-none">NCs</h3>
            <div className="card-tools">
              <div className="row">
                <Link
                  to="#"
                  className="btn btn-sm btn-danger mr-2"
                  onClick={handleSeletcAllItems}
                >
                  <i className="fa fa-check"></i> Selecionar tudo
                </Link>
                {(Array.isArray(itemsSelected) || selectAll) && (
                  <Link
                    to="#"
                    className="btn btn-sm btn-dark mr-2"
                    onClick={handleMarkOnOff}
                  >
                    <i className="fa fa-check-square"></i> Selecionado{" "}
                    {selectAll
                      ? totalItems -
                        (itemsSelected?.length > 0 ? itemsSelected?.length : 0)
                      : itemsSelected?.length}
                  </Link>
                )}
                <Link
                  to="#"
                  className="btn btn-sm btn-secondary mr-2"
                  onClick={handleReport}
                >
                  <i className="fa fa-bug"></i> Gerar relatório
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <Spinner direction={"center"} label="carregando" />
          {Array.isArray(report?.data) && (
            <ListOversightReport
              items={orderReport}
              itemsSelected={itemsSelected}
              selectItem
              setItemsSelected={setItemsSelected}
              selectAll={selectAll}
              handleReport={handleReport}
            />
          )}
          <div className="card-footer clearfix">
            <ul className="pagination pagination-sm m-0 float-right">
              {Array.isArray(report?.links) &&
                report.links.map((item, i) => (
                  <RenderPaginationPages item={item} key={i} />
                ))}
            </ul>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <button className="btn btn-secondary" onClick={handleCancel}>
              <i className="fa fa-arrow-left"></i> Voltar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default ReportByOversight;
