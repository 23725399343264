import React, { useContext } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { authContext } from '../../../context/authContext'
const SearchHigway = ({handleNew}) => {
	const { state, onChange } = useContext(formContext)
	const { getWhereItemHighway, getItemHighway } = useContext(requestContext)
	const { user } = useContext(authContext)
	const {
		rod_desc
	} = state
	const handleSearch = () => {
		if (!rod_desc) {
			return getItemHighway()
		}
		getWhereItemHighway(rod_desc)
	}
	return (
		<div className="input-group input-group-sm">
			{user?.set_nivel <= 1 && (
				<div className="input-group-append">
					<button className="btn btn-tool btn-sm" onClick={handleNew}>
                        Novo <i className="fa fa-plus"></i>
					</button>
				</div>
			)}
			<input type="text" className="form-control float-right"
				name="rod_desc" value={rod_desc} onChange={onChange}
				placeholder="Digite nome rodovia ou concessionaria" />
			<div className="input-group-append">
				<button className="btn btn-default" onClick={handleSearch}>
					<i className="fas fa-search"></i>
				</button>
			</div>
		</div>
	)
}
export default SearchHigway