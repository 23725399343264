import React, { useState } from 'react'
const TabsChardDashboard = ({
	setByDealership,
	setByHighway,
	setByLocation,
	setByActive,
	tabclassName
}) => {
	const [active, setActive] = useState('setByDealership')
	const onClick = id => {
		if (
			!setByDealership ||
			!setByHighway ||
			!setByLocation ||
			!setByActive
		) {
			return
		}
		setActive(id)
		setByDealership('')
		setByHighway('')
		setByLocation('')
		setByActive('')
		switch (id) {
		case 'setByDealership':
			setByDealership(tabclassName)
			break
		case 'setByHighway':
			setByHighway(tabclassName)
			break
		case 'setByLocation':
			setByLocation(tabclassName)
			break
		case 'setByActive':
			setByActive(tabclassName)
			break
		default:
			break
		}
	}
	return (
		<ul className="nav nav-tabs" id="myTab" role="tablist">
			<li className="nav-item" role="presentation">
				<button onClick={() => onClick('setByDealership')} className={active === 'setByDealership' ? 'nav-link active' : 'nav-link'} id="dealership-tab" data-toggle="tab" data-target="#dealership" type="button" role="tab" aria-controls="dealership" aria-selected="true">Por Concessecionária</button>
			</li>
			<li className="nav-item" role="presentation">
				<button onClick={() => onClick('setByHighway')} className={active === 'setByHighway' ? 'nav-link active' : 'nav-link'} id="highway-tab" data-toggle="tab" data-target="#highway" type="button" role="tab" aria-controls="highway" aria-selected="false">Por Rodovia</button>
			</li>
			<li className="nav-item" role="presentation">
				<button onClick={() => onClick('setByLocation')} className={active === 'setByLocation' ? 'nav-link active' : 'nav-link'} id="location-tab" data-toggle="tab" data-target="#location" type="button" role="tab" aria-controls="location" aria-selected="false">Por KM</button>
			</li>
			<li className="nav-item" role="presentation">
				<button onClick={() => onClick('setByActive')} className={active === 'setByActive' ? 'nav-link active' : 'nav-link'} id="active-tab" data-toggle="tab" data-target="#active" type="button" role="tab" aria-controls="active" aria-selected="false">Por Atividade</button>
			</li>
		</ul>
	)
}
export default TabsChardDashboard