import React, { useContext } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
const SearchTypeOversight = ({ handleNew }) => {
	const { state, onChange } = useContext(formContext)
	const { getWhereItemTypeOversight, getItemTypeOversight } = useContext(requestContext)
	const {
		tip_ped_venda_desc
	} = state
	const handleSearch = () => {
		if (!tip_ped_venda_desc) {
			return getItemTypeOversight()
		}
		getWhereItemTypeOversight(tip_ped_venda_desc)
	}
	return (
		<div className="input-group input-group-sm">
			<div className="input-group-append">
				<button className="btn btn-tool btn-sm" onClick={() => getItemTypeOversight()}>
                    Atualizar lista
				</button>
			</div>
			<div className="input-group-append">
				<button className="btn btn-tool btn-sm" onClick={handleNew}>
                    Novo <i className="fa fa-plus"></i>
				</button>
			</div>
			<input type="text" className="form-control float-right"
				name="tip_ped_venda_desc" value={tip_ped_venda_desc} onChange={onChange}
				placeholder="Procurar pelo tipo de obra" />
			<div className="input-group-append">
				<button className="btn btn-default" onClick={handleSearch}>
					<i className="fas fa-search"></i>
				</button>
			</div>
		</div>
	)
}
export default SearchTypeOversight