import React, { useContext, useEffect } from 'react'
import { formContext } from '../../../../context/formContext'
import { requestContext } from '../../../../context/requestContext'
const FilterStatusOversight = ({ hidden }) => {
	const { onChangeSelectInput, handleForm, state: { st_cod } } = useContext(formContext)
	const { status } = useContext(requestContext)
	useEffect(() => {
		handleForm({
			ped_venda_status: st_cod
		})
	}, [st_cod])
	return (
		<div className="form-group">
			{!hidden &&
                <label>Situação</label>
			}
			<select onChange={onChangeSelectInput}
				className="form-control form-control-border">
				<option value={JSON.stringify({
					ped_venda_status: 0,
					st_cor_valor: '#333',
					st_desc: 'Todos'
				})}>Todos status</option>
				{Array.isArray(status?.data) && status.data.map(item => (
					<option key={item.st_cod} value={JSON.stringify({
						...item,
						ped_venda_status: item.st_cod
					})}>{item.st_desc}</option>
				))}
			</select>
		</div>
	)
}
export default FilterStatusOversight