import React, { useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { requestContext } from '../../../context/requestContext'
import ContentHeader from '../../../components/contentHeader'
import Spinner from '../../../components/spinner'
import ListNotified from '../../../components/list/notifed'
import { formContext } from '../../../context/formContext'
import SearchNotifiedDealership from '../../../components/form/searchNotifiedDealership'
const OverSightNotified = () => {
	const { getItemNotifiedDealership, getWhereItemNotifiedDealership, data, setData } =
    useContext(requestContext)
	const { state: { conc_nome } } = useContext(formContext)
	const navigate = useNavigate()
	useEffect(() => {
		setData(null)
	}, [])
	useEffect(() => {
		resetValues()
		getItemNotifiedDealership()
	}, [])
	const handleCancel = () => {
		resetValues()
		navigate('/notification')
	}
	const resetValues = () => {
		setData(null)
	}
	const handlePage = (item) => {
		if (!item) {
			return
		}
		const { label } = item
		const { current_page, last_page } = data
		window.scrollTo(0, 0)
		if (label === 'pagination.previous') {
			return conc_nome ? getWhereItemNotifiedDealership(current_page > 1 && `${conc_nome}?page=${current_page - 1}`) : getItemNotifiedDealership(current_page > 1 && `0?page=${current_page - 1}`)
		}
		if (label === 'pagination.next') {
			return conc_nome ? getWhereItemNotifiedDealership(current_page < last_page && `${conc_nome}?page=${current_page + 1}`) : getItemNotifiedDealership(current_page < last_page && `0?page=${current_page + 1}`)
		}
		if (!label) {
			return conc_nome ? getWhereItemNotifiedDealership(conc_nome) : getItemNotifiedDealership()
		}
		if (conc_nome) {
			return getWhereItemNotifiedDealership(`${conc_nome}?page=${label}`)
		}
		getItemNotifiedDealership(`0?page=${label}`)
	}
	return (
		<>
			<ContentHeader title={'Concessionárias notificadas'} />
			<div className="content">
				<div className="card">
					<div className="card-header">
						<h3 className="card-title d-md-block d-none">
              Ocorrências notificadas
						</h3>
						<div className="card-tools">
							<SearchNotifiedDealership />
						</div>
					</div>
					<Spinner direction={'center'} label="carregando" />
					{Array.isArray(data?.data) &&
            data?.data.map((item, i) => <ListNotified item={item} key={i} />)}
					<div className="card-footer clearfix">
						<ul className="pagination pagination-sm m-0 float-right">
							{Array.isArray(data?.links) && (data.links.map((item) => (
								<li className="page-item" key={item.label}>
									<Link
										className={`page-link ${item.active && 'text-light bg-danger'
										}`}
										to="#"
										onClick={() => handlePage(item)}
									>
										{item.label === 'pagination.previous' ? (
											<i className="fa fa-angle-left"></i>
										) : item.label === 'pagination.next' ? (
											<i className="fa fa-angle-right"></i>
										) : (
											item.label
										)}
									</Link>
								</li>
							)))}
						</ul>
					</div>
				</div>
				<div className="card">
					<div className="card-body">
						<button className="btn btn-secondary" onClick={handleCancel}>
							<i className="fa fa-arrow-left"></i> Voltar
						</button>
					</div>
				</div>
			</div>
		</>
	)
}
export default OverSightNotified
