import React from 'react'
import ContentHeader from '../../../../components/contentHeader'
import Spinner from '../../../../components/spinner'
import FormCheckListPaviment from '../../../../components/form/checkListPaviment'
import { useNavigate } from 'react-router-dom'
import { namesScreens } from '../../../../constants'
const NewPavementCheckList = () => {
	const navigate = useNavigate()
	const onSubmit = () => {

	}
	const handleCancel = () => {
		navigate(namesScreens.PAVEMENT_CHECKLIST_MANAGER)
	}
	return (
		<>
			<ContentHeader title={'Cadastro de Checklist'} />
			<Spinner />
			<div className='content'>
				<div className="card card-danger">
					<div className="card-header">
						<h3 className="card-title">Informações sobre o item do Checklist</h3>
						<div className="card-tools">
							<button
								type="button"
								className="btn btn-tool"
								data-card-widget="collapse"
							>
								<i className="fas fa-minus"></i>
							</button>
							<button
								type="button"
								className="btn btn-tool"
								data-toggle="modal"
								data-target="#concessionaria"
							>
								<i className="fa fa-question-circle"></i>
							</button>
						</div>
					</div>
					<form onSubmit={onSubmit}>
						<div className="card-body">
							<FormCheckListPaviment />
						</div>
						<div className="card-body">
							<button type="submit" className="btn btn-danger mr-3">
								Salvar <i className="fa fa-save"></i>
							</button>
							<div className="btn btn-secondary" onClick={handleCancel}>
								Cancelar <i className="fa fa-times-circle"></i>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}
export default NewPavementCheckList