import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ContentHeader from '../../../components/contentHeader'
import Deadline from '../../../components/form/deadline'
import Dealership from '../../../components/form/dealership'
import Selectservice from '../../../components/form/selectservice'
import Spinner from '../../../components/spinner'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { isValid, handleWaring } from '../../../services/isValid'

const NewDeadline = () => {
	const { state, handleForm } = useContext(formContext)
	const { setInfoRequest, postItemDeadline, getAllItemDealership, getItemDealershipContractAttachment } = useContext(requestContext)
	const navigate = useNavigate()
	const {
		praz_cod,
		praz_dias,
		praz_ane_inf,
		praz_lote,
		praz_serv_sub_cat,
		conc_cod,
		reload,
		lot_conc_cod,
		serv_sub_cat_cod,
		conc_nome,
		lot_conc_desc
	} = state
	useEffect(() => {
		if (!praz_cod) {
			handleForm({
				praz_dias: '',
				praz_ane_inf: '',
				praz_lote: '',
				praz_serv_sub_cat: '',
				serv_sub_cat_cod: '',
				lot_conc_cod: '',
				lot_cont_desc: '',
				conc_cod: '',
				conc_nome: '',
				serv_sub_cat_desc: '',
			})
		}
	}, [praz_cod])
	useEffect(() => {
		getAllItemDealership()
	}, [])
	useEffect(() => {
		if (conc_cod) {
			getItemDealershipContractAttachment(conc_cod)
		}
	}, [conc_cod])
	useEffect(() => {
		handleForm({
			[!praz_cod && 'conc_nome']: '',
			conc_cod: '',
			lot_conc_cod: '',
			[!praz_cod && 'lot_conc_desc']: '',
		})
	}, [reload])
	useEffect(() => {
		if (lot_conc_cod) {
			handleForm({
				praz_lote: lot_conc_cod
			})
		}
	}, [lot_conc_cod])
	useEffect(() => {
		if (serv_sub_cat_cod) {
			handleForm({
				praz_serv_sub_cat: serv_sub_cat_cod
			})
		}
	}, [serv_sub_cat_cod])
	const onSubmit = (e) => {
		e.preventDefault()
		window.scrollTo(0, 0)
		const item = {
			praz_dias,
			praz_lote,
			praz_serv_sub_cat
		}
		const validate = isValid(item)
		if (!validate) {
			return setInfoRequest(handleWaring())
		}
		postItemDeadline({
			...item,
			id: praz_cod,
			praz_ane_inf
		})
	}
	const handleCancel = () => {
		handleForm({
			praz_dias: '',
			praz_ane_inf: '',
			praz_lote: '',
			praz_serv_sub_cat: '',
			serv_sub_cat_cod: '',
			lot_conc_cod: '',
			lot_cont_desc: '',
			conc_cod: '',
			conc_nome: '',
			serv_sub_cat_desc: '',
		})
		navigate('/deadline')
	}
	return (
		<>
			<ContentHeader title={'Cadastro de Prazos'} />
			<div className='content'>
				<Spinner />
				<form onSubmit={onSubmit}>
					<div className="card card-danger">
						<div className="card-header">
							<h3 className="card-title">Concessionária</h3>
							<div className="card-tools">
								<button type="button" className="btn btn-tool" data-card-widget="collapse">
									<i className="fas fa-minus"></i>
								</button>
								<button type="button" className="btn btn-tool" data-toggle="modal" data-target="#concessionaria">
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
						<div className="card-body">
							{!praz_cod ?
								<Dealership />
								:
								<>
									<div className="row">
										<div className="form-group col-md-8 col-sm-12">
											<label>Nome da Concessionária:</label>
											<div className="input-group">
												<div className="form-control form-control-border">
													{conc_nome}
												</div>
											</div>
										</div>
										<div className="form-group col-md-4 col-sm-12">
											<label>Lote da Concessionária:</label>
											<div className="input-group">
												<div className="form-control form-control-border">
													{lot_conc_desc}
												</div>
											</div>
										</div>
									</div>
								</>
							}
						</div>
					</div>
					{!praz_cod && (
						<div className="card card-danger">
							<div className="card-header">
								<h3 className="card-title">Serviço</h3>
								<div className="card-tools">
									<button type="button" className="btn btn-tool" data-card-widget="collapse">
										<i className="fas fa-minus"></i>
									</button>
									<button type="button" className="btn btn-tool" data-toggle="modal" data-target="#concessionaria">
										<i className="fa fa-question-circle"></i>
									</button>
								</div>
							</div>
							<div className="card-body">
								<Selectservice deadline />
							</div>
						</div>
					)
					}
					<div className="card card-danger">
						<div className="card-header">
							<h3 className="card-title">Definição do Prazo</h3>
							<div className="card-tools">
								<button type="button" className="btn btn-tool" data-card-widget="collapse">
									<i className="fas fa-minus"></i>
								</button>
								<button type="button" className="btn btn-tool" data-toggle="modal" data-target="#concessionaria">
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
						<div className="card-body">
							<Deadline />
						</div>
					</div>
					<div className="card">
						<div className='card-body'>
							<button className='btn btn-danger mr-3'>
								Salvar <i className="fa fa-save"></i>
							</button>
							<div className='btn btn-secondary' onClick={handleCancel}>
								Cancelar <i className="fa fa-times-circle"></i>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}
export default NewDeadline