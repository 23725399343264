/**
 * Retrieves the dimensions and position properties of a DOM element or an array of DOM elements.
 * @param {string|string[]} elId - The id(s) of the DOM element(s) to retrieve properties from.
 * @returns {Object} An object containing width, height, left, and top properties representing the dimensions and position of the element(s).
 */
export const getPropsDimissions = (elId) => {
	let nodes = []
	if (Array.isArray(elId)) {
		for (let i = 0; i < elId.length; i++) {
			const el = document.querySelector(`#${elId}`)
			const props = el.getBoundingClientRect()
			const {
				width, height
			} = props
			nodes.push({
				width, height,
				left: props.left + window.scrollX,
				top: props.top + window.scrollY
			})
		}
		const sum_height = nodes.reduce((a, r) => a + r.height, 0)
		const sum_width = nodes.reduce((a, r) => a + r.width, 0)
		return {
			width: Math.trunc(sum_width),
			height: Math.trunc(sum_height)
		}
	}
	const el = document.querySelector(`#${elId}`)
	const props = el.getBoundingClientRect()
	const {
		width, height
	} = props

	return {
		width, height, left: props.left + window.scrollX,
		top: props.top + window.scrollY
	}
}
