import React from 'react'
import TableMonitoring from '../../../table/pavement/monitoring'

const ModalMonitoring = ({ items }) => {
	if (!Array.isArray(items)) {
		return <div className='col-12'>Não há itens para serem exibidos</div>
	}

	return (
		<TableMonitoring items={items} />
	)
}
export default ModalMonitoring