import React,{ useContext, useEffect, useRef, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { handleWaring } from '../../../services/isValid'
import { allowedImageExtensions, allowedMoviesExtensions } from '../../../constants'
import { generateBse64, getExt } from '../../../services/files'
import { generateDateTimeStampJS } from '../../../services/dateHandless'
import { randomNumber } from '../../../services/randomId'
import ListMidias from '../../list/midias'
import returnPosition from '../../../services/geolocation'

const FormAttachMidias = ({closeModal}) => {
	const { state, onChange } = useContext(formContext)
	const { service, setInfoRequest, postItemOversightPhotos } = useContext(requestContext)
	const [services, setServices] = useState([])
	const input = useRef(null)
	const {
		pvs_loc_foto_legenda,
		pvs_loc_foto_pedidovenda,
	} = state
	const [files, setFiles] = useState([])
	useEffect(() => {
		const controllItems = () => {
			if (Array.isArray(service?.data)) {
				const { data } = service
				setServices(data)
			}
		}
		controllItems()
	}, [service])
	const handleMedia = () => {
		if (input?.current?.click) {
			return input?.current?.click()
		}
	}
	const onChangeFile = async ({ target: { files: filesFromInput } }) => {
		if (!filesFromInput?.length) {
			return setInfoRequest(handleWaring('Você precisa selecionar pelo menos um arquivo'))
		}
		const resultFiles = []
		const { lat: pvs_loc_foto_lat, lng: pvs_loc_foto_lng } = await returnPosition()
		for (const file of filesFromInput) {
			const { name } = file
			const ext = getExt(name)
			if (allowedImageExtensions.includes(ext) || allowedMoviesExtensions.includes(ext)) {
				const base64 = await generateBse64(file)
				resultFiles.push({
					timestamp: generateDateTimeStampJS(),
					pvs_loc_foto_cod: randomNumber(),
					pvs_loc_foto_path: base64,
					pvs_loc_foto_legenda: pvs_loc_foto_legenda,
					pvs_loc_foto_pedidovenda,
					pvs_loc_foto_lat,
					pvs_loc_foto_lng,
				})
			}
		}
		if (!resultFiles.length) {
			return setInfoRequest(handleWaring('Você precisa selecionar pelo menos um arquivo'))
		}
		if (files?.length) {
			return setFiles([...files, resultFiles])
		}
		setFiles(resultFiles)
	}
	const handleSendMedia = () => {
		if (!files.length || !pvs_loc_foto_pedidovenda || !pvs_loc_foto_legenda) {
			return setInfoRequest(handleWaring('Você precisa preencher todos os campos obrigatórios'))
		}
		files.forEach(item => {
			postItemOversightPhotos(item)
		})
		if(closeModal){
			closeModal()
		}
	}
	return (
		<div className="row">
			<div className="form-group col-12">
				<label>Observação sobre a foto ou vídeo:</label>
				<textarea type="text"
					className={`form-control form-control-border ${!pvs_loc_foto_legenda && 'is-invalid'}`}
					onChange={onChange}
					name='pvs_loc_foto_legenda' value={pvs_loc_foto_legenda}
					placeholder='Descreva brevemente sobre o anexo' />
			</div>
			{services?.length ? (
				<div className="form-group col-12">
					<label>Anexo relacionado a algum serviço:</label>
					<select
						onChange={onChange}
						name='pvs_loc_foto_pedidovenda' value={pvs_loc_foto_pedidovenda}
						className={`form-control form-control-border ${!pvs_loc_foto_pedidovenda && 'is-invalid'}`}
					>
						<option>Selecionar</option>
						{services.map(item => (
							<option key={item.prog_serv_serv_cod} value={item.prog_serv_serv_cod}>
								{item.serv_sub_cat_desc}
							</option>
						))
						}
					</select>
				</div>
			) : ''}
			<input className="d-none"
				type="file"
				multiple
				ref={input}
				onChange={onChangeFile}
			/>
			<div className="col-6">
				<button className="btn btn-danger" onClick={handleSendMedia}>
                    Enviar  <i className="fa fa-paper-plane" aria-hidden="true"></i>
				</button>
			</div>
			<div className="col-6">
				<button className="btn btn-secondary" onClick={handleMedia}>
                    Anexar <i className="fa fa-paperclip" aria-hidden="true"></i>
				</button>
			</div>
			<div className="col-12">
				{files.length ? (
					<ListMidias items={files} setFiles={setFiles} />
				) : ''}
			</div>
		</div>
	)
}
export default FormAttachMidias