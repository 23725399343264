import React, { useContext } from 'react'
import { Routes, Route } from 'react-router-dom'
import Toasy from '../../components/toasty'
import Authentication from '../../views/auth/login'
import NoMatch from '../../views/NoMatch'
import ForgotPassWord from '../../views/forgotPassword'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { requestContext } from '../../context/requestContext'
import { storageCache } from '../../services/cacheManager'
const AuthRouter = () => {
	const { setInfoRequest } = useContext(requestContext)
	const closeOnClick = () => {
		setInfoRequest({
			status: '',
			message: '',
			color: ''
		})
		storageCache('lass_message')
	}
	return (
		<>
			<Toasy />
			<ToastContainer closeOnClick={closeOnClick} />
			<Routes>
				<Route
					index
					element={<Authentication />}
				/>
				<Route path='/forgot_password' element={<ForgotPassWord />} />
				<Route
					path="*"
					element={<NoMatch />}
				/>
			</Routes>
		</>
	)
}
export default AuthRouter