import React, { useState } from 'react'
import { styleImage } from '../../../../css/globlalStyle'
import { getExt } from '../../../../services/files'
import ImageItem from '../../../list/img'
import { RenderLocation, RenderServicoInfo } from '../../../list/serviceOversight'
const InfoWindowOversight = ({ oversight_photos, state }) => {
	const [photos, setPhotos] = useState(oversight_photos)
	const {
		conc_nome,
		pvs_obs,
		serv_desc,
		serv_cat_desc,
		serv_sub_cat_desc,
		st_cor_valor,
		st_desc,
		pvs_prazo_fim,
		pv_loc_km_ini,
		pv_loc_km_init_mts,
		pv_loc_km_fim,
		pv_loc_km_fim_mts,
		rod_sent_desc,
		pv_loc_obs,
	} = state
	const item = {
		pvs_obs,
		serv_desc,
		serv_cat_desc,
		serv_sub_cat_desc,
		st_cor_valor,
		st_desc,
		pvs_prazo_fim,
		pv_loc_km_ini,
		pv_loc_km_init_mts,
		pv_loc_km_fim,
		pv_loc_km_fim_mts,
		rod_sent_desc,
		pv_loc_obs,
	}
	return (
		<div className="card w-100">
			{Array.isArray(oversight_photos) && (
				<div className='row flex-nowrap mb-3' style={{ overflowY: 'scroll' }}>
					{oversight_photos.map((item, i) => (
						getExt(item.pvs_loc_foto_path) === 'mp4' ? (
							<div className='col-md-6 col-sm-12' key={i}>
								<video controls width={'100%'} style={styleImage} height={750} src={item.pvs_loc_foto_path} autoPlay></video>
							</div>
						) : (
							<div className="col-md-4 col-sm-12" key={i}>
								<ImageItem
									item={item}
									legend={item.pvs_loc_foto_legenda}
									src={
										item.pvs_loc_foto_path
											? item.pvs_loc_foto_path
											: item.base64
									}
									setPhotos={setPhotos}
									data={photos}
								/>
							</div>
						)
					))}
				</div>
			)}
			<div className='card-body'>
				<h1 className='card-title mb-3'>{conc_nome}</h1>
				<div className='row'>
					<RenderServicoInfo item={item} edit={false} />
					<RenderLocation item={item} />
				</div>
			</div>
		</div>
	)
}
export default InfoWindowOversight