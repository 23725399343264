import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { requestContext } from '../../context/requestContext'
import { parseFromMysqlDateToJSDate } from '../../services/dateHandless'
import { filterAndRemove } from '../../services/arrayHandless'
import { postCache, recoverCache } from '../../services/cacheManager'
import ModalNotications from '../modal/notifications'
const Header = () => {
	const [showNotification, setShowNotification] = useState(false)
	const { check_inspection, notification, getItemCheckInspection } = useContext(requestContext)
	const [items, setItems] = useState(null)
	const [show, setShow] = useState(false)
	useEffect(() => {
		getItemCheckInspection()
	}, [])
	useEffect(() => {
		if (!show) {
			const body = document.getElementById('root')
			body.className = 'wrapper'
		}
	}, [show])
	useEffect(() => {
		const loadItemsNotification = () => {
			const newItems = []
			if (Array.isArray(check_inspection?.data)) {
				const { data: check_inspection_data } = check_inspection
				for (const item of check_inspection_data) {
					const { fisc_cod, created_at, fisc_obs: message, ...rest } = item
					const parse_created_at = parseFromMysqlDateToJSDate(created_at)
					newItems.push({
						id: fisc_cod,
						fisc_cod,
						message,
						...rest,
						hours: parse_created_at,
					})
				}
			}
			if (Array.isArray(notification)) {
				for (const item_notification of notification) {
					newItems.push({
						...item_notification
					})
				}
			}
			if (newItems.length) {
				setItems(newItems.filter(filterNotification))
			}
		}
		loadItemsNotification()
	}, [check_inspection, notification])
	const filterNotification = (item) => {
		const getRemovedNotification = recoverCache('removeNotification')
		if (!getRemovedNotification) {
			return item
		}
		return !getRemovedNotification.includes(item.id)
	}
	const removeNotification = (notify) => {
		const { id } = notify
		const { data } = filterAndRemove(items, 'id', id)
		postCache('removeNotification', id)
		setItems(data)
	}
	const handleViewMore = () => {
		setShow(true)
		setShowNotification(false)
		const body = document.getElementById('root')
		body.className = 'sidebar-mini layout-fixed sidebar-collapse'
	}
	const RenderNotificationItem = ({ item }) => {
		return (
			<Link to="#" className="dropdown-item"
				onClick={() => removeNotification(item)}
			>
				<div className="row p-1">
					<div className="col-1 mb-3">
						<i className="far fa-bell"></i>
					</div>
					<div className="col-10">
						<div className="col-12 text-wrap bd-highlight">
							{item.message}
						</div>
						<div className="col-12">
							<span className="float-left text-muted text-sm">
								<i className="fa fa-clock-o" aria-hidden="true"></i> {item?.hours}
							</span>
						</div>
					</div>
				</div>
				<div className="dropdown-divider"></div>
			</Link>
		)
	}
	return (
		<nav className="main-header navbar navbar-expand navbar-white navbar-light">
			<ul className="navbar-nav">
				<li className="nav-item">
					<Link className="nav-link" data-widget="pushmenu" to="#" role="button"><i className="fas fa-bars"></i></Link>
				</li>
				<li className="nav-item d-none d-sm-inline-block">
					<Link to="/" className="nav-link">Home</Link>
				</li>
				<li className="nav-item d-none d-sm-inline-block">
					<Link to="/sac" className="nav-link">Chamar Suporte</Link>
				</li>
			</ul>
			<ul className="navbar-nav ml-auto">
				<li className={`nav-item dropdown ${showNotification && 'show'}`}>
					<Link className="nav-link" data-toggle="dropdown" to="#" onClick={() => setShowNotification(!showNotification)}>
						<i className="far fa-bell"></i>
						<span className="badge badge-danger navbar-badge">
							{Array.isArray(items) ? items.length : 0}
						</span>
					</Link>
					<div className={`dropdown-menu dropdown-menu-lg dropdown-menu-right ${showNotification && 'show'}`}
						style={{ left: 'inherit', right: 0 }}
					>
						<span className="dropdown-item dropdown-header">Notificações</span>
						<div className="dropdown-divider"></div>
						<div style={{ overflowX: 'hidden', overflowY: 'scroll', maxHeight: 250 }}>
							{Array.isArray(items) &&
                                items.map((item, i) => (<RenderNotificationItem item={item} key={i} />))
							}
							{check_inspection?.total > 15 && (
								<Link to="#" className="dropdown-item" onClick={handleViewMore}>
									<div className="row p-1">
										<div className="col-1 mb-3">
											<i className="fa fa-eye"></i>
										</div>
										<div className="col-10">
											<div className="col-12 text-wrap bd-highlight">
                                                Ver todas as Notificações
											</div>
										</div>
									</div>
									<div className="dropdown-divider"></div>
								</Link>
							)}
						</div>
					</div>
				</li>
				<li className="nav-item">
					<Link className="nav-link" data-widget="control-sidebar" data-controlsidebar-slide="true" to="/user/logout"
						role="button">
						<i className="fas fa-sign-out-alt"></i>
					</Link>
				</li>
			</ul>
			<ModalNotications show={show} setShow={setShow} removeNotification={removeNotification} notifications={items} />
		</nav>
	)
}
export default Header