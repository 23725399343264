import React, { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { styleButtonSearch } from '../../../css/globlalStyle'
import { namesScreens } from '../../../constants'
import { useLocation } from 'react-router-dom'
const SelectCardinalPoints = () => {
	const { state, onChangeSelectInput, handleForm } = useContext(formContext)
	const { cardinal_points, getAllItemCardinalPoints } = useContext(requestContext)
	const { rod_concessionaria, rod_sent_cod, rod_cod, rod_sent_desc } = state
	const [disabled, setDisabled] = useState(false)
	const location = useLocation()
	useEffect(() => {
		const controllDisabledInputs = () => {
			const { pathname } = location
			if (pathname === namesScreens.REPORT_CONSTRUCTION_PLAN) {
				return setDisabled(true)
			}
			return setDisabled(false)
		}
		controllDisabledInputs()
	}, [location])
	useEffect(() => {
		if (rod_cod && rod_concessionaria) {
			getAllItemCardinalPoints(rod_concessionaria, rod_cod)
		}
	}, [rod_cod, rod_concessionaria])
	const handleCancel = () => {
		handleForm({
			rod_sent_cod: '',
			rod_sent_desc: '',
		})
	}
	return (
		<div className={'form-group col-md-6 col-sm-12'}>
			<label>Sentido da rodovia:</label>
			<div className="input-group">
				{rod_sent_desc ? (
					<div className='form-control form-control-border'>
						{rod_sent_desc}
					</div>
				) : (
					<select
						onChange={onChangeSelectInput}
						disabled={disabled}
						className={`form-control form-control-border ${!rod_sent_cod && 'is-invalid'}`}
					>
						<option>Selecionar</option>
						{Array.isArray(cardinal_points?.data) &&
							cardinal_points?.data?.map((item) => (
								<option key={item.rod_sent_cod} value={JSON.stringify(item)}>
									{item.rod_sent_desc}
								</option>
							))}
					</select>
				)}
				{rod_sent_desc && !disabled && (
					<div className="input-group-prepend">
						<span className="input-group-text bg-danger"
							style={styleButtonSearch}
							onClick={handleCancel}>
							<i className="fa fa-times-circle" aria-hidden="true"></i>
						</span>
					</div>
				)}
			</div>
		</div>
	)
}
export default SelectCardinalPoints