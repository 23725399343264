import React, { useContext, useEffect } from 'react'
import ContentHeader from '../../../components/contentHeader'
import { requestContext } from '../../../context/requestContext'
import { formContext } from '../../../context/formContext'
import { handleWaring, isValid } from '../../../services/isValid'
import { useNavigate } from 'react-router-dom'
import FormWorkOfArt from '../../../components/form/workOfArt'
const NewWorkOfArt = () => {
	const { state, handleForm } = useContext(formContext)
	const {
		setObArtType,
		setObArtElem,
		setInfoRequest,
		setCardinalPoints,
		getAllItemObArtType,
		getAllItemObArtElement,
		getAllItemObArtClass,
		getAllItemDealership,
		postItemObArt,
	} = useContext(requestContext)
	const naviate = useNavigate()
	useEffect(() => {
		getAllItemObArtType()
		getAllItemObArtElement()
		getAllItemObArtClass()
		getAllItemDealership()
	}, [])
	const {
		ob_art_cod,
		ob_art_desc,
		ob_art_item_cod,
		ob_art_tipo,
		ob_art_class,
		ob_art_elem,
		ob_art_loc_km,
		ob_art_loc_mts,
		ob_art_rodovia,
		ob_art_lote,
		ob_art_sent,
		ob_art_ativo
	} = state
	const handleCancel = () => {
		handleForm({
			ob_art_cod: '',
			ob_art_desc: '',
			ob_art_item_cod: '',
			ob_art_tipo: '',
			ob_art_class: '',
			ob_art_elem: '',
			ob_art_loc_km: '',
			ob_art_loc_mts: '',
			ob_art_rodovia: '',
			ob_art_lote: '',
			ob_art_sent: '',
			ob_art_ativo: ''
		})
		setObArtType(null)
		setObArtElem(null)
		setCardinalPoints(null)
		return naviate('/work_of_art')
	}
	const onSubmit = (e) => {
		e.preventDefault()
		window.scrollTo(0, 0)
		const item = {
			ob_art_desc,
			ob_art_item_cod,
			ob_art_tipo,
			ob_art_class,
			ob_art_elem,
			ob_art_loc_km,
			ob_art_loc_mts,
			ob_art_rodovia,
			ob_art_lote,
			ob_art_sent,
			ob_art_ativo
		}
		const validate = isValid(item)
		if (!validate) {
			return setInfoRequest(handleWaring())
		}
		postItemObArt({
			...item,
			id: ob_art_cod
		})
		handleCancel()
	}
	return (
		<>
			<ContentHeader title={'Nova Obra de Arte'} />
			<div className='content'>
				<div className="card card-danger">
					<div className="card-header">
						<h3 className="card-title">Dados da empresa</h3>
						<div className="card-tools">
							<button
								type="button"
								className="btn btn-tool"
								data-card-widget="collapse"
							>
								<i className="fas fa-minus"></i>
							</button>
							<button
								type="button"
								className="btn btn-tool"
								data-toggle="modal"
								data-target="#concessionaria"
							>
								<i className="fa fa-question-circle"></i>
							</button>
						</div>
					</div>
					<div className="card-body">
						<form onSubmit={onSubmit}>
							<FormWorkOfArt />
							<div className="card">
								<div className="card-body">
									<button type="submit" className="btn btn-danger mr-3">
                                        Salvar <i className="fa fa-save"></i>
									</button>
									<div className="btn btn-secondary" onClick={() => handleCancel()}>
                                        Cancelar <i className="fa fa-times-circle"></i>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	)
}
export default NewWorkOfArt