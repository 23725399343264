import React, { useContext, useEffect } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
const SearchDealershipContract = () => {
	const { state, onChange, handleForm } = useContext(formContext)
	const { getWhereItemDealershipContract, getItemDealershipContract } = useContext(requestContext)
	const {
		conc_nome
	} = state
	useEffect(() => {
		handleForm({
			conc_nome: ''
		})
	}, [])
	const handleSearch = () => {
		if (!conc_nome) {
			return getItemDealershipContract()
		}
		getWhereItemDealershipContract(conc_nome)
	}

	return (
		<div className="input-group input-group-sm">

			<input type="text" className="form-control float-right"
				name="conc_nome" value={conc_nome} onChange={onChange}
				placeholder="Procurar por concessionaria" />
			<div className="input-group-append">
				<button className="btn btn-default" onClick={handleSearch}>
					<i className="fas fa-search"></i>
				</button>
			</div>
		</div>
	)
}
export default SearchDealershipContract