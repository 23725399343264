import React, { useContext } from 'react'
import { requestContext } from '../../../context/requestContext'
import ListHighwayLimit from '../../list/highwayLimit'
const SelectLimitHighwayUser = () => {
	const { limithigway } = useContext(requestContext)
	return (
		<div className="row">
			<div className="col-12">
				<ListHighwayLimit data={limithigway} justList selectUser />
			</div>
		</div>
	)
}
export default SelectLimitHighwayUser