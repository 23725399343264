import React, { useContext, useEffect, useState } from 'react'
import { authContext } from '../../../context/authContext'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import Dealership from '../dealership'
import SelectCompany from '../selectCompany'
import { useLocation } from 'react-router-dom'
import { variablesId } from '../../../constants'
import SelectGroups from '../selectGroups'
import SelectSector from '../selectSector'
import SelectConcUserLevel from '../selectConcUserLevel'
const User = () => {
	const { state, onChange, handleForm, } = useContext(formContext)
	const [typenewpassowrd, setNewPassWord] = useState('password')
	const [typepassword_compare, setPassword_compare] = useState('password')
	const [typeoldpassword, setTypeOldPassword] = useState('password')
	const { user, } = useContext(authContext)
	const { getItemAllCompany, getAllItemDealership, getUserMenus } = useContext(requestContext)
	const [updateUser, setUpdateUser] = useState(true)
	const [changePass, setChangePass] = useState(false)
	const location = useLocation()

	useEffect(() => {
		try {
			if (location) {
				const { state: state_params } = location
				const {
					updateUser: updateUser_,
					changePass: changePass_
				} = state_params
				if (updateUser_) {
					setUpdateUser(updateUser_)
				} else {
					setUpdateUser(false)
				}
				if (changePass_) {
					setChangePass(changePass_)
				}
			}
		} catch (error) {
			setUpdateUser(false)
		}
	}, [location])
	const {
		email,
		new_password,
		password_compare,
		old_password,
		set_cod,
		usu_ativo,
		conc_cod,
		usu_email,
		name,
		emp_cod,
		set_nivel
	} = state
	useEffect(() => {
		handleForm({
			new_password: '',
			password_compare: '',
			old_password: ''
		})
		if (usu_email === '' && updateUser) {
			handleForm({
				email: user?.email,
				user: user?.user
			})
		}
		if (!usu_email) {
			handleForm({
				email: usu_email,
			})
		}
	}, [user, usu_email])
	useEffect(() => {
		const selectCompanyOrDealership = () => {
			if (parseInt(set_cod) === parseInt(variablesId.set_cod)) {
				return getAllItemDealership()
			}
			return getItemAllCompany()
		}

		if (user?.set_nivel <= 1) {
			selectCompanyOrDealership()
		}
	}, [user, set_cod])
	useEffect(() => {
		if (conc_cod) {
			handleForm({
				usu_emp: conc_cod
			})
		}
	}, [conc_cod])
	useEffect(() => {
		if (emp_cod) {
			handleForm({
				usu_emp: emp_cod
			})
		}
	}, [emp_cod])
	useEffect(() => {
		getUserMenus(email, set_nivel)
	}, [])
	return (
		<div className="row">
			{!changePass && (
				<div className={user?.set_nivel >= 2 ? 'form-group col-12' : 'form-group col-md-6 col-sm-12'}>
					<label>Email</label>
					<input type="email"
						name="email"
						onChange={onChange}
						className="form-control form-control-border"
						placeholder="E-mail"
						value={email}
						disabled={updateUser} />
				</div>
			)}
			{user?.set_nivel <= 1 && !updateUser && !changePass && (
				<div className="form-group col-md-6 col-sm-12">
					<label>Nome</label>
					<input type="text"
						name="name"
						className="form-control form-control-border"
						onChange={onChange}
						placeholder="Nome"
						value={name} />
				</div>
			)}
			{(updateUser || changePass) && user?.set_nivel > 1 && (
				<div className="form-group col-md-6 col-sm-12 mb-3">
					<label>Senha atual</label>
					<div className="input-group">
						<input type={typeoldpassword}
							onChange={onChange}
							name="old_password" value={old_password}
							className={`form-control form-control-border ${!old_password && 'is-invalid'}`}
							placeholder="Sua senha atual"
							maxLength={100}
						/>
						<div className="input-group-append">
							<div className="input-group-text" onClick={() => setTypeOldPassword(typeoldpassword === 'password' ? 'text' : 'password')}>
								<span className={old_password?.length > 3 && typeoldpassword === 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'}></span>
							</div>
						</div>
					</div>
				</div>
			)}
			<div className="form-group col-md-6 col-sm-12">
				<label>Nova senha</label>
				<div className="input-group">
					<input type={typenewpassowrd}
						onChange={onChange}
						name="new_password" value={new_password}
						className={`form-control form-control-border ${!new_password || new_password === old_password || new_password !== password_compare ? 'is-invalid' : ''}`}
						placeholder="Sua nova senha"
						maxLength={8}
					/>
					<div className="input-group-append">
						<div className="input-group-text" onClick={() => setNewPassWord(typenewpassowrd === 'password' ? 'text' : 'password')}>
							<span className={new_password?.length > 3 && typenewpassowrd === 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'}></span>
						</div>
					</div>
					{new_password ?
						new_password === old_password &&
						<span id="exampleInputPassword1-error" className="error invalid-feedback">Nova senha não pode ser igual senha atual</span>
						: ''}
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-12">
				<label>Repita nova senha</label>
				<div className="input-group">
					<input type={typepassword_compare}
						onChange={onChange}
						name="password_compare" value={password_compare}
						className={`form-control form-control-border ${!password_compare || password_compare !== new_password ? 'is-invalid' : ''}`}
						placeholder="Repita sua nova senha"
						maxLength={8}
					/>
					<div className="input-group-append">
						<div className="input-group-text" onClick={() => setPassword_compare(typepassword_compare === 'password' ? 'text' : 'password')}>
							<span className={password_compare?.length > 3 && typepassword_compare === 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'}></span>
						</div>
					</div>
					{password_compare ?
						new_password !== password_compare &&
						<span id="exampleInputPassword1-error" className="error invalid-feedback">Senhas não conferem</span>
						: ''}
				</div>
			</div>
			{user?.set_nivel <= 1 && !changePass && (
				<SelectSector />
			)}
			{user?.set_nivel <= 1 && !changePass && (
				<div className="form-group col-md-6 col-sm-6">
					<label>Usuario ativo</label>
					<div className="input-group">
						<select
							value={usu_ativo}
							name="usu_ativo"
							onChange={onChange}
							className={`form-control form-control-border ${!usu_ativo ? 'is-invalid' : ''}`}
						>
							<option>Selecionar</option>
							<option value={0}>
								Não
							</option>
							<option value={1}>
								Sim
							</option>
						</select>
					</div>
				</div>
			)}
			{user?.set_nivel <= 1 && !changePass && (
				<>
					{parseInt(set_cod) === parseInt(variablesId.set_cod) ? (
						<Dealership col={'col-md-6 col-sm-6'} selectToUser />
					) : (
						<SelectCompany col={'col-md-6 col-sm-6'} />
					)}
				</>
			)}

			{user?.set_nivel <= 1 && !changePass && (
				<SelectGroups col={'col-md-6 col-sm-6'} />
			)}
			{user?.set_nivel <= 1 && !changePass && set_nivel ===1 && (
				<SelectConcUserLevel col={'col-md-12 col-sm-12'} />
			)}
		</div>
	)
}
export default User