import React, { useContext } from "react";
import { formContext } from "../../../context/formContext";
import { Link } from "react-router-dom";
import { authContext } from "../../../context/authContext";
const FormForgotPassWord = () => {
    const { state, onChange } = useContext(formContext)
    const { showSpinner } = useContext(authContext)
    const {
        email
    } = state
    return (
        <>
            <div className="mb-3">
                <label htmlFor="email" className="form-label">E-mail</label>
                <input type="email" id="email" className="form-control" name="email"
                    value={email} onChange={onChange}
                    placeholder="Digite seu e-mail aqui"
                    required />
            </div>
            {
                showSpinner ?
                    <div className="mb-3 d-grid">
                        <button type="submit" className="btn btn-danger btn-block">
                            <div className="spinner-border spinner-border-sm" role="status" />
                        </button>
                    </div>
                    :
                    <div className="mb-3 d-grid">
                        <button type="submit" className="btn btn-danger btn-block">
                            Reenviar a senha
                        </button>
                    </div>
            }
            <Link to={'/'} className="text-secondary">Voltar para o login</Link>
        </>
    )
}
export default FormForgotPassWord