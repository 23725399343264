import React, { useContext, useEffect, useState } from "react";
import { requestContext } from "../../../context/requestContext";
import { getBaseUrlFile } from "../../../webService";
const ListTutoriais = ({ data }) => {
    const { deleteItemTutoriais } = useContext(requestContext)
    const [items, setItems] = useState(null)
    useEffect(() => {
        const loadItems = () => {
            if (!Array.isArray(data?.data)) {
                return
            }
            const { data: data_item } = data
            setItems(data_item)
        }
        loadItems()
    }, [data])
    const handleDelete = (item) => {
        const { tut_titulo, tut_cod } = item
        const confirm = window.confirm(`Você deseja mesmo excluir o tutorial ${tut_titulo} ?`)
        if (!confirm) {
            return
        }
        return deleteItemTutoriais(tut_cod)
    }
    const handleOPen = (item) => {
        const { tut_path } = item
        if (!tut_path) {
            return
        }
        window.open(`${getBaseUrlFile()}${tut_path}`).focus()
    }
    if (!Array.isArray(items)) {
        return <></>
    }
    return (
        items.map(item => (
            <div className="row" key={item.tut_cod}>
                <div className="col-md-10 col-sm-8">
                    <div className="row">
                        <div className="col-md-4 col-sm-6 mb-3">
                            <span className="text-secondary mr-2">
                                Título:
                            </span>
                            {item.tut_titulo}
                        </div>
                        <div className="col-md-4 col-sm-6 mb-3">
                            <span className="text-secondary mr-2">
                                Descrição:
                            </span>
                            {item.tut_descricao}
                        </div>
                        <div className="col-md-4 col-sm-6 mb-3">
                            <span className="text-secondary mr-2">
                                Tipo:
                            </span>
                            {item.tut_tipo_arquivo}
                        </div>
                        <div className="col-md-4 col-sm-6 mb-3">
                            <span className="text-secondary mr-2">
                                Disponível:
                            </span>
                            {item.tut_disp === 0 ? 'Não' : 'Sim'}
                        </div>
                        <div className="col-md-4 col-sm-6 mb-3">
                            <span className="text-secondary mr-2">
                                Criado por:
                            </span>
                            {item.name}
                        </div>
                    </div>
                </div>
                <div className="col-md-2 col-sm-4">
                    <div className="row">
                        <div className="col">
                            <button className="btn btn-danger btn-sm" onClick={() => handleOPen(item)}>
                                <i className="fa fa-play"></i>
                            </button>
                        </div>
                        <div className="col">
                            <button className="btn btn-secondary btn-sm" onClick={() => handleDelete(item)}>
                                <i className="fa fa-trash"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        ))
    )
}
export default ListTutoriais