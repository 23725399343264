import React, { useContext } from 'react'
import ContentHeader from '../../../components/contentHeader'
import { requestContext } from '../../../context/requestContext'
import { formContext } from '../../../context/formContext'
import { handleWaring, isValid } from '../../../services/isValid'
import { useNavigate } from 'react-router-dom'
import FormSyncStatus from '../../../components/form/syncStatus'
const NewSyncStatus = () => {
	const { state, handleForm } = useContext(formContext)
	const { setInfoRequest, postIemSyncStatus } = useContext(requestContext)
	const naviate = useNavigate()
	const {
		st_cod,
		st_desc,
		st_cor,
		st_ativo,
		st_cor_cod,
		st_cor_valor,
	} = state
	const handleCancel = () => {
		handleForm({
			sinc_st_cod: '',
			sinc_st_desc: '',
		})
		return naviate('/sync_status')
	}
	const onSubmit = (e) => {
		e.preventDefault()
		window.scrollTo(0, 0)
		const item = {
			st_desc,
			st_ativo,
			st_cor_valor,
		}
		const validate = isValid(item)
		if (!validate) {
			return setInfoRequest(handleWaring())
		}
		return postIemSyncStatus({
			...item,
			id: st_cod,
			st_cor_cod,
			st_cor
		})
	}
	return (
		<>
			<ContentHeader title={'Novo Status de situação'} />
			<div className='content'>
				<form onSubmit={onSubmit}>
					<div className="card card-danger">
						<div className="card-header">
							<h3 className="card-title">Descrição da situação</h3>
							<div className="card-tools">
								<button
									type="button"
									className="btn btn-tool"
									data-card-widget="collapse"
								>
									<i className="fas fa-minus"></i>
								</button>
								<button
									type="button"
									className="btn btn-tool"
									data-toggle="modal"
									data-target="#concessionaria"
								>
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
						<div className="card-body">
							<FormSyncStatus />
						</div>
					</div>
					<div className="card card-danger">
						<div className="card-header">
							<h3 className="card-title">Cor</h3>
							<div className="card-tools">
								<button
									type="button"
									className="btn btn-tool"
									data-card-widget="collapse"
								>
									<i className="fas fa-minus"></i>
								</button>
								<button
									type="button"
									className="btn btn-tool"
									data-toggle="modal"
									data-target="#concessionaria"
								>
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
					</div>
					<div className="card">
						<div className="card-body">
							<button type="submit" className="btn btn-danger mr-3">
                                Salvar <i className="fa fa-save"></i>
							</button>
							<div className="btn btn-secondary" onClick={() => handleCancel()}>
                                Cancelar <i className="fa fa-times-circle"></i>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}
export default NewSyncStatus