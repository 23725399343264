import React, { useContext, useEffect } from 'react'
import Dealership from '../../dealership'
import Selectservice from '../../selectservice'
import SelectServiceCat from '../../selectServiceCat'
import SelectServiceSubCat from '../../selectServiceSubCat'
import { formContext } from '../../../../context/formContext'
import { requestContext } from '../../../../context/requestContext'
const FilterDeadline = ({ onSubmit }) => {
	const { handleForm, state: { serv_cod, serv_cat_cod } } = useContext(formContext)
	const { getAllItemCategoryService, getAllItemServiceSubCat, getItemDeadline, setServicecat, setServiceSubcat } = useContext(requestContext)
	useEffect(() => {
		const loadServiceCat = () => {
			if (serv_cod) {
				getAllItemCategoryService(serv_cod)
			}
		}
		loadServiceCat()
	}, [serv_cod])
	useEffect(() => {
		const loadServiceSubCat = () => {
			if (serv_cat_cod) {
				handleForm({
					serv_sub_cat_serv_cat: serv_cat_cod,
					serv_cat_prazo: '',
				})
				getAllItemServiceSubCat(serv_cat_cod)
			}
		}
		loadServiceSubCat()
	}, [serv_cat_cod])
	const resetValues = () => {
		handleForm({
			conc_nome: '',
			lot_conc_cod: '',
			serv_cod: '',
			serv_cat_cod: '',
			serv_sub_cat_cod: '',
			serv_desc: '',
			serv_cat_desc: '',
			serv_sub_cat_desc: ''
		})
		setServicecat(null)
		setServiceSubcat(null)
		getItemDeadline(0, 0, true)
	}
	return (
		<div className="row">
			<div className='col-12'>
				<Dealership />
			</div>
			<div className="col-md-6 col-sm-12">
				<Selectservice label={'Tipo Atividade:'} />
			</div>
			<div className='col-md-6 col-sm-12'>
				<SelectServiceCat />
			</div>
			<div className='col-12'>
				<SelectServiceSubCat />
			</div>
			<div className='col-12'>
				<div className='row'>
					<div className="mb-3 col-md-3 col-sm-6">
						<button className="btn btn-danger mr-3" onClick={onSubmit}>
                            Aplicar filtro <i className="fa fa-filter"></i>
						</button>
					</div>
					<div className="mb-3 col-md-3 col-sm-6">
						<button className="btn btn-dark mr-3" onClick={resetValues}>
                            Limpar filtros <i className="fa fa-eraser"></i>
						</button>
					</div>

				</div>
			</div>
		</div>
	)
}
export default FilterDeadline