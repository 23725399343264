import React, { useEffect, useState } from 'react'
import {
	Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale,
	LinearScale,
	BarElement,
	Title,
	PointElement,
	LineController,
	LineElement,
} from 'chart.js'
import { Doughnut, Bar, Line, Pie } from 'react-chartjs-2'
import Spinner from '../spinner'
import { generateRandomColorPaletteWithBrightness } from '../../services/arrayHandless'
import { splitString } from '../../services/stringHandless'


ChartJS.register(
	ArcElement, Tooltip, Legend,
	CategoryScale,
	LinearScale,
	BarElement,
	LineController,
	LineElement,
	PointElement,
	Title)
export function DoughnutChart({ data, labelPie, reaisTotal }) {
	const [chartData, setChardData] = useState(null)

	useEffect(() => {
		if (Array.isArray(data)) {
			let ArrayColors = generateRandomColorPaletteWithBrightness(data?.length)
			let labelsData = data.map(item => item.serv_sub_cat_desc,)
			let dataValuesArray = data.map(item => item.prog_serv_percen_progress)
			let dataTotalValue = data.reduce((total, item) => total + (item.pvs_qtd * item.pvs_valor), 0)

			const dataFinalValue = dataTotalValue * 100 / reaisTotal

			let dataValueSum = dataValuesArray.reduce((total, item) => total + item)

			if (data?.length > 0) {
				labelsData = [...labelsData, 'Pendente']
				dataValuesArray = [...dataValuesArray, dataFinalValue - dataValueSum]
				ArrayColors = [...ArrayColors, '#FFF']
			}
			const newData = {
				labels: labelsData,
				datasets: [
					{
						label: labelPie,
						data: dataValuesArray,
						backgroundColor: ArrayColors,
						borderColor: '#DCDCDC',
						borderWidth: 1,
					}
				],
			}
			setChardData(newData)
		}
	}, [data, labelPie])
	if (!chartData) {
		return <Spinner></Spinner>
	}
	return <Doughnut data={chartData} />

}
export const ChartBarDisciplines = ({ data }) => {
	const [chartData, setChardData] = useState(null)
	useEffect(() => {
		const controllData = () => {
			if (!data) {
				return
			}
			setChardData(data)
		}
		controllData()
	}, [data])
	const options = {
		indexAxis: 'x',
		elements: {
			bar: {
				borderWidth: 2,
			},
		},
		responsive: true,
		plugins: {
			legend: {
				position: 'left',
			},
			title: {
				display: true,
				text: 'Displinas',
			},
		},
	}
	if (!chartData) {
		return <div className='col-12'>
			Não há dados para serem exibidos
		</div>
	}
	return <Bar options={options} data={chartData} />
}
export const BarChart = ({ data, label, breakthrough }) => {
	const [chartData, setChardData] = useState(null)
	const options = {
		responsive: true,
	}
	useEffect(() => {
		if (data) {
			let labels = []
			let datasets = []
			let datasets_1 = []
			Object.keys(data).map(item => {
				return labels.push(data[item]?.at(-1)?.serv_desc)
			})
			const colors = generateRandomColorPaletteWithBrightness(labels.length)
			Object.keys(data).map(item => {
				const serv_valor = data[item].reduce((a, b) => a + b.prog_serv_percen_progress, 0)
				return datasets.push(serv_valor)
			})
			if (breakthrough) {
				Object.keys(data).forEach(item => {
					data[item].forEach((serv) => datasets_1.push(serv.prog_serv_por_cen_ant))
				})
				const newData = {
					labels,
					datasets: [
						{
							label: label,
							data: datasets_1,
							backgroundColor: colors[0].brightness
						},
						{
							label: label,
							data: datasets,
							backgroundColor: colors[0].color
						}
					],
				}
				setChardData(newData)
			} else {
				const newData = {
					labels,
					datasets: [
						{
							label: 'Progresso em relação à obra (%): ',
							data: datasets,
							backgroundColor: colors[0].color
						}
					],
				}
				setChardData(newData)
			}
		}
	}, [data])
	if (!chartData) {
		return <Spinner></Spinner>
	}
	return <Bar options={options} data={chartData} />
}

export const LineChart = ({ data }) => {
	const [chartData, setChardData] = useState(null)
	const options = {
		responsive: true,
		spanGaps: true, // Conecta os pontos mesmo com lacunas
		plugins: {
			legend: {
				position: 'top'
			},
			title: {
				display: true,
				text: 'Progresso Previsto vs Executado'
			}
		},
		scales: {
			y: {
				beginAtZero: true, // Força o eixo Y a começar do 0
				title: {
					display: true,
					text: 'Porcentagem (%)'
				}
			},
			x: {
				title: {
					display: true,
					text: 'Datas'
				}
			}
		}
	};
	useEffect(() => {
		const loadChartData = () => {
			if (!data) {
				return
			}
			setChardData(data)
		}
		loadChartData()
	}, [data])
	if (!chartData) {
		return <Spinner></Spinner>
	}
	return <Line data={chartData} options={options} />
}

export const ChartDashBoardDealership = ({ data, field_label }) => {
	const [chartData, setChardData] = useState(null)
	const options = {
		indexAxis: 'y',
		elements: {
			bar: {
				borderWidth: 2,
			},
		},
		responsive: true,
		plugins: {
			legend: {
				position: 'right',
			},
			title: {
				display: true,
				text: 'KM Inicial',
			},
		},
	}
	useEffect(() => {
		if (Array.isArray(data)) {
			const labels = ['']
			const colors = generateRandomColorPaletteWithBrightness(data.length)
			const datasets = data.map((item, i) => {
				return {
					label: `${item[field_label]}`,
					data: [item.count],
					borderColor: colors[i].brightness,
					backgroundColor: colors[i].color,
				}
			})
			setChardData({
				labels,
				datasets,
			})
		}
	}, [data, field_label])
	if (!data || !field_label) {
		return <div className='col-12'></div>
	}
	if (!chartData) {
		return <div className='col12'></div>
	}
	return (
		<Bar options={options}
			data={chartData} />
	)
}
export const ChartDashBoardHighway = ({ data }) => {
	const [chartData, setChardData] = useState(null)
	const options = {
		responsive: true,
		plugins: {
			legend: {
				display: false
			},
			title: {
				display: true,
				text: 'Atividades',
			},
		},
	}
	useEffect(() => {
		const loadChartData = () => {
			if (Array.isArray(data)) {
				const labels = []
				const datasets = []
				for (const item of data) {
					labels.push('')
					datasets.push(item.count)
				}
				const colors = generateRandomColorPaletteWithBrightness(labels.length)
				setChardData({
					labels,
					datasets: [{
						label: 'Total de Ncs',
						data: datasets,
						borderColor: colors[0].color,
						backgroundColor: colors[0].brightness
					}]
				})
			}
		}
		loadChartData()
	}, [])
	if (!chartData) {
		return <></>
	}
	return <Line options={options} data={chartData} />
}
export const ChartDashBoardLocate = ({ data, field_label }) => {
	const [chartData, setChardData] = useState(null)
	useEffect(() => {
		if (Array.isArray(data)) {
			const colors = generateRandomColorPaletteWithBrightness(data.length)
			const datasets = data.map((item, i) => {
				const label = splitString(field_label, '.')
				return {
					label_1: `${item[label.secondPart]}`,
					label_2: item[label.firstPart],
					data: item.count,
					borderColor: colors[i].brightness,
					backgroundColor: colors[i].color,
				}
			})
			setChardData(datasets)
		}
	}, [data, field_label])
	if (!data || !field_label) {
		return <div className='col-12'></div>
	}
	if (!chartData) {
		return <div className='col12'></div>
	}
	return (
		<div className='row'>
			{chartData.map((item, i) => (
				<div className="col-12 col-sm-6 col-md-3" key={i}>
					<div className="info-box">
						<span className="info-box-icon elevation-1" style={{
							width: 10, backgroundColor: item.backgroundColor
						}}>
						</span>
						<div className="info-box-content">
							<div className='row'>
								<div className='col'>
									<span className="info-box-text">
										Rodovia: <span className="info-box-number">
											{item.label_1}
										</span>
									</span>
								</div>
								<div className='col'>
									<span className="info-box-text">
										KM: <span className="info-box-number">
											{item.label_2}
										</span>
									</span>
								</div>
							</div>
							Total de Ncs: <span className="info-box-number">
								<small>{item.data}</small> Ncs
							</span>
						</div>

					</div>
				</div>))}
		</div>
	)
}
export const ChartDashBoardService = ({ data }) => {
	const [chartData, setChardData] = useState(null)
	const options = {
		responsive: true,
		plugins: {
			legend: {
				display: false
			},
			title: {
				display: true,
				text: 'Atividades',
			},
		},
	}
	useEffect(() => {
		const loadChartData = () => {
			if (Array.isArray(data)) {
				const labels = []
				const datasets = []
				for (const item of data) {
					labels.push('')
					datasets.push(item.count)
				}
				const colors = generateRandomColorPaletteWithBrightness(labels.length)
				setChardData({
					labels,
					datasets: [{
						label: 'Total de Ncs',
						data: datasets,
						borderColor: colors[0].color,
						backgroundColor: colors[0].brightness
					}]
				})
			}
		}
		loadChartData()
	}, [])
	if (!chartData) {
		return <></>
	}
	return <Line options={options} data={chartData} />
}
export const ChartPie = ({ data, isReport }) => {
	if (!data) {
		return <></>
	}
	return (
		<div className='w-100 h-50 mb-4'>
			<Pie data={data} />
		</div>
	)
}