/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { requestContext } from "../../context/requestContext";
import ContentHeader from "../../components/contentHeader";
import ListSAC from "../../components/list/sac";
const SAC = () => {
    const { getAllItemSAC, sac } = useContext(requestContext)
    useEffect(() => {
        getAllItemSAC()
    }, [])
    return (
        <>
            <ContentHeader title={"SAC"} />
            <div className="content">
                <div className="card">
                    <div className="card-header">
                        <div className="card-title">
                            SAC para Contato
                        </div>
                    </div>
                    <div className="card-body">
                        <ListSAC data={sac} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default SAC