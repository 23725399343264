/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { requestContext } from "../../context/requestContext";
import ContentHeader from "../../components/contentHeader";
import Spinner from "../../components/spinner";
import { formContext } from "../../context/formContext";
import { Link, useNavigate } from "react-router-dom";
import { authContext } from "../../context/authContext";
import SearchTypeSubServiceCategory from "../../components/form/searchTypeSubServiceCategory";
import ListTypeSubServiceCategory from "../../components/list/listTypeSubCategory";
const ManagerTypeServiceSubCategory = () => {
    const { getItemTypeSubServiceCategory, getItemWhereTypeSubServiceCategory, gerEm } = useContext(requestContext);
    const { state: { tip_serv_sub_cat_desc }, handleForm } = useContext(formContext)
    const { user } = useContext(authContext);
    const navigate = useNavigate();
    useEffect(() => {
        getItemTypeSubServiceCategory();
    }, []);
    const handlePage = (item) => {
        if (!item) {
            return
        }
        const { label } = item
        const { current_page, last_page } = gerEm
        window.scrollTo(0, 0);
        if (label === 'pagination.previous') {
            return tip_serv_sub_cat_desc ? getItemWhereTypeSubServiceCategory(current_page > 1 && `${tip_serv_sub_cat_desc}?page=${current_page - 1}`) : getItemTypeSubServiceCategory(current_page > 1 && `0?page=${current_page - 1}`)
        }
        if (label === 'pagination.next') {
            return tip_serv_sub_cat_desc ? getItemWhereTypeSubServiceCategory(current_page > 1 && `${tip_serv_sub_cat_desc}?page=${current_page - 1}`) : getItemTypeSubServiceCategory(current_page < last_page && `0?page=${current_page + 1}`)
        }
        if (!label) {
            return tip_serv_sub_cat_desc ? getItemWhereTypeSubServiceCategory(tip_serv_sub_cat_desc) : getItemTypeSubServiceCategory();
        }
        if (tip_serv_sub_cat_desc) {
            return getItemWhereTypeSubServiceCategory(`${tip_serv_sub_cat_desc}?page=${label}`)
        }
        getItemTypeSubServiceCategory(`0?page=${label}`);
    };
    const handleNew = () => {
        handleForm({
            tip_serv_sub_cat_cod: '',
            tip_serv_sub_cat_desc: '',
            tip_serv_sub_cat_disp: 1,
        })
        navigate('/type_service/new')
    }
    return (
        <>
            <ContentHeader title={"Gerenciar Tipos de serviços"} />
            <div className="content">
                <div className="card sticky-top">
                    <div className="card-header">
                        <h3 className="card-title col-8 d-none d-md-block">
                            Tipos de serviços
                        </h3>
                        <div className="card-tools">
                            <SearchTypeSubServiceCategory />
                        </div>
                    </div>
                </div>
                <div className="card">
                    <Spinner direction={"center"} label="carregando" />
                    <ListTypeSubServiceCategory data={gerEm} />
                    <div className="card-footer clearfix">
                        <ul className="pagination pagination-sm m-0 float-right">
                            {Array.isArray(gerEm?.links) &&
                                gerEm.links.map((item) => (
                                    <li className="page-item" key={item.label}>
                                        <Link
                                            className={`page-link ${item.active && "text-light bg-danger"
                                                }`}
                                            to="#"
                                            onClick={() => handlePage(item)}
                                        >
                                            {item.label === "pagination.previous" ? (
                                                <i className="fa fa-angle-left"></i>
                                            ) : item.label === "pagination.next" ? (
                                                <i className="fa fa-angle-right"></i>
                                            ) : (
                                                item.label
                                            )}
                                        </Link>
                                    </li>
                                ))}
                        </ul>
                        {user && user.set_nivel === 1 && (
                            <ul className="pagination pagination-sm m-0 float-left">
                                <li className="page-item">
                                    <div className="page-link" onClick={handleNew}>
                                        Novo <i className="fa fa-plus mr-1 ml-1"></i>
                                    </div>
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
export default ManagerTypeServiceSubCategory;
