import { config } from "../../constants"
import { filter } from "../arrayHandless"
import { splitString } from "../stringHandless"

const loadConfigIRI = async (setSmallerValueIRI) => {
    const nav_tip_iri_smaller_value = await config('nav_tip_iri_smaller_value')
    if (!nav_tip_iri_smaller_value) {
        return
    }
    const config_nav_tip_iri_smaller_value = await nav_tip_iri_smaller_value
    const { config_para_value } = config_nav_tip_iri_smaller_value
    const { firstPart, secondPart } = splitString(config_para_value, ',')
    setSmallerValueIRI({
        value: Number(firstPart),
        id: Number(secondPart)
    })
}
const loadConfigIGG = async (setSmallerValueIGG) => {
    const nav_tip_igg_smaller_value = await config('nav_tip_igg_smaller_value')
    if (!nav_tip_igg_smaller_value) {
        return
    }
    const config_nav_tip_igg_smaller_value = await nav_tip_igg_smaller_value
    const { config_para_value } = config_nav_tip_igg_smaller_value
    const { firstPart, secondPart } = splitString(config_para_value, ',')
    setSmallerValueIGG({
        value: Number(firstPart),
        id: Number(secondPart)
    })
}
const loadConfigIGGIRI = async (setIdnavpiggiri) => {
    const navp_tip_iri_igg = await config('navp_tip_iri_igg')
    if (!navp_tip_iri_igg) {
        return
    }
    const config_navp_tip_iri_igg = await navp_tip_iri_igg
    const { config_para_value } = config_navp_tip_iri_igg
    setIdnavpiggiri(Number(config_para_value))
}
const controllCrosInfo = ({
    items, monitoring
}) => {
    try {
        const crossInfo = []
        if (items?.length && monitoring?.length) {
            for (const monItem of monitoring) {
                const { localizacao_levantamento_indice_pavimento_consolidado } = monItem
                const {
                    loc_lipc_rod_cod,
                    loc_lipc_rod_sent_cod,
                    loc_lipc_km_init,
                    loc_lipc_km_fim,
                } = localizacao_levantamento_indice_pavimento_consolidado
                const { data: findItems } = filter(items, 'localizacao_levantamento_indice_pavimento_consolidado.loc_lipc_rod_cod', loc_lipc_rod_cod)
                if (!findItems?.length) {
                    continue
                }
                for (const findItem of findItems) {
                    const { lipc_id } = findItem
                    const {
                        loc_lipc_km_init: item_loc_lipc_km_init,
                        loc_lipc_rod_sent_cod: mon_loc_lipc_rod_sent_cod
                    } = findItem.localizacao_levantamento_indice_pavimento_consolidado
                    if ((item_loc_lipc_km_init >= loc_lipc_km_init && item_loc_lipc_km_init <= loc_lipc_km_fim) && (loc_lipc_rod_sent_cod === mon_loc_lipc_rod_sent_cod)) {
                        crossInfo.push({
                            ...monItem,
                            lipc_id,
                        })
                        continue
                    }
                    crossInfo.push({
                        ...monItem,
                        lipc_id: 0
                    })
                }
            }
        }
        return crossInfo
    } catch (error) {
        console.log(error)
        return []
    }
}
export { loadConfigIGG, loadConfigIRI, loadConfigIGGIRI, controllCrosInfo }