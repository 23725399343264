/**
 * Splits a string into two variables based on a specified separator.
 *
 * @param {string} fullString - The complete string to be split.
 * @param {string} separator - The character used to separate the string into parts.
 * @returns {Object} An object containing two variables, each representing a part of the split string.
 *
 * @example
 * const fullString = 'pedido_venda.ped_venda_cod';
 * const result = splitString(fullString, '.');
 * console.log(result);
 * // Output:
 * // {
 * //   firstPart: 'pedido_venda',
 * //   secondPart: 'ped_venda_cod',
 * // }
 */
function splitString(fullString, separator = '.') {
	try {
		// Split the string using the specified separator
		const parts = fullString.split(separator)

		// Assign each part to variables
		const firstPart = parts?.[0] || ''
		const secondPart = parts?.[1] || ''

		// Return an object containing the two parts
		return {
			firstPart,
			secondPart,
		}
	} catch (error) {
		console.log(error)
		return {
			firstPart: '',
			secondPart: ''
		}
	}
}
/**
 * Changes the unit representation in a given string.
 *
 * @function
 * @param {string} value - The string containing units to be changed.
 * @returns {string|undefined} - The string with updated units or `undefined` if the input is falsy.
 * @example
 * const originalString = 'Area: 100M2, Volume: 50M3';
 * const updatedString = changeUnit(originalString);
 * console.log(updatedString); // Output: 'Area: 100M², Volume: 50M³'
*/
const changeUnit = (value) => {
	if (!value) {
		return
	}

	/**
	 * String with updated units.
	 * @type {string}
	 */
	const updatedValue = value.replace('M2', 'M²').replace('M3', 'M³')
	if (updatedValue === 'Porcentagem') {
		return '%'
	}
	return updatedValue
}
/**
 * Remove all dots from a string.
 *
 * @param {string} inputString - The string from which dots will be removed.
 * @returns {string} A new string with all dots removed.
 * @throws {null} If the argument is not a string.
 * @example
 * const stringWithDots = '1.234.567.890';
 * const stringWithoutDots = removeDots(stringWithDots);
 * console.log(stringWithoutDots); // Output: "1234567890"
 */
const removeDots = (inputString) => {
	if (typeof inputString !== 'string') {
		return null
	}
	return inputString.replace(/\./g, '')
}
/**
 * Remove dots, commas, and dashes from a string.
 *
 * @param {string} inputString - The string from which dots, commas, and dashes will be removed.
 * @returns {string} A new string with dots, commas, and dashes removed.
 * @throws {null} If the argument is not a string.
 * @example
 * const stringWithSpecialChars = '1.234,567-890';
 * const stringWithoutSpecialChars = removeSpecialChars(stringWithSpecialChars);
 * console.log(stringWithoutSpecialChars); // Output: "1234567890"
 */
const removeSpecialChars = (inputString) => {
	if (typeof inputString !== 'string') {
		return null
	}
	return inputString.replace(/[.,-]/g, '')
}
/**
   * Added a period to every three digits of a number.
   * @param {Object} params - The number that will be formatted.
   * @param {string} params.string - A string that will be formatted.
   * @param {number} params.padNumber - After 5 digits you want to add the points.
   * @returns {string} A string containing the number formatted with periods every three digits.
   * @example
   * const number = 1234567890;
   * const formattednumber = addPointToThreeDigits({sring: number, paddNumber:3});
   * console.log(FormattedNumber); //Output: "1,234,567,890"
   */
const addDots = ({ string, padNumber, }) => {
	const numeroString = String(string)
	const partes = []
	let temp = ''
	for (let i = numeroString.length - 1; i >= 0; i--) {
		temp = numeroString[i] + temp
		if (temp.length === padNumber || i === 0) {
			partes.unshift(temp)
			temp = ''
		}
	}
	const resultado = partes.join('.')
	return resultado
}
/**
 * Converts a string representing a numeric value to a formatted currency string in Brazilian Real (BRL).
 *
 * @function
 * @param {string} stringValue - The string representing the numeric value.
 * @param {boolean} show_tipe_coin - The string show type coin, for exemplo R$ when formatted currency string in Brazilian.
 * @returns {string} - The formatted currency string in Brazilian Real (BRL).
 * @throws {string} - return string empty when error.
 */
const formatCurrency = (stringValue, show_tipe_coin = true) => {
	/**
	 * Numeric value obtained by parsing the input string.
	 * @type {number}
	 */
	const numberValue = parseFloat(stringValue)

	if (isNaN(numberValue)) {
		return ''
	}

	/**
	 * Formatted currency string in Brazilian Real (BRL).
	 * @type {string}
	 */
	const formattedString = numberValue.toLocaleString('pt-BR', {
		style: 'currency',
		currency: 'BRL',
	})
	if (!show_tipe_coin) {
		return formattedString.replace('R$', '').trim()
	}
	return formattedString
}
/**
 * Converts a string to uppercase and replaces special characters.
 * @param {string} str - The string to process.
 * @returns {string} The processed string.
 */
const formatString = (str) => {
	return str.trim().toUpperCase().replace(/³/g, '3').replace(/²/g, '2')
}
/**
 * Removes dashes, dots, accents, and other non-numeric characters from a string.
 * @param {string} str - The string to be processed.
 * @returns {string} Returns a new string containing only the numbers.
 * @example
 *const text = '123-456.789'
 *const cleanedText = cleanString(text)
 *console.log(cleanedText) // Output: "123456789"
 */
function cleanString(str) {
	// Remove all non-numeric characters using a regular expression
	return str.replace(/[^\d]/g, '')
}
/**
 * Formats a phone number to Brazilian format (XX) XXXX-XXXX or (XX) XXXXX-XXXX.
 * 
 * @param {string} phoneNumber - The phone number to format.
 * @returns {string} The formatted phone number.
 */
function formatPhoneNumber(phoneNumber) {
	// Remove all non-numeric characters from the input string
	const digitsOnly = phoneNumber.replace(/\D/g, '')

	// Check the length to determine the format
	const formattedNumber = digitsOnly.length === 11 ?
		digitsOnly.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3') :
		digitsOnly.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')

	return formattedNumber
}
/**
 * Replaces occurrences of search strings with corresponding replacement strings in a given string.
 * 
 * @param {string} str - The original string to be modified.
 * @param {Object[]} replacements - An array of objects containing search and replace strings.
 * @returns {string} The modified string with replacements applied.
 * 
 * @example
 * const originalString = 'This is a test';
 * const replacements = [
 *     { search: 'a', replace: '1' },
 *     { search: 'e', replace: '2' },
 *     { search: 'i', replace: '3' },
 *     { search: 'o', replace: '4' },
 *     { search: 'u', replace: '5' }
 * ];
 * 
 * const modifiedString = replaceWithArray(originalString, replacements);
 * console.log(modifiedString); // Output: Th3s 3s 1 t2st
 */
function replaceWithArray(str, replacements) {
	let modifiedString = str

	replacements.forEach(({ search, replace }) => {
		modifiedString = modifiedString.replace(new RegExp(search, 'g'), replace)
	})

	return modifiedString
}
/**
 * Search in strings with corresponding a array of strings.
 * 
 * @param {string} targetString - The original string to be searched.
 * @param {Array[]} wordsToSearch - An array containing search and replace strings.
 * @returns {boolean} True if found or false if not found.
 * 
 * @example
 * const targetString = 'This is a test';
 * const wordsToSearch = [
 *     'a',
 *     'e',
 *     'i',
 *     'o',
 *     'u', * ];
 * 
 * const isWordFound = searchSomeWordInString(targetString, replacements);
 * console.log(isWordFound); // true|false
 */
function searchSomeWordInString(targetString, wordsToSearch) {
	try {
		if (!targetString) {
			return false
		}
		// Verifica se alguma das palavras no array existe na string
		const wordExists = wordsToSearch.some(word => targetString.includes(word))
		return wordExists
	} catch (error) {
		return String(error)
	}
}
/**
 * Converts the case of a string to title case.
 * @param {string} string - The string to convert.
 * @returns {string} The string converted to title case.
 */
function convertToTitleCase(string) {
	return string.toLowerCase().replace(/\n/g, ' ').replace(/(?:^|\s|-)\S/g, (match) => {
		return match.toUpperCase()
	})
}
/**
 * Extracts numbers from a string and concatenates them into a single string.
 * @param {string} text - The input text containing numbers.
 * @returns {string} A string containing only the numbers extracted from the input text.
 */
function extractNumbers(text) {
	const numbers = text.match(/\d/g) // Extract numbers from the text
	if (numbers === null) return '' // If no numbers found, return an empty string
	return numbers.join('') // Concatenate the extracted numbers into a single string
}
/**
 * Replaces multiple words in a string with a specified value.
 * @param {string} text - The input text.
 * @param {string[]} wordsToRemove - An array containing the words to be removed.
 * @param {string} [replacement=" "] - The value to replace the words with (default is a space).
 * @returns {string} The input text with the specified words replaced by the replacement value.
 * @example
 * // Example usage:
 * const inputText = "This is an example text with multiple words that need to be removed.";
 * const wordsToRemove = ["example", "words", "removed"];
 * const newText = replaceWords(inputText, wordsToRemove);
 * console.log(newText);
 */
function replaceWords(text, wordsToRemove, replacement = '') {
	try {
		const regex = new RegExp(wordsToRemove.join('|'), 'gi')
		return text.replace(regex, replacement)
	} catch (error) {
		console.log(error)
		return text
	}
}
/**
 * Formats a percentage number to two decimal places before
 * and two decimal places after the comma.
 *
 * @param {number} percentage - The percentage number to be formatted.
 * @returns {string} - The formatted percentage string.
 */
function formatPercentage(percentage) {
	const formattedNumber = percentage.toFixed(2) // Formata com 2 casas decimais após a vírgula
	const [beforeDecimal, afterDecimal] = formattedNumber.split('.') // Divide parte inteira e decimal
	const formattedBeforeDecimal = beforeDecimal.padStart(2, '0') // Garante duas casas antes da vírgula

	return `${formattedBeforeDecimal},${afterDecimal}%`
}
/**
 * When double-click to copy the text content of an HTML element.
 * Returns a Promise that resolves to `true` if the text was copied successfully, `false` otherwise.
 *
 * @param {HTMLElement} element - The HTML element to attach the double-click event to.
 * @returns {boolean} - A Promise resolving to `true` if copy was successful, `false` otherwise.
 */
async function enableDoubleClickToCopy(element) {
	try {
		const textToCopy = element.textContent || element.innerText
		await navigator.clipboard.writeText(textToCopy)
		return textToCopy

	} catch (error) {
		console.log(error)
		return false
	}
}
/**
 * Extracts a portion of a string based on the number of segments to take, split by a dot (`.`).
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.input - The input string to be processed.
 * @param {number} [params.whichPartToTake=1] - The number of segments to extract from the start of the string.
 * @param {string} [params.joinString=''] - The string used to join the extracted segments. If empty, an array of segments is returned.
 * @returns {string|string[]} - The extracted portion of the string. If `joinString` is provided, returns a single string; otherwise, returns an array of segments.
 *
 * @throws {Error} If the input is invalid or an unexpected error occurs.
 *
 * @example
 * // Example 1: Return the first segment as an array
 * getPartTextFromCharacter({ input: '2.1.01.1.0' });
 * // Output: ["2"]
 *
 * @example
 * // Example 2: Return the first two segments joined by '.'
 * getPartTextFromCharacter({ input: '2.1.01.1.0', whichPartToTake: 2, joinString: '.' });
 * // Output: "2.1"
 *
 * @example
 * // Example 3: Return the first three segments joined by '-'
 * getPartTextFromCharacter({ input: '04.01.01.1.0', whichPartToTake: 3, joinString: '-' });
 * // Output: "04-01-01"
 */
function getPartTextFromCharacter({
	input,
	whichPartToTake = 1,
	joinString = ''
}) {
	try {
		const parts = input.split('.')
		if (joinString) {
			return parts.slice(0, whichPartToTake).join(joinString)
		}
		return parts.slice(0, whichPartToTake)
	} catch (error) {
		console.log(error)
		return ''
	}
}
export {
	splitString,
	changeUnit,
	removeDots,
	removeSpecialChars,
	addDots,
	formatCurrency,
	convertToTitleCase,
	formatString,
	cleanString,
	formatPhoneNumber,
	replaceWithArray,
	searchSomeWordInString,
	replaceWords,
	extractNumbers,
	formatPercentage,
	enableDoubleClickToCopy,
	getPartTextFromCharacter
}
