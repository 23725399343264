import React, { useContext } from 'react'
import { formContext } from '../../../context/formContext'
import { useNavigate } from 'react-router-dom'
import { requestContext } from '../../../context/requestContext'
import { parseFromMysqlDateToJSDate } from '../../../services/dateHandless'
const ListConfigurationParameters = ({ data }) => {
	const { handleForm } = useContext(formContext)
	const { deleteItemConfigurationParameters } = useContext(requestContext)
	const navigate = useNavigate()
	const handleEdit = (item) => {
		handleForm({
			...item,
		})
		navigate('/configurations_paramaters/new')
	}
	const handleDelete = (item) => {
		const { config_para_cod, sett_desc } = item
		const confirm = window.confirm(`Você deseja mesmo remover a configuração ${sett_desc}`)
		if (!confirm) {
			return
		}
		deleteItemConfigurationParameters(config_para_cod)
	}
	if (!Array.isArray(data)) {
		return <div></div>
	}
	return data.map((item) => (
		<div className="row p-3" key={item.config_para_cod}>
			<div className="col-md-11 mb-3 col-sm-10">
				<div className="row">
					<div className="col-md-6 mb-3 col-sm-12">
						<span className="text-secondary mr-2">
              Descrição:
						</span>
						{item.sett_desc}
					</div>
					<div className="col-md-6 mb-3 col-sm-12">
						<span className="text-secondary mr-2">
              Nome da chave:
						</span>
						{item.config_para_desc}
					</div>
					<div className="col-md-3 mb-3 col-sm-12">
						<span className="text-secondary mr-2">
              Valor da chave:
						</span>
						{item.config_para_value}
					</div>
					<div className="col-md-3 mb-3 col-sm-12">
						<span className="text-secondary mr-2">
              Criado no dia:
						</span>
						{parseFromMysqlDateToJSDate(item.created_at)}
					</div>
				</div>
			</div>
			<div className="col-md-1 mb-3 col-sm-2">
				<div className="row">
					<button
						className="btn btn-danger btn-sm mr-2"
						onClick={() => handleEdit(item)}
					>
						<i className="ml-1 fa fa-edit"></i>
					</button>
					<button
						className="btn btn-secondary btn-sm"
						onClick={() => handleDelete(item)}
					>
						<i className="fa fa-trash"></i>
					</button>
				</div>
			</div>
		</div>
	))
}
export default ListConfigurationParameters