import React, { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { handleWaring, isValid } from '../../../services/isValid'
import { requestContext } from '../../../context/requestContext'
import Spinner from '../../spinner'
const FormApprovedRecusedProgress = ({ handleClear }) => {
	const { state, onChange } = useContext(formContext)
	const { setInfoRequest, postApproveProgress } = useContext(requestContext)
	const [approved, setApproved] = useState(false)
	const [placeholder, setPlaceHolder] = useState(null)
	const {
		pvs_pedidovenda,
		prog_serv_qtd_acul,
		fisc_cod,
		fisc_current_obs,
		prog_serv_cod,
		serv_sub_cat_desc,
		fisc_obs,
	} = state
	useEffect(() => {
		const controllPlaceHolder = () => {
			if (!serv_sub_cat_desc) {
				return
			}
			setPlaceHolder(`Descreva o porque você ${approved ? 'aprova' : 'não aprova'} essa alteração no serviço ${serv_sub_cat_desc}`)
		}
		controllPlaceHolder()
	}, [serv_sub_cat_desc])

	const handleSave = async () => {
		const item = {
			pvs_pedidovenda,
			prog_serv_qtd_acul,
			approved: !approved ? 0 : 1,
			fisc_cod,
			prog_serv_cod,
		}
		const valid = isValid(item)
		if (!valid) {
			return setInfoRequest(handleWaring('Você precisa preencher todos os campos obrigatórios'))
		}
		if (!approved) {
			item.fisc_obs = fisc_current_obs
		}
		const response = await postApproveProgress({
			id: fisc_cod,
			...item,
		})
		if (handleClear && response) {
			handleClear()
		}
	}
	return (
		<div className="row">
			<div className="form-group col-12">
				<label><i className="fa fa-info-circle mr-2" aria-hidden="true"></i> Fiscalização atual:</label>
				<div className="input-group">
					<textarea
						type="text"
						name="fisc_obs"
						value={fisc_obs}
						disabled
						className="form-control form-control-border" />
				</div>
			</div>
			<div className="form-group col-12">
				<div className='row'>
					<div className='col-6'>
						<button className='btn btn-sm btn-danger' onClick={() => setApproved(true)}>
                            Aprovar {approved ? (<i className="fa fa-check-circle" aria-hidden="true"></i>
							) : ''}
						</button>
					</div>
					<div className='col-6'>
						<button className='btn btn-sm btn-secondary' onClick={() => setApproved(false)}>
                            Não aprovar {!approved ? (<i className="fa fa-check-circle" aria-hidden="true"></i>
							) : ''}
						</button>
					</div>
				</div>
			</div>
			<div className="form-group col-12">
				<label>Observações sobre essa fiscaliazção:</label>
				<div className="input-group">
					<textarea
						type="text"
						name="fisc_current_obs"
						onChange={onChange}
						className="form-control form-control-border"
						minLength={5}
						maxLength={100}
						placeholder={placeholder} />
				</div>
			</div>
			<div className="col-12">
				<button className="btn btn-danger" onClick={handleSave}>
					<i className="fa fa-save"></i> Salvar <Spinner direction={'center'} label={' '} />
				</button>
			</div>
		</div >
	)
}
export default FormApprovedRecusedProgress