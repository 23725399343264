import React, { useContext, useEffect, useRef, useState} from 'react'
import { formContext } from '../../../context/formContext'
import { getExt, generateBse64 } from '../../../services/files'
const FormTutorial = () => {
	const { state, onChange, handleForm } = useContext(formContext)
	const { tut_titulo, tut_descricao, tut_tipo_arquivo, tut_disp } = state
	const [file, setFile] = useState({
		file:'',
		name:'',
		type:''
	})
	const input_file_ref = useRef()

	const onChangeFile = ({ target }) => {
		const { files } = target
		if (files.length === 0) {
			return
		}
   
		setFile({
			file:files[0],
			name:files[0].name,
			type: files[0].type
		})
    
	}


	useEffect(()=> {
		if(file?.file!==null) {
			convertFile()
			handleForm({'tut_tipo_arquivo':file.type})
      
		}
    
	}, [file])

	const handleAttachments = () => {
		input_file_ref.current.click()
	}
	const convertFile = async () => {
		const filegenerateBse64 = await generateBse64(file.file)
		handleForm({'tut_base_64':filegenerateBse64})
	}
	return (
		<div className="row">
			<div className="form-group col-md-12 col-sm-12">
				<label>Título:</label>
				<div className="input-group">
					<input
						type="text"
						onChange={onChange}
						name="tut_titulo"
						value={tut_titulo}
						maxLength={100}
						minLength={5}
						className={`form-control form-control-border ${
							!tut_titulo && 'is-invalid'
						}`}
						placeholder="Título do Tutorial"
						required
					/>
				</div>
			</div>
			<div className="form-group col-md-12 col-sm-12">
				<label>Descrição:</label>
				<div className="input-group">
					<textarea
						type="text"
						rows={4}
						onChange={onChange}
						name="tut_descricao"
						value={tut_descricao}
						maxLength={500}
						minLength={5}
						className={`form-control form-control-border ${
							!tut_descricao && 'is-invalid'
						}`}
						placeholder="Descrição do Tutorial"
						required
					/>
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-12">
				<label>Status</label>
				<select 
					name="tut_disp" 
					value={tut_disp}
					className={'form-control form-control-border'}
					onChange={onChange}
					required
				>
					<option value="">
              Selecione
					</option>
					<option value={1}>
              Ativo
					</option>
					<option value={0}>
              Inativo
					</option>
				</select>
			</div>
			<div className="form-group col-md-6 col-sm-12">
				<label>Anexar:</label>
				<div className="input-group">
					<div className="btn btn-dark btn-sm" onClick={handleAttachments} >
						<i className="fa fa-paperclip"></i> {file?.name}
					</div>
				</div>
			</div>
			<input
				name="tut_tipo_arquivo"
				className="d-none"
				type="file"
				onChange={onChangeFile}
				ref={input_file_ref}
				multiple
			/>
    
		</div>
	)
}
export default FormTutorial
