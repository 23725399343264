import { MODELS } from '../../model'
import { formatDate } from '../dateHandless'

const debuggerGenerate = () => {
	const SYNC_SALES_ORDER = localStorage.getItem(MODELS.SYNC_SALES_ORDER)
	const SYNC_SALES_ORDER_SERVICE = localStorage.getItem(MODELS.SYNC_SALES_ORDER_SERVICE)
	const SYNC_SALES_ORDER_CHECK_INSPECTOR = localStorage.getItem(MODELS.SYNC_SALES_ORDER_CHECK_INSPECTOR)
	const SALES_ORDER = localStorage.getItem(MODELS.SALES_ORDER)
	const SALES_ORDER_SERVICE = localStorage.getItem(MODELS.SALES_ORDER_SERVICE)
	const SALES_ORDER_CHECK_INSPECTOR = localStorage.getItem(MODELS.SALES_ORDER_CHECK_INSPECTOR)
	const SALES_ORDER_PHOTOS = localStorage.getItem(MODELS.SALES_ORDER_PHOTOS)
	const PARSE_SYNC_SALES_ORDER = SYNC_SALES_ORDER ? JSON.parse(SYNC_SALES_ORDER) : []
	const PARSE_SYNC_SALES_ORDER_SERVICE = SYNC_SALES_ORDER_SERVICE ? JSON.parse(SYNC_SALES_ORDER_SERVICE) : []
	const PARSE_SYNC_SALES_ORDER_CHECK_INSPECTOR = SYNC_SALES_ORDER_CHECK_INSPECTOR ? JSON.parse(SYNC_SALES_ORDER_CHECK_INSPECTOR) : []
	const PARSE_SALES_ORDER = SALES_ORDER ? JSON.parse(SALES_ORDER) : []
	const PARSE_SALES_ORDER_SERVICE = SALES_ORDER_SERVICE ? JSON.parse(SALES_ORDER_SERVICE) : []
	const PARSE_SALES_ORDER_CHECK_INSPECTOR = SALES_ORDER_CHECK_INSPECTOR ? JSON.parse(SALES_ORDER_CHECK_INSPECTOR) : []
	const PARSE_SALES_ORDER_PHOTOS = SALES_ORDER_PHOTOS ? JSON.parse(SALES_ORDER_PHOTOS) : []
	return [
		{ PARSE_SYNC_SALES_ORDER: PARSE_SYNC_SALES_ORDER },
		{ PARSE_SYNC_SALES_ORDER_SERVICE: PARSE_SYNC_SALES_ORDER_SERVICE },
		{ PARSE_SYNC_SALES_ORDER_CHECK_INSPECTOR: PARSE_SYNC_SALES_ORDER_CHECK_INSPECTOR },
		{ PARSE_SALES_ORDER: PARSE_SALES_ORDER },
		{ PARSE_SALES_ORDER_SERVICE: PARSE_SALES_ORDER_SERVICE },
		{ PARSE_SALES_ORDER_CHECK_INSPECTOR: PARSE_SALES_ORDER_CHECK_INSPECTOR },
		{ PARSE_SALES_ORDER_PHOTOS: PARSE_SALES_ORDER_PHOTOS },
	]
}
export const downloadReport = (report_, nameFile) => {
	const exportObj = report_ || []
	var dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportObj))
	const exportName = `${nameFile || 'download'}_report_${formatDate()}`
	var downloadAnchorNode = document.createElement('a')
	downloadAnchorNode.setAttribute('href',     dataStr)
	downloadAnchorNode.setAttribute('download', exportName + '.json')
	document.body.appendChild(downloadAnchorNode) // required for firefox
	downloadAnchorNode.click()
	downloadAnchorNode.remove()
}
export default debuggerGenerate