import React, { useState } from 'react'
const TabsConstructionPlan = ({
	setservices,
	setgraphics,
	settimeline,
	setinfo,
	setattachments,
	setmidias,
	tabclassName
}) => {
	const [active, setActive] = useState('setservices')
	const onClick = id => {
		if (
			!setservices ||
            !setgraphics ||
            !settimeline ||
            !setinfo ||
            !setattachments ||
            !setmidias
		) {
			return
		}
		setActive(id)
		setservices('')
		setgraphics('')
		settimeline('')
		setinfo('')
		setattachments('')
		setmidias('')
		switch (id) {
		case 'setservices':
			setservices(tabclassName)
			break
		case 'setgraphics':
			setgraphics(tabclassName)
			break
		case 'settimeline':
			settimeline(tabclassName)
			break
		case 'setinfo':
			setinfo(tabclassName)
			break
		case 'setattachments':
			setattachments(tabclassName)
			break
		case 'setmidias':
			setmidias(tabclassName)
			break

		default:
			break
		}
	}
	return (
		<ul className="nav nav-tabs" id="myTab" role="tablist">
			<li className="nav-item" role="presentation">
				<button onClick={() => onClick('setservices')} className={active === 'setservices' ? 'nav-link active' : 'nav-link'} id="services-tab" data-toggle="tab" data-target="#services" type="button" role="tab" aria-controls="services" aria-selected="true">Serviços</button>
			</li>
			<li className="nav-item" role="presentation">
				<button onClick={() => onClick('setgraphics')} className={active === 'setgraphics' ? 'nav-link active' : 'nav-link'} id="graphics-tab" data-toggle="tab" data-target="#graphics" type="button" role="tab" aria-controls="graphics" aria-selected="false">Gráficos</button>
			</li>
			<li className="nav-item" role="presentation">
				<button onClick={() => onClick('settimeline')} className={active === 'settimeline' ? 'nav-link active' : 'nav-link'} id="timeline-tab" data-toggle="tab" data-target="#timeline" type="button" role="tab" aria-controls="timeline" aria-selected="false">Cronograma</button>
			</li>
			<li className="nav-item" role="presentation">
				<button onClick={() => onClick('setinfo')} className={active === 'setinfo' ? 'nav-link active' : 'nav-link'} id="info-tab" data-toggle="tab" data-target="#info" type="button" role="tab" aria-controls="info" aria-selected="false">Informações adicionais</button>
			</li>
			<li className="nav-item" role="presentation">
				<button onClick={() => onClick('setattachments')} className={active === 'setattachments' ? 'nav-link active' : 'nav-link'} id="attachments-tab" data-toggle="tab" data-target="#attachments" type="button" role="tab" aria-controls="attachments" aria-selected="false">Anexos (Doc, Planilhas e etc.)</button>
			</li>
			<li className="nav-item" role="presentation">
				<button onClick={() => onClick('setmidias')} className={active === 'setmidias' ? 'nav-link active' : 'nav-link'} id="midias-tab" data-toggle="tab" data-target="#midias" type="button" role="tab" aria-controls="midias" aria-selected="false">Fotos e Vídeos</button>
			</li>
		</ul>
	)
}
export default TabsConstructionPlan