import React from 'react'

const FormCheckListPaviment = () => {
	return (
		<div className="row">
			<div className="form-group col-md-6 col-sm-12">
				<label>Titulo do item:</label>
				<div className="input-group">
					<input type="text"
						className="form-control form-control-border"
						placeholder='Titulo item do checklist' />
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-12">
				<label>Descrição do item:</label>
				<div className="input-group">
					<input type="text"
						className="form-control form-control-border"
						placeholder='Descreva a pergunta do item do checklist' />
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-12">
				<label>Tipo de resposta do item:</label>
				<div className="input-group">
					<select
						className="form-control form-control-border">
						<option>
							CheckBox (Sim ou Não)
						</option>
						<option>
							Text or Numeric (Texto livre ou qualquer numero)
						</option>
						<option>
							TextArea (Texto Livre)
						</option>
						<option>
							InputFile (Upload de arquivo)
						</option>
					</select>
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-12">
				<label>Item anterior:</label>
				<div className="input-group">
					<select
						className="form-control form-control-border">
						<option>

						</option>
					</select>
				</div>
			</div>
		</div>
	)
}
export default FormCheckListPaviment