import React, { useContext, useEffect } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { styleButtonSearch } from '../../../css/globlalStyle'
const SelectGroupIndexPavement = ({ id, label }) => {
	const { onChangeSelectInput, state, handleForm } = useContext(formContext)
	const { SERVICES_CONSTRUCTIONS, getItemPavementIndicatorsGroupsIndex } = useContext(requestContext)
	const {
		gp_ind_pav_cod,
		gp_ind_pav_desc
	} = state
	useEffect(() => {
		handleCancel()
		getItemPavementIndicatorsGroupsIndex({ id: 0, not_paginate: false })
	}, [])
	useEffect(() => {
		if (gp_ind_pav_cod) {
			handleForm({
				ipc_gp_ind_pav_cod: gp_ind_pav_cod
			})
		}
	}, [gp_ind_pav_cod])
	const handleCancel = () => {
		handleForm({
			gp_ind_pav_cod: '',
			gp_ind_pav_desc: '',
		})
	}
	if (id) {
		return (
			<div className={'form-group col-md-6 col-sm-12'}>
				<label>Grupo de Indece de {label}:</label>
				<select
					id={id}
					className='form-control form-control-border'
				>
					<option>Selecionar</option>
					{SERVICES_CONSTRUCTIONS?.total > 0 &&
						SERVICES_CONSTRUCTIONS.data.map((item) => (<option key={item.gp_ind_pav_cod} value={item.gp_ind_pav_cod}>{item.gp_ind_pav_desc}
						</option>
						))}
				</select>
			</div>
		)
	}
	return (
		<div className={'form-group col-md-6 col-sm-12'}>
			<label>Grupo de Indece:</label>
			<div className="input-group">
				{gp_ind_pav_desc ? (
					<div className='form-control form-control-border'>
						{gp_ind_pav_desc}
					</div>
				) : (
					<select
						onChange={onChangeSelectInput}
						className={`form-control form-control-border ${!gp_ind_pav_cod && 'is-invalid'
						}`}
					>
						<option>Selecionar</option>
						{SERVICES_CONSTRUCTIONS?.total > 0 &&
							SERVICES_CONSTRUCTIONS.data.map((item) => (<option key={item.gp_ind_pav_cod} value={JSON.stringify(item)}>{item.gp_ind_pav_desc}
							</option>
							))}
					</select>
				)}
				{gp_ind_pav_desc && (
					<div className="input-group-prepend">
						<span className="input-group-text bg-danger"
							style={styleButtonSearch}
							onClick={handleCancel}>
							<i className="fa fa-times-circle" aria-hidden="true"></i>
						</span>
					</div>
				)}
			</div>
		</div>
	)
}
export default SelectGroupIndexPavement