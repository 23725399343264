import React, { useContext, useEffect } from "react"
import { formContext } from "../../../../context/formContext"
import { convertToTitleCase } from "../../../../services/stringHandless"
import { formateValidatedDate } from "../../../../services/dateHandless"

const CardHeader = () => {
    const { state: { lot_conc_desc, conc_nome, fisc_obs }, handleForm } = useContext(formContext)
    useEffect(() => {
        if (conc_nome && lot_conc_desc) {
            handleForm({
                fisc_obs: `Lote ${lot_conc_desc} - ${conc_nome} - ${convertToTitleCase('LEVANTAMENTO DE ÍNDICES DO PAVIMENTO CONSOLIDADO DO ANO')} ${String(formateValidatedDate()).substring(0, 4)}`
            })
        }
    }, [conc_nome, lot_conc_desc])
    return (
        lot_conc_desc && conc_nome ? (
            <h3 className="card-title text-capitalize">{fisc_obs}</h3>
        ) : (
            <h3 className="card-title text-capitalize">{convertToTitleCase('LEVANTAMENTO DE ÍNDICES DO PAVIMENTO CONSOLIDADO DO ANO')} {String(formateValidatedDate()).substring(0, 4)}</h3>
        )
    )
}
export default CardHeader