import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { authContext } from '../../context/authContext'
import { recoverCache } from '../../services/cacheManager'
import { namesScreens } from '../../constants'
const Menu = () => {
	const { user, setUser, userProfile } =
		useContext(authContext)
	const [openMenuUser, setOpenMenuUser] = useState(false)
	const [openMenuRoad, setOpenMenuRoad] = useState(false)
	const [openMenuDealership, setOpenMenuDealership] = useState(false)
	const [openMenuConfig, setOpenMenuConfig] = useState(false)
	const [openMenuOversight, setOpenMenuOversight] = useState(false)
	const [openMenuReport, setOpenMenuReport] = useState(false)
	const [openMenuService, setOpenMenuService] = useState(false)
	const [openMenuNotification, setOpenMenuNotification] = useState(false)
	const [openMenuContructionPlan, setOpenMenuContructionPlan] = useState(false)
	const [openMenuPavement, setOpenMenuPavement] = useState(false)
	const [openMenuCompany, setOpenMenuCompany] = useState(false)
	const [openMenuSector, setOpenMenuSector] = useState(false)
	const [openMenuHelp, setOpenMenuHelp] = useState(false)
	const [openMenuWorkOfArt, setOpenMenuWorkOfArt] = useState(false)
	const [openMenuTimeLine, setOpenMenuTimeLine] = useState(false)
	const location = useLocation()
	const { pathname } = location
	useEffect(() => {
		userProfile()
	}, [])
	useEffect(() => {
		if (!user) {
			setUser(recoverCache('profile'))
		}
	}, [user])
	useEffect(() => {
		if (!user) {
			setUser(recoverCache('user'))
		}
	}, [user])
	if (!user) {
		return <div></div>
	}
	return (
		<aside className="main-sidebar sidebar-dark-primary elevation-4">
			<div className="sidebar">
				<div className="user-panel mt-3 pb-3 mb-3 d-flex">
					<div className="image">
						<i className="fa fa-user fa-2x mr-2" style={{ color: '#FFF' }}></i>
					</div>
					<div className="info">
						<Link to="#" className="d-block">
							{user?.name}
						</Link>
					</div>
				</div>
				<nav className="mt-2">
					<ul
						className="nav nav-pills nav-sidebar flex-column"
						data-widget="treeview"
						role="menu"
						data-accordion="false"
					>
						<li className="nav-item">
							<Link
								to="/"
								className={`nav-link ${pathname === '/' && 'active'}`}
							>
								<i className="nav-icon fas fa-tachometer-alt"></i>
								<p>Dashboard</p>
							</Link>
						</li>
						{user.set_nivel === 0 && (
							<li
								className={
									openMenuDealership
										? 'nav-item menu-is-opening menu-open'
										: 'nav-item'
								}
								onClick={() => setOpenMenuDealership(!openMenuDealership)}
							>
								<Link
									to="#"
									className={`nav-link ${pathname === '/dealership' ||
										pathname === '/dealership_contract'
										? 'active'
										: ''
										}`}
								>
									<i className="nav-icon fa fa-building"></i>
									<p>
										Concessionárias
										<i className="fas fa-angle-left right"></i>
									</p>
								</Link>
								<ul className="nav nav-treeview">
									<li className="nav-item">
										<Link
											to="/dealership"
											className={`nav-link ${pathname === '/dealership' && 'active'
												}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Gerenciar</p>
										</Link>
									</li>
									<li className="nav-item">
										<Link
											to="/dealership_contract"
											className={`nav-link ${pathname === '/dealership_contract' && 'active'
												}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Contratos</p>
										</Link>
									</li>
								</ul>
							</li>
						)}
						<li
							className={
								openMenuConfig
									? 'nav-item menu-is-opening menu-open'
									: 'nav-item'
							}
							onClick={() => setOpenMenuConfig(!openMenuConfig)}
						>
							<Link
								to="#"
								className={`nav-link ${pathname === '/sector' ||
									pathname === '/complements' ||
									pathname === '/complements/new' ||
									pathname === '/sync' ||
									pathname === '/status' ||
									pathname === '/status/new' ||
									pathname === '/version' ||
									pathname === '/highway_limit' ||
									pathname === '/type_service' ||
									pathname === '/configurations_paramaters' ||
									pathname === '/type_sales_order' ||
									pathname === '/type_sales_order/new' ||
									pathname === '/sync_status' ||
									pathname === '/sync_status/new' ||
									pathname === '/spreedsheet_default' ||
									pathname === '/spreedsheet_default/new'
									? 'active'
									: ''
									}`}
							>
								<i className="nav-icon fa fa-cogs"></i>
								<p>
									Configurações
									<i className="fas fa-angle-left right"></i>
								</p>
							</Link>
							<ul className="nav nav-treeview">
								{user.set_nivel <= 3 && (
									<li className="nav-item">
										<Link
											to="/sync"
											className={`nav-link ${pathname === '/sync' && 'active'}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Sincronização</p>
										</Link>
									</li>
								)}
								{user.set_nivel <= 3 && (
									<li className="nav-item">
										<Link
											to="/version"
											className={`nav-link ${pathname === '/version' && 'active'}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Versões do sistema</p>
										</Link>
									</li>
								)}
							</ul>
							{user.set_nivel === 0 && (
								<ul className="nav nav-treeview">
									<li className="nav-item">
										<Link
											to="/complements"
											className={`nav-link ${pathname === '/complements' && 'active'
												}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Complementos</p>
										</Link>
									</li>
									<li className="nav-item">
										<Link
											to="/manage_emails"
											className={`nav-link ${pathname === '/manage_emails' && 'active'}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Gerenciar e-mails</p>
										</Link>
									</li>
									<li className="nav-item">
										<Link
											to="/highway_limit"
											className={`nav-link ${pathname === '/highway_limit' && 'active'
												}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Limites para rodovias</p>
										</Link>
									</li>
									<li className="nav-item">
										<Link
											to="/configurations_paramaters"
											className={`nav-link ${pathname === '/configurations_paramaters' && 'active'
												}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Parametros</p>
										</Link>
									</li>
									<li className="nav-item">
										<Link
											to="/status"
											className={`nav-link ${pathname === '/status' && 'active'
												}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Status para ocorrências</p>
										</Link>
									</li>
									<li className="nav-item">
										<Link
											to="/sync_status"
											className={`nav-link ${pathname === '/sync_status' && 'active'
												}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Status para Sincronização</p>
										</Link>
									</li>
									<li className="nav-item">
										<Link
											to="/type_service"
											className={`nav-link ${pathname === '/type_service' && 'active'}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Tipos de serviços</p>
										</Link>
									</li>
									<li className="nav-item">
										<Link
											to="/type_sales_order"
											className={`nav-link ${pathname === '/type_sales_order' && 'active'}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Tipos de NCs</p>
										</Link>
									</li>
									<li className="nav-item">
										<Link
											to="/spreedsheet_default"
											className={`nav-link ${pathname === '/spreedsheet_default' && 'active'}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Planilhas Modelo p/ Obras</p>
										</Link>
									</li>
								</ul>
							)}
						</li>
						{user.set_nivel === 0 && (
							<li
								className={
									openMenuCompany
										? 'nav-item menu-is-opening menu-open'
										: 'nav-item'
								}
								onClick={() => setOpenMenuCompany(!openMenuCompany)}
							>
								<Link
									to="#"
									className={`nav-link ${pathname === '/company'
										? 'active'
										: ''
										}`}
								>
									<i className="nav-icon fa fa-building"></i>
									<p>
										Empresas
										<i className="fas fa-angle-left right"></i>
									</p>
								</Link>

								<ul className="nav nav-treeview">
									<li className="nav-item">
										<Link
											to="/company"
											className={`nav-link ${pathname === '/company' && 'active'
												}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Gerenciar</p>
										</Link>
									</li>
								</ul>
							</li>
						)}
						<li
							className={
								openMenuOversight
									? 'nav-item menu-is-opening menu-open'
									: 'nav-item'
							}
							onClick={() => setOpenMenuOversight(!openMenuOversight)}
						>
							<Link
								to="#"
								className={`nav-link ${pathname === '/oversight' ||
									pathname === '/oversight/new' ||
									pathname === '/sync_oversight' ||
									pathname === '/sync_oversight/return_nc'
									? 'active'
									: ''
									}`}
							>
								<i className="nav-icon fa fa-list-ul"></i>
								<p>
									Não Conformidades
									<i className="fas fa-angle-left right"></i>
								</p>
							</Link>

							<ul className="nav nav-treeview">
								<li className="nav-item">
									<Link
										to="/oversight"
										className={`nav-link ${pathname === '/oversight' && 'active'
											}`}
									>
										<i className="far fa-circle nav-icon"></i>
										<p>Gerenciar</p>
									</Link>
								</li>
							</ul>
						</li>
						{user.set_nivel <= 1 && (

							<li
								className={
									openMenuNotification
										? 'nav-item menu-is-opening menu-open'
										: 'nav-item'
								}
								onClick={() => setOpenMenuNotification(!openMenuNotification)}
							>
								<Link
									to="#"
									className={`nav-link ${pathname === '/notification/new' ||
										pathname === '/notification' ||
										pathname === '/notification/notifed'
										? 'active'
										: ''
										}`}
								>
									<i className="nav-icon fa fa-exclamation-triangle"></i>
									<p>
										Notificação
										<i className="fas fa-angle-left right"></i>
									</p>
								</Link>
								<ul className="nav nav-treeview">
									<li className="nav-item">
										<Link
											to="/notification"
											className={`nav-link ${pathname === '/notification' && 'active'
												}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Gerenciar</p>
										</Link>
									</li>
									<li className="nav-item">
										<Link
											to="/notification/notifed"
											className={`nav-link ${pathname === '/notification/notifed' && 'active'
												}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Ver notificações</p>
										</Link>
									</li>
								</ul>
							</li>
						)}
						{user?.set_nivel <= 0 && (
							<li
								className={
									openMenuWorkOfArt
										? 'nav-item menu-is-opening menu-open'
										: 'nav-item'
								}
							>

								<Link
									to="#"
									className={`nav-link ${pathname === '/work_of_art'
										|| pathname === '/type_work_of_art'
										|| pathname === '/work_of_art_class'
										|| pathname === '/work_of_art_element' ? 'active' : ''
										}`}
									onClick={() =>
										setOpenMenuWorkOfArt(!openMenuWorkOfArt)
									}
								>
									<i className="nav-icon fa fa-archway"></i>
									<p>
										Obra de arte
										<i className="fas fa-angle-left right"></i>
									</p>
								</Link>
								<ul className="nav nav-treeview">
									<li className="nav-item">
										<Link
											to="/work_of_art"
											className={`nav-link ${pathname === '/work_of_art' && 'active'
												}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Gerenciar</p>
										</Link>
									</li>
									<li className="nav-item">
										<Link
											to="/work_of_art_type"
											className={`nav-link ${pathname === '/work_of_art_type' && 'active'
												}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Tipos de Obra de Arte</p>
										</Link>
									</li>
									<li className="nav-item">
										<Link
											to="/work_of_art_element"
											className={`nav-link ${pathname === '/work_of_art_element' && 'active'
												}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Elementos de Obra de Arte</p>
										</Link>
									</li>
									<li className="nav-item">
										<Link
											to="/work_of_art_class"
											className={`nav-link ${pathname === '/work_of_art_class' && 'active'
												}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Classificador de Obra de Arte</p>
										</Link>
									</li>
								</ul>
							</li>
						)}
						<li
							className={
								openMenuTimeLine
									? 'nav-item menu-is-opening menu-open'
									: 'nav-item'
							}
						>
							<Link
								to="#"
								className={`nav-link ${pathname === '/timeline' && 'active'
									}`}
								onClick={() =>
									setOpenMenuTimeLine(!openMenuTimeLine)
								}
							>
								<i className="nav-icon fa fa-calendar"></i>
								<p>
									Programado
									<i className="fas fa-angle-left right"></i>
								</p>
							</Link>
							<ul className="nav nav-treeview">
								<li className="nav-item">
									<Link
										to="/timeline"
										className={`nav-link ${pathname === '/timeline' && 'active'
											}`}
									>
										<i className="far fa-circle nav-icon"></i>
										<p>Gerenciar</p>
									</Link>
								</li>
							</ul>
						</li>
						<li
							className={
								openMenuContructionPlan
									? 'nav-item menu-is-opening menu-open'
									: 'nav-item'
							}
						>
							<Link
								to="#"
								className={`nav-link ${pathname === '/construction_plan' && 'active'
									}`}
								onClick={() =>
									setOpenMenuContructionPlan(!openMenuContructionPlan)
								}
							>
								<i className="nav-icon fa fa-building"></i>
								<p>
									Plano de obra
									<i className="fas fa-angle-left right"></i>
								</p>
							</Link>
							<ul className="nav nav-treeview">
								<li className="nav-item">
									<Link
										to="/construction_plan"
										className={`nav-link ${pathname === '/construction_plan' && 'active'
											}`}
									>
										<i className="far fa-circle nav-icon"></i>
										<p>Gerenciar</p>
									</Link>
								</li>
							</ul>
						</li>
						{/*------------------------------------------------------------*/}
						{user.set_nivel === 0 && (
							<li
								className={
									openMenuPavement
										? 'nav-item menu-is-opening menu-open'
										: 'nav-item'
								}
							>
								<Link
									to="#"
									className={`nav-link ${pathname === namesScreens.PAVEMENT
										|| pathname === namesScreens.PAVEMENT_INDICATOR
										|| pathname === namesScreens.PAVEMENT_INDICATOR_GROUPS_INDEX
										|| pathname === namesScreens.PAVEMENT_INDICATOR_GROUPS_INDEX_NEW
										|| pathname === namesScreens.PAVEMENT_INDICATOR_TYPES_INDEX
										|| pathname === namesScreens.PAVEMENT_INDICATOR_TYPES_INDEX_NEW
										|| pathname === namesScreens.PAVEMENT_CHECKLIST_MANAGER
										|| pathname === namesScreens.PAVEMENT_CHECKLIST_NEW
										&& 'active'
										}`}
									onClick={() =>
										setOpenMenuPavement(!openMenuPavement)
									}
								>
									<i className="nav-icon fa fa-road"></i>
									<p>
										Pavimento
										<i className="fas fa-angle-left right"></i>
									</p>
								</Link>
								<ul className="nav nav-treeview">
									<li className="nav-item">
										<Link
											to={namesScreens.PAVEMENT}
											className={`nav-link ${pathname === namesScreens.PAVEMENT && 'active'
												}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Gerenciar</p>
										</Link>
									</li>
									<li className="nav-item">
										<Link
											to={namesScreens.PAVEMENT_INDICATOR}
											className={`nav-link ${pathname === namesScreens.PAVEMENT_INDICATOR && 'active'
												}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Monitoramento</p>
										</Link>
									</li>
									<li className="nav-item">
										<Link
											to={namesScreens.PAVEMENT_INDICATOR_GROUPS_INDEX}
											className={`nav-link ${pathname === namesScreens.PAVEMENT_INDICATOR_GROUPS_INDEX && 'active'
												}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Grupos de Indices</p>
										</Link>
									</li>
									<li className="nav-item">
										<Link
											to={namesScreens.PAVEMENT_INDICATOR_TYPES_INDEX}
											className={`nav-link ${pathname === namesScreens.PAVEMENT_INDICATOR_TYPES_INDEX && 'active'
												}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Tipos de Indices</p>
										</Link>
									</li>
									<li className="nav-item">
										<Link
											to={namesScreens.PAVEMENT_CHECKLIST_MANAGER}
											className={`nav-link ${pathname === namesScreens.PAVEMENT_CHECKLIST_MANAGER && 'active'
												}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Checklist</p>
										</Link>
									</li>
								</ul>
							</li>
						)}
						{/*--------------------------------------------------------------*/}
						{user.set_nivel === 0 && (
							<li
								className={
									openMenuRoad
										? 'nav-item menu-is-opening menu-open'
										: 'nav-item'
								}
								onClick={() => setOpenMenuRoad(!openMenuRoad)}
							>
								<Link
									to="#"
									className={`nav-link ${pathname === '/highway' && 'active'}`}
								>
									<i className="nav-icon fas fa-road"></i>
									<p>
										Rodovias
										<i className="fas fa-angle-left right"></i>
									</p>
								</Link>

								<ul className="nav nav-treeview">
									<li className="nav-item">
										<Link
											to="/highway"
											className={`nav-link ${pathname === '/highway' && 'active'}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Gerenciar</p>
										</Link>
									</li>
								</ul>
							</li>
						)}

						{user.set_nivel === 0 && (
							<li
								className={
									openMenuSector
										? 'nav-item menu-is-opening menu-open'
										: 'nav-item'
								}
								onClick={() => setOpenMenuSector(!openMenuSector)}
							>
								<Link
									to="#"
									className={`nav-link ${pathname === '/sector'
										? 'active'
										: ''
										}`}
								>
									<i className="nav-icon fa fa-building"></i>
									<p>
										Setor
										<i className="fas fa-angle-left right"></i>
									</p>
								</Link>
								<ul className="nav nav-treeview">
									<li className="nav-item">
										<Link
											to="/sector"
											className={`nav-link ${pathname === '/sector' && 'active'
												}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Gerenciar</p>
										</Link>
									</li>
								</ul>
							</li>
						)}
						{user?.set_nivel <= 0 && (
							<li
								className={
									openMenuService
										? 'nav-item menu-is-opening menu-open'
										: 'nav-item'
								}
								onClick={() => setOpenMenuService(!openMenuService)}
							>
								<Link
									to="#"
									className={`nav-link ${pathname === '/service' ||
										pathname === '/service/category' ||
										pathname === '/service/subcategory' ||
										pathname === '/deadline' ||
										pathname === namesScreens.SERVICES_CONSTRUCTIONS
										? 'active'
										: ''
										}`}
								>
									<i className="nav-icon fa fa-compress"></i>
									<p>
										Serviços
										<i className="fas fa-angle-left right"></i>
									</p>
								</Link>
								<ul className="nav nav-treeview">
									<li className="nav-item">
										<Link
											to="/service"
											className={`nav-link ${pathname === '/service' && 'active'
												}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Gerenciar</p>
										</Link>
									</li>
									<li className="nav-item">
										<Link
											to="/deadline"
											className={`nav-link ${pathname === '/deadline' && 'active'
												}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Prazos</p>
										</Link>
									</li>
									<li className="nav-item">
										<Link
											to={namesScreens.SERVICES_CONSTRUCTIONS}
											className={`nav-link ${pathname === namesScreens.SERVICES_CONSTRUCTIONS && 'active'
												}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Obras</p>
										</Link>
									</li>
								</ul>
							</li>
						)}
						<li
							className={
								openMenuReport
									? 'nav-item menu-is-opening menu-open'
									: 'nav-item'
							}
							onClick={() => setOpenMenuReport(!openMenuReport)}
						>
							<Link
								to="#"
								className={`nav-link ${pathname === '/report' ||
									pathname === '/report/oversight' ||
									pathname === '/report/oversight/routine'
									? 'active'
									: ''
									}`}
							>
								<i className="nav-icon fa fa-bug"></i>
								<p>
									Relatórios
									<i className="fas fa-angle-left right"></i>
								</p>
							</Link>
							<ul className="nav nav-treeview">
								<li className={openMenuReport ? 'nav-item meu-is-opening menu-open'
									: 'nav-item'}>
									<Link to="#" className="nav-link">
										<p>Não Conformidade</p>
										<i className="fas fa-angle-left right"></i>
									</Link>
									<ul className="nav nav-treeview">
										<li className="nav-item">
											<Link
												to="/report"
												className={`nav-link ${pathname === '/report' && 'active'
													}`}
											>
												<i className="far fa-circle nav-icon"></i>
												<p>Fiscalizações</p>
											</Link>
										</li>
										{user.set_nivel === 0 && (
											<li className="nav-item">
												<Link
													to="/report/painel"
													className={`nav-link ${pathname === '/report/painel' && 'active'
														}`}
												>
													<i className="far fa-circle nav-icon"></i>
													<p>Painel de Conservação</p>
												</Link>
											</li>
										)}
										<li className="nav-item">
											<Link
												to="/report/oversight"
												className={`nav-link ${pathname === '/report/oversight' && 'active'
													}`}
											>
												<i className="far fa-circle nav-icon"></i>
												<p>Detalhado</p>
											</Link>
										</li>
										<li className="nav-item">
											<Link
												to="/report/oversight/routine"
												className={`nav-link ${pathname === '/report/oversight/routine' && 'active'
													}`}
											>
												<i className="far fa-circle nav-icon"></i>
												<p>Resumido</p>
											</Link>
										</li>
										<li className="nav-item">
											<Link
												to="/report/oversight/map"
												className={`nav-link ${pathname === '/report/oversight/map' && 'active'
													}`}
											>
												<i className="far fa-circle nav-icon"></i>
												<p>Georeferenciado</p>
											</Link>
										</li>
									</ul>
								</li>
							</ul>
						</li>

						<li
							className={
								openMenuUser ? 'nav-item menu-is-opening menu-open' : 'nav-item'
							}
							onClick={() => setOpenMenuUser(!openMenuUser)}
						>
							<Link
								to="#"
								className={`nav-link ${pathname === '/user/profile' ||
									pathname === '/user/password' ||
									pathname === '/users' ||
									pathname === '/user/logout'
									? 'active'
									: ''
									}`}
							>
								<i className="nav-icon fa fa-user"></i>
								<p>
									Usuário
									<i className="fas fa-angle-left right"></i>
								</p>
							</Link>
							<ul className="nav nav-treeview">
								{user?.set_nivel === 0 && (
									<li className="nav-item">
										<Link
											to="/users"
											className={`nav-link ${pathname === '/users' && 'active'
												}`}
										>
											<i className="far fa-circle nav-icon"></i>
											<p>Gerenciar</p>
										</Link>
									</li>
								)}
								<li className="nav-item">
									<Link
										to="/user/profile"
										className={`nav-link ${pathname === '/user/profile' && 'active'
											}`}
									>
										<i className="fa fa-user-circle nav-icon"></i>
										<p>Perfil</p>
									</Link>
								</li>
								<li className="nav-item">
									<Link
										to="/user/password"
										state={{
											changePass: true
										}}
										className={`nav-link ${pathname === '/user/password' && 'active'
											}`}
									>
										<i className="fa fa-key nav-icon"></i>
										<p>Mudar senha</p>
									</Link>
								</li>
								<li className="nav-item">
									<Link
										to="/user/logout"
										className={`nav-link ${pathname === '/user/logout' && 'active'
											}`}
									>
										<i className="fas fa-sign-out-alt nav-icon"></i>
										<p>Sair</p>
									</Link>
								</li>
							</ul>
						</li>
						<li
							className={
								openMenuHelp ? 'nav-item menu-is-opening menu-open' : 'nav-item'
							}
							onClick={() => setOpenMenuHelp(!openMenuHelp)}
						>
							<Link
								to="#"
								className={`nav-link ${pathname === '/help' ||
									pathname === '/tutorial/new' ? 'active'
									: ''
									}`}
							>
								<i className="nav-icon fa fa-question"></i>
								<p>
									Tutorial
									<i className="fas fa-angle-left right"></i>
								</p>
							</Link>
							<ul className="nav nav-treeview">
								<li className="nav-item">
									<Link
										to="/tutorial"
										className={`nav-link ${pathname === '/tutorial' && 'active'}`}
									>
										<i className="fa fa-film nav-icon"></i>
										<p>Ver tutoriais</p>
									</Link>
								</li>
								<li className="nav-item">
									<Link
										to="/help/new"
										className={`nav-link ${pathname === '/help/new' && 'active'}`}
									>
										<i className="far fa-circle nav-icon"></i>
										<p>Novo pedido de ajuda</p>
									</Link>
								</li>
							</ul>
						</li>
						<li
							className={'nav-item'} >
							<Link
								to="/sac"
								className={`nav-link ${pathname === '/sac' ? 'active'
									: ''
									}`}
							>
								<i className="nav-icon fa fa-mobile"></i>
								<p>
									SAC
								</p>
							</Link>
						</li>
					</ul>
				</nav>
			</div >
		</aside >
	)
}
export default Menu
