import React, { useContext, useEffect } from 'react'
import { formContext } from '../../../../context/formContext'
import { requestContext } from '../../../../context/requestContext'
import Dealership from '../../dealership'
import SelectHighway from '../../selectHighway'
const FilterHighway = () => {
	const { state: { conc_cod } } = useContext(formContext)
	const { getAllItemDealership, getAllItemHighway } = useContext(requestContext)
	useEffect(() => {
		getAllItemDealership()
	}, [])
	useEffect(() => {
		if (conc_cod) {
			getAllItemHighway(conc_cod)
		}
	}, [conc_cod])
	return (
		<div className='row'>
			<div className="col-md-6 col-sm-12">
				<Dealership />
			</div>
			<div className="col-md-6 col-sm-12  mb-3">
				<SelectHighway />
			</div>
		</div>
	)
}
export default FilterHighway