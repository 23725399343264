import { recoverCache } from '../services/cacheManager'

export const statusCode = {
	successfull: 200,
	bad_request: 400,
	forbidden: 401,
	internal_server_error: 500
}
export const namesCache = {
	DEALERSHIP: 'dealership',
	REQUEST_SPREDSHEET_MONITORING_PAVAMENT: 'request_spredsheet_monitoring_pavement'
}
export const nameVersion = {
	WEB: 'web',
	APP: 'app',
}

export const variablesId = {
	set_cod: 35
}
export const typeReport = {
	PDF: 'pdf',
	JSON: 'json',
	XLS: 'xls'
}
export const dayOfWeek = [
	'Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado',
]
export const defaultStatus = {
	PROGRAMADO_PEDENTE_APROVACAO: 'Pendente Aprovação'
}
/**
 * Essa função retorna os parametros de configuração da API.
 * @param string config_para_desc - Esse parametro serve para procurar por um parametro de configuração especifico.
 * @returns {Object|Array<Object>} response - Contêm os parametros de configuração obetidos da API. Quando config_para_desc é diferente de null é retornado um objeto. Quando config_para_desc é igual a null é retornado um array.
 */
export async function config(config_para_desc) {
	const response = recoverCache('config')
	if (!response) {
		return response
	}
	if (!config_para_desc) {
		return response
	}
	const findParameters = response.find(
		item => item?.config_para_desc === config_para_desc,
	)
	return findParameters
}
export const typesChart = [
	{
		type: 'dealership',
		text: 'Por concessionária',
		icon: 'mr-2 fa fa-building'
	},
	{
		type: 'highway',
		text: 'Por Rodovia',
		icon: 'mr-2 fa fa-road',
	},
	{
		type: 'locale',
		text: 'Por KM',
		icon: 'mr-2 fa fa-location-arrow',
	},
	{
		type: 'service',
		text: 'Por Atividade',
		icon: 'mr-2 fa fa-list',
	},
]
export const allowedXlsExtensions = [
	'xls',
	'xlsx',
	'xlsm',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.ms-excel.sheet.DisciplinaEnabled.12',
	'ms-excel'
]
export const allowedImageExtensions = [
	'jpeg',
	'jpg',
	'png',
	'gif',
	'bmp',
	'tiff',
	'webp'
]
export const allowedMoviesExtensions = [
	'mp4'
]
export const replacements = [{ search: 'bytes', replace: '' },
	{ search: 'MB', replace: '' },
	{ search: 'GB', replace: '' }
]
export const wordsToSearch = replacements.map(item => item.search)
export const DRIVERS = {
	PUBLIC: 'public',
}

export const namesScreens = {
	SYNC: '/sync',
	USERPROFILE: '/user/profile',
	USERPASSWORD: '/user/password',
	USERLOGOUT: '/user/logout',
	OVERSIGHT: '/oversight',
	OVERSIGHTVIEW: '/oversight/view',
	OVERSIGHTNEW: '/oversight/new',
	HIGHWAY: '/highway',
	HIGHWAYNEW: '/highway/new',
	DEALERSHIP: '/dealership',
	DEALERSHIPNEW: '/dealership/new',
	DEALERSHIP_CONTRACT: '/dealership_contract',
	REPORTPAINEL: '/report/painel',
	SERVICE: '/service',
	SERVICENEW: '/service/new',
	DEADLINE: '/deadline',
	DEADLINENEW: '/deadline/new',
	NOTIFICATIONNEW: '/notification/new',
	NOTIFICATION: '/notification',
	NOTIFICATIONNOTIFED: '/notification/notifed',
	REPORTOVERSIGHT: '/report/oversight',
	REPORT: '/report',
	SAC: '/sac',
	REPORTOVERSIGHTMAP: '/report/oversight/map',
	SECTOR: '/sector',
	SECTORNEW: '/sector/new',
	COMPANY: '/company',
	VERSION: '/version',
	COMPANYNEW: '/company/new',
	STATUS: '/status',
	STATUSNEW: '/status/new',
	SYNC_STATUS: '/sync_status',
	SYNC_STATUSNEW: '/sync_status/new',
	HIGHWAY_LIMIT: '/highway_limit',
	HIGHWAY_LIMITNEW: '/highway_limit/new',
	COMPLEMENTS: '/complements',
	COMPLEMENTSNEW: '/complements/new',
	USERS: '/users',
	USERSNEW: '/users/new',
	WORK_OF_ART: '/work_of_art',
	WORK_OF_ARTNEW: '/work_of_art/new',
	WORK_OF_ART_TYPE: '/work_of_art_type',
	WORK_OF_ART_TYPENEW: '/work_of_art_type/new',
	WORK_OF_ART_CLASS: '/work_of_art_class',
	WORK_OF_ART_CLASSNEW: '/work_of_art_class/new',
	WORK_OF_ART_ELEMENT: '/work_of_art_element',
	WORK_OF_ART_ELEMENTNEW: '/work_of_art_element/new',
	HELP: '/help',
	HELPNEW: '/help/new',
	TUTORIAL: '/tutorial',
	TUTORIALNEW: '/tutorial/new',
	MANAGE_EMAILS: '/manage_emails',
	TYPE_SERVICE: '/type_service',
	TYPE_SERVICENEW: '/type_service/new',
	TYPE_SALES_ORDER: '/type_sales_order',
	TYPE_SALES_ORDERNEW: '/type_sales_order/new',
	TIMELINE: '/timeline',
	NOT_FOUND: '*',
	PAVEMENT: '/pavement',
	PAVEMENT_INDICATOR: '/pavement_indicators',
	PAVEMENT_INDICATOR_NEW: '/pavement_indicators/new',
	PAVEMENT_INDICATOR_VIEW: '/pavement_indicators/view',
	PAVEMENT_INDICATOR_GROUPS_INDEX: '/pavement_indicators/groups_index',
	PAVEMENT_INDICATOR_GROUPS_INDEX_NEW: '/pavement_indicators/groups_index/new',
	PAVEMENT_INDICATOR_TYPES_INDEX: '/pavement_indicators/types_index',
	PAVEMENT_INDICATOR_TYPES_INDEX_NEW: '/pavement_indicators/types_index/new',
	PAVEMENT_CHECKLIST_MANAGER: '/paviment/check_list',
	PAVEMENT_CHECKLIST_NEW: '/paviment/check_list/new',
	CONFIGURATIONS_PARAMATERS: '/configurations_paramaters',
	CONFIGURATIONS_PARAMATERSNEW: '/configurations_paramaters/new',
	DEALERSHIP_CONTRACTNEW: '/dealership_contract/new',
	REPORTOVERSIGHTVIEW: '/report/oversight/view',
	REPORTOVERSIGHTROUTINE: '/report/oversight/routine',
	REPORTOVERSIGHTROUTINEVIEW: '/report/oversight/routine/view',
	SERVICES_CONSTRUCTIONS: '/services_constructions',
	SERVICES_CONSTRUCTIONS_NEW: '/services_constructions/new',
	CONSTRUCTION_PLAN: '/construction_plan',
	CONSTRUCTION_PLANNEW: '/construction_plan/new',
	CONSTRUCTION_PLANVIEW: '/construction_plan/view',
	REPORT_CONSTRUCTION_PLAN: '/construction_plan/report',
	SPREEDSHEET_DEFAULT: '/spreedsheet_default',
	SPREEDSHEET_DEFAULTNEW: '/spreedsheet_default/new',
	ANSWER_CHECKLIST: '/answer_checklist'
}
export const iconsAttachments = [
	{ ext: ['pdf'], name: 'fa fa-file-pdf', color: 'danger' },
	{ ext: ['docx'], name: 'fa fa-file-word ', color: 'info' },
	{ ext: ['doc'], name: 'fa fa-save', color: 'secondary' },
	{ ext: [allowedImageExtensions], name: 'fa fa-file-image', color: 'warning' },
	{ ext: [allowedXlsExtensions], name: 'fa fa-file-excel', color: 'success' },
	{ ext: ['mp4'], name: 'fa-file-video', color: 'dark' }
]
export const labelsToChartPorcenExec = ['Acumulado anterior', 'Progresso no Perido', 'A Executar']
export const colorsToChartPercentageExecution = [
	'#17a2b8',
	'#14A44D',
	'#6c757d',
]
export const openModal = {
	style: {
		display: 'block'
	},
	className: 'modal fade show bd-example-modal-lg'
}
export const closeModal = {
	style: {
		display: 'none'
	},
	className: 'modal fade bd-example-modal-lg'
}