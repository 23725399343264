import React, { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { styleButtonPlus } from '../../../css/globlalStyle'
import { handleWaring } from '../../../services/isValid'
import ListAneDesc from '../../list/aneDesc'
const DealershipContract = ({ edit }) => {
	const { state, onChange, handleForm } = useContext(formContext)
	const { setInfoRequest, contract_Attachment } = useContext(requestContext)
	const [className, setClassName] = useState('form-group col-md-6 col-sm-12')
	const {
		cont_ncontrato,
		cont_data_ass,
		ane_desc,
		ane_desc_infla,
		ane_clausula_infla,
		cont_atual,
		conc_nome,
		cont_concessionaria,
		conc_cod,
	} = state
	useEffect(() => {
		if (edit) {
			setClassName('form-group col-md-4 col-sm-12')
		}
	}, [edit])
	useEffect(() => {
		if (cont_atual === '0' && edit && cont_concessionaria) {
			const confirm = window.confirm(`Você tem certeza que deseja Cancelar <i className="fa fa-times-circle"></i> o contrato da concessionaria ${conc_nome}?`)
			if (!confirm) {
				handleForm({
					cont_atual: 1
				})
			}
		}
	}, [cont_atual, edit])
	useEffect(() => {
		handleForm({
			ane_desc: '',
		})
		if (contract_Attachment?.total > 0) {
			const data = contract_Attachment
			handleForm({
				ane_desc: data.data
			})
		}
	}, [contract_Attachment])
	const handleAnexos = () => {
		if (!ane_desc_infla || !ane_clausula_infla) {
			return setInfoRequest(handleWaring('Você precisa preencher numero do anexo, com o edital e a clausula'))
		}
		Array.isArray(ane_desc) ? handleForm({
			ane_desc: [...ane_desc, {
				ane_desc_infla,
				ane_clausula_infla
			}]
		}) : handleForm({
			ane_desc: [{
				ane_desc_infla,
				ane_clausula_infla
			}]
		})
		handleForm({
			ane_clausula_infla: ''
		})
	}
	const RenderListAnex = () => {
		return (
			<>
				<div className='form-group col-12'>
					<label>Anexos do contrato</label>
				</div>
				{
					ane_desc.map((item, i) => (
						<div className='col-12' key={i}>
							<ListAneDesc item={item} data={ane_desc} />
						</div>
					)
					)
				}
			</>
		)
	}
	return (
		<div className="row">
			<div className="form-group col-md-12 col-sm-12">
				<label>Concessionária:</label>
				<div className="input-group">
					<input type="text"
						disabled
						name='conc_nome' value={conc_nome}
						className="form-control form-control-border"
						placeholder='Selecionar Concessionária' />
				</div>
			</div>
			<div className={className}>
				<label>N° do Contrato</label>
				<div className='input-group'>
					<input type='text' onChange={onChange}
						className={`form-control form-control-border ${!cont_ncontrato && 'is-invalid'}`}
						placeholder="Numero do contrato"
						name='cont_ncontrato' value={cont_ncontrato}
						disabled={conc_cod}
					/>
				</div>
			</div>
			{edit && (<div className={className}>
				<label>Situação do contrato</label>
				<div className='input-group'>
					<select onChange={onChange}
						className={`form-control form-control-border ${!cont_atual && 'is-invalid'}`}
						name='cont_atual' value={cont_atual}
					>
						<option>Selecionar situação</option>
						<option value={'1'}>Ativo</option>
						<option value={'0'}>Encerrado</option>
					</select>
				</div>
			</div>)}
			<div className={className}>
				<label>Data assinatura contrato</label>
				<div className='input-group'>
					<input type='date' onChange={onChange}
						className={`form-control form-control-border ${!cont_data_ass && 'is-invalid'}`}
						placeholder="Numero do contrato"
						name='cont_data_ass' value={cont_data_ass}
						max="2020-08-24"
					/>
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-12">
				<label>Anexos referente ao contrato da notificação</label>
				<div className='input-group'>
					<input type='text' onChange={onChange}
						className={`form-control form-control-border ${!ane_desc_infla && 'is-invalid'}`}
						placeholder="Numero do anexo e edital"
						name='ane_desc_infla' value={ane_desc_infla}
					/>
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-12">
				<label>Clausula do anexo</label>
				<div className='input-group'>
					<input type='text' onChange={onChange}
						className={`form-control form-control-border ${!ane_clausula_infla && 'is-invalid'}`}
						placeholder="Numero do anexo e edital"
						name='ane_clausula_infla' value={ane_clausula_infla}
					/>
					<div className="input-group-prepend">
						<span className="input-group-text bg-danger"
							onClick={handleAnexos}
							style={styleButtonPlus}>
							<i className="fa fa-plus" aria-hidden="true"></i>
						</span>
					</div>
				</div>
			</div>
			{Array.isArray(ane_desc) && (<RenderListAnex />)}
		</div>
	)
}
export default DealershipContract