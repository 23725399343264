import React, { useContext } from "react";
import { formContext } from "../../../context/formContext";
const FormSector = () => {
    const { state, onChange } = useContext(formContext)
    const {
        set_disp,
        set_desc,
    } = state
    return (
        <div className="row">
            <div className="form-group col-12">
                <label>Nome:</label>
                <div className="input-group">
                    <input type="text"
                        onChange={onChange}
                        name='set_desc' value={set_desc}
                        className={`form-control form-control-border ${!set_desc && 'is-invalid'}`}
                        placeholder='Nome do setor' />
                </div>
            </div>
            <div className="form-group col-md-6 col-sm-6">
                <label>Situação:</label>
                <div className="input-group">
                    <select name="set_disp" value={set_disp}
                        className={`form-control form-control-border ${!set_disp && 'is-invalid'}`}
                        onChange={onChange}
                    >
                        <option value={0}>
                            Inativo
                        </option>
                        <option value={1}>
                            Ativo
                        </option>
                    </select>
                </div>
            </div>
        </div>
    )
}
export default FormSector