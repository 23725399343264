import React from 'react'

const RealtimeFilter = ({fullArray, filteredArray, fieldId, fieldDescription, setFilterFunc, placeholder, funcOnSelect}) =>{
	const filterArr = (searchString) => {
		const tempFilter = fullArray.filter(item => item[fieldDescription] && item[fieldDescription].includes(searchString))
		setFilterFunc(tempFilter)
	}
	return(
		<div className="card-body">
			<div className='d-flex align-items-center border rounded mb-3'>
				<i className="fa fa-search m-0 mr-2 ml-2"></i>
				<input className='border-0' placeholder={placeholder} onChange={(e)=>filterArr(e.target.value)} />
			</div>
			{Array.isArray(filteredArray) && filteredArray?.map(item=>(
				<div className='d-flex justify-content-start ml-2' key={item?.[fieldId]}>
					<input onClick={()=>funcOnSelect(fieldId, item?.[fieldId])} className='d-inline' type='checkbox' value={item?.[fieldId]} />
					<p className='d-inline m-0 ml-1 text-secondary'>{item?.[fieldDescription]}</p>
				</div>
			)
			)}
		</div>
	)
}
export default RealtimeFilter