
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ContentHeader from '../../../components/contentHeader'
import SelectServiceConstructionPlan from '../../../components/form/selectServiceConstructionPlan'
import ListConstructionPlan from '../../../components/list/serviceConstructionPlan'
import Spinner from '../../../components/spinner'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { calcItemsOversight, cleanArray, containsKeyword, refactorRowsWithServices, sumArray } from '../../../services/arrayHandless'
import returnPosition from '../../../services/geolocation'
import groupBy from '../../../services/groupBy'
import { handleWaring, isValid, handleSuccess, handleError } from '../../../services/isValid'
import { generateBse64, getExt, importExcelToArray } from '../../../services/files'
import { recoverCache, storageCache } from '../../../services/cacheManager'
import FormContructions from '../../../components/form/constructionPlan'
import { convertToTitleCase } from '../../../services/stringHandless'
import { scrollToElement } from '../../../services/scroll'
import { allowedXlsExtensions, config } from '../../../constants'
const NewContructionPlan = () => {
	const { handleForm, state, bases64, setBases64 } = useContext(formContext)
	const [items, setItems] = useState(null)
	const input = useRef()
	const refListConstruction = useRef()
	const [showInputFile, setShowInputFile] = useState(null)
	const [monitoringProgressItems, setMonitoringProgressItems] = useState([])
	const [totalSumItems, setTotalSumItems] = useState({
		totalQtd: 0,
		totalValor: 0,
		totalByGroup: 0,
	})
	const [file, setFile] = useState({
		base64: '',
		fileName: '',
	})
	const {
		setInfoRequest,
		setData,
		setService,
		getAllItemDealershipLot,
		getAllItemHighway,
		setContractAttachment,
		oversight,
		setOversight,
		postItemConstructionPlan,
		getToDownloadDefaultSpreedSheetConstructionPlan
	} = useContext(requestContext)
	const {
		pl_ob_data_program_ini,
		pl_ob_data_program_fim,
		pl_ob_data_ana_ini,
		pl_ob_data_ana_fim,
		pl_ob_data_prev_ini,
		pl_ob_data_prev_fim,
		pl_ob_valor,
		conc_cod,
		tip_ob_cod,
		pl_ob_cod,
		pl_ob_tipo,
		rod_cod,
		rod_sent_cod,
		rod_km,
		rod_km_ini,
		rod_km_fim,
		rod_km_ini_mt,
		rod_km_fim_mt,
		pv_loc_obs,
		pl_ob_cod_item,
		pl_ob_desc_object,
		ped_venda_date_init,
		ped_venda_date_fim,
		type_date,
		mode_sisf,
		tip_ob_desc,
	} = state
	
	useEffect(() => {
		if (Array.isArray(oversight)) {
			const group = groupBy(oversight, 'serv_desc')
			setItems(group)
		} else {
			setItems(null)
			setTotalSumItems({
				totalQtd: 0,
				totalValor: 0,
			})
		}
	}, [oversight])
	useEffect(() => {
		if (Array.isArray(oversight) && items) {
			const keys = Object.keys(items)
			if (keys.length === 1) {
				return Object.keys(items).forEach((element) => {
					const calcItems = calcItemsOversight(items, element, oversight, 'serv_sub_cat_valor')
					setTotalSumItems({
						...calcItems,
					})
				})
			}
			Object.keys(items).forEach((element) => {
				if (!Array.isArray(items[element])) {
					return setTotalSumItems({
						totalValor: 0,
					})
				}
				const calcItems = calcItemsOversight(items, element, oversight, 'serv_sub_cat_valor')
				setTotalSumItems({
					...calcItems,
				})
			})
		}
	}, [oversight, items])
	useEffect(() => {
		if (totalSumItems) {
			handleForm({
				pl_ob_valor: totalSumItems?.totalValor,
			})
		}
	}, [totalSumItems])

	const navigate = useNavigate()
	useEffect(() => {
		if (!pl_ob_cod) {
			handleForm({
				pl_ob_data_program_ini: '',
				pl_ob_data_program_fim: '',
				pl_ob_data_exec_ini: '',
				pl_ob_data_exec_fim: '',
				pl_ob_data_ana_ini: '',
				pl_ob_data_ana_fim: '',
				pl_ob_data_prev_ini: '',
				pl_ob_data_prev_fim: '',
				prog_ob_por_cen_progress: '',
				pl_ob_valor: '',
				conc_cod: '',
				rod_cod: '',
				anexos_plano_obra: '',
				pl_ob_cod: '',
				tip_ob_cod: '',
				conc_nome: '',
				rod_desc: '',
				pl_ob_desc_object: '',
			})
		}
	}, [pl_ob_cod])
	useEffect(() => {
		const loadData = () => {
			setData(null)
			setBases64(null)
			setService(null)
			setOversight(null)
			setContractAttachment(null)
			getAllItemDealershipLot()
		}
		loadData()
	}, [])
	useEffect(() => {
		if (tip_ob_cod) {
			handleForm({
				pl_ob_tipo: tip_ob_cod,
			})
		}
	}, [tip_ob_cod])
	useEffect(() => {
		if (conc_cod) {
			getAllItemHighway(conc_cod)
		} else {
			getAllItemHighway()
		}
	}, [conc_cod])


	const onSubmit = async (e) => {
		console.log('SUBMIT - monitoringProgressItems',monitoringProgressItems)
		e.preventDefault()
		window.scrollTo(0, 0)
		const item = {
			pl_ob_data_program_ini,
			pl_ob_data_program_fim,
			pl_ob_data_ana_ini,
			pl_ob_data_ana_fim,
			pl_ob_data_prev_ini,
			pl_ob_data_prev_fim,
			pl_ob_valor,
			rod_cod,
			rod_km,
			rod_km_ini,
			rod_km_fim,
			rod_km_ini_mt,
			rod_km_fim_mt,
			pv_loc_obs,
			acompanhamento_obra: monitoringProgressItems
		}
		const validate = isValid(item)
		if (!validate) {
			return setInfoRequest(handleWaring())
		}
		if (!Array.isArray(oversight) && !file.base64) {
			return setInfoRequest(handleWaring())
		}
		if (!rod_sent_cod) {
			return setInfoRequest(handleWaring('Você precisa informar o sentido da rodovia'))
		}
		const { lat, lng } = await returnPosition()
		postItemConstructionPlan({
			...item,
			acompanhamento_obra:monitoringProgressItems,
			id: pl_ob_cod,
			pl_ob_tipo: pl_ob_tipo || undefined,
			ob_services: oversight || [],
			ob_attachments: bases64 || [],
			pl_ob_spreadsheet: file.base64 ? file.base64 : undefined,
			pl_ob_cod_item: pl_ob_cod_item || undefined,
			pl_ob_desc_object: pl_ob_desc_object || undefined,
			ob_local: {
				pv_loc_sentido: rod_sent_cod,
				pv_loc_km_ini: rod_km_ini,
				pv_loc_km_fim: rod_km_fim,
				pv_loc_km_init_mts: rod_km_ini_mt,
				pv_loc_km_fim_mts: rod_km_fim_mt,
				pv_loc_obs,
				pv_loc_lat: lat,
				pv_loc_lng: lng,
			},
			filter: {
				id_from_filter: conc_cod,
				rod_cod,
				ped_venda_date_init,
				ped_venda_date_fim,
				type_date
			}
		})
		handleCancel()
	}
	const handleCancel = () => {
		setData(null)
		setBases64(null)
		resetValues()
		navigate('/construction_plan')
	}
	const resetValues = () => handleForm({
		pl_ob_cod_item: '',
		pl_ob_desc_object: '',
		pl_ob_cod: '',
		pl_ob_data_program_ini: '',
		pl_ob_data_program_fim: '',
		pl_ob_data_exec_ini: '',
		pl_ob_data_exec_fim: '',
		pl_ob_data_ana_ini: '',
		pl_ob_data_ana_fim: '',
		pl_ob_data_prev_ini: '',
		pl_ob_data_prev_fim: '',
		prog_ob_por_cen_progress: '',
		pl_ob_valor: '',
		conc_cod: '',
		rod_cod: '',
		anexos_plano_obra: '',
		tip_ob_cod: '',
		conc_nome: '',
		rod_desc: '',
		rod_sent_cod: ''
	})
	const handleGetSpreedSheet = async () => {
		const config_parameters = await config('tip_ob_cod_spreedsheet_default')
		let temp_tip_ob_cod = tip_ob_cod || 0
		if (config_parameters && !tip_ob_cod) {
			const { config_para_value } = config_parameters
			temp_tip_ob_cod = mode_sisf ? tip_ob_cod : parseInt(config_para_value)
		}
		if (!tip_ob_cod && mode_sisf) {
			return setInfoRequest(handleWaring('Você precisa preencher o tipo de obra para poder usar esse recurso'))
		}
		const confirm = window.confirm('Você já tem a planilha modelo baixada no seu computador?')
		if (confirm) {
			if (showInputFile) {
				return handleFile()
			}
			return setShowInputFile(true)
		} else {
			storageCache(`request_spreadshet_${temp_tip_ob_cod}`)
		}
		const request_spreadshet = recoverCache(`request_spreadshet_${temp_tip_ob_cod}`)
		if (request_spreadshet) {
			return setShowInputFile(true)
		}
		const response = await getToDownloadDefaultSpreedSheetConstructionPlan(temp_tip_ob_cod)
		if(!response?.success) {
			return setInfoRequest(handleError(`Não foi possível baixar planilha modelo para obra ${tip_ob_desc ? tip_ob_desc: '.'}`))
		}
		if (response?.file_name) {
			const { file_name } = response
			setInfoRequest(handleSuccess(`Planilha Modelo para o tipo de obra ${String(file_name).replace('.xlsx', '')} baixado com sucesso`))
		}
		setShowInputFile(response)
	}
	const handleFile = () => {
		if (input.current) {
			input.current.click()
		}
	}
	const readXlsServices = (rows) => {
		let temp_pl_ob_desc_object = ''
		const details = rows.slice(1, 2)[0]
		const keyword = 'NOME DA OBRA'
		const find = details.find(row => containsKeyword(row, keyword))
		if (find) {
			temp_pl_ob_desc_object = convertToTitleCase(details[1])
		}
		const services = rows.slice(11, rows.length)
		const clean_rows = cleanArray(services)
		const temp_pedido_venda_servico = refactorRowsWithServices(clean_rows)
		return {
			pedido_venda_servico: temp_pedido_venda_servico,
			pl_ob_desc_object: String(temp_pl_ob_desc_object).slice(0, 100),
		}
	}
	const onChangeFile = async ({ target }) => {
		const { files } = target
		if (files.length === 0) {
			return setFile({
				fileName: '',
				base64: '',
			})
		}
		if (files.length > 1) {
			setFile({
				base64: '',
				fileName: ''
			})
			return setInfoRequest(handleWaring('Você só pode selecionar uma planilha por plano de obras criado'))
		}

		const { name } = files[0]

		const fileExtension = String(getExt(name)).toLowerCase()
		if (allowedXlsExtensions.includes(fileExtension)) {
			const base64_ = await generateBse64(files[0])
			const xlsrows = await importExcelToArray(files[0])
			const { pedido_venda_servico, pl_ob_desc_object: temp_pl_ob_desc_object } = readXlsServices(xlsrows)
			if (!pedido_venda_servico?.length) {
				return setInfoRequest(handleWaring('Planilha preenchida incorretamente ou não há serviços inseridos nela'))
			}
			handleForm({
				pl_ob_desc_object: temp_pl_ob_desc_object,
			})
			setOversight(pedido_venda_servico)
			setInfoRequest(handleSuccess('Planilha selecionada com sucesso'))
			scrollToElement(refListConstruction.current)
			return setFile({
				base64: base64_,
				fileName: name,
			})
		}
		setFile({
			base64: '',
			fileName: ''
		})
		return setInfoRequest(handleWaring(`O arquivo ${name} não é válido`))

	}
	return (
		<>
			<ContentHeader title={'Cadastro Plano de obra'} />
			<div className="content">
				<Spinner label={'Salvando dados'} />
				<div className="card">
					<div className="card-body">
						<div className="row">
							{!file.fileName && (
								<div className="col-md-6 col-sm-12">
									<Link to="#" onClick={handleGetSpreedSheet} className="text-dark">
										Deseja usar uma planilha modelo para inserir os serviços e então usar essa planilha? Fazer isso agiliza seu trabalho 😁{' '}
										<i className="fa fa-download"></i>
									</Link>
								</div>
							)}
							{showInputFile &&
								(
									<div className="col-md-6 col-sm-12">
										<div className="btn btn-danger" onClick={handleFile}>
											{file.fileName ? `Anexado ${file.fileName}` : 'Anexar a Planilha Planilha_Plano_Obras'}
										</div>
									</div>
								)}
							<input
								name="file_attachments"
								className="d-none"
								type="file"
								onChange={onChangeFile}
								ref={input}
							/>
						</div>
					</div>
				</div>
				<form onSubmit={onSubmit}>
					<FormContructions file={file?.fileName}
						monitoringProgressItems={monitoringProgressItems}
						setMonitoringProgressItems={setMonitoringProgressItems}
					/>
					<div className="card card-danger">
						<div className="card-header">
							<h3 className="card-title">Serviços serem executados na obra</h3>
							<div className="card-tools">
								<button
									type="button"
									className="btn btn-tool"
									data-card-widget="collapse"
								>
									<i className="fas fa-minus"></i>
								</button>
								<button
									type="button"
									className="btn btn-tool"
									data-toggle="modal"
									data-target="#concessionaria"
								>
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
						<div className="card-body" ref={refListConstruction}>
							<SelectServiceConstructionPlan />
							{items &&
								Object.keys(items).map((item, i) => (
									<>
										<div className="row" key={i}>
											<div className="col-md-11">
												<div className="text-bold">
													{item}
												</div>
											</div>
											<div className="col-md-1"></div>
										</div>
										<ListConstructionPlan
											key={i}
											data={items[item]}
											group={item}
											totalByGroup={totalSumItems?.totalByGroup}
											totalValor={totalSumItems?.totalValor}
										/>
									</>
								))}
							{
								totalSumItems && (
									<div className="row mb-3">
										<div className="col-md-11 col-sm-12">
											<div className="row">
												<div className="col-md-3 col-sm-6">
													<div className="text-bold"></div>
												</div>
												<div className="col-md-3 col-sm-6 text-bold"></div>
												<div className="col-md-3 col-sm-6">
													<div className="text-bold">Valor total R$ {totalSumItems?.totalValor ? parseFloat(totalSumItems?.totalValor)?.toFixed(2) : '0,00'}</div>

												</div>
												<div className="col-md-3 col-sm-12">
												</div>
											</div>
										</div>
										<div className="col-md-1 col-sm-12"></div>
									</div>
								)
							}
						</div>
					</div>
					<div className="card">
						<div className="card-body">
							<button type="submit" className="btn btn-danger mr-3">
								Salvar <i className="fa fa-save"></i>
							</button>
							<div className="btn btn-secondary" onClick={() => handleCancel()}>
								Cancelar <i className="fa fa-times-circle"></i>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}
export default NewContructionPlan
