import React, { useContext } from "react"
import { formContext } from "../../../context/formContext"
import { requestContext } from "../../../context/requestContext"
const SearchSector = ({ handleNew }) => {
    const { state, onChange } = useContext(formContext)
    const { getItemWhereSector, getItemSector } = useContext(requestContext)
    const {
        set_desc
    } = state
    const handleSearch = () => {
        if (!set_desc) {
            return getItemSector()
        }
        getItemWhereSector(set_desc)
    }
    return (
        <div className="input-group input-group-sm">
            <div className="input-group-append">
                <button className="btn btn-tool btn-sm" onClick={() => getItemSector()}>
                    Atualizar lista
                </button>
            </div>
            <input type="text" className="form-control float-right"
                name="set_desc" value={set_desc} onChange={onChange}
                placeholder="Digite aqui para pesquisar" />
            <div className="input-group-append">
                <button className="btn btn-default" onClick={handleSearch}>
                    <i className="fas fa-search"></i>
                </button>
            </div>
        </div>
    )
}
export default SearchSector