import React, { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { styleButtonPlus } from '../../../css/globlalStyle'
import { isValid, handleWaring, handleSuccess } from '../../../services/isValid'
import { randomNumber } from '../../../services/randomId'
import { isExist } from '../../../services/arrayHandless'
import Selectservice from '../selectservice'
import { find } from '../../../services/arrayHandless'
const SelectServiceConstructionPlan = ({ justFilter = false }) => {
	const { state, onChangeSelectInput, handleForm } = useContext(formContext)
	const [qtd, setQtd] = useState(1)
	const [serv_value, setServValue] = useState(0)
	const {
		oversight,
		setOversight,
		setInfoRequest,
		setCardinalPoints,
		servicecat,
		serviceSubcat,
		getItemDERService,
		getAllItemService,
		getAllItemCategoryService,
		getAllItemServiceSubCat
	} = useContext(requestContext)
	const {
		serv_cod,
		serv_desc,
		ob_serv_med_desc,
		serv_cat_cod,
		serv_sub_cat_cod,
		serv_sub_cat_desc,
		serv_sub_cat_disp,
		serv_sub_cat_serv_cat,
		serv_sub_cat_valor,
		serv_cat_desc,
		tip_ob_cod,
		mode_sisf
	} = state
	useEffect(() => {
		const loadService = () => {
			if (mode_sisf && tip_ob_cod) {
				getAllItemService(`${tip_ob_cod}/3`)
			} else {
				getItemDERService()
			}
		}
		loadService()
	}, [mode_sisf, tip_ob_cod])
	useEffect(() => {
		const loadServiceCat = () => {
			if (serv_cod) {
				getAllItemCategoryService(serv_cod)
			}
		}
		loadServiceCat()
	}, [serv_cod])
	useEffect(() => {
		const loadServiceSubCat = () => {
			if (Array.isArray(servicecat?.data) && serv_cod) {
				const { data: items_data } = servicecat
				const { data: find_serv_cat_cod } = find(items_data, 'serv_cat_serv', serv_cod)
				if (find_serv_cat_cod) {
					const { serv_cat_cod: serv_cat_cod_ } = find_serv_cat_cod
					handleForm({
						serv_cat_cod: serv_cat_cod_,
					})
				}
			}
		}
		loadServiceSubCat()
	}, [servicecat, serv_cod])
	useEffect(() => {
		const loadServiceSubCat = () => {
			if (serv_cat_cod) {
				handleForm({
					serv_sub_cat_serv_cat: serv_cat_cod
				})
				getAllItemServiceSubCat(serv_cat_cod)
			}
		}
		loadServiceSubCat()
	}, [serv_cat_cod])
	useEffect(() => {
		setServValue(0)
		handleForm({
			serv_cod: 0
		})
	}, [])
	useEffect(() => {
		if (serv_sub_cat_valor > 0) {
			setServValue(serv_sub_cat_valor)
		}
	}, [serv_sub_cat_valor])
	useEffect(() => {
		if (serv_cod === '') {
			onClearValues()
		}
	}, [])
	useEffect(() => {
		setOversight(null)
		setCardinalPoints(null)
	}, [])
	useEffect(() => {
		const setValuePlObTipo = () => {
			if (tip_ob_cod) {
				handleForm({
					pl_ob_tipo: tip_ob_cod
				})
			}
		}
		setValuePlObTipo()
	}, [tip_ob_cod])

	const verifyTotalByGroup = (group) => {
		if (!Array.isArray(oversight)) {
			return 0
		}
		return oversight
			.filter((el) => el.serv_desc === group)
			.reduce((a, b) => a + b.serv_sub_cat_valor, 0)
	}

	const changeUnit = (value) => {
		return value.replace('M2', 'M²').replace('M3', 'M³')
	}
	const handleNewItem = () => {
		const serv_valor_total_by_group = verifyTotalByGroup(serv_desc)
		if (serv_valor_total_by_group === 100) {
			return setInfoRequest(
				handleWaring(
					`O grupo de serviço ${serv_desc} já está 100% preenchido, remova alguns itens ou altere a porcentagem dos itens`
				)
			)
		}
		const item = {
			serv_sub_cat_cod,
			serv_sub_cat_desc,
			serv_sub_cat_disp,
			serv_cat_desc,
			serv_sub_cat_serv_cat,
			ob_serv_med_desc: ob_serv_med_desc || 'UN',
			serv_sub_cat_valor: Number(serv_value),
			serv_sub_cat_qtd: Number(qtd),
			prog_serv_por_cen_total: 0,
			serv_desc,
			serv_cod,
			qtd,
		}
		const valid = isValid(item)
		if (!valid) {
			return setInfoRequest(handleWaring())
		}
		if (isExist(oversight, 'serv_sub_cat_cod', serv_sub_cat_cod, 'equal')) {
			return setInfoRequest(
				handleWaring(
					`O serviço ${serv_desc} com a Atividade ${serv_sub_cat_desc} já foi adicionado`
				)
			)
		}
		const id = randomNumber()
		if (Array.isArray(oversight)) {
			return setOversight([
				...oversight,
				{
					id,
					...item,
					is_xls_origem: false,
				},
			])
		}
		setOversight([
			{
				id,
				...item,
				is_xls_origem: false,
			},
		])
		setInfoRequest(handleSuccess(`Servico ${serv_sub_cat_desc} inserido`))
		setServValue(0)
		setQtd(1)
	}
	const onClearValues = () => {
		handleForm({
			ob_serv_med_desc: '',
			serv_cat_valor: '',
			serv_cat_medida: '',
			serv_cat_serv: '',
			serv_cat_disp: '',
			serv_cat_prazo: '',
			serv_cat_desc: '',
			serv_cat_cod: '',
			serv_desc: '',
			serv_sub_cat_cod: '',
			serv_sub_cat_desc: '',
			serv_sub_cat_disp: '',
			serv_sub_cat_valor: '',
			serv_sub_cat_qtd: '',
			serv_sub_cat_serv_cat: '',
		})
		setQtd(1)
		setServValue(null)
	}
	const onChange = ({ target: { value } }) => {
		setServValue(Number(value))
	}
	const onChangeQtd = ({ target: { value } }) => {
		if (value > 0) {
			setQtd(Number(value))
		}
	}
	return (
		<div className="row">
			<div className={justFilter ? 'col-md-6 col-sm-12' : "col-md-4 col-sm-12"}>
				<Selectservice label={'Disciplina'} constructionPlan />
			</div>
			<div className={justFilter ? 'col-md-6 col-sm-12' : "form-group col-md-4 col-sm-8"}>
				<label>Tipo de Serviço:</label>
				<div className="input-group">
					<select
						disabled={serv_cod > 0 ? false : true}
						onChange={onChangeSelectInput}
						className={`form-control form-control-border ${!serv_cat_cod && 'is-invalid'
							}`}
					>
						<option value={''}>Selecionar Serviço na obra</option>
						{serviceSubcat?.data?.length > 0 &&
							serviceSubcat?.data?.map((item) => (
								<option key={item.serv_sub_cat_cod}
									value={JSON.stringify(item)}
								>
									{item.serv_sub_cat_desc}
								</option>
							))}
					</select>
				</div>
			</div>
			{!justFilter && (
				<>
					<div className="form-group col-md-2 col-sm-4">
						<label>Valor (R$):</label>
						<div className="input-group">
							<input
								type="number"
								className={`form-control form-control-border ${!serv_value && 'is-invalid'
									}`}
								name="serv_value"
								value={serv_value}
								onChange={onChange}
								placeholder="0%"
							/>
						</div>
					</div>
					<div className="form-group col-md-2 col-sm-4">
						<label>Quantidade {ob_serv_med_desc ? `(${ob_serv_med_desc === 'Porcentagem' ? 'un' : changeUnit(ob_serv_med_desc)})` : ''}:</label>
						<div className="input-group">
							<input
								type="number"
								className={`form-control form-control-border ${!qtd && 'is-invalid'
									}`}
								name="serv_sub_cat_qtd"
								value={qtd}
								onChange={onChangeQtd}
								placeholder="0"
							/>
							<div className="input-group-prepend">
								<span
									className="input-group-text bg-danger"
									onClick={handleNewItem}
									style={styleButtonPlus}
								>
									<i className="fa fa-plus" aria-hidden="true"></i>
								</span>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	)
}
export default SelectServiceConstructionPlan
