import React, { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../context/formContext'
import Dealership from '../dealership'
import Highway from '../highway'
import SelectTypeContruction from '../selectTypeContruction'
import { addDots, removeDots } from '../../../services/stringHandless'
import FormMonitoring from './monitoring'
import FormMonitoringProgressConstructionPlan from '../monitoringProgressConstructionPlan'
import { useLocation } from 'react-router-dom'
import { namesScreens } from '../../../constants'
import { requestContext } from '../../../context/requestContext'
import Spinner from '../../spinner'
const FormContructions = ({ monitoringProgressItems, setMonitoringProgressItems }) => {
	const { state, onChange, handleForm } = useContext(formContext)
	const { postItemConstructionPlan } = useContext(requestContext)
	const location = useLocation()
	const [disabled, setDisabled] = useState(false)
	useEffect(() => {
		const controllDisabledInputs = () => {
			const { pathname } = location
			if (pathname === namesScreens.REPORT_CONSTRUCTION_PLAN) {
				return setDisabled(true)
			}
			return setDisabled(false)
		}
		controllDisabledInputs()
	}, [location])
	const {
		pl_ob_cod,
		pl_ob_desc_object,
		pl_ob_cod_item,
		pl_ob_data_program_ini,
		pl_ob_data_program_fim,
		pl_ob_data_exec_ini,
		pl_ob_data_exec_fim,
		pl_ob_data_ana_ini,
		pl_ob_data_ana_fim,
		pl_ob_data_prev_ini,
		pl_ob_data_prev_fim,
		pl_ob_tipo,
		pv_loc_pedidovenda,
		pv_loc_sentido,
		pv_loc_km_ini,
		pv_loc_km_init_mts,
		pv_loc_km_fim,
		pv_loc_km_fim_mts,
		pv_loc_obs,
	} = state
	const onBlur = ({ target: { value } }) => {
		const formated_value = removeDots(value)
		const new_value = addDots({ string: formated_value, padNumber: 3 })
		handleForm({
			pl_ob_cod_item: new_value,
		})
	}
	/*
	 "pl_ob_data_program_ini" => "date",
	 "pl_ob_data_program_fim" => "date",
	 "pl_ob_data_exec_ini" => "date",
	 "pl_ob_data_exec_fim" => "date",
	 "pl_ob_data_ana_ini" => "date",
	 "pl_ob_data_ana_fim" => "date",
	 "pl_ob_data_prev_ini" => "date",
	 "pl_ob_data_prev_fim" => "date",
	 "pl_ob_cod_item" => "string|min:5|max:100",
	 "pl_ob_desc_object" => "string|min:5|max:100",
	 "pl_ob_tipo" => "numeric",
	 "pv_loc_pedidovenda" => "required|numeric",
	 "pv_loc_sentido" => "numeric",
	 "pv_loc_km_ini" => "numeric",
	 "pv_loc_km_init_mts" => "numeric",
	 "pv_loc_km_fim" => "numeric",
	 "pv_loc_km_fim_mts" => "numeric",
	 "pv_loc_obs" => "string|min:3|max:100",
	*/
	const onSubmit = (e) => {
		e.preventDefault()
		e.stopPropagation()
		const payload = {
			id: pl_ob_cod,
			pl_ob_desc_object,
			pl_ob_cod_item,
			pl_ob_data_program_ini: pl_ob_data_program_ini || undefined,
			pl_ob_data_program_fim: pl_ob_data_program_fim || undefined,
			pl_ob_data_exec_ini: pl_ob_data_exec_ini || undefined,
			pl_ob_data_exec_fim: pl_ob_data_exec_fim || undefined,
			pl_ob_data_ana_ini: pl_ob_data_ana_ini || undefined,
			pl_ob_data_ana_fim: pl_ob_data_ana_fim || undefined,
			pl_ob_data_prev_ini: pl_ob_data_prev_ini || undefined,
			pl_ob_data_prev_fim: pl_ob_data_prev_fim || undefined,
			pl_ob_tipo,
			pv_loc_pedidovenda,
			pv_loc_sentido,
			pv_loc_km_ini,
			pv_loc_km_init_mts,
			pv_loc_km_fim,
			pv_loc_km_fim_mts,
			pv_loc_obs,
		}
		postItemConstructionPlan(payload)
	}
	return (
		<form className='p-3' onSubmit={onSubmit}>
			<div className='row'>
				<div className='col-12'>
					<SelectTypeContruction />
				</div>
				<Dealership />
				<Highway />
				<div className="form-group col-md-6 col-sm-12">
					<label>Nome da Obra ou do objeto:</label>
					<div className="input-group">
						<input
							type="text"
							className={`form-control form-control-border ${!pl_ob_desc_object && 'is-invalid'}`}
							name="pl_ob_desc_object"
							value={pl_ob_desc_object}
							onChange={onChange}
							placeholder='Nome da Obra ou do objeto'
							disabled={disabled}
						/>
					</div>
				</div>
				<div className="form-group col-md-6 col-sm-12">
					<label>Item de serviço:</label>
					<div className="input-group">
						<input
							type="text"
							className={`form-control form-control-border ${!pl_ob_cod_item && 'is-invalid'}`}
							name="pl_ob_cod_item"
							value={pl_ob_cod_item}
							onChange={onChange}
							onBlur={onBlur}
							placeholder='Item de serviço'
							disabled={disabled}
						/>
					</div>
				</div>
				<div className='col-12'>
					<FormMonitoring />
				</div>
				{monitoringProgressItems && setMonitoringProgressItems ? (
					<div className='card card-danger col-12'>
						<div className="card-header">
							<h3 className="card-title">Cronograma de Acompanhamento da Obra</h3>
							<div className="card-tools">
								<button
									type="button"
									className="btn btn-tool"
									data-card-widget="collapse"
								>
									<i className="fas fa-minus"></i>
								</button>
								<button
									type="button"
									className="btn btn-tool"
									data-toggle="modal"
									data-target="#monitoring"
								>
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
						<div className='card-body'>
							<FormMonitoringProgressConstructionPlan
								monitoringProgressItems={monitoringProgressItems}
								setMonitoringProgressItems={setMonitoringProgressItems} />
						</div>
					</div>
				) : null}
			</div>
			<button className='btn btn-danger' type={'submit'}>
				<i className='fa fa-save'></i> Salvar <Spinner direction={'center'} label={' '}  />
			</button>
		</form >
	)
}
export default FormContructions
