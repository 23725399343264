import React, { useContext } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { authContext } from '../../../context/authContext'
const SearchHighwayLimit = ({ handleNew }) => {
	const { state, onChange } = useContext(formContext)
	const { getWhereItemHighwayLimit, getItemHighwayLimit } = useContext(requestContext)
	const { user } = useContext(authContext)
	const {
		gp_desc,
		conc_cod, 
		rod_cod, 
		gp_cod
	} = state
	const handleSearch = () => {
		if (!gp_desc) {
			return getItemHighwayLimit({
				id: gp_cod,
				conc_cod,
				rod_cod,
			})
		}
		getWhereItemHighwayLimit(gp_desc)
	}
	const handleRefresh = () => {
		getItemHighwayLimit({
			id: gp_cod,
			conc_cod,
			rod_cod,  
		})
	}
	return (
		<div className="input-group input-group-sm">
			<div className="input-group-append">
				<button className="btn btn-tool btn-sm" onClick={handleRefresh}>
                    Atualizar lista
				</button>
			</div>
			{user?.set_nivel <= 1 && handleNew && (
				<div className="input-group-append">
					<button className="btn btn-tool btn-sm" onClick={handleNew}>
						<i className="fas fa-plus"></i> Novo
					</button>
				</div>
			)}
			<input type="text" className="form-control float-right"
				name="gp_desc" value={gp_desc} onChange={onChange}
				placeholder="Digite aqui para pesquisar" />
			<div className="input-group-append">
				<button className="btn btn-default" onClick={handleSearch}>
					<i className="fas fa-search"></i>
				</button>
			</div>
		</div>
	)
}
export default SearchHighwayLimit