import React, { useContext, useEffect, useRef, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { styleImage } from '../../../css/globlalStyle'
import { requestContext } from '../../../context/requestContext'
import { authContext } from '../../../context/authContext'
const ImageItem = ({ item, src, data, legend, noTrashButton, removePhoto, setPhotos, byPassSetLevel }) => {
	const { setBases64 } = useContext(formContext)
	const { deleteItemOversightPhotos } = useContext(requestContext)
	const { user } = useContext(authContext)
	const ref_img = useRef()
	const [error, setError] = useState(false)
	const handleRemove = async () => {
		if (removePhoto) {
			return handleRemoveFromOtherComponent()
		}
		if (item) {
			const { pvs_loc_foto_pedidovenda, pvs_loc_foto_cod, pvs_loc_foto_legenda } = item
			const confirm = window.confirm(`Você deseja mesmo remover a foto ${pvs_loc_foto_legenda}`)
			if (confirm) {
				const result = await deleteItemOversightPhotos({
					id: pvs_loc_foto_cod, pvs_cod: pvs_loc_foto_pedidovenda
				})
				if (!result) {
					return
				}
				const newData = data.filter((value) => parseInt(value.pvs_loc_foto_cod) !== parseInt(pvs_loc_foto_cod))
				setPhotos(newData)
			}
		} else {
			const newData = data.filter((value) => value !== src)
			setBases64(newData)
		}
	}
	const handleRemoveFromOtherComponent = () => {
		const newData = data.filter(({ base64 }) => base64 !== src)
		removePhoto(newData)
	}
	const openImage = () => {
		const image = new Image()
		image.src = src
		const w = window.open('')
		w.document.write(image.outerHTML)
	}
	useEffect(() => {
		if (src && ref_img) {
			try {
				ref_img.current.onerror = function () {
					setError(true)
				}
			} catch (error) {
				setError(true)
			}
		}
	}, [ref_img, src])
	if (error) {
		return <div></div>
	}
	return (
		<div className="row m-3">
			<div className="col-12 mb-3">
				<img
					ref={ref_img}
					onClick={openImage}
					className="rounded"
					src={src}
					style={styleImage}
					alt={src}
				/>
			</div>
			{user?.set_nivel === 0 || byPassSetLevel ? (
				<>
					<div className="col-8">
						{legend && <div className="text-secondary mr-2 mb-3">{legend}</div>}
					</div>
					<div className="col-2">
						{!noTrashButton && (
							<div className="btn btn-secondary" onClick={handleRemove}>
								<i className="fa fa-trash"></i>
							</div>
						)}
					</div>
				</>
			) : (
				<div className="col-12">
					{legend && <div className="text-secondary mr-2 mb-3">{legend}</div>}
				</div>
			)}
		</div>
	)
}
export default ImageItem
