import React, { useContext } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { useNavigate } from 'react-router-dom'
import { parseFromMysqlDateToJSDate } from '../../../services/dateHandless'
const ListSpreedsheetConstructionPlan = ({ data }) => {
	const { handleForm } = useContext(formContext)
	const { deleteItemDefaultSpreedSheetConstructionPlan, getToDownloadDefaultSpreedSheetConstructionPlan } = useContext(requestContext)
	const navigate = useNavigate()
	if (!Array.isArray(data)) {
		return <div className='row'>
			<div className='col-12'>
				Não há itens para serem exibidos
			</div>
		</div>
	}
	const handleEdit = (item) => {
		handleForm({
			...item
		})
		navigate('/spreedsheet_default/new')
	}
	const handleDownload = (item) => {
		const { pmo_tip_pl_ob } = item
		if (!pmo_tip_pl_ob) {
			return
		}
		getToDownloadDefaultSpreedSheetConstructionPlan(pmo_tip_pl_ob)
	}
	const handleDelete = (item) => {
		const { tip_ob_desc, pmo_cod } = item
		const confirm = window.confirm(`Você deseja mesmo excluir a planilha modelo para o tipo de obra ${tip_ob_desc}?`)
		if (!confirm) {
			return
		}
		deleteItemDefaultSpreedSheetConstructionPlan(pmo_cod)
	}
	return (
		data.map(item => (
			<div className='row' key={item.pmo_cod}>
				<div className='col-md-9'>
					<div className='row'>
						<div className='col-md-4 col-sm-6 mb-3'>
							<span className='text-secondary mr-2'>Tipo de obra: </span>
							{item.tip_ob_desc}
						</div>
						<div className='col-md-4 col-sm-6 mb-3'>
							<span className='text-secondary mr-2'>Criado por: </span>
							{item.name}
						</div>
						<div className='col-md-4 col-sm-6 mb-3'>
							<span className='text-secondary mr-2'>Email do criador: </span>
							{item.usu_email}
						</div>
						<div className='col-md-4 col-sm-6 mb-3'>
							<span className='text-secondary mr-2'>Criado dia: </span>
							{parseFromMysqlDateToJSDate(item.created_at)}
						</div>
					</div>
				</div>
				<div className='col-md-3'>
					<div className='row'>
						<div className='col-6'>
							<button className='btn btn-danger btn-sm mr-2' onClick={() => handleEdit(item)}>
								<i className='fa fa-edit'></i>
							</button>
						</div>
						<div className='col-6'>
							<button className='btn btn-secondary btn-sm mr-2 mb-3' onClick={() => handleDelete(item)}>
								<i className='fa fa-trash'></i>
							</button>
						</div>
						<div className='col-12'>
							<button className='btn btn-dark btn-sm' onClick={() => handleDownload(item)}>
								<i className='fa fa-download'></i> Baixar planilha modelo
							</button>
						</div>
					</div>
				</div>
			</div>
		))
	)
}
export default ListSpreedsheetConstructionPlan