import React, { useContext } from 'react'
import { Routes, Route } from 'react-router-dom'
import Header from '../../components/header'
import Menu from '../../components/menu'
import Dashboard from '../../views/dashboard'
import Footer from '../../components/footer'
import NoMatch from '../../views/NoMatch'
import Profile from '../../views/auth/profile'
import ChangePass from '../../views/auth/changePass'
import Toasy from '../../components/toasty'
import Logout from '../../views/auth/logout'
import OverSight from '../../views/oversight'
import ManagerHighway from '../../views/highway'
import ManagerDealership from '../../views/dealership'
import NewDealership from '../../views/dealership/new'
import NewHighway from '../../views/highway/new'
import ScrollToTop from '../../services/scroll'
import DealershipContract from '../../views/dealershipContract'
import NewDealershipContract from '../../views/dealershipContract/new'
import OverSightNew from '../../views/oversight/new'
import OversightView from '../../views/oversight/view'
import Sync from '../../views/Sync'
import ManagerService from '../../views/service'
import NewService from '../../views/service/new'
import DeadlineManager from '../../views/deadline'
import NewDeadline from '../../views/deadline/new'
import NewNotification from '../../views/notification/new'
import OverSightNotification from '../../views/notification'
import OverSightNotified from '../../views/notification/notified'
import ReportByOversight from '../../views/report/byOversight'
import ReportOversightView from '../../views/report/byOversight/view'
import ReportOversightRoutine from '../../views/report/routineMaintance'
import ReportOversightRoutineView from '../../views/report/routineMaintance/view'
import ManegerServiceConstructions from '../../views/service/constructions'
import ManagerConstructionPlan from '../../views/constructionPlan'
import NewContructionPlan from '../../views/constructionPlan/new'
import ViewContructionPlan from '../../views/constructionPlan/view'
import ManagerComplements from '../../views/complements'
import ManagerUsers from '../../views/users'
import NewUsers from '../../views/users/new'
import PanelOversight from '../../views/report/panelOversight'
import ReportMapByOversight from '../../views/report/reportMapByOversight'
import Report from '../../views/report'
import ManagerCompany from '../../views/company'
import ManagerSector from '../../views/sector'
import NewSector from '../../views/sector/new'
import NewCompany from '../../views/company/new'
import ManagerStatusOversight from '../../views/statusOversight'
import NewStatus from '../../views/statusOversight/new'
import ManagerVersion from '../../views/version'
import ManagerHighwayLimit from '../../views/highwayLimit'
import Help from '../../views/tutorial/help'
import NewHelp from '../../views/tutorial/help/new'
import HelpMovies from '../../views/tutorial'
import ManageWorkOfArt from '../../views/workOfArt'
import NewWorkOfArt from '../../views/workOfArt/new'
import ManageWorkOfArtType from '../../views/workOfArt/workOfArtType'
import NewWorkOfArtType from '../../views/workOfArt/workOfArtType/new'
import ManageEmails from '../../views/manageEmails'
import ManagerTypeServiceSubCategory from '../../views/typeServiceSubCategory'
import NewTypeServiceSubCategory from '../../views/typeServiceSubCategory/new'
import ManagerTimeLine from '../../views/timeline'
import ManagerWorkOfArtClass from '../../views/workOfArt/classWorkOfArt'
import NewClassWorkOfArt from '../../views/workOfArt/classWorkOfArt/new'
import ManageWorkOfArtElement from '../../views/workOfArt/workOfArtElement'
import SAC from '../../views/sac'
import ManagerConfigurationParameters from '../../views/configurations'
import NewComplements from '../../views/complements/new'
import NewConfigurationParameters from '../../views/configurations/new'
import ManagerTypeOversight from '../../views/typeOversight'
import NewTypeOversight from '../../views/typeOversight/new'
import NewLimitHighway from '../../views/highwayLimit/new'
import ManagerSyncStatus from '../../views/syncStatus'
import NewSyncStatus from '../../views/syncStatus/new'
import NewTutorial from '../../views/tutorial/new'
import NewWorkOfArtElement from '../../views/workOfArt/workOfArtElement/new'
import MangegerSpreedsheetDefault from '../../views/spreedsheetDefault'
import NewSpreedsheetDefault from '../../views/spreedsheetDefault/new'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { requestContext } from '../../context/requestContext'
import { namesScreens } from '../../constants'
import ManagerPavement from '../../views/pavement'
import NewPavementIndicators from '../../views/pavement/indicator/new'
import ManagerGroupsIndex from '../../views/pavement/groupsIndex'
import ManagerTypesIndex from '../../views/pavement/typesIndex'
import NewTypeIndex from '../../views/pavement/typesIndex/new'
import ReportNCPhotosPDF from '../../views/report/byOversight/view/reportNCPhotos'
import ManagerCheckListPaviment from '../../views/pavement/checklist'
import NewPavementCheckList from '../../views/pavement/checklist/new'
import NewGroupPavementIndex from '../../views/pavement/groupsIndex/new'
import NewTypePavementIndex from '../../views/pavement/typesIndex/new'
import ManagerPavementIndicator from '../../views/pavement/indicator'
import AnswerChecklist from '../../views/pavement/checklist/answer'
import ViewPavementIndicators from '../../views/pavement/indicator/view'
import { storageCache } from '../../services/cacheManager'
import NewServiceConstructions from '../../views/service/constructions/new'
import ReportConstructionPlan from '../../views/constructionPlan/report'
const AppRouter = () => {
	const { setInfoRequest } = useContext(requestContext)
	const closeOnClick = () => {
		setInfoRequest({
			status: '',
			message: '',
			color: ''
		})
		storageCache('lass_message')
	}
	return (
		<>
			<Header />
			<Menu />
			<ScrollToTop />
			<Toasy />
			<ToastContainer closeOnClick={closeOnClick} />
			<div className="content-wrapper">
				<Routes>
					<Route index element={<Dashboard />} />
					<Route path={namesScreens.SYNC} element={<Sync />} />
					<Route path={namesScreens.USERPROFILE} element={<Profile />} />
					<Route path={namesScreens.USERPASSWORD} element={<ChangePass />} />
					<Route path={namesScreens.USERLOGOUT} element={<Logout />} />
					<Route path={namesScreens.OVERSIGHT} element={<OverSight />} />
					<Route path={namesScreens.OVERSIGHTVIEW} element={<OversightView />} />
					<Route path={namesScreens.OVERSIGHTNEW} element={<OverSightNew />} />
					<Route path={namesScreens.HIGHWAY} element={<ManagerHighway />} />
					<Route path={namesScreens.HIGHWAYNEW} element={<NewHighway />} />
					<Route path={namesScreens.DEALERSHIP} element={<ManagerDealership />} />
					<Route path={namesScreens.DEALERSHIPNEW} element={<NewDealership />} />
					<Route path={namesScreens.DEALERSHIP_CONTRACT} element={<DealershipContract />} />
					<Route path={namesScreens.REPORTPAINEL} element={<PanelOversight />} />
					<Route path={namesScreens.SERVICE} element={<ManagerService />} />
					<Route path={namesScreens.SERVICENEW} element={<NewService />} />
					<Route path={namesScreens.DEADLINE} element={<DeadlineManager />} />
					<Route path={namesScreens.DEADLINENEW} element={<NewDeadline />} />
					<Route path={namesScreens.NOTIFICATIONNEW} element={<NewNotification />} />
					<Route path={namesScreens.NOTIFICATION} element={<OverSightNotification />} />
					<Route path={namesScreens.NOTIFICATIONNOTIFED} element={<OverSightNotified />} />
					<Route path={namesScreens.REPORTOVERSIGHT} element={<ReportByOversight />} />
					<Route path={namesScreens.REPORT} element={<Report />} />
					<Route path={namesScreens.SAC} element={<SAC />} />
					<Route path={namesScreens.REPORTOVERSIGHTMAP} element={<ReportMapByOversight />} />
					<Route path={namesScreens.SECTOR} element={<ManagerSector />} />
					<Route path={namesScreens.SECTORNEW} element={<NewSector />} />
					<Route path={namesScreens.COMPANY} element={<ManagerCompany />} />
					<Route path={namesScreens.VERSION} element={<ManagerVersion />} />
					<Route path={namesScreens.COMPANYNEW} element={<NewCompany />} />
					<Route path={namesScreens.STATUS} element={<ManagerStatusOversight />} />
					<Route path={namesScreens.STATUSNEW} element={<NewStatus />} />
					<Route path={namesScreens.SYNC_STATUS} element={<ManagerSyncStatus />} />
					<Route path={namesScreens.SYNC_STATUSNEW} element={<NewSyncStatus />} />
					<Route path={namesScreens.HIGHWAY_LIMIT} element={<ManagerHighwayLimit />} />
					<Route path={namesScreens.HIGHWAY_LIMITNEW} element={<NewLimitHighway />} />
					<Route path={namesScreens.COMPLEMENTS} element={<ManagerComplements />} />
					<Route path={namesScreens.COMPLEMENTSNEW} element={<NewComplements />} />
					<Route path={namesScreens.USERS} element={<ManagerUsers />} />
					<Route path={namesScreens.USERSNEW} element={<NewUsers />} />
					<Route path={namesScreens.WORK_OF_ART} element={<ManageWorkOfArt />} />
					<Route path={namesScreens.WORK_OF_ARTNEW} element={<NewWorkOfArt />} />
					<Route path={namesScreens.WORK_OF_ART_TYPE} element={<ManageWorkOfArtType />} />
					<Route path={namesScreens.WORK_OF_ART_TYPENEW} element={<NewWorkOfArtType />} />
					<Route path={namesScreens.WORK_OF_ART_CLASS} element={<ManagerWorkOfArtClass />} />
					<Route path={namesScreens.WORK_OF_ART_CLASSNEW} element={<NewClassWorkOfArt />} />
					<Route path={namesScreens.WORK_OF_ART_ELEMENT} element={<ManageWorkOfArtElement />} />
					<Route path={namesScreens.WORK_OF_ART_ELEMENTNEW} element={<NewWorkOfArtElement />} />
					<Route path={namesScreens.HELP} element={<Help />} />
					<Route path={namesScreens.HELPNEW} element={<NewHelp />} />
					<Route path={namesScreens.TUTORIAL} element={<HelpMovies />} />
					<Route path={namesScreens.TUTORIALNEW} element={<NewTutorial />} />
					<Route path={namesScreens.MANAGE_EMAILS} element={<ManageEmails />} />
					<Route path={namesScreens.TYPE_SERVICE} element={<ManagerTypeServiceSubCategory />} />
					<Route path={namesScreens.TYPE_SERVICENEW} element={<NewTypeServiceSubCategory />} />
					<Route path={namesScreens.TYPE_SALES_ORDER} element={<ManagerTypeOversight />} />
					<Route path={namesScreens.TYPE_SALES_ORDERNEW} element={<NewTypeOversight />} />
					<Route path={namesScreens.TIMELINE} element={<ManagerTimeLine />} />
					<Route path={namesScreens.NOT_FOUND} element={<NoMatch />} />
					<Route path={namesScreens.CONFIGURATIONS_PARAMATERS} element={<ManagerConfigurationParameters />} />
					<Route path={namesScreens.CONFIGURATIONS_PARAMATERSNEW} element={<NewConfigurationParameters />} />
					<Route
						path={namesScreens.DEALERSHIP_CONTRACTNEW}
						element={<NewDealershipContract />}
					/>
					<Route
						path={namesScreens.REPORTOVERSIGHTVIEW}
						element={<ReportOversightView />}
					/>
					<Route
						path={namesScreens.REPORTOVERSIGHTROUTINE}
						element={<ReportOversightRoutine />}
					/>
					<Route
						path={namesScreens.REPORTOVERSIGHTROUTINEVIEW}
						element={<ReportOversightRoutineView />}
					/>
					<Route path={namesScreens.SERVICES_CONSTRUCTIONS} element={<ManegerServiceConstructions />} />
					<Route path={namesScreens.SERVICES_CONSTRUCTIONS_NEW} element={<NewServiceConstructions />} />
					<Route
						path={namesScreens.CONSTRUCTION_PLAN}
						element={<ManagerConstructionPlan />}
					/>
					<Route
						path={namesScreens.CONSTRUCTION_PLANNEW}
						element={<NewContructionPlan />}
					/>
					<Route
						path={namesScreens.CONSTRUCTION_PLANVIEW}
						element={<ViewContructionPlan />}
					/>
					<Route
						path={namesScreens.REPORT_CONSTRUCTION_PLAN}
						element={<ReportConstructionPlan />}
					/>
					<Route
						path="/spreedsheet_default"
						element={<MangegerSpreedsheetDefault />}
					/>
					<Route
						path={namesScreens.SPREEDSHEET_DEFAULTNEW}
						element={<NewSpreedsheetDefault />}
					/>
					<Route
						path="/reportncphotospdf"
						element={<ReportNCPhotosPDF />}
					/>
					<Route
						path={namesScreens.PAVEMENT}
						element={<ManagerPavement />}
					/>
					<Route
						path={namesScreens.PAVEMENT_INDICATOR}
						element={<ManagerPavementIndicator />}
					/>
					<Route
						path={namesScreens.PAVEMENT_INDICATOR_NEW}
						element={<NewPavementIndicators />}
					/>
					<Route
						path={namesScreens.PAVEMENT_INDICATOR_VIEW}
						element={<ViewPavementIndicators />}
					/>
					<Route
						path={namesScreens.PAVEMENT_INDICATOR_GROUPS_INDEX}
						element={<ManagerGroupsIndex />}
					/>
					<Route
						path={namesScreens.PAVEMENT_INDICATOR_GROUPS_INDEX_NEW}
						element={<NewGroupPavementIndex />}
					/>
					<Route
						path={namesScreens.PAVEMENT_INDICATOR_TYPES_INDEX}
						element={<ManagerTypesIndex />}
					/>
					<Route
						path={namesScreens.PAVEMENT_INDICATOR_TYPES_INDEX_NEW}
						element={<NewTypePavementIndex />}
					/>
					<Route
						path={namesScreens.PAVEMENT_CHECKLIST_MANAGER}
						element={<ManagerCheckListPaviment />}
					/>
					<Route
						path={namesScreens.PAVEMENT_CHECKLIST_NEW}
						element={<NewPavementCheckList />}
					/>
					<Route
						path={namesScreens.PAVEMENT_INDICATOR_TYPES_INDEX_NEW}
						element={<NewTypeIndex />}
					/>
					<Route
						path={namesScreens.ANSWER_CHECKLIST}
						element={<AnswerChecklist />}
					/>
				</Routes>
			</div>
			<Footer />
		</>
	)
}
export default AppRouter
