import React, { useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { authContext } from '../../context/authContext'
import { formContext } from '../../context/formContext'
import { requestContext } from '../../context/requestContext'
import ContentHeader from '../../components/contentHeader'
import SearchOversight from '../../components/form/searchOversight'
import ListOversight from '../../components/list/oversight'
import Spinner from '../../components/spinner'
import { recoverCache, storageCache } from '../../services/cacheManager'
import FormoversightByDate from '../../components/form/filters/oversightByDate'
const OverSight = () => {
	const { handleForm, state: { ped_venda_status, serv_cat_prazo_from, serv_cat_prazo_to,
		type_date, conc_cod, rod_cod, gp_cod, desc }, setBases64 } = useContext(formContext)
	const {
		oversight,
		setHighway,
		getItemOversightStatus,
		getItemAllStatusOversight,
	} = useContext(requestContext)
	const { user } = useContext(authContext)
	const navigate = useNavigate()
	useEffect(() => {
		setHighway(null)
		getItemAllStatusOversight()
	}, [])
	useEffect(() => {
		onClick()
	}, [])
	const onClick = () => {
		getItemOversightStatus({
			id: ped_venda_status,
			conc_cod: conc_cod,
			rod_cod: rod_cod,
			ped_venda_date_from: serv_cat_prazo_from,
			ped_venda_date_to: serv_cat_prazo_to,
			type_date: type_date,
			desc,
			gp_cod: gp_cod,
		})
	}
	const handlePage = (item) => {
		if (!item) {
			return
		}
		const { label } = item
		const { current_page, last_page } = oversight
		window.scrollTo(0, 0)
		if (label === 'pagination.previous') {
			return getItemOversightStatus(current_page > 1 && {
				id: ped_venda_status,
				conc_cod: conc_cod,
				rod_cod: rod_cod,
				ped_venda_date_from: serv_cat_prazo_from,
				ped_venda_date_to: serv_cat_prazo_to,
				type_date: type_date,
				desc,
				gp_cod: `${gp_cod}?page=${current_page - 1}`,
			})
		}
		if (label === 'pagination.next') {
			return getItemOversightStatus(current_page < last_page && {
				id: ped_venda_status,
				conc_cod: conc_cod,
				rod_cod: rod_cod,
				ped_venda_date_from: serv_cat_prazo_from,
				ped_venda_date_to: serv_cat_prazo_to,
				type_date: type_date,
				desc,
				gp_cod: `${gp_cod}?page=${current_page + 1}`,
			})
		}
		if (!label) {
			return getItemOversightStatus({
				id: ped_venda_status,
				conc_cod: conc_cod,
				rod_cod: rod_cod,
				ped_venda_date_from: serv_cat_prazo_from,
				ped_venda_date_to: serv_cat_prazo_to,
				type_date: type_date,
				desc,
				gp_cod: gp_cod,
			})
		}
		getItemOversightStatus({
			id: ped_venda_status,
			conc_cod: conc_cod,
			rod_cod: rod_cod,
			ped_venda_date_from: serv_cat_prazo_from,
			ped_venda_date_to: serv_cat_prazo_to,
			type_date: type_date,
			desc,
			gp_cod: `${gp_cod || 0}?page=${label}`,
		})
	}
	const handleNew = () => {
		const getAPK = recoverCache('getAPK')
		if (!getAPK) {
			const confirme = window.confirm(
				'Para melhor experiência sugerimos você baixar o aplicativo da ARTESP para abertura de ocorrências, posso baixar o aplicativo? Pressione OK para baixar'
			)
			if (confirme) {
				storageCache('getAPK', 'yes')
				return navigate('/version')
			}
			storageCache('getAPK')
		}
		resetValues()
		return navigate('/oversight/new')
	}
	const resetValues = () => {
		setBases64()
		handleForm({
			rod_extencao: '',
			rod_extencao_: '',
			ped_venda_cod: '',
			ped_venda_date: '',
			ped_venda_concessionaria: '',
			desc: '',
			pv_servico: '',
			lot_conc_desc: '',
			ped_venda_responsavel: '',
			ped_venda_rodovia: '',
			created_at: '',
			updated_at: '',
			st_desc: '',
			st_cor_valor: '',
			name: '',
			rod_desc: '',
			pv_loc_cod: '',
			pv_loc_pedidovenda: '',
			pv_loc_sentido: '',
			pv_loc_km_ini: '',
			pv_loc_km_init_mts: '',
			pv_loc_km_fim: '',
			pv_loc_km_fim_mts: '',
			pv_loc_obs: '',
			pv_loc_lat: '',
			pv_loc_lng: '',
			rod_sent_desc: '',
			rod_cod: '',
			rod_km: '',
			rod_sent_cod: '',
			rod_km_ini: '',
			rod_km_fim: '',
			rod_km_ini_mt: '',
			rod_km_fim_mt: '',
			rod_tipo: '',
			rod_concessionaria: '',
			rod_km_: '',
			serv_cat_prazo_from: '',
			serv_cat_prazo_to: '',
			type_date: '',
			ped_venda_status: 0,
			conc_nome: '',
			conc_cod: '',
			gp_cod: '',
		})
	}
	const handleClar = () => {
		resetValues()
		getItemOversightStatus({
			id: 0,
			conc_cod: null,
			ped_venda_date_from: null,
			ped_venda_date_to: null,
			desc: null,
			type_date: 0,
			gp_cod: 0,
		})
	}
	const RenderLinkFooter = ({ item }) => {
		return (
			<li className="page-item" key={item.label}>
				<Link
					className={`page-link ${item.active && 'text-light bg-danger'
					}`}
					to="#"
					onClick={() => handlePage(item)}
				>
					{item.label === 'pagination.previous' ? (
						<i className="fa fa-angle-left"></i>
					) : item.label === 'pagination.next' ? (
						<i className="fa fa-angle-right"></i>
					) : (
						item.label
					)}
				</Link>
			</li>
		)
	}
	return (
		<>
			<ContentHeader title={'Não conformidades de Rotina'} />
			<div className="content">
				<div className="card">
					<div className="card-header">
						<h3 className="card-title d-md-block d-none">Todas ocorrências</h3>
						<div className="card-tools">
							<SearchOversight handleNew={handleNew} />
						</div>
					</div>
					<div className="card-body">
						<FormoversightByDate typeDate />
					</div>
					<div className="card-footer">
						<div className="row">
							<div className="col-md-9 col-sm-8">
								<button className="btn btn-danger mr-2" onClick={onClick}>
									<i className="fa fa-filter" aria-hidden="true"></i>
									Aplicar filtro</button>
								<button className="btn btn-dark" onClick={handleClar}>
									<i className="fa fa-eraser" aria-hidden="true"></i>
									Limpar filtro
								</button>
							</div>
							<div className="col-md-3 col-sm-4">
								{oversight?.total} ocorrências encontradas
							</div>
						</div>
					</div>
				</div>
				<div className='card'>
					<div className="card-body">
						<Spinner direction={'center'} label="carregando" />
						<ListOversight data={oversight} />
					</div>
					<div className="card-footer clearfix">
						<ul className="pagination pagination-sm m-0 float-right">
							{Array.isArray(oversight?.links) &&
								oversight.links.map((item) => (
									<RenderLinkFooter item={item} key={item.label} />
								))}
						</ul>
						{user && user.set_nivel <= 2 && (
							<ul className="pagination pagination-sm m-0 float-left">
								<li className="page-item">
									<div className="page-link" onClick={handleNew}>
										Novo <i className="fa fa-plus mr-1 ml-1"></i>
									</div>
								</li>
							</ul>
						)}
					</div>
				</div>
			</div>
		</>
	)
}
export default OverSight
