import React, { useContext } from 'react'
import ContentHeader from '../../../../components/contentHeader'
import { handleError } from '../../../../services/isValid'
import { formContext } from '../../../../context/formContext'
import { requestContext } from '../../../../context/requestContext'
import { useNavigate } from 'react-router'
import { namesScreens } from '../../../../constants'
import FormTypesIndex from '../../../../components/form/typeIndex'
const NewTypePavementIndex = () => {
	const { state: { tip_ind_pav_cod, tip_ind_pav_desc }, handleForm } = useContext(formContext)
	const { setInfoRequest, postItemTypeIndexPavementIndicators } = useContext(requestContext)
	const navigate = useNavigate()
	const onSubmit = (e) => {
		if (!tip_ind_pav_desc) {
			return setInfoRequest(handleError())
		}
		e.preventDefault()
		e.stopPropagation()
		window.scrollTo(0, 0)
		postItemTypeIndexPavementIndicators({
			id: tip_ind_pav_cod,
			tip_ind_pav_desc
		})
	}
	const handleCancel = () => {
		handleForm({
			tip_ind_pav_cod: '',
			tip_ind_pav_desc: ''
		})
		navigate(namesScreens.PAVEMENT_INDICATOR_TYPES_INDEX)
	}
	return (
		<>
			<ContentHeader title={'Novo Group de Indice'} />
			<form onSubmit={onSubmit} className='p-3'>
				<div className="card card-danger">
					<div className="card-header">
						<h3 className="card-title text-capitalize"></h3>
						<div className="card-tools">
							<button
								type="button"
								className="btn btn-tool"
								data-card-widget="collapse"
							>
								<i className="fas fa-minus"></i>
							</button>
							<button
								type="button"
								className="btn btn-tool"
								data-toggle="modal"
								data-target="#concessionaria"
							>
								<i className="fa fa-question-circle"></i>
							</button>
						</div>
					</div>
					<div className="card-body">
						<FormTypesIndex />
					</div>
				</div>
				<div className="card">
					<div className="card-body">
						<button className="btn btn-danger mr-3" type='submit'>
                            Salvar <i className="fa fa-save"></i>
						</button>
						<div className="btn btn-secondary" onClick={handleCancel}>
                            Cancelar <i className="fa fa-times-circle"></i>
						</div>
					</div>
				</div>
			</form>
		</>
	)
}
export default NewTypePavementIndex