import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { FLAVOR } from '../../config/flavor'
import { convertToTitleCase } from '../stringHandless'
import { ENV } from '../../config'
/**
 * Scroll to the top of the window whenever the pathname changes.
 */
export default function ScrollToTop() {
	/**
   * Scrolls to the top of the window whenever the pathname changes.
   * @returns {null} Returns null.
   */
	const { pathname } = useLocation()

	useEffect(() => {
		const setScroll = () => {
			window.scrollTo(0, 0)
			if (pathname !== '/construction_plan/view') {
				document.title = `${FLAVOR} - ${convertToTitleCase(ENV)}`
			}
		}
		setScroll()
	}, [pathname])

	return null
}

/**
 * Focuses on the given element and scrolls to its position.
 * @param {HTMLElement} elem - The element to focus on.
 * @returns {void}
 */
export const cursorFocus = function (elem) {
	const x = elem.offsetTop
	const y = elem.offsetLeft
	elem.focus()
	window.scrollTo(x, y)
}

/**
 * Scrolls to the given element.
 * @param {HTMLElement} element - The element to scroll to.
 * @returns {void}
 */
export const scrollToElement = (element) => {
	if (element) {
		element.scrollIntoView()
	}
}
