import React, { useContext } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { authContext } from '../../../context/authContext'
const SearchSpreedsheetConstructionPlan = ({ handleNew }) => {
	const { state, onChange } = useContext(formContext)
	const { getWhereItemDefaultSpreedSheetConstructionPlan, getItemDefaultSpreedSheetConstructionPlan } = useContext(requestContext)
	const { user } = useContext(authContext)
	const {
		tip_ob_desc,
		tip_ob_cod,
	} = state
	const handleSearch = () => {
		if (!tip_ob_desc) {
			return getItemDefaultSpreedSheetConstructionPlan(tip_ob_cod)
		}
		getWhereItemDefaultSpreedSheetConstructionPlan(tip_ob_desc)
	}
	return (
		<div className="input-group input-group-sm">
			<div className="input-group-append">
				<button className="btn btn-tool btn-sm" onClick={() => getItemDefaultSpreedSheetConstructionPlan('0')}>
					Atualizar
				</button>
			</div>
			{user?.set_nivel <= 1 && (
				<div className="input-group-append">
					<button className="btn btn-tool btn-sm" onClick={handleNew}>
						Novo <i className="fa fa-plus"></i>
					</button>
				</div>
			)}
			<input type="text" className="form-control float-right"
				name="tip_ob_desc" value={tip_ob_desc} onChange={onChange}
				placeholder="Procurar pelo tipo da obra" />
			<div className="input-group-append">
				<button className="btn btn-default" onClick={handleSearch}>
					<i className="fas fa-search"></i>
				</button>
			</div>
		</div >
	)
}
export default SearchSpreedsheetConstructionPlan