/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ContentHeader from '../../../components/contentHeader'
import FormTypeOversight from '../../../components/form/typeOversight'
import Spinner from '../../../components/spinner'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { handleWaring, isValid } from '../../../services/isValid'
const NewTypeOversight = () => {
	const { handleForm, state } = useContext(formContext)
	const {
		setInfoRequest,
		postItemTypeOversight,
	} = useContext(requestContext)
	const { tip_ped_venda_cod, tip_ped_venda_desc } = state
	const navigate = useNavigate()
	useEffect(() => {
		handleForm({
			tip_sub_ob: '',
		})
		if (!tip_ped_venda_cod) {
			handleForm({
				tip_ped_venda_desc: '',
			})
		}
	}, [tip_ped_venda_cod])
	const onSubmit = (e) => {
		window.scrollTo(0, 0)
		e.preventDefault()
		e.stopPropagation()
		const item = {
			tip_ped_venda_desc,
		}
		const validate = isValid(item)
		if (!validate) {
			return setInfoRequest(handleWaring())
		}
		postItemTypeOversight({
			...item,
			id: tip_ped_venda_cod,
		})
		handleForm({
			tip_ped_venda_cod: '',
			tip_ped_venda_desc: '',
		})
	}
	const handleCancel = () => {
		handleForm({
			tip_ped_venda_cod: '',
			tip_ped_venda_desc: '',
		})
		navigate('/type_sales_order')
	}
	return (
		<>
			<ContentHeader title={'Cadastro de Tipos de Obras e atividades'} />
			<div className="content">
				<Spinner label={'Salvando dados'} />
				<form onSubmit={onSubmit}>
					<div className="card card-danger">
						<div className="card-header">
							<h3 className="card-title">Tipo de obra</h3>
							<div className="card-tools">
								<button
									type="button"
									className="btn btn-tool"
									data-card-widget="collapse"
								>
									<i className="fas fa-minus"></i>
								</button>
								<button
									type="button"
									className="btn btn-tool"
									data-toggle="modal"
									data-target="#concessionaria"
								>
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
						<div className="card-body">
							<FormTypeOversight />
						</div>
					</div>
					<div className="card">
						<div className="card-body">
							<button type="submit" className="btn btn-danger mr-3">
                Salvar <i className="fa fa-save"></i>
							</button>
							<div className="btn btn-secondary" onClick={() => handleCancel()}>
                Cancelar <i className="fa fa-times-circle"></i>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}
export default NewTypeOversight
