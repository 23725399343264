import React, { useContext } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
const SearchElementWorkofArt = ({ handleNew }) => {
	const { state, onChange } = useContext(formContext)
	const { getWhereItemObArtElement, getItemObArtElement } = useContext(requestContext)
	const {
		ob_art_elem_desc
	} = state
	const handleSearch = () => {
		if (!ob_art_elem_desc) {
			return getItemObArtElement()
		}
		getWhereItemObArtElement(ob_art_elem_desc)
	}
	return (
		<div className="input-group input-group-sm">
			<div className="input-group-append">
				<button className="btn btn-tool btn-sm" onClick={handleNew}>
                    Novo <i className="fa fa-plus"></i>
				</button>
			</div>
			<div className="input-group-append">
				<button className="btn btn-tool btn-sm" onClick={() => getItemObArtElement()}>
                    Atualizar lista
				</button>
			</div>
			<input type="text" className="form-control float-right"
				name="ob_art_elem_desc" value={ob_art_elem_desc} onChange={onChange}
				placeholder="Digite aqui para pesquisar" />
			<div className="input-group-append">
				<button className="btn btn-default" onClick={handleSearch}>
					<i className="fas fa-search"></i>
				</button>
			</div>
		</div>
	)
}
export default SearchElementWorkofArt