import React from 'react'
const HeaderTable = () => {
	const ipcs = [
		'IRI(mm/m)',
		'QI (cont./km)',
		'IGG'
	]
	return (
		<thead>
			<tr>
				<th scope="col" className='text-center'>#ID</th>
				<th scope="col" className='text-center'>Rodovia</th>
				<th scope="col" className='text-center'>Pista</th>
				<th scope="col" className='text-center'>Observações</th>
				<th scope="col" className='text-center'>Km Inicial</th>
				<th scope="col" className='text-center'>Km Final</th>
				<th scope='col' colSpan="2" className='text-center'>Irregularidade Longitudinal</th>
				<th scope='col' colSpan="1" className='text-center'>Superfície</th>
				<td scope="col" className='text-center'></td>
				<th scope="col" className='text-center'>Monitoramento</th>
			</tr>
			<tr>
				<td scope="col" className='text-center'></td>
				<td scope="col" className='text-center'></td>
				<td scope="col" className='text-center'></td>
				<td scope="col" className='text-center'></td>
				<td scope="col" className='text-center'></td>
				<td scope="col" className='text-center'></td>
				{ipcs.sort((a, b) => a - b).map((item, i) => (
					<td scope="col" key={i} className='text-center'>
						{item}
					</td>
				))}
				<td scope="col" className='text-center'>Data do levantamento</td>
				<td scope="col" className='text-center'></td>
			</tr>
		</thead>
	)
}
export default HeaderTable