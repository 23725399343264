import React, { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { useLocation } from 'react-router-dom'
import { namesScreens } from '../../../constants'
const SelectTypeContruction = () => {
	const { state, handleForm } = useContext(formContext)
	const location = useLocation()
	const [disabled, setDisabled] = useState(false)
	useEffect(() => {
		const controllDisabledInputs = () => {
			const { pathname } = location
			if (pathname === namesScreens.REPORT_CONSTRUCTION_PLAN) {
				return setDisabled(true)
			}
			return setDisabled(false)
		}
		controllDisabledInputs()
	}, [location])
	const {
		type_constructions,
		getItemTypeConstructions,
		oversight,
		setOversight,
	} = useContext(requestContext)
	const { tip_ob_cod } = state
	useEffect(() => {
		if (tip_ob_cod) {
			handleForm({
				pl_ob_tipo: tip_ob_cod,
				serv_tip_obra: tip_ob_cod,
			})
		}
	}, [tip_ob_cod])
	useEffect(() => {
		const getAllInfoTypeConstruction = () => {
			if (Array.isArray(type_constructions?.data) && tip_ob_cod) {
				const { data } = type_constructions
				const { data: findItem } = find(data, 'tip_ob_cod', Number(tip_ob_cod))
				if (findItem) {
					handleForm({
						...findItem
					})
				}
			}
		}
		getAllInfoTypeConstruction()
	}, [type_constructions, tip_ob_cod])
	useEffect(() => {
		getItemTypeConstructions()
	}, [])

	const onChange = ({ target }) => {
		if (!tip_ob_cod || !Array.isArray(oversight)) {
			return handleForm({
				tip_ob_cod: target.value,
			})
		}
		if (Array.isArray(oversight)) {
			const confirm = window.confirm(
				'Você deseja mesmo trocar o tipo de obra? Se sim, todas os serviços inseridos serão removidos'
			)
			if (confirm) {
				setOversight(null)
				handleForm({
					tip_ob_cod: target.value,
				})
			}
		}
	}
	return (
		<div className="form-group">
			<label>Tipo de obra:</label>
			<div className="input-group">
				<select
					onChange={onChange}
					className={`form-control form-control-border ${!tip_ob_cod && 'is-invalid'}`}
					value={tip_ob_cod}
					name="tip_ob_cod"
					disabled={disabled}
				>
					<option value={''}>Selecionar Tipo de obra</option>
					{Array.isArray(type_constructions?.data) &&
						type_constructions.data.map((item) => (
							<option key={item.tip_ob_cod} value={item.tip_ob_cod}>
								{item.tip_ob_desc}
							</option>
						))}
				</select>
			</div>
		</div>
	)
}
export default SelectTypeContruction
