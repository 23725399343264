import React, { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { formatCurrency } from '../../../services/stringHandless'
import { find, getLengthValuesSmallerThan } from '../../../services/arrayHandless'
import { loadConfigIGG, loadConfigIGGIRI, loadConfigIRI } from '../../../services/paviment'
const FormLimitRelavitValuesPavement = ({ nota_avaliacao_pavimento, segmentsData, setPayloadNavp }) => {
	const { state } = useContext(formContext)
	const [items, setItems] = useState(null)
	const [smallerValueIRI, setSmallerValueIRI] = useState(null)
	const [smallerValueIGG, setSmallerValueIGG] = useState(null)
	const [idnavpiggiri, setIdnavpiggiri] = useState(null)
	const [sumLMItems, setLMSumItms] = useState({
		ipc_perc: 0,
		ipc_val: 0
	})
	const { lipc_tot_seg } = state
	useEffect(() => {
		const loadConfig = async () => {
			try {
				await loadConfigIRI(setSmallerValueIRI)
				await loadConfigIGG(setSmallerValueIGG)
				await loadConfigIGGIRI(setIdnavpiggiri)
			} catch (error) {
				console.log(error)
			}
		}
		loadConfig()
	}, [])
	useEffect(() => {
		const controlsetPayloadNavp = () => {
			if (items && sumLMItems && idnavpiggiri) {
				setPayloadNavp([...items, {
					navp_perc: sumLMItems?.ipc_perc,
					navp_val: sumLMItems?.ipc_val,
					navp_tip_cod: idnavpiggiri,
					tip_ind_pav_cod: idnavpiggiri
				}])
			}
		}
		controlsetPayloadNavp()
	}, [items, sumLMItems, idnavpiggiri])
	useEffect(() => {
		const loadValuesSmallerThan = async () => {
			if (Array.isArray(nota_avaliacao_pavimento) && Array.isArray(segmentsData) && lipc_tot_seg > 0 && smallerValueIGG && smallerValueIRI) {
				const key_of_value = 'ipc_val'
				const temp_count = []
				const temp_segmentsData = []
				segmentsData.forEach(item => {
					const { indice_pavimento_consolidado } = item
					for (const row of indice_pavimento_consolidado) {
						const {
							ipc_val,
							ipc_perc,
							ipc_tip_cod
						} = row
						temp_segmentsData.push({
							ipc_val,
							ipc_perc,
							ipc_tip_cod,
						})
					}
				})
				const smallerValue = [{ ...smallerValueIGG }, { ...smallerValueIRI }]
				nota_avaliacao_pavimento.forEach(item => {
					const { tip_ind_pav_cod, tip_ind_pav_desc } = item
					const { data: findsmallerValues } = find(smallerValue, 'id', tip_ind_pav_cod)
					const count = getLengthValuesSmallerThan({
						valueSmallerToCompare: findsmallerValues?.value,
						ipc_tip_cod: tip_ind_pav_cod,
						segmentsData: temp_segmentsData,
						key_of_value
					})
					if (!count?.isError) {
						const navp_val = count.total
						const navp_perc = count.total / lipc_tot_seg
						temp_count.push({
							navp_val: navp_val > 0 ? navp_val : item.navp_val,
							navp_perc: navp_perc > 0 ? navp_perc : item.navp_perc,
							tip_ind_pav_desc,
							tip_ind_pav_cod
						})
					}

				})
				setItems(temp_count)
			}
		}
		loadValuesSmallerThan()
	}, [segmentsData, lipc_tot_seg, smallerValueIRI, smallerValueIGG])
	useEffect(() => {
		const loadSumLMValues = () => {
			if (Array.isArray(segmentsData) && lipc_tot_seg > 0) {
				const result = segmentsData.reduce((prev, next) => prev + next.l, 0)
				setLMSumItms({
					ipc_val: result,
					ipc_perc: result / lipc_tot_seg
				})
			}
		}
		loadSumLMValues()
	}, [segmentsData, lipc_tot_seg])
	if (!Array.isArray(segmentsData)) {
		return <div className="row">
			Não há itens para serem exibidos
		</div>
	}
	return (
		<div className='row'>
			<div className='col-md-6 col-sm-12'>
				<div className='row'>
					<div className='mb-3 col-12'>
						<label>
							Valores limites relativos à fase inicial:
						</label>
					</div>
					<div className='mb-3 col-12'>
						<label className='mr-2'>Irregularidade Longitudinal:</label>
						<span className='text-secondary'>
							{'IRI < 3,46 mm/m; QI < 45 cont./km'}
						</span>
					</div>
					<div className='mb-3 col-12'>
						<label className='mr-2'>Superfície: </label>
						<span className='text-secondary'>
							{'IGG < 40'}
						</span>
					</div>
				</div>
			</div>
			<div className='col-md-6 col-sm-12'>
				<div className='row'>
					<div className='mb-3 col-12'>
						<label>

						</label>
					</div>
					<div className='mb-3 col-12'>
						<label className='mr-2'>Total atendido (Irreg.+IGG):</label>
						<span className='text-secondary'>
							{formatCurrency(sumLMItems?.ipc_val, false)}
						</span>
					</div>
					<div className='mb-3 col-12'>
						<label className='mr-2'>Perc. atendido (Irreg.+IGG): </label>
						<span className='text-secondary'>
							{parseFloat(sumLMItems?.ipc_perc).toFixed(2)}%
						</span>
					</div>
				</div>
			</div>
			<div className='col-12'>
				<label className='mr-2'>Valores obtidos apartir dos dados informados:</label>
			</div>
			<div className='col-md-6 col-sm-12'>
				<div className='row'>
					<div className='mb-3 col-12'>
						<label className='mr-2'>Total de seguimentos:</label>
						<span className='text-secondary'>
							{lipc_tot_seg || 0}
						</span>
					</div>
				</div>
			</div>
			{Array.isArray(items) && (
				<div className='col-md-6 col-sm-12'>
					{items.map((item, i) => (
						<div className='row' key={i}>
							<div className='mb-3 col-md-6 col-sm-12'>
								<label className='mr-2'>Total atendido {item.tip_ind_pav_desc}.:</label>
								<span className='text-secondary'>
									{formatCurrency(item.navp_val, false)}
								</span>
							</div>
							<div className='mb-3 col-md-6 col-sm-12'>
								<label className='mr-2'>Perc. atendido {item.tip_ind_pav_desc}: </label>
								<span className='text-secondary'>
									{formatCurrency(item.navp_perc, false)}%
								</span>
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	)
}
export default FormLimitRelavitValuesPavement