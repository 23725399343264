/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { requestContext } from "../../context/requestContext";
import ContentHeader from "../../components/contentHeader";
import Spinner from "../../components/spinner";
import SearchCompany from "../../components/form/searchCompony";
import { formContext } from "../../context/formContext";
import { Link, useNavigate } from "react-router-dom";
import { authContext } from "../../context/authContext";
import ListCompany from "../../components/list/company";
const ManagerCompany = () => {
    const { getItemCompany, getItemWhereCompany, company, } = useContext(requestContext);
    const { handleForm, state: { emp_nome } } = useContext(formContext)
    const { user } = useContext(authContext);
    const navigate = useNavigate()
    useEffect(() => {
        getItemCompany();
    }, []);
    const handlePage = (item) => {
        if (!item) {
            return
        }
        const { label } = item
        const { current_page, last_page } = company
        window.scrollTo(0, 0);
        if (label === 'pagination.previous') {
            return emp_nome ? getItemWhereCompany(current_page > 1 && `${emp_nome}?page=${current_page - 1}`) : getItemCompany(current_page > 1 && `0?page=${current_page - 1}`)
        }
        if (label === 'pagination.next') {
            return emp_nome ? getItemWhereCompany(current_page > 1 && `${emp_nome}?page=${current_page - 1}`) : getItemCompany(current_page < last_page && `0?page=${current_page + 1}`)
        }
        if (!label) {
            return emp_nome ? getItemWhereCompany(emp_nome) : getItemCompany();
        }
        if (emp_nome) {
            return getItemWhereCompany(`${emp_nome}?page=${label}`)
        }
        getItemCompany(`0?page=${label}`);
    };
    const handleNew = () => {
        handleForm({
            emp_nome: '',
            emp_cod: '',
            emp_cnpj: ''
        })
        navigate('/company/new')
    }
    return (
        <>
            <ContentHeader title={"Cadastro de Empresas"} />
            <div className="content">
                <div className="card sticky-top">
                    <div className="card-header">
                        <h3 className="card-title col-8 d-none d-md-block">
                            Empresas cadastrados
                        </h3>
                        <div className="card-tools">
                            <SearchCompany handleNew={handleNew} />
                        </div>
                    </div>
                </div>
                <div className="card">
                    <Spinner direction={"center"} label="carregando" />
                    <ListCompany data={company} />
                    <div className="card-footer clearfix">
                        <ul className="pagination pagination-sm m-0 float-right">
                            {Array.isArray(company?.links) &&
                                company.links.map((item, i) => (
                                    <li className="page-item" key={item.label}>
                                        <Link
                                            className={`page-link ${item.active && "text-light bg-danger"
                                                }`}
                                            to="#"
                                            onClick={() => handlePage(item)}
                                        >
                                            {item.label === "pagination.previous" ? (
                                                <i className="fa fa-angle-left"></i>
                                            ) : item.label === "pagination.next" ? (
                                                <i className="fa fa-angle-right"></i>
                                            ) : (
                                                item.label
                                            )}
                                        </Link>
                                    </li>
                                ))}
                        </ul>
                        {user && user.set_nivel === 1 && (
                            <ul className="pagination pagination-sm m-0 float-left">
                                <li className="page-item">
                                    <div className="page-link" onClick={handleNew}>
                                        Novo <i className="fa fa-plus mr-1 ml-1"></i>
                                    </div>
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
export default ManagerCompany;
