export const randomNumber = (min, max) => {
	min = Math.ceil(min || 1000)
	max = Math.floor(max || 100)
	return Math.floor(Math.random() * (max - min + 1)) + min
}
export function getRandomInt(min, max) {
	min = Math.ceil(min)
	max = Math.floor(max)
	return Math.floor(Math.random() * (max - min) + min)
}
