import React, { useContext } from 'react'
import { formContext } from '../../../context/formContext'
import { authContext } from '../../../context/authContext'
import { requestContext } from '../../../context/requestContext'
const SearchPavement = ({ handleNew }) => {
	const { state, onChange } = useContext(formContext)
	const { getItemPavementIndicators } = useContext(requestContext)
	const { user } = useContext(authContext)
	const {
		desc,
		conc_cod,
		rod_cod
	} = state
	const handleSearch = () => {

	}
	const onClick = () => {
		getItemPavementIndicators({
			id: conc_cod, rod_cod: rod_cod
		})
	}
	return (
		<div className="input-group input-group-sm mb-3">
			<div className="input-group-append">
				<button className="btn btn-tool btn-sm" onClick={onClick}>
					Atualizar lista
				</button>
			</div>
			{user?.set_nivel === 0 && (
				<div className="input-group-append">
					<button className="btn btn-tool btn-sm" onClick={handleNew}>
						<i className="fas fa-plus"></i> Novo
					</button>
				</div>
			)}
			<input type="text" className="form-control float-right"
				name="desc" value={desc} onChange={onChange}
				placeholder="Procurar por concessionaria ou Rodovia" />
			<div className="input-group-append">
				<button className="btn btn-default" onClick={handleSearch}>
					<i className="fas fa-search"></i>
				</button>
			</div>
		</div>
	)
}
export default SearchPavement