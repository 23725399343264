import React, { useContext, useEffect } from 'react'
import { requestContext } from '../../../context/requestContext'
import { formContext } from '../../../context/formContext'
import { isValid, handleWaring } from '../../../services/isValid'
import ContentHeader from '../../../components/contentHeader'
import DealershipContract from '../../../components/form/dealershipContract'
import { useNavigate } from 'react-router-dom'

const NewDealershipContract = () => {
	const { state, handleForm } = useContext(formContext)
	const { setInfoRequest, getItemDealershipContractAttachment,
		setContract, setContractAttachment, postItemDealershipContract } = useContext(requestContext)
	const navigate = useNavigate()
	const {
		cont_cod,
		cont_concessionaria,
		cont_ncontrato,
		cont_data_ass,
		cont_atual,
		ane_desc,
	} = state
	useEffect(() => {
		if (cont_concessionaria) {
			getItemDealershipContractAttachment(cont_concessionaria)
		}
	}, [cont_concessionaria])
	const onSubmit = (e) => {
		e.preventDefault()
		window.scrollTo(0, 0)
		const item = {
			cont_cod,
			cont_concessionaria,
			cont_ncontrato,
			cont_data_ass,
			cont_atual,
			ane_desc,
		}
		const validate = isValid(item)
		if (!cont_cod && !ane_desc) {
			return setInfoRequest(handleWaring('Você não adicionou anexos e clausulas para esse contrato'))
		}
		if (!validate) {
			return setInfoRequest(handleWaring())
		}
		// SUBMIT TO API
		postItemDealershipContract({
			...item,
			id: item.cont_cod
		})
	}
	const handleCancel = () => {
		handleForm({
			ane_desc: '',
			cont_cod: '',
			conc_cod: '',
			conc_nome: '',
			cont_atual: '',
			cont_concessionaria: '',
			cont_data_ass: '',
			cont_ncontrato: '',
		})
		setContract(null)
		setContractAttachment(null)
		navigate('/dealership_contract')
	}
	return (
		<>
			<ContentHeader title={'Cadastro de Contrato da Concessionária'} />
			<div className='content'>
				<form onSubmit={onSubmit} id='form' method="post" noValidate>
					<div className="card card-danger">
						<div className="card-header">
							<h3 className="card-title">Concessionária</h3>
							<div className="card-tools">
								<button type="button" className="btn btn-tool" data-card-widget="collapse">
									<i className="fas fa-minus"></i>
								</button>
								<button type="button" className="btn btn-tool" data-toggle="modal" data-target="#concessionaria">
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
						<div className="card-body">
							<DealershipContract edit />
						</div>
					</div>
					<div className="card">
						<div className='card-body'>
							<button className='btn btn-danger mr-3'>
                                Salvar <i className="fa fa-save"></i>
							</button>
							<div className='btn btn-secondary' onClick={handleCancel}>
                                Cancelar <i className="fa fa-times-circle"></i>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}
export default NewDealershipContract