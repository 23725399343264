import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../../services/dateHandless";
import RenderIcon from "../../icons";
import { requestContext } from "../../../context/requestContext";
const ListAttachmentsContructionPlan = ({ items, justPreview = false }) => {
	const { deleteItemAttachmentsConstructionPlan } = useContext(requestContext)
	if (!Array.isArray(items)) {
		return <div className="col-12">
			<p className="text-secondary">Não há itens para serem exibidos</p>
		</div>
	}
	const handleOpen = (ane_pl_ob_path) => {
		if (ane_pl_ob_path) {
			return window.open(ane_pl_ob_path, '_blank').focus()
		}
	}
	const handleDelete = (item) => {
		const { ane_pl_ob_cod, ane_pl_ob_obs, ane_pl_ob_obra_cod } = item
		const confirm = window.confirm(`Deseja mesmo remover a foto ${ane_pl_ob_obs} ?`)
		if (confirm) {
			deleteItemAttachmentsConstructionPlan({
				id: ane_pl_ob_cod,
				pl_ob_cod: ane_pl_ob_obra_cod,
			})
		}
	}
	return (
		<div className="row">
			{items.sort((a, b) => a.timestamp - b.timestamp).map(item => (
				<div className="col-md-4 col-sm-6" key={items.ane_pl_ob_cod}>
					<div className="card" style={{
						width: '18rem',
					}}>
						<RenderIcon path={item.ane_pl_ob_path} />
						<div className="card-body">
							<h5 className="card-title">
								{item.tip_ane_desc}
							</h5>
							<p className="card-text">
								<span className="text-secondary mr-2">
									Observação:
								</span>
								{item.ane_pl_ob_obs}
							</p>
							{item?.created_at && (
								<p>
									<span className="text-secondary mr-2">
										Anexado dia:
									</span>
									{formatDate(item.created_at)}
								</p>
							)}
							{justPreview ? '' : (
								<div className="row">
									<div className="col-6">
										<Link to="#" className="btn btn-danger" onClick={() => handleOpen(item.ane_pl_ob_path)}>Baixar <i className="fa fa-download" aria-hidden="true"></i></Link>
									</div>
									<div className="col-6" onClick={() => handleDelete(item)}>
										<button className="btn btn-secondary btn-sm">
											<i className="fa fa-trash"></i>
										</button>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			))}
		</div>
	)
}
export default ListAttachmentsContructionPlan