import React, { useContext, useEffect } from 'react'
import { formContext } from '../../../../context/formContext'
import { requestContext } from '../../../../context/requestContext'
import { filter, filterMoreOne } from '../../../../services/arrayHandless'
import Dealership from '../../dealership'
import SelectHighway from '../../selectHighway'
import FilterStatusOversight from '../statusOversight'
const FilterSingleOversight = ({ setFilterData }) => {
	const { state } = useContext(formContext)
	const { getAllItemHighway, oversight } = useContext(requestContext)
	const {
		conc_cod,
		rod_cod,
		rod_sent_cod
	} = state
	useEffect(() => {
		if (conc_cod) {
			getAllItemHighway(conc_cod)
		}
	}, [conc_cod])
	useEffect(() => {
		const handleFilter = () => {
			if (oversight && setFilterData) {
				const { data, total, ...rest } = oversight
				if (conc_cod && rod_cod) {
					return setFilterData({
						...filterMoreOne(
							data,
							'ped_venda_concessionaria', conc_cod,
							'ped_venda_rodovia', rod_cod, { ...rest }
						)
					})
				}
				if (conc_cod && !rod_cod) {
					return setFilterData({
						...filter(data, 'ped_venda_concessionaria', conc_cod, { ...rest })
					})
				}
				if (rod_sent_cod) {
					return setFilterData({
						...filter(data, 'pv_loc_sentido', rod_sent_cod, { ...rest })
					})
				}
				if (!conc_cod && !rod_cod) {
					return setFilterData({
						data: data,
						total,
						...rest
					})
				}
			}
		}
		handleFilter()
	}, [conc_cod, rod_cod, rod_sent_cod])
	return (
		<div className="row">
			<div className="col-12 mb-3 text-secondary mb-3">Filtrar situação da ocorrência</div>
			<div className="col-md-6 col-sm-12 mb-3">
				<FilterStatusOversight />
			</div>
			<>
				<div className="col-12 mb-3 text-secondary">Filtrar por Lote e Rodovia</div>
				<div className="col-md-6 col-sm-12 mb-3">
					<Dealership />
				</div>
				<div className="col-md-6 col-sm-12  mb-3">
					<SelectHighway />
				</div>
			</>
		</div>
	)
}
export default FilterSingleOversight