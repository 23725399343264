import React, { useContext } from 'react'
import { formContext } from '../../../context/formContext'
const FormService = () => {
	const { state, onChange } = useContext(formContext)
	const {
		serv_desc,
		serv_disp,
		serv_valor,
	} = state
	return (
		<div className='row'>
			<div className="form-group col-md-6 col-sm-6">
				<label>Serviço Descrição:</label>
				<div className="input-group">
					<input type="text"
						onChange={onChange}
						name='serv_desc' value={serv_desc}
						className={`form-control form-control-border ${!serv_desc && 'is-invalid'}`}
						placeholder='Descrição do serviço' />
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-6">
				<label>Serviço Disponibilidade:</label>
				<div className="input-group">
					<select
						onChange={onChange}
						name='serv_disp' value={serv_disp}
						className={`form-control form-control-border ${!serv_disp && 'is-invalid'}`}
					>
						<option>Selecionar</option>
						<option value={1}>Disponível</option>
						<option value={0}>Indisponível</option>
					</select>
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-6">
				<label>Serviço Valor (R$):</label>
				<div className="input-group">
					<input type="text"
						onChange={onChange}
						name='serv_valor' value={serv_valor}
						className="form-control form-control-border"
						placeholder='Valor do serviço em R$' />
				</div>
			</div>
		</div>
	)
}
export default FormService