import React, { useContext, useEffect } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import SelectHighway from '../selectHighway'
import Dealership from '../dealership'

const FormPavementsIndicators = () => {
	const { state, onChange } = useContext(formContext)
	const { getAllItemHighway, getAllItemDealership } = useContext(requestContext)
	const { conc_cod, lipc_obs } = state
	useEffect(() => {
		getAllItemDealership()
	}, [])
	useEffect(() => {
		if (conc_cod) {
			getAllItemHighway(conc_cod)
		}
	}, [conc_cod])
	return (
		<div className="row">
			<div className="col-md-6 col-sm-12">
				<Dealership />
			</div>
			<div className="col-md-6 col-sm-12  mb-3">
				<SelectHighway />
			</div>
			<div className='form-group col-md-12 col-sm-12'>
				<label>Observações:</label>
				<textarea name='lipc_obs' rows={4} onChange={onChange} value={lipc_obs} className='form-control form-control-border'>
					{lipc_obs}
				</textarea>
			</div>
		</div>
	)
}
export default FormPavementsIndicators