import React, { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { styleButtonSearch } from '../../../css/globlalStyle'
import { authContext } from '../../../context/authContext'
import { filter } from '../../../services/arrayHandless'
const SelectGroups = ({ col }) => {
	const { state, onChangeSelectInput, handleForm } = useContext(formContext)
	const { groups, getAllItemGroups } = useContext(requestContext)
	const { user } = useContext(authContext)
	const [items, setItems] = useState(null)
	const {
		gp_cod,
		gp_desc
	} = state
	useEffect(() => {
		getAllItemGroups()
	}, [])
	useEffect(() => {
		if (gp_cod) {
			handleForm({
				usu_gp: gp_cod
			})
		}
	}, [gp_cod])
	useEffect(() => {
		const loadItemsByGroupProfile = () => {
			if (Array.isArray(groups?.data) && user?.set_nivel !== 0) {
				const { data } = groups
				const { data: filterByGroupProfile } = filter(data, 'gp_cod', user.usu_gp)
				return setItems(filterByGroupProfile)
			}
			return setItems(groups?.data)
		}
		loadItemsByGroupProfile()
	}, [user, groups])
	const cancelSelect = () => {
		handleForm({
			gp_cod: '',
			gp_desc: '',
		}
		)
	}
	return (
		<div className={`form-group ${col || 'col-12'}`}>
			<label>{gp_desc ? 'Grupo' : 'Grupo'}</label>
			<div className="input-group">
				{gp_desc ?
					<div className='form-control form-control-border'>
						{gp_desc}
					</div>
					:
					<select onChange={onChangeSelectInput}
						className={`form-control form-control-border ${!gp_cod && 'is-invalid'}`}>
						<option>Selecionar um Grupo</option>
						{Array.isArray(items) && items.map(item => (
							<option key={item.gp_cod} value={JSON.stringify(item)}>
								{item.gp_desc}
							</option>
						))}
					</select>
				}
				{gp_desc && (<div className="input-group-prepend" onClick={cancelSelect}>
					<span className="input-group-text bg-danger" style={styleButtonSearch}>
						<i className="fa fa-times-circle" aria-hidden="true"></i>
					</span>
				</div>)
				}
			</div>
		</div>
	)
}
export default SelectGroups