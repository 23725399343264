import React, { useContext, useEffect, useState } from 'react'
import { formatDate } from '../../../services/dateHandless'
import { requestContext } from '../../../context/requestContext'
import { find } from '../../../services/arrayHandless'
import { authContext } from '../../../context/authContext'
import { handleWaring } from '../../../services/isValid'
import returnPosition from '../../../services/geolocation'
import { config } from '../../../constants'

const ListCheckinspector = ({ data }) => {
	const [pvs_status_pending_changes, setPvsStatusPendingChanges] = useState(null)
	useEffect(() => {
		const loadConfig = async () => {
			const config_parameters = await config('pvs_status')
			if (!config_parameters) {
				return
			}
			const { config_para_value } = config_parameters
			setPvsStatusPendingChanges(parseInt(config_para_value))
		}
		loadConfig()
	}, [])
	const { service, postApproveCheckcInspection, setInfoRequest } = useContext(requestContext)
	const { user } = useContext(authContext)
	const handleAprovedFisc = async (item) => {
		const { fisc_cod, fisc_ped_venda, pvs_cod, approved, created_at } = item
		const confirm = window.confirm(`Você deseja mesmo ${approved ? 'aprovar' : 'recusar'} essa alteração?`)
		if (!confirm || !service?.data) {
			return
		}
		let temp_fisc_obs = ''
		if (!approved) {
			temp_fisc_obs = window.prompt('Explique o porque você não aprovou essa alteração.')
			if (temp_fisc_obs?.length > 100 || temp_fisc_obs?.length < 5) {
				return setInfoRequest(handleWaring('Você deve escrever a explicação com minimo 5 e máximo 100 caracteres '))
			}
		}
		const position = await returnPosition()
		postApproveCheckcInspection({
			ped_venda_cod: fisc_ped_venda,
			approved,
			pvs_cod,
			fisc_obs: temp_fisc_obs,
			fisc_cod,
			fisc_lat: position.lat,
			fisc_lng: position.lng,
			created_at,
		})
	}
	const RenderButtonAprovedFisc = ({ item }) => {
		try {
			const { fisc_cod, fisc_ped_venda, fisc_adicional_parametros, created_at } = item
			if (!Array.isArray(service?.data)) {
				return (
					<div className="text-secondary"></div>
				)
			}
			const { data: items } = service
			const { data: findService } = find(items, 'pvs_pedidovenda', fisc_ped_venda)
			if (!findService) {
				return (
					<div className="text-secondary"></div>
				)
			}
			const parse_fisc_adicional_parametros = JSON.parse(fisc_adicional_parametros)
			const { pvs_cod } = parse_fisc_adicional_parametros
			if (pvs_cod === findService?.pvs_cod) {
				const { pvs_cod, pvs_status } = findService
				if (pvs_status !== pvs_status_pending_changes) {
					return <div className='text-secondary'></div>
				}
				return (
					<>
						<div className='col-md-6 col-sm-12'>
							<div className="btn btn-block btn-success btn-xs" onClick={() => handleAprovedFisc({
								pvs_cod,
								fisc_cod,
								fisc_ped_venda,
								st_cod: 2,
								approved: true,
								created_at,
							})}>
								<i className='fa fa-check'></i> Aprovar alteração
							</div>
						</div>
						<div className='col-md-6 col-sm-12'>
							<div className="btn btn-block btn-danger btn-xs" onClick={() => handleAprovedFisc({
								pvs_cod,
								fisc_cod,
								fisc_ped_venda,
								approved: false,
								created_at,
							})}>
								<i className='fa fa-times-circle'></i> Recusar alteração
							</div>
						</div>
					</>
				)
			}
			return (
				<div className='text-secondary'></div>
			)
		} catch (error) {
			<div className='d-none'>{JSON.stringify(error)}</div>
		}
	}
	return (
		data?.total > 0 &&
		data?.data?.map(item => (
			<div className='row' key={item.fisc_cod}>
				<div className='mb-3 col-md-4 col-sm-12'>
					<div className='row'>
						{item.created_at &&
							<>
								<div className='mb-3 col-md-9 col-sm-12'>
									<span className='text-secondary mr-2'>Fiscalizado no dia: </span>
									{formatDate(item.created_at)}
								</div>
							</>
						}
						{user?.set_nivel <= 0 && (
							<div className='col-12'>
								<div className="row">
									<RenderButtonAprovedFisc item={item} />
								</div>
							</div>
						)}
					</div>
				</div>
				<div className='mb-3 col-md-8 col-sm-12'>
					<span className='text-secondary mr-2'>Descrição:</span>
					{item.fisc_obs}
				</div>
				<hr className='col-12' />
			</div >
		))
	)
}
export default ListCheckinspector