import React, { useContext } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
const SearchDeadline = ({ handleNew }) => {
	const { state, onChange } = useContext(formContext)
	const { getWhereItemDeadline, getItemDeadline } = useContext(requestContext)
	const {
		desc
	} = state
	const handleSearch = () => {
		if (!desc) {
			return getItemDeadline()
		}
		getWhereItemDeadline(desc)
	}
	return (
		<div className="input-group input-group-sm">
			<div className="input-group-append">
				<button className="btn btn-tool btn-sm" onClick={handleNew}>
                    Novo <i className="fa fa-plus"></i>
				</button>
			</div>
			<input type="text" className="form-control float-right"
				name="desc" value={desc} onChange={onChange}
				placeholder="Procurar pelo lote ou serviço ou concessionaria" />
			<div className="input-group-append">
				<button className="btn btn-default" onClick={handleSearch}>
					<i className="fas fa-search"></i>
				</button>
			</div>
		</div>
	)
}
export default SearchDeadline