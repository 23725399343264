import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
const ListHighway = ({ data }) => {
	const { handleForm, setShow } = useContext(formContext)
	const { deleteItemHighway, setDealership } = useContext(requestContext)
	const navigate = useNavigate()
	const handleClick = (item) => {
		setDealership({
			total: 1,
			data: [
				{
					...item,
				},
			],
		})
		handleForm(item)
		setShow(true)
		navigate('/highway/new')
	}
	const handleDelete = (item) => {
		const confirm = window.confirm(
			`Deseja mesmo excluir a rodovia ${item.rod_desc} da concessionária ${item.conc_nome}?`
		)
		if (confirm) {
			deleteItemHighway(item.rod_cod)
		}
	}
	return data && data.total > 0 ? (
		data.data.map((item) => (
			<div key={item.rod_cod} className='row'>
				<div className='mb-3 col-md-10 col-sm-12'>
					<div className="row">
						<div className="mb-3 col-md-4 col-sm-12">
							<span className="text-secondary mr-2">Código rodovia:</span>
							{item.rod_km}
						</div>
						<div className='mb-3 col-md-8 col-sm-6'>
							<span className="text-secondary mr-2">Descrição da Rodovia:</span>
							{item.rod_desc}
						</div>
						<div className="mb-3 col-md-3 col-sm-6">
							<span className="text-secondary mr-2">KM Inicial:</span>
							{item.rod_km_ini}, {item.rod_km_ini_mt} mts
						</div>
						<div className="mb-3 col-md-3 col-sm-6">
							<span className="text-secondary mr-2">KM Final:</span>
							{item.rod_km_fim}, {item.rod_km_fim_mt} mts
						</div>
						<div className="mb-3 col-md-4 col-sm-6">
							<span className="text-secondary mr-2">Concessionária:</span>
							{item.conc_nome}
						</div>
						<div className="mb-3 col-md-2 col-sm-6">
							<span className="text-secondary mr-2">Lote:</span>
							{item.lot_conc_desc}
						</div>
					</div>
				</div>
				<div className='mb-3 col-md-2 col-sm-12'>
					<button
						className="btn btn-danger btn-sm mr-2"
						onClick={() => handleClick(item)}
					>
						<i className="ml-1 fa fa-edit"></i>
					</button>
					<button
						className="btn btn-secondary btn-sm"
						onClick={() => handleDelete(item)}
					>
						<i className="fa fa-trash"></i>
					</button>
				</div>
			</div>
		))
	) : (
		<div className="col-12 p-3 mb-3">{data?.message}</div>
	)
}
export default ListHighway
