import React, { useContext, useEffect, useState } from 'react'
import { requestContext } from '../../../context/requestContext'
import { formContext } from '../../../context/formContext'
import { verifyLength } from '../../../services/arrayHandless'

const SelectServiceCat = ({ label }) => {
	const { servicecat } = useContext(requestContext)
	const { state: { serv_cat_cod, serv_cod }, onChangeSelectInput } = useContext(formContext)
	const [textOptionServiceCat, setTextOptionServiceCat] = useState(null)
	const [disabled, setDisable] = useState(false)
	useEffect(() => {
		if (servicecat) {
			setTextOptionServiceCat(verifyLength(servicecat))
		} else {
			setTextOptionServiceCat('Selelecione um Tipo Atividade')
		}
	}, [servicecat])
	useEffect(() => {
		const controlDisableInput = () => {
			if (!serv_cod) {
				return setDisable(true)
			}
			return setDisable(false)
		}
		controlDisableInput()
	}, [serv_cod])
	return (
		<div className="form-group">
			<label>{label || 'Grupo Atividade:'}</label>
			<div className="input-group">
				<select
					onChange={onChangeSelectInput}
					disabled={disabled}
					className={`form-control form-control-border ${!serv_cat_cod && 'is-invalid'}`}
				>
					<option>{textOptionServiceCat}</option>
					{Array.isArray(servicecat?.data) &&
						servicecat?.data.map((item) => (<option key={item.serv_cat_cod} value={JSON.stringify(item)}>{item.serv_cat_desc}
						</option>))}
				</select>
			</div>
		</div>
	)
}
export default SelectServiceCat