export const MODELS = {
	SYNC_SALES_ORDER: 'SYNC_SALES_ORDER',
	SYNC_SALES_ORDER_SERVICE: 'SYNC_SALES_ORDER_SERVICE',
	SYNC_SALES_ORDER_CHECK_INSPECTOR: 'SYNC_SALES_ORDER_CHECK_INSPECTOR',
	SALES_ORDER: 'SALES_ORDER',
	SALES_ORDER_SERVICE: 'SALES_ORDER_SERVICE',
	SALES_ORDER_CHECK_INSPECTOR: 'SALES_ORDER_CHECK_INSPECTOR',
	SALES_ORDER_PHOTOS: 'SALES_ORDER_PHOTOS',
	HIGWAY: 'HIGWAY',
	CARDINAL_POINTS: 'CARDINAL_POINTS',
	DEALERSHIP: 'DEALERSHIP',
	SERVICES: 'SERVICES',
	SERVICES_TIME_LINE: 'SERVICES_TIME_LINE',
	DEALERSHIP_LOTES: 'DEALERSHIP_LOTES',
	SERVICES_SUB_CATEGORY: 'SERVICES_SUB_CATEGORY',
	SERVICES_CATEGORY: 'SERVICES_CATEGORY',
	TYPE_HIGWAY: 'TYPE_HIGWAY',
	DEADLINE: 'DEADLINE',
	STATUS_SALES_ORDER: 'STATUS_SALES_ORDER',
	COMPLEMENTS: 'COMPLEMENTS',
	MENU: 'MENU'
}