import React, { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { styleButtonSearch } from '../../../css/globlalStyle'
import { handleWaring } from '../../../services/isValid'
import { filter, filterAndRemoveRepeatedByProps } from '../../../services/arrayHandless'
const SelectConcUserLevel = ({ col }) => {
	const [ concs, setConcs ] = useState([])
	const { state, handleForm, onChange } = useContext(formContext)
	const {
		limithigway,
		setInfoRequest,
		userLimithigway
	} = useContext(requestContext)
	const { selected_conc, selected_concs, usu_gp, usu_emp } = state

	useEffect(()=>{
		handleForm({
			'selected_concs':'',
			'selected_conc':''
		})
	},[])

	useEffect(() => {

		if(usu_emp&&usu_gp){
			if (limithigway?.data?.length > 0) {
			
				let tempArr = [...limithigway.data]
	
				if (usu_gp) {
					const data = filter(tempArr, 'lim_gp', usu_gp)
					tempArr = data?.data
				}
				
				if (usu_emp) {
					const data = filter(tempArr, 'lim_emp', usu_emp)
					tempArr = data?.data
				}

				if(userLimithigway?.data?.length>0){
					const tempUserLimits = userLimithigway.data
					tempArr = tempArr.filter(conc => !tempUserLimits.some(limit => limit.conc_cod === conc.lim_conc))
				}
				
				setConcs(filterAndRemoveRepeatedByProps({ array: tempArr, props: 'conc_nome' }))
				
			}
		}		
		
	}, [usu_emp, usu_gp, userLimithigway])

	const handleDelete = (item) => {
		const { conc_nome, conc_cod } = item
		const confirm = window.confirm(`Deseja mesmo remover a concessionária ${conc_nome}?`)
		if (!confirm) {
			return
		}
		const tempConcInfo = selected_concs.filter(temp=>temp.conc_cod!==conc_cod)

		handleForm({
			'selected_concs':tempConcInfo
		})
	}

	const handleAdd = () => {

		const exists = selected_concs?.length>0?selected_concs?.filter(item=>item.conc_cod === selected_conc):false
		
		if(exists?.length>0){
			return setInfoRequest(
				handleWaring('Você já adicionou essa concessionária')
			)
		}

		const tempConcInfo = concs.find(item=>item.lim_conc==selected_conc)

		const tempSelected = [...selected_concs,{'conc_cod':selected_conc, 'conc_nome':tempConcInfo.conc_nome}]
		handleForm({
			'selected_concs':tempSelected
		})
		
	}

	return (
		<div className={col || 'form-group col-md-12 col-sm-12'}>
			<label>Concessionárias fiscalizadas:</label>
			<div className="input-group">
				<select
					name="selected_conc"
					value={selected_conc}
					onChange={onChange}
					className={'form-control form-control-border'}
					disabled={!usu_emp || !usu_gp}
				>
					<option value={null}>Selecionar</option>
					{Array.isArray(concs) &&
						concs.map((item) => (
							<option key={item.lim_conc} value={item.lim_conc}>
								{item.conc_nome}
							</option>
						))}
				</select>
				<div className="input-group-prepend">
					<span
						className="input-group-text bg-danger"
						style={styleButtonSearch}
						onClick={handleAdd}
					>
						<i className="fa fa-plus" aria-hidden="true"></i>
					</span>
				</div>
			</div>
			<br />
			<label>Concessionárias adicionadas:</label>
			{Array.isArray(selected_concs) &&
						selected_concs.map((item) => (
							<div key={item.lim_conc} className='row mt-1'>
								<div className='col-11'>
									<div className='row'>
										<div className='col-md-12'>
											<span className='text-secondary'>Concessionária: </span>
											{item.conc_nome}
										</div>
									</div>
								</div>
								<div className='col-1' onClick={() => handleDelete(item)}>
									<div className='btn btn-danger btn-sm'>
										<i className='fa fa-trash'></i>
									</div>
								</div>
							</div>
							
						))}
			
		</div>
	)
}
export default SelectConcUserLevel