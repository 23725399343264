import React, { useContext, useEffect } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
const SelectTypeAttachmentConstructionPlan = () => {
    const { contract_Attachment } = useContext(requestContext)
    const { onChange, state, handleForm } = useContext(formContext)
    const {
        tip_ane_cod,
    } = state
    useEffect(() => {
        const controllHandleForm = () => {
            handleForm({
                ane_pl_ob_tipo: tip_ane_cod
            })
        }
        controllHandleForm()
    }, [tip_ane_cod])
    return (
        <div className="form-group col-md-6 col-sm-12">
            <label>Tipo de anexo:</label>
            <select
                onChange={onChange}
                name='tip_ane_cod' value={tip_ane_cod}
                className={`form-control form-control-border ${!tip_ane_cod && 'is-invalid'}`}
            >
                <option>Selecionar</option>
                {contract_Attachment?.total > 0 &&
                    contract_Attachment.data.map(item => (
                        <option key={item.tip_ane_cod} value={item.tip_ane_cod}>
                            {item.tip_ane_desc}
                        </option>
                    ))
                }
            </select>
        </div>
    )
}
export default SelectTypeAttachmentConstructionPlan