import React, { useEffect, useState } from 'react'
import { formatDate } from '../../../services/dateHandless'
const ListHelp = ({ data }) => {
	const [items, setItems] = useState(null)
	useEffect(() => {
		if (data?.total > 0) {
			setItems(data.data)
		} else {
			setItems(null)
		}
	}, [data])
	if (!Array.isArray(items)) {
		return <></>
	}
	return (
		items.map(item => (
			<div className="row p-3">
				<div className="col-12">
					<span className="text-secondary">Sua dúvida ou sugestão:</span>
				</div>
				<div className="col-12">
					{item.help_desc}
				</div>
				<div className="col-12">
					<span className="text-secondary">Resposta:</span>
				</div>
				<div className="col-12">
					{item.help_desc_response}
				</div>
				<div className="col-12">
					<span className="text-secondary">Dúvida ou sugestão lançada no dia: </span>
					{formatDate(item.created_at)}
				</div>
			</div>
		))
	)
}
export default ListHelp