/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { requestContext } from '../../../context/requestContext'
import { cleanArray, filterAndRemove, filterAndRemoveRepeatedByProps } from '../../../services/arrayHandless'
import { formContext } from '../../../context/formContext'
import SimpleModal from '../../modal/simple'
import { changeUnit, formatCurrency, searchSomeWordInString } from '../../../services/stringHandless'
import FormServiceConstructionPlan from '../../form/serviceConstructionPlan'
import { generateBse64, importExcelToArray } from '../../../services/files'
import { handleWaring, handleSuccess, isValid } from '../../../services/isValid'
import ImageItem from '../img'
import returnPosition from '../../../services/geolocation'
import { allowedXlsExtensions, allowedImageExtensions } from '../../../constants'
import { recoverCache, storageCache } from '../../../services/cacheManager'
import ServiceAdvancement from '../../modal/serviceAdvancement'
const ListServiceConstructionPlan = ({
	data,
	totalByGroup,
	labelGroup,
	group,
	totalValor,
	reload,
	setReload,
}) => {
	const {
		oversight,
		setOversight,
		deleteItemProgressConstructionPlan,
		postItemProgressConstructionPlan,
		postItemOversightPhotos,
		setInfoRequest,
		getItemCalcMemorySpreadSheet
	} = useContext(requestContext)

	const inputref = useRef(null)
	const { state: { prog_ob_por_cen_progress, pl_ob_ped_venda, pl_ob_cod, pl_ob_desc_object, pvs_cod, pvs_loc_foto_legenda, }, handleForm } = useContext(formContext)
	const [sumSubGroup, setSumSubGroup] = useState(0)
	const [dataPlan, setDataPlan] = useState(null)
	const [file, setFile] = useState({
		base64: '',
		pvs_cod: '',
		fileName: '',
	})
	const [modalProperties, setModalProperties] = useState({
		modalWidth: 50,
		modalHeight: 'auto',
		modalTitle: 'Dar avanço',
		modalChild: ''
	})
	const [newValue, setNewValue] = useState(0)
	const [enableEdit, setEnableEdit] = useState(true)
	const input = useRef()
	useEffect(() => {
		const controllEnableEdit = () => {
			if (searchSomeWordInString(String(pl_ob_ped_venda), [','])) {
				return setEnableEdit(false)
			}
			return setEnableEdit(true)
		}
		controllEnableEdit()
	}, [pl_ob_ped_venda])
	useEffect(() => {
		const loadData = () => {
			if (Array.isArray(data)) {
				setDataPlan(filterAndRemoveRepeatedByProps({
					array: data,
					props: 'prog_serv_cod'
				}))
			}
		}
		loadData()
	}, [data])

	const [show, setShow] = useState(false)
	const [loading, setLoading] = useState(false)
	useEffect(() => {
		const controlShowToast = () => {
			if (loading) {
				return setInfoRequest(handleWaring('Baixando planilha, aguarde'))
			}
		}
		controlShowToast()
	}, [loading])

	useEffect(() => {
		if (data) {
			setDataPlan(data)
		}
	}, [data])
	useEffect(() => {
		if (Array.isArray(dataPlan)) {
			setSumSubGroup(dataPlan.reduce((a, b) => a + (b['serv_sub_cat_valor'] * b['serv_sub_cat_qtd']), 0))
		}
	}, [dataPlan, group, totalByGroup])
	const handleSaveProgress = async (item) => {

		try {
			const value = inputref.current.value
			if (value <= item?.prog_serv_qtd_acul) {
				return setInfoRequest(handleWaring('O valor deve ser maior que ' + item?.prog_serv_qtd_acul))
			}
			if (value > item?.serv_sub_cat_qtd) {
				return setInfoRequest(handleWaring('O valor deve ser menor que ' + item?.serv_sub_cat_qtd))
			}
			const novo_por_cen = (value * item?.pvs_valor) * 100 / totalValor
			const { data } = filterAndRemove(dataPlan, 'id', item.id)
			setDataPlan([...data, {
				...item,
				prog_serv_qtd_acul: parseInt(value),
				prog_serv_por_cen_total: novo_por_cen,
				prog_serv_reais_total: parseInt(value) * item?.pvs_valor,
			}])

			const rightNow = Date.now()
			const paylaod = {
				id: item?.id,
				prog_serv_por_cen_total: novo_por_cen,
				pvs_pedidovenda: item?.pvs_pedidovenda,
				prog_serv_qtd_acul: parseInt(value),
				prog_serv_reais_total: parseInt(value) * item?.pvs_valor,
				updated_at: rightNow
			}
			const calc_memory = recoverCache(`calc_memory_${pl_ob_cod}`)
			if (calc_memory?.isXls) {
				paylaod.ob_attachments = {
					...calc_memory
				}
			}
			await postItemProgressConstructionPlan({ ...paylaod })
			setReload(!reload)
			setShow(false)
		} catch (e) {
			console.log('error', e)
		}
	}
	const handleDowloadCalcMemorySpreedSheet = async (item) => {
		const { prog_serv_cod, serv_sub_cat_desc } = item
		if (!prog_serv_cod) {
			return setInfoRequest(handleWaring(`Não foi possível obter o código do serviço ${serv_sub_cat_desc}`))
		}
		setLoading(true)
		await getItemCalcMemorySpreadSheet(prog_serv_cod)
		setLoading(false)
	}
	const readXlsCalcMemory = async (rows, base64_) => {
		const clean_rows = cleanArray(rows)
		let row_exec_per = 0
		for (const clean_row of clean_rows) {
			for (const row of clean_row) {
				if (row === 'Acum. Atual') {
					row_exec_per = clean_row.find(item => typeof item === 'number')
					break
				}
			}
		}
		if (row_exec_per === 0) {
			return setInfoRequest(handleWaring('O valor informado é menor ou igual a 0'))
		}
		if (inputref?.current) {
			inputref.current.value = row_exec_per
			const paylaod = {
				pl_ob_cod,
				ob_attachments: [{
					ane_pl_ob_obra_cod: pl_ob_cod,
					ane_pl_ob_path: base64_,
					ane_pl_ob_obs: `Planilha Memória de Calculo referente a atividade ${pvs_loc_foto_legenda}.`,
				}]
			}
			storageCache(`calc_memory_${pl_ob_cod}`, {
				id: null,
				...paylaod,
				isXls: true
			})
		}
	}
	const handleImportCalcMemorySpreedSheet = async ({ files, name }) => {
		const fileExtension = name.split('.').pop().toLowerCase()
		if (allowedXlsExtensions.includes(fileExtension)) {
			const base64_ = await generateBse64(files[0])
			const xlsrows = await importExcelToArray(files[0])
			setInfoRequest(handleSuccess('Planilha selecionada com sucesso'))
			readXlsCalcMemory(xlsrows, base64_, name)
		}
	}
	const handleOpenModal = (item) => {
		let tempValue = item?.prog_serv_qtd_acul
		handleForm({
			...item,
			pl_ob_cod: item.prog_serv_obra
		})
		const child = <ServiceAdvancement
			file={file}
			handleAddMedia={handleAddMedia}
			handleDowloadCalcMemorySpreedSheet={handleDowloadCalcMemorySpreedSheet}
			handleSaveProgress={handleSaveProgress}
			inputref={inputref}
			item={item}
			pl_ob_cod={pl_ob_cod}
			tempValue={tempValue}
		/>
		setModalProperties({ ...modalProperties, modalChild: child })
		setShow(true)

	}


	const handleRemove = (item) => {
		if (item.prog_serv_cod) {
			return handleDelete(item)
		}
		const { data } = filterAndRemove(oversight, 'id', item.id)
		if (data?.length > 0) {
			setOversight(data)
		} else {
			setOversight(null)
		}
	}
	const handleDelete = (item) => {
		const { prog_serv_cod, pvs_pedidovenda, serv_sub_cat_desc, serv_desc } = item
		const confirm = window.confirm(`Deseja mesmo remover o serviço ${serv_sub_cat_desc} de ${serv_desc}`)
		if (!confirm) {
			return
		}
		deleteItemProgressConstructionPlan({
			id: prog_serv_cod,
			pvs_pedidovenda,
		})

		setReload(!reload)

	}
	const handleEdit = (item) => {
		setShow(true)
		handleForm(item)
		setModalProperties({
			...modalProperties, modalTitle: `Obra N° ${pl_ob_cod} | ${pl_ob_desc_object}`,
			modalWidth: 'auto',
			modalChild: <FormServiceConstructionPlan setShow={setShow} reload={reload} setReload={setReload} setModalProperties={setModalProperties} />,
		})
	}
	const handleAddMedia = (item) => {
		if (pvs_cod && file?.base64) {
			const confirm = window.confirm(`Já existe uma foto pendente para envio (Foto referente a atividade: ${pvs_loc_foto_legenda}) . Se você confirmar essa foto pendente será discartada.`)
			if (!confirm) {
				return
			}
		}
		setFile({
			base64: '',
			legend: '',
			fileName: '',
		})
		const { serv_sub_cat_desc, pvs_pedidovenda, prog_serv_serv_cod } = item
		if (input?.current) {
			handleForm({
				pvs_cod: prog_serv_serv_cod,
				pvs_loc_foto_legenda: serv_sub_cat_desc,
				pvs_pedidovenda,
			})
			return input.current.click()
		}
	}
	const onChangeFile = async ({ target }) => {
		const { files } = target
		if (files.length === 0) {
			return setFile({
				fileName: '',
				base64: '',
			})
		}
		const { name } = files[0]
		const fileExtension = name.split('.').pop().toLowerCase()
		const isValidFileExtension = allowedXlsExtensions.find(item => item === fileExtension)
		if (isValidFileExtension) {
			if (files.length > 1) {
				setFile({
					base64: '',
					fileName: ''
				})
				return setInfoRequest(handleWaring('Você só pode selecionar uma planilha por plano de obras criado'))
			}
			return handleImportCalcMemorySpreedSheet({ files, name })
		}
		if (allowedImageExtensions.includes(fileExtension)) {
			const attachments = []
			for (let index = 0; index < files.length; index++) {
				const element = files[index];
				const base64_ = await generateBse64(element)
				const { name } = element
				attachments.push({
					base64: base64_,
					fileName: name,
					legend: `Foto referente a atividade ${pvs_loc_foto_legenda}.`
				})
			}
			return setFile(attachments)
		}
		setFile({
			base64: '',
			fileName: ''
		})
		return setInfoRequest(handleWaring(`O arquivo ${name} não é válido`))
	}
	const handleSubmitMedia = async () => {
		const position = await returnPosition()
		if (!Array.isArray(file)) {
			return setInfoRequest(handleWaring("Não há itens para serem anexados"))
		}
		for (const row of file) {
			const payload = {
				pvs_loc_foto_base_64: row.base64,
				pvs_loc_foto_pedidovenda: pvs_cod,
				pvs_loc_foto_lat: position.lat || 0.0,
				pvs_loc_foto_lng: position.lng || 0.0,
				pvs_loc_foto_legenda: row.legend,
			}
			const valid = isValid(payload)
			if (!valid) {
				return setInfoRequest(handleWaring())
			}
			postItemOversightPhotos({
				...payload,
				reload: false
			})
		}
		setFile({
			base64: '',
			fileName: '',
			pvs_cod: ''
		})
		setReload(!reload)
	}
	const ReturnValueDisciplinaItem = () => {
		const value = (sumSubGroup * 100 / totalValor)
		return (
			<>
				<p>Valor total do Disciplina item R$ {parseFloat(sumSubGroup)?.toFixed(2)} ({`${parseFloat(value)?.toFixed(2)}% da obra total`})</p>
				<div className="progress">
					<div
						className={`progress-bar progress-bar-striped progress-bar-animated ${parseFloat(value)?.toFixed(2) === 100 ? 'bg-danger' : 'bg-dark'}`}
						role="progressbar"
						aria-valuenow={Number(parseFloat(value)?.toFixed(2))}
						aria-valuemin="0"
						aria-valuemax="100"
						style={{
							width: `${value}%`,
						}}
					>
						{parseFloat(value)?.toFixed(2)}%
					</div>
				</div>
			</>
		)
	}
	const calcProgressInPerc = (item) => {
		let multiplic = item?.serv_sub_cat_valor * item?.serv_sub_cat_qtd * 100
		if (pl_ob_cod) {
			multiplic = item.pvs_valor * item.prog_serv_qtd_acul * 100
		}
		const divisor = multiplic / totalValor
		const result = parseFloat(divisor || 0).toFixed(2)
		return result
	}
	const RenderEditButtons = ({ item }) => {
		if (!pl_ob_cod) {
			return (
				<div className='col-md-1 col-sm-12'>
					<div
						className="btn btn-secondary btn-sm"
						onClick={() => handleRemove(item)}
					>
						<i className="fa fa-trash"></i>
					</div>
				</div>
			)
		}
		const calcProg = calcProgressInPerc(item)
		if ((calcProg === item.prog_serv_por_cen_total) || (item?.serv_sub_cat_qtd === item.prog_serv_qtd_acul)) {
			if (pl_ob_cod) {
				return (
					<div className="col-md-3 col-sm-2"></div>
				)
			}
		}
		return (
			<div className="col-md-3 col-sm-12">
				<div className='row mb-3'>
					{prog_ob_por_cen_progress < 100 && pl_ob_cod && (
						<div
							className="btn btn-danger btn-sm mr-2"
							onClick={() => handleEdit(item)}
						>
							<i className="fa fa-edit"></i>
						</div>
					)}
					{enableEdit && (
						<div
							className="btn btn-danger btn-sm mr-2"
							onClick={() => handleOpenModal(item, newValue, setNewValue)}
						>
							Avanço
						</div>
					)}
					<SimpleModal
						visible={show}
						setVisible={setShow}
						properties={modalProperties}
					/>
					{enableEdit && (
						<div
							className="btn btn-secondary btn-sm"
							onClick={() => handleRemove(item)}
						>
							<i className="fa fa-trash"></i>
						</div>
					)}
				</div>
				{!Array.isArray(file) && (pl_ob_cod && enableEdit) && (
					<div className='btn btn-secondary btn-sm' onClick={() => handleAddMedia(item)}>
						Adicionar foto ou vídeo <i className="fa fa-camera" aria-hidden="true"></i>
					</div>
				)}
				{Array.isArray(file) && (pvs_cod === item.prog_serv_serv_cod) && pl_ob_cod && (
					<div className='btn btn-secondary btn-sm' onClick={handleSubmitMedia}>
						Enviar o(s) arquivo(s) selecionado(s) <i className="fa fa-paper-plane" aria-hidden="true"></i>
					</div>
				)}
			</div>
		)
	}
	return Array.isArray(dataPlan) ? (
		<>
			<div className="row mb-3 sticky-top bg-danger">
				<div className={`${pl_ob_cod ? 'col-md-9 col-sm-8 mb-3' : 'col-md-11 col-sm-12'}`}>
					<div className="row text-secondary">
						<div className="col-md-3 col-sm-3 text-bold text-light">Descrição</div>
						<div className="col-md-2 col-sm-3 text-bold text-light">Qtd. Prev</div>
						<div className="col-md-2 col-sm-3 text-bold text-light">Qtd. Exec</div>
						<div className="col-md-2 col-sm-3 text-bold text-light">Progresso</div>
						<div className="col-md-2 col-sm-3 text-bold text-light">Acumulado</div>
					</div>
				</div>
				<div className={`${pl_ob_cod ? 'col-md-3 col-sm-12' : 'col-md-1 col-sm-12'}`}></div>
			</div>
			{dataPlan.map((item) => (
				<div className="row mb-3" key={item.id}>
					{pl_ob_cod ? (
						<div className="col-md-9 col-sm-8 mb-3">
							<div className="row">
								<div className="col-md-3 col-sm-3">
									{item.serv_sub_cat_desc}
								</div>
								<div className="col-md-2 col-sm-3">
									{formatCurrency(item.pvs_qtd, false)} {changeUnit(item?.ob_serv_med_desc)}
								</div>
								<div className='col-md-2 col-sm-3'>
									{formatCurrency(item.prog_serv_qtd_acul, false)} {changeUnit(item?.ob_serv_med_desc)}
								</div>
								<div className="col-md-2 col-sm-3 text-light">
									{item.prog_serv_percen_progress} %
								</div>
								<div className="col-md-2 col-sm-3 text-light">
									{formatCurrency(Math.abs(item.prog_serv_percen_acul), false)}%
								</div>
							</div>
						</div>
					) : (
						<div className="col-md-11 col-sm-8 mb-3">
							<div className="row">
								<div className="col-md-3 col-sm-3">
									{item.serv_sub_cat_desc}
								</div>
								<div className="col-md-3 col-sm-3">
									{formatCurrency(item?.serv_sub_cat_qtd, false)} ({calcProgressInPerc(item)}%)
								</div>
								<div className="col-md-3 col-sm-3">
									0.0
								</div>
								<div className="col-md-3 col-sm-3">
									0.0
								</div>
							</div>
						</div>
					)}
					<RenderEditButtons item={item} />
					{Array.isArray(file) && pl_ob_cod && (pvs_cod === item.prog_serv_serv_cod) && (
						<div className='row'>
							{file.map((row, index) => (
								<div className='col-md-6 col-sm-4' key={index}>
									<ImageItem src={row.base64} data={file} legend={row.legend} />
								</div>
							))}
						</div>
					)}
				</div>
			))}
			{totalByGroup && group && (
				<div className="row mb-3">
					<div className="col-md-11 col-sm-10 mb-3">
						<div className="row">
							<div className="col-md-5 col-sm-6 text-secondary">{labelGroup}</div>
							<div className="col-12 mb-3 mt-3 text-secondary text-bold">
								<ReturnValueDisciplinaItem />
							</div>
						</div>
					</div>
					<div className="col-md-1 col-sm-2"></div>
					<hr className="w-100 col-12" />
				</div>
			)}
			<input
				name="file_attachments"
				className="d-none"
				type="file"
				onChange={onChangeFile}
				ref={input}
				multiple
			/>
		</>
	) : (
		''
	)
}
export default ListServiceConstructionPlan
