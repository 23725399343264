import React, { useContext, useEffect } from 'react'
import { formContext } from '../../../context/formContext'
import FilterSingleOversight from '../filters/singleOversight'
import Selectservice from '../selectservice'
import { requestContext } from '../../../context/requestContext'
import SelectGroups from '../selectGroups'
import SelectServiceCat from '../selectServiceCat'
import SelectServiceSubCat from '../selectServiceSubCat'
const FormReport = ({ onSubmit }) => {
	const { state, onChange, onChangeNumber, handleForm } = useContext(formContext)
	const {
		setReport,
		setServicecat,
		setServiceSubcat,
		getAllItemCategoryService,
		getAllItemServiceSubCat
	} = useContext(requestContext)
	const {
		serv_cod,
		serv_cat_cod,
		serv_cat_prazo_from,
		serv_cat_prazo_to,
		rod_km_ini_from,
		rod_km_ini_to
	} = state
	useEffect(() => {
		const loadServiceCat = () => {
			if (serv_cod) {
				getAllItemCategoryService(serv_cod)
			}
		}
		loadServiceCat()
	}, [serv_cod])
	useEffect(() => {
		const loadServiceSubCat = () => {
			if (serv_cat_cod) {
				handleForm({
					serv_sub_cat_serv_cat: serv_cat_cod,
					serv_cat_prazo: '',
				})
				getAllItemServiceSubCat(serv_cat_cod)
			}
		}
		loadServiceSubCat()
	}, [serv_cat_cod])
	const resetValues = () => {
		window.scrollTo(0, 0)
		setReport(null)
		setServicecat(null)
		setServiceSubcat(null)
		handleForm({
			conc_cod: '',
			rod_cod: '',
			rod_sent_cod: '',
			serv_cod: '',
			serv_cat_cod: '',
			serv_sub_cat_cod: '',
			serv_cat_prazo_from: '',
			serv_cat_prazo_to: '',
			rod_km_ini_from: '',
			rod_km_ini_to: '',
			ped_venda_status: '',
			gp_cod: '',
			conc_nome: '',
			rod_desc: '',
		})
	}
	return (
		<div className="row">
			<FilterSingleOversight />
			<div className="col-md-6 col-sm-12">
				<Selectservice label={'Tipo Atividade:'} />
			</div>
			<div className='col-md-6 col-sm-12'>
				<SelectServiceCat />
			</div>
			<div className='col-12'>
				<SelectServiceSubCat />
			</div>
			<div className="form-group col-md-6 col-sm-6 mb-3">
				<label>Data constatação inicial:</label>
				<div className="input-group">
					<input
						onChange={onChange}
						type="date"
						value={serv_cat_prazo_from}
						name="serv_cat_prazo_from"
						className={'form-control form-control-border'}
						placeholder="Selecionar Serviço"
					/>
				</div>
			</div>
			<div className="form-group col-md-6 col-sm-6 mb-3">
				<label>Data constatação final:</label>
				<div className="input-group">
					<input
						type="date"
						onChange={onChange}
						value={serv_cat_prazo_to}
						name="serv_cat_prazo_to"
						className={'form-control form-control-border'}
						placeholder="Selecionar Serviço"
					/>
				</div>
			</div>
			<div className="form-group mb-3 col-md-4 col-sm-6">
				<label>Do KM:</label>
				<input
					type="number"
					onChange={onChangeNumber}
					value={rod_km_ini_from}
					min={0}
					max={rod_km_ini_to}
					name="rod_km_ini_from"
					id="rod_km_ini"
					className={'form-control form-control-border'}
					placeholder="Km"
				/>
			</div>
			<div className="form-group mb-3 col-md-4 col-sm-6">
				<label>Até o KM:</label>
				<input
					type="number"
					onChange={onChangeNumber}
					value={rod_km_ini_to}
					name="rod_km_ini_to"
					id="rod_km_ini"
					min={rod_km_ini_from}
					max={100000}
					className={'form-control form-control-border'}
					placeholder="Km"
				/>
			</div>
			<SelectGroups col={'mb-3 mb-3 col-md-4 col-sm-12'} />
			<div className='col-12'>
				<div className='row'>
					<div className="mb-3 col-md-3 col-sm-6">
						<button className="btn btn-danger mr-3" onClick={onSubmit}>
							Aplicar filtro <i className="fa fa-filter"></i>
						</button>
					</div>
					<div className="mb-3 col-md-3 col-sm-6">
						<button className="btn btn-dark mr-3" onClick={resetValues}>
							Limpar filtros <i className="fa fa-eraser"></i>
						</button>
					</div>

				</div>
			</div>
		</div>
	)
}
export default FormReport