import React from "react";
const FormPavementShowTables = ({
    seeMonitoring,
    setSeeMonitoring,
    seeItems,
    setSeeItems
}) => {
    return (
        <div className='row p-3'>
            <div className="col-md-6 col-sm-6">
                <div className="form-group">
                    <div className="custom-control custom-switch custom-switch-off-secondary custom-switch-on-danger">
                        <input type="checkbox" className="custom-control-input" id="see_items"
                            checked={seeItems} onChange={() => {
                                setSeeItems(!seeItems)
                                setSeeMonitoring(false)
                            }}
                        />
                        <label className="custom-control-label" htmlFor="see_items">Ver tabela de seguimentos</label>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-sm-6">
                <div className="form-group">
                    <div className="custom-control custom-switch custom-switch-off-secondary custom-switch-on-danger">
                        <input type="checkbox" className="custom-control-input" id="see_monitoring"
                            checked={seeMonitoring} onChange={() => {
                                setSeeMonitoring(!seeMonitoring)
                                setSeeItems(false)
                            }}
                        />
                        <label className="custom-control-label" htmlFor="see_monitoring">Ver tabela de monitoramento</label>
                    </div>
                </div>
            </div>
        </div>)
}
export default FormPavementShowTables