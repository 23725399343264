import React, { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { handleWaring, handleSuccess } from '../../../services/isValid'
import { requestContext } from '../../../context/requestContext'
import { useLocation } from 'react-router-dom'
import { namesScreens } from '../../../constants'
const HighwayExtension = ({ valuesFixed }) => {
	const { onChangeNumber, onBlurValidMinMax, state, handleForm } = useContext(formContext)
	const { rod_km_fim, rod_km_fim_mt, rod_km_ini, rod_km_ini_mt, rod_desc } = state
	const { setInfoRequest } = useContext(requestContext)
	const [disabled, setDisabled] = useState(false)
	const location = useLocation()
	useEffect(() => {
		const controllDisabledInputs = () => {
			const { pathname } = location
			if (pathname === namesScreens.REPORT_CONSTRUCTION_PLAN) {
				return setDisabled(true)
			}
			return setDisabled(false)
		}
		controllDisabledInputs()
	}, [location])
	const onBlur = ({ target }) => {
		const { name } = target
		const init_rod_ref =
			valuesFixed.rod_km_ini * 1000 + valuesFixed.rod_km_ini_mt
		const fim_rod_ref =
			valuesFixed.rod_km_fim * 1000 + valuesFixed.rod_km_fim_mt
		const init_rod_preenchido = rod_km_ini * 1000 + rod_km_ini_mt
		const fim_rod_preenchido = rod_km_fim * 1000 + rod_km_fim_mt
		if (name === 'rod_km_ini_mt') {
			if (
				init_rod_preenchido >= init_rod_ref &&
				init_rod_preenchido <= fim_rod_ref
			) {
				setInfoRequest(handleSuccess('Extensão inciail preenchida corretamente'))
			} else {
				setInfoRequest(
					handleWaring('Extensão inforada está diferente da cadtrada na rodovia')
				)
				handleForm({
					rod_km_ini_mt: '',
				})
			}
		} else {
			if (
				fim_rod_preenchido >= init_rod_ref &&
				fim_rod_preenchido <= fim_rod_ref
			) {
				setInfoRequest(handleSuccess('Extensão final preenchida corretamente'))

			} else {
				setInfoRequest(
					handleWaring('Extensão inforada está diferente da cadtrada na rodovia')
				)
				handleForm({
					rod_km_fim_mt: '',
				})
			}
		}
		if (init_rod_preenchido > fim_rod_preenchido) {
			setInfoRequest(handleWaring('Posição inicial maior do que a final'))
		}
	}
	return (
		<>
			{valuesFixed && (
				<>
					<div className="form-group col-12">
						<label className="text-secondary">
							Dados atuais da rodovia {rod_desc}
						</label>
					</div>
					<div className="form-group col-md-3 col-6">
						<label>Rodovia KM inicial:</label>
						<div className="form-control form-control-border">
							{valuesFixed?.rod_km_ini}
						</div>
					</div>
					<div className="form-group col-md-3 col-6">
						<label>Rodovia mts inicial:</label>
						<div className="form-control form-control-border">
							{valuesFixed?.rod_km_ini_mt}
						</div>
					</div>
					<div className="form-group col-md-3 col-6">
						<label>Rodovia KM final:</label>
						<div className="form-control form-control-border">
							{valuesFixed?.rod_km_fim}
						</div>
					</div>
					<div className="form-group col-md-3 col-6">
						<label>Rodovia mts final:</label>
						<div className="form-control form-control-border">
							{valuesFixed?.rod_km_fim_mt}
						</div>
					</div>
				</>
			)}
			<div className="form-group col-12">
				<label className="text-secondary">Sua localização</label>
			</div>
			<div className="form-group col-md-3 col-6">
				<label>+ Rodovia KM inicial:</label>
				<input
					type="number"
					onChange={onChangeNumber}
					onBlur={onBlurValidMinMax}
					name="rod_km_ini"
					id="rod_km_ini"
					value={rod_km_ini}
					min={valuesFixed?.rod_km_ini || 0}
					max={valuesFixed?.rod_km_fim || 0}
					className={`form-control form-control-border ${rod_km_ini === '' && 'is-invalid'}`}
					placeholder="Km"
					disabled={disabled}
				/>
			</div>
			<div className="form-group col-md-3 col-6">
				<label>+ Rodovia mts inicial:</label>
				<input
					type="number"
					onChange={onChangeNumber}
					name="rod_km_ini_mt"
					value={rod_km_ini_mt}
					min={0}
					max={999}
					className={`form-control form-control-border ${rod_km_ini_mt === '' && 'is-invalid'}`}
					placeholder="m"
					disabled={disabled}
				/>
			</div>
			<div className="form-group col-md-3 col-6">
				<label>+ Rodovia KM final:</label>
				<input
					type="number"
					onChange={onChangeNumber}
					onBlur={onBlurValidMinMax}
					name="rod_km_fim"
					value={rod_km_fim}
					min={valuesFixed?.rod_km_ini || 0}
					max={valuesFixed?.rod_km_fim || 0}
					className={`form-control form-control-border ${rod_km_fim === '' && 'is-invalid'}`}
					placeholder="Km"
					disabled={disabled}
				/>
			</div>
			<div className="form-group col-md-3 col-6">
				<label>+ Rodovia mts final:</label>
				<input
					type="number"
					onChange={onChangeNumber}
					onBlur={onBlur}
					name="rod_km_fim_mt"
					value={rod_km_fim_mt}
					min={0}
					max={999}
					className={`form-control form-control-border ${rod_km_fim_mt === '' && 'is-invalid'}`}
					placeholder="m"
					disabled={disabled}
				/>
			</div>
		</>
	)
}
export default HighwayExtension