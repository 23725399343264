import { LOCAL_URL } from './local-config'
import img_background from '../assets/Artesp/images/img_background.jpg'
import logo from '../assets/Artesp/images/logo.png'
import Package from '../../package.json'
import markerPrimary from '../assets/icons/marker_#007bff.png'
import markerWarning from '../assets/icons/marker_#ffc107.png'
import markerSuccess from '../assets/icons/marker_#28a745.png'
import markerDanger from '../assets/icons/marker_#dc3545.png'
export const FLAVOR = 'Artesep SGI-DIN'
export const URL_TESTE = 'https://dswebtestapis.ddns.net'
export const URL_HOMOL = ''
export const URL_PRODUCTION = 'https://www.dswebapis.com.br'
export const URL_DEVELOP = LOCAL_URL
export const IMG_BACKGROUND = img_background
export const LOGO = logo
export const VERSION = Package.version
export const OFFLINE_POST = true
export const CONTACT = '5519999705447'
export const URL_FILE = 'https://artesp-production.s3.amazonaws.com'
export const URL_FILE_TESTE = 'https://artesp-test.s3.amazonaws.com'
export const URL_FILE_HOMOL = 'https://artesp-sisf.s3.amazonaws.com'
export const URL_FILE_DEVELOP = `${LOCAL_URL}/storage`
export const DOWNLOAD_APK_URL =
  'https://drive.google.com/file/d/1IWdAZBHRcI8bqT9o6Ylq0t0Biv8jks-x/view?usp=share_link'
export const MARKER_ICONS = [
	{
		path: markerPrimary,
		name: 'marker_#007bff',
	},
	{
		path: markerWarning,
		name: 'marker_#ffc107',
	},
	{
		path: markerSuccess,
		name: 'marker_#28a745',
	},
	{
		path: markerDanger,
		name: 'marker_#dc3545',
	},
]
export const CAMERA_CONFIG = {
	constraints: {
		audio: false,
		video: {
			width: 1024,
			height: 720,
			facingMode: 'environment',
		},
	},
}
export const PDF_CONFIG = {
	orientation: 'landscape',
	unit: 'pt',
	format: 'A4',
	options: {
		filename: new Date().toString(),
	},
}
