import React, { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../../context/formContext'
import Dealership from '../../dealership'
import { LoadMaxDate, loadMinDate } from '../../../../services/dateHandless'
import SelectHighway from '../../selectHighway'
import { requestContext } from '../../../../context/requestContext'

const FormFilterPavementsIndicators = ({ min, max, label, filterByKm, onSubmit }) => {
	const { state, onChange } = useContext(formContext)
	const { getAllItemHighway, getAllItemDealership } = useContext(requestContext)
	const { serv_cat_prazo_from, serv_cat_prazo_to, conc_cod, rod_km_ini_from, rod_km_ini_to } = state
	const [minDate, setMinDate] = useState(null)
	const [maxDate, setMaxDate] = useState(null)
	useEffect(() => {
		if (min) {
			loadMinDate(setMinDate)
		}
	}, [min])
	useEffect(() => {
		if (max) {
			LoadMaxDate(setMaxDate)
		}
	}, [max])
	useEffect(() => {
		getAllItemDealership()
	}, [])
	useEffect(() => {
		if (conc_cod) {
			getAllItemHighway(conc_cod)
		}
	}, [conc_cod])
	const handleSubmit = () => {
		if (onSubmit) {
			onSubmit()
		}
	}
	return (
		<div className="row">
			<div className="col-md-6 col-sm-12">
				<Dealership />
			</div>
			<div className="col-md-6 col-sm-12  mb-3">
				<SelectHighway />
			</div>
			<div className="form-group col-md-6 col-sm-6">
				<label>Da data ({label || 'avaliação'}):</label>
				{minDate ? (
					<div className="input-group">
						<input type="date"
							onChange={onChange}
							name='serv_cat_prazo_from' value={serv_cat_prazo_from}
							max={serv_cat_prazo_to || maxDate}
							min={minDate}
							className={`form-control form-control-border ${!serv_cat_prazo_from && 'is-invalid'}`}
						/>
					</div>
				) : (
					<div className="input-group">
						<input type="date"
							onChange={onChange}
							name='serv_cat_prazo_from' value={serv_cat_prazo_from}
							max={serv_cat_prazo_to}
							className={`form-control form-control-border ${!serv_cat_prazo_from && 'is-invalid'}`}
						/>
					</div>
				)}
			</div>
			<div className="form-group col-md-6 col-sm-6">
				<label>Até a data ({label || 'avaliação'}):</label>
				{maxDate ? (
					<div className="input-group">
						<input type="date"
							onChange={onChange}
							name='serv_cat_prazo_to' value={serv_cat_prazo_to}
							min={serv_cat_prazo_from || minDate}
							max={maxDate}
							className={`form-control form-control-border ${!serv_cat_prazo_to && 'is-invalid'}`}
						/>
					</div>
				) : (
					<div className="input-group">
						<input type="date"
							onChange={onChange}
							name='serv_cat_prazo_to' value={serv_cat_prazo_to}
							min={serv_cat_prazo_from}
							className={`form-control form-control-border ${!serv_cat_prazo_to && 'is-invalid'}`}
						/>
					</div>
				)}
			</div>
			{filterByKm ? (
				<>
					<div className="form-group col-md-6 col-sm-6">
						<label>Do KM inicial:</label>
						<div className="input-group">
							<input
								type="number"
								placeholder='0'
								min={0}
								onChange={onChange}
								name='rod_km_ini_from' value={rod_km_ini_from}
								className={`form-control form-control-border ${!rod_km_ini_from && 'is-invalid'}`}
							/>
						</div>
					</div>
					<div className="form-group col-md-6 col-sm-6">
						<label>Até o KM inicial:</label>
						<div className="input-group">
							<input
								type="number"
								placeholder='0'
								min={0}
								onChange={onChange}
								name='rod_km_ini_to' value={rod_km_ini_to}
								className={`form-control form-control-border ${!rod_km_ini_to && 'is-invalid'}`}
							/>
						</div>
					</div>
				</>
			) : (
				''
			)}
			<div className='col-12'>
				<button onClick={handleSubmit} className='btn btn-danger'>
					<i className="fa fa-filter" aria-hidden="true"></i> Filtrar
				</button>
			</div>
		</div>
	)
}
export default FormFilterPavementsIndicators