/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { requestContext } from "../../context/requestContext";
import ContentHeader from "../../components/contentHeader";
import ListComplements from "../../components/list/complements";
import Spinner from "../../components/spinner";
import { formContext } from "../../context/formContext";
import { authContext } from "../../context/authContext";
import SearchComplements from "../../components/form/searchComplements";
const ManagerComplements = () => {
  const { getItemComplements, getItemWhereComplements, complements } = useContext(requestContext);
  const { handleForm, state: { comp_descricao } } = useContext(formContext)
  const { user } = useContext(authContext)
  const navigate = useNavigate()
  useEffect(() => {
    getItemComplements();
  }, []);
  const handlePage = (item) => {
    if (!item) {
      return
    }
    const { label } = item
    const { current_page, last_page } = complements
    window.scrollTo(0, 0);
    if (label === 'pagination.previous') {
      return comp_descricao ? getItemWhereComplements(current_page > 1 && `${comp_descricao}?page=${current_page - 1}`) : getItemComplements(current_page > 1 && `0?page=${current_page - 1}`)
    }
    if (label === 'pagination.next') {
      return comp_descricao ? getItemWhereComplements(current_page > 1 && `${comp_descricao}?page=${current_page - 1}`) : getItemComplements(current_page < last_page && `0?page=${current_page + 1}`)
    }
    if (!label) {
      return comp_descricao ? getItemWhereComplements(comp_descricao) : getItemComplements();
    }
    if (comp_descricao) {
      return getItemWhereComplements(`${comp_descricao}?page=${label}`)
    }
    getItemComplements(`0?page=${label}`);
  };
  const handleNew = () => {
    handleForm({
      comp_cod: "",
      comp_descricao: ''
    })
    navigate('/complements/new')
  }
  return (
    <>
      <ContentHeader title={"Cadastro de Complementos"} />
      <div className="content">
        <div className="card sticky-top">
          <div className="card-header">
            <h3 className="card-title col-8 d-none d-md-block">
              Complementos cadastrados
            </h3>
            <div className="card-tools">
              <SearchComplements handleNew={handleNew} />
            </div>
          </div>
        </div>
        <div className="card">
          <Spinner direction={"center"} label="carregando" />
          <ListComplements data={complements?.data} />
          <div className="card-footer clearfix">
            <ul className="pagination pagination-sm m-0 float-right">
              {Array.isArray(complements?.links) &&
                complements.links.map((item, i) => (
                  <li className="page-item" key={item.label}>
                    <Link
                      className={`page-link ${item.active && "text-light bg-danger"
                        }`}
                      to="#"
                      onClick={() => handlePage(item)}
                    >
                      {item.label === "pagination.previous" ? (
                        <i className="fa fa-angle-left"></i>
                      ) : item.label === "pagination.next" ? (
                        <i className="fa fa-angle-right"></i>
                      ) : (
                        item.label
                      )}
                    </Link>
                  </li>
                ))}
            </ul>
            {user && user.set_nivel === 1 && (
              <ul className="pagination pagination-sm m-0 float-left">
                <li className="page-item">
                  <div className="page-link" onClick={handleNew}>
                    Novo <i className="fa fa-plus mr-1 ml-1"></i>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default ManagerComplements;
